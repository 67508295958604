angular
  .module('CfcCharity.recordachpayment', [
    'ui.router',
    'CfcCharity.security',
    'hateoas'
  ])
  .config([
    '$stateProvider',
    '$urlRouterProvider',
    'HateoasInterceptorProvider',
    'USER_ROLES',
    'SYSTEM_USER_SECONDARY_ROLES',
    function myAppConfig(
      $stateProvider,
      $urlRouterProvider,
      HateoasInterceptorProvider,
      USER_ROLES,
      SYSTEM_USER_SECONDARY_ROLES
    ) {
      $stateProvider
        .state('charityapplicationachpayment', {
          url: '/charityapplicationachpayment',
          views: {
            main: {
              template: require('../views/private/admin/recordachpayment.html'),
              controller: 'RecordAchIndAppPaymentCtrl'
            }
          },
          data: {
            pageTitle: 'Record ACH Payment',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.system_user],
            authorizedSecondaryRoles: [
              SYSTEM_USER_SECONDARY_ROLES.customer_service_manager,
              SYSTEM_USER_SECONDARY_ROLES.customer_service_tier_2_finance,
              SYSTEM_USER_SECONDARY_ROLES.gb_finance_admin,
              SYSTEM_USER_SECONDARY_ROLES.opm_admin
            ]
          },
          params: {
            campaignId: null,
            applicationId: null,
            cfcCode: null,
            charityName: null,
            applicationFee: null
          }
        })
        .state('federationapplicationachpayment', {
          url: '/federationapplicationachpayment',
          views: {
            main: {
              template: require('../views/private/admin/recordachpayment.html'),
              controller: 'RecordAchFedAppPaymentCtrl'
            }
          },
          data: {
            pageTitle: 'Record ACH Federation Application Payment',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.system_user],
            authorizedSecondaryRoles: [
              SYSTEM_USER_SECONDARY_ROLES.customer_service_manager,
              SYSTEM_USER_SECONDARY_ROLES.customer_service_tier_2_finance,
              SYSTEM_USER_SECONDARY_ROLES.gb_finance_admin,
              SYSTEM_USER_SECONDARY_ROLES.opm_admin
            ]
          },
          params: {
            cfcCode: null,
            federationName: null,
            applicationId: null,
            totalMemberApplicationsFee: null,
            campaignId: null,
            federationApplicationFee: null
          }
        })
        .state('charitylistingachpayment', {
          url: '/charitylistingachpayment',
          views: {
            main: {
              template: require('../views/private/admin/recordachpayment.html'),
              controller: 'AchPaymentIndListingCtrl'
            }
          },
          data: {
            pageTitle: 'Record ACH Independent Listing Payment',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.system_user],
            authorizedSecondaryRoles: [
              SYSTEM_USER_SECONDARY_ROLES.customer_service_manager,
              SYSTEM_USER_SECONDARY_ROLES.customer_service_tier_2_finance,
              SYSTEM_USER_SECONDARY_ROLES.gb_finance_admin,
              SYSTEM_USER_SECONDARY_ROLES.opm_admin
            ]
          },
          params: {
            cfcCode: null,
            charityName: null,
            listingId: null,
            campaignId: null
          },
          resolve: {
            charityListingFeeResponse: function($http, $stateParams) {
              if ($stateParams.listingId) {
                var charityListingId = $stateParams.listingId;
                var url =
                  '/CfcCharity/api/private/users/charitylisting/feedetails/' +
                  charityListingId;
                return $http.get(url).then(function(res) {
                  return res.data;
                });
              }
            }
          }
        })
        .state('federationlistingachpayment', {
          url: '/federationlistingachpayment',
          views: {
            main: {
              template: require('../views/private/admin/recordachpayment.html'),
              controller: 'RecordAchFedListingPaymentCtrl'
            }
          },
          data: {
            pageTitle: 'Record ACH Federation Listing Payment',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.system_user],
            authorizedSecondaryRoles: [
              SYSTEM_USER_SECONDARY_ROLES.customer_service_manager,
              SYSTEM_USER_SECONDARY_ROLES.customer_service_tier_2_finance,
              SYSTEM_USER_SECONDARY_ROLES.gb_finance_admin,
              SYSTEM_USER_SECONDARY_ROLES.opm_auditor
            ]
          },
          params: {
            cfcCode: null,
            listingId: null,
            federationId: null,
            federationName: null,
            totalMemberListingFee: null,
            campaignId: null,
            listingIdList: null
          }
        })
        .state('federationappachpaymentcharityuser', {
          url: '/federationappachpaymentcharityuser',
          views: {
            main: {
              template: require('../views/private/federation/fedachpaymentcharityuser.html'),
              controller: 'FedAppAchPaymentCharityUser'
            }
          },
          data: {
            pageTitle: 'ACH Federation Application Payment',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.charity_user]
          },
          params: {
            cfcCode: null,
            federationName: null,
            applicationId: null,
            totalMemberApplications: null,
            campaignId: null,
            memberApplicationFee: null,
            federationApplicationFee: null
          }
        })
        .state('federationlistingachpaymentcharityuser', {
          url: '/federationlistingachpaymentcharityuser',
          views: {
            main: {
              template: require('../views/private/federation/fedachpaymentcharityuser.html'),
              controller: 'FedListingAchPaymentCharityUser'
            }
          },
          data: {
            pageTitle: 'ACH Federation Listing Payment',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.charity_user]
          },
          params: {
            cfcCode: null,
            federationName: null,
            listingId: null,
            federationId: null,
            totalMemberListings: null,
            campaignId: null,
            listingIdList: null
          }
        });
    }
  ])
  .service('AchService', [
    '$http',
    'CubeSvc',
    function($http, CubeSvc) {
      return {
        getCampaignFee: function(campaignId) {
          return $http.get(
            '/CfcCharity/api/private/admin/getCampaignFee/' + campaignId
          );
        },
        recordIndependentAchPayment: function(achPaymentBean) {
          return $http.post(
            '/CfcCharity/api/private/admin/recordIndependentAchPayment',
            achPaymentBean
          );
        },
        recordFederationAchPayment: function(achPaymentBean) {
          return $http.post(
            '/CfcCharity/api/private/admin/recordFederationAchPayment',
            achPaymentBean
          );
        },
        getApplicationStep2Details: function(applicationId) {
          return CubeSvc.get(
            '/CfcCharity/api/private/application/step2/' + applicationId,
            null,
            false
          );
        }
      };
    }
  ])
  .controller('RecordAchIndAppPaymentCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    '$locale',
    '$http',
    'BrowserEventService',
    'AchService',
    'ApplicationService',
    '$filter',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      $locale,
      $http,
      BrowserEventService,
      AchService,
      ApplicationService,
      $filter
    ) {
      $scope.setModuleName('CfcCharity.recordachpayment');
      $scope.indAppAchPayment = true;
      $scope.achPaymentBean = {};
      var charityType = {};
      $scope.successPayment = {};
      var campaignId = $state.params.campaignId;
      var applicationId = $state.params.applicationId;
      $scope.cfcCode = $state.params.cfcCode;
      $scope.name = $state.params.charityName;
      $scope.achPaymentBean.transactionType = 'Choose type:';
      $scope.achPaymentBean.transactionNumber = '';
      $scope.achPaymentBean.paymentDate = '';
      $scope.campaignFee = {};
      var applicationFee = $state.params.applicationFee;
      if (applicationId && campaignId) {
        AchService.getApplicationStep2Details(applicationId).then(function(
          res
        ) {
          var result = res.data.value;
          if (result && result.charityType) {
            charityType = result.charityType;
            AchService.getCampaignFee(campaignId).then(function(resp) {
              var result = resp.data;
              $scope.appIndFee = applicationFee;
              if (result !== null && result !== '') {
                $scope.achIndPercent =
                  result.independentAchProcessingFeePercentage;
                $scope.achIndPercentAmt =
                  (parseFloat($scope.appIndFee) *
                    parseFloat($scope.achIndPercent)) /
                  100;
                $scope.achPaymentBean.transactionAmount =
                  parseFloat($scope.appIndFee) +
                  parseFloat($scope.achIndPercentAmt);
              }
            });
          }
        });
      }

      $scope.showErrorMessage = function(error) {
        $scope.achPaymentError = error;
      };
      $scope.cancelClicked = function() {
        $state.go('applicationPayment', {
          charityApplicationId: applicationId
        });
      };
      $scope.gotoDashboard = function() {
        $('.modal-backdrop').remove();
        $state.go('applicationDashboard', {
          applicationId: applicationId
        });
      };
      $scope.gotoPaymentReceipt = function() {
        $('.modal-backdrop').remove();
        $state.go('paymentreceipt', {
          userId: $scope.successPayment.userId,
          id: $scope.successPayment.id,
          type: $scope.successPayment.type,
          feeType: $scope.successPayment.feeType,
          paymentMethod: $scope.successPayment.paymentMethod,
          year: $scope.successPayment.year
        });
      };
      $scope.recordingPayment = false;
      $scope.recordPayment = function() {
        if ($scope.achPaymentBean.transactionType === 'Choose type:') {
          $scope.showErrorMessage('Please select Transaction Type');
          return;
        }
        if ($scope.achPaymentBean.transactionNumber === '') {
          $scope.showErrorMessage('Please enter Transaction number');
          return;
        }
        if ($scope.achPaymentBean.paymentDate === '') {
          $scope.showErrorMessage('Please select payment date');
          return;
        }
        var hasNonalphas = /\W/.test($scope.achPaymentBean.transactionNumber);
        if (hasNonalphas) {
          $scope.showErrorMessage(
            'Only Alphanumerics are allowed for Transaction Number'
          );
          return;
        }
        var datePattern = /^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}$/.test(
          $scope.achPaymentBean.paymentDate
        );
        if (!datePattern) {
          $scope.showErrorMessage(
            'Please enter valid payment date, The date format should be MM/dd/yyyy'
          );
          return;
        }
        $scope.achPaymentBean.applicationId = applicationId;
        $scope.achPaymentBean.campaignId = campaignId;
        $scope.achPaymentBean.cfcCode = $scope.cfcCode;
        $scope.achPaymentBean.user = $scope.getCurrentUser();
        $scope.achPaymentBean.paymentDate = new Date(
          $scope.achPaymentBean.paymentDate
        );
        $scope.achPaymentBean.feeType = 'APPLICATION';
        $scope.achPaymentBean.feeAmount = $scope.appIndFee;
        $scope.achPaymentBean.procFeeAmount = $scope.achIndPercentAmt;
        $scope.recordingPayment = true;
        AchService.recordIndependentAchPayment($scope.achPaymentBean)
          .then(function(res) {
            var result = res.data;
            var msg = result.message.split(',');
            $scope.successPayment.id = msg[1].trim();
            $scope.successPayment.feeType = 'Application';
            $scope.successPayment.type = 'INDEPENDENT';
            $scope.successPayment.userId = $scope.achPaymentBean.user.id;
            $scope.successPayment.paymentMethod = 'ACH Push';
            $scope.successPayment.year = $scope.achPaymentBean.paymentDate.getFullYear();
            $scope.achPaymentError = '';
            if (result.statusCode !== 'IND_APP_ACH_PAYMENT_DONE') {
              $scope.achPaymentBean.paymentDate = $filter('date')(
                $scope.achPaymentBean.paymentDate,
                'MM/dd/yyyy'
              );
              $scope.successMsg =
                'This payment has been received and this application is submitted for review.';
              $('#achpaymentthankyou').modal();
            } else {
              //if (result.statusCode === 'IND_APP_ACH_PAYMENT_DONE') {
              $scope.showErrorMessage(
                'ACH Payment is already made for this application'
              );
              $scope.achPaymentBean.paymentDate = $filter('date')(
                $scope.achPaymentBean.paymentDate,
                'MM/dd/yyyy'
              );
            }
          })
          .catch(function(err) {
            $scope.achPaymentBean.paymentDate = $filter('date')(
              $scope.achPaymentBean.paymentDate,
              'MM/dd/yyyy'
            );
            var error =
              'Error while adding record ACH Payment. Please try again';
          })
          .finally(function() {
            $scope.recordingPayment = false;
          });
      };
    }
  ])
  .controller('RecordAchFedAppPaymentCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    '$locale',
    '$http',
    'BrowserEventService',
    'AchService',
    'FederationApplicationStep2Service',
    '$filter',
    'CubeSvc',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      $locale,
      $http,
      BrowserEventService,
      AchService,
      FederationApplicationStep2Service,
      $filter,
      CubeSvc
    ) {
      $scope.setModuleName('CfcCharity.recordachpayment');
      $scope.fedAppAchPayment = true;
      $scope.achPaymentBean = {};
      var charityType = {};
      var campaignId = $state.params.campaignId;
      var applicationId = $state.params.applicationId;
      $scope.cfcCode = $state.params.cfcCode;
      $scope.name = $state.params.federationName;
      var totalMemberApplicationsFee = $state.params.totalMemberApplicationsFee;
      var totalMemberApplications = BrowserEventService.getTotalMemberApplications();
      $scope.achPaymentBean.transactionType = 'Choose type:';
      $scope.achPaymentBean.transactionNumber = '';
      $scope.achPaymentBean.paymentDate = '';
      $scope.campaignFee = {};
      $scope.successPayment = {};
      var federationApplicationFee = $state.params.federationApplicationFee;
      $scope.showErrorMessage = function(error) {
        $scope.achPaymentError = error;
      };
      if (applicationId && campaignId) {
        FederationApplicationStep2Service.federationApplicationStep2Details(
          applicationId
        ).then(function(res) {
          var result = res.data.value;
          if (result && result.charityType) {
            charityType = result.charityType;
            AchService.getCampaignFee(campaignId).then(function(resp) {
              var result = resp.data;
              if (result !== null && result !== '') {
                $scope.totalMemberApplicationsFee = totalMemberApplicationsFee;
                $scope.federationApplicationFee = federationApplicationFee;
                $scope.achFedAppPercent =
                  result.federationAchProcessingFeePercentage;
                $scope.achMemberAppPercent =
                  result.memberAchProcessingFeePercentage;
                $scope.totalAppFeePlusMemFee =
                  parseFloat($scope.totalMemberApplicationsFee) +
                  parseFloat($scope.federationApplicationFee);
                $scope.achFedAppPercentAmt =
                  (parseFloat($scope.federationApplicationFee) *
                    parseFloat($scope.achFedAppPercent)) /
                  100;
                $scope.achMemberAppPercentAmt =
                  (parseFloat($scope.totalMemberApplicationsFee) *
                    parseFloat($scope.achMemberAppPercent)) /
                  100;
                $scope.achPaymentBean.transactionAmount =
                  parseFloat($scope.totalAppFeePlusMemFee) +
                  parseFloat($scope.achFedAppPercentAmt);
              }
            });
          }
        });
      }
      $scope.cancelClicked = function() {
        $state.go('federationapplicationfee', {
          federationId: BrowserEventService.getFederationId()
        });
      };
      $scope.gotoDashboard = function() {
        $('.modal-backdrop').remove();
        CubeSvc.get(
          '/CfcCharity/api/private/federation/' + applicationId,
          null,
          false
        )
          .then(function(response) {
            $state.go('federationapplication', {
              year: response.data.value.pledgeYear,
              cfcCode: $scope.cfcCode,
              fedAppId: applicationId
            });
          })
          .catch(function() {
            $state.go('federationapplication');
          });
      };
      $scope.gotoPaymentReceipt = function() {
        $('.modal-backdrop').remove();
        $state.go('paymentreceipt', {
          userId: $scope.successPayment.userId,
          id: $scope.successPayment.id,
          type: $scope.successPayment.type,
          feeType: $scope.successPayment.feeType,
          paymentMethod: $scope.successPayment.paymentMethod,
          year: $scope.successPayment.year
        });
      };
      $scope.submittingPayment = false;
      $scope.recordPayment = function() {
        if ($scope.achPaymentBean.transactionType === 'Choose type:') {
          $scope.showErrorMessage('Please select Transaction Type');
          return;
        }
        if ($scope.achPaymentBean.transactionNumber === '') {
          $scope.showErrorMessage('Please enter Transaction number');
          return;
        }
        if ($scope.achPaymentBean.paymentDate === '') {
          $scope.showErrorMessage('Please select payment date');
          return;
        }
        var datePattern = /^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}$/.test(
          $scope.achPaymentBean.paymentDate
        );
        if (!datePattern) {
          $scope.showErrorMessage(
            'Please enter valid payment date, The date format should be MM/dd/yyyy'
          );
          return;
        }
        var hasNonalphas = /\W/.test($scope.achPaymentBean.transactionNumber);
        if (hasNonalphas) {
          $scope.showErrorMessage(
            'Only Alphanumerics are allowed for Transaction Number'
          );
          return;
        }
        $scope.achPaymentBean.applicationId = applicationId;
        $scope.achPaymentBean.campaignId = campaignId;
        $scope.achPaymentBean.cfcCode = $scope.cfcCode;
        $scope.achPaymentBean.user = $scope.getCurrentUser();
        $scope.achPaymentBean.paymentDate = new Date(
          $scope.achPaymentBean.paymentDate
        );
        $scope.achPaymentBean.feeType = 'APPLICATION';
        $scope.achPaymentBean.feeAmount = $scope.totalAppFeePlusMemFee;
        $scope.achPaymentBean.procFeeAmount = $scope.achFedAppPercentAmt;
        $scope.achPaymentBean.memberFee = $scope.totalMemberApplicationsFee;
        $scope.achPaymentBean.federationApplicationFee =
          $scope.federationApplicationFee;
        $scope.achPaymentBean.noOfMembers = totalMemberApplications;
        $scope.achPaymentBean.processingFee =
          $scope.achFedAppPercentAmt + $scope.achMemberAppPercentAmt;

        $scope.submittingPayment = true;
        AchService.recordFederationAchPayment($scope.achPaymentBean)
          .then(function(res) {
            var result = res.data;
            $scope.successPayment = result;
            var msg = result.message.split(',');
            $scope.successPayment.id = msg[1].trim();
            $scope.successPayment.feeType = 'Application';
            $scope.successPayment.type = 'FEDERATION';
            $scope.successPayment.userId = $scope.achPaymentBean.user.id;
            $scope.successPayment.paymentMethod = 'ACH Push';
            $scope.successPayment.year = $scope.achPaymentBean.paymentDate.getFullYear();
            $scope.achPaymentError = '';
            if (msg[0] === 'SAVE_SUCCESS') {
              $scope.achPaymentBean.paymentDate = $filter('date')(
                $scope.achPaymentBean.paymentDate,
                'MM/dd/yyyy'
              );
              $scope.successMsg =
                'This payment has been received and all applications are submitted for review.';
              $('#achpaymentthankyou').modal();
            } else if (result.statusCode === 'FED_APP_ACH_PAYMENT_DONE') {
              $scope.showErrorMessage(
                'ACH Payment is already made for this application'
              );
              $scope.achPaymentBean.paymentDate = $filter('date')(
                $scope.achPaymentBean.paymentDate,
                'MM/dd/yyyy'
              );
            } else if (result.statusCode === 'FED_APP_FEE_ACH_PAYMENT_DONE') {
              $scope.showErrorMessage(
                'Federation Application Fee already exists for this application'
              );
              $scope.achPaymentBean.paymentDate = $filter('date')(
                $scope.achPaymentBean.paymentDate,
                'MM/dd/yyyy'
              );
            }
          })
          .finally(function() {
            $scope.submittingPayment = false;
          })
          .catch(function(err) {
            $scope.achPaymentBean.paymentDate = $filter('date')(
              $scope.achPaymentBean.paymentDate,
              'MM/dd/yyyy'
            );
            var error =
              'Error while adding record ACH Payment. Please try again';
          });
      };
    }
  ])
  .controller('AchPaymentIndListingCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    '$locale',
    '$http',
    'charityListingFeeResponse',
    'BrowserEventService',
    'AchService',
    '$filter',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      $locale,
      $http,
      charityListingFeeResponse,
      BrowserEventService,
      AchService,
      $filter
    ) {
      $scope.setModuleName('CfcCharity.recordachpayment');
      $scope.indListingAchPayment = true;
      $scope.achPaymentBean = {};
      $scope.successPayment = {};
      var campaignId = $state.params.campaignId;
      var listingId = $state.params.listingId;
      $scope.cfcCode = $state.params.cfcCode;
      $scope.name = $state.params.charityName;
      $scope.achPaymentBean.transactionType = 'Choose type:';
      $scope.achPaymentBean.transactionNumber = '';
      $scope.achPaymentBean.paymentDate = '';
      $scope.campaignFee = {};
      $scope.showErrorMessage = function(error) {
        $scope.achPaymentError = error;
      };
      if (listingId) {
        $scope.indListingFee =
          charityListingFeeResponse.charityListingFeeBean.independentCharityListingFee;

        AchService.getCampaignFee(campaignId).then(function(res) {
          var result = res.data;
          if (result !== null && result !== '') {
            $scope.achFedlistingPercent =
              result.independentAchProcessingFeePercentage;
            $scope.achFedListingFee =
              (parseFloat($scope.indListingFee) *
                parseFloat($scope.achFedlistingPercent)) /
              100;
            $scope.achPaymentBean.transactionAmount =
              parseFloat($scope.indListingFee) +
              parseFloat($scope.achFedListingFee);
          }
        });
      }
      $scope.cancelClicked = function() {
        $state.go('charitylistingpayment', {
          charityListingId: listingId
        });
      };
      $scope.gotoDashboard = function() {
        $('.modal-backdrop').remove();
        $state.go('charitylisting', {
          charityDashboardCfcCode: $scope.cfcCode,
          pledgeYear: BrowserEventService.getPledgeYear(),
          appType: BrowserEventService.getAppType()
        });
      };
      $scope.gotoPaymentReceipt = function() {
        $('.modal-backdrop').remove();
        $state.go('paymentreceipt', {
          userId: $scope.successPayment.userId,
          id: $scope.successPayment.id,
          type: $scope.successPayment.type,
          feeType: $scope.successPayment.feeType,
          paymentMethod: $scope.successPayment.paymentMethod,
          year: $scope.successPayment.year
        });
      };
      $scope.submittingPayment = false;
      $scope.recordPayment = function() {
        if ($scope.achPaymentBean.transactionType === 'Choose type:') {
          $scope.showErrorMessage('Please select Transaction Type');
          return;
        }
        if ($scope.achPaymentBean.transactionNumber === '') {
          $scope.showErrorMessage('Please enter Transaction number');
          return;
        }
        if ($scope.achPaymentBean.paymentDate === '') {
          $scope.showErrorMessage('Please select payment date');
          return;
        }
        var hasNonalphas = /\W/.test($scope.achPaymentBean.transactionNumber);
        if (hasNonalphas) {
          $scope.showErrorMessage(
            'Only Alphanumerics are allowed for Transaction Number'
          );
          return;
        }
        var datePattern = /^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}$/.test(
          $scope.achPaymentBean.paymentDate
        );
        if (!datePattern) {
          $scope.showErrorMessage(
            'Please enter valid payment date, The date format should be MM/dd/yyyy'
          );
          return;
        }
        $scope.achPaymentBean.listingId = listingId;
        $scope.achPaymentBean.campaignId = campaignId;
        $scope.achPaymentBean.cfcCode = $scope.cfcCode;
        $scope.achPaymentBean.user = $scope.getCurrentUser();
        $scope.achPaymentBean.paymentDate = new Date(
          $scope.achPaymentBean.paymentDate
        );
        $scope.achPaymentBean.feeType = 'LISTING';
        $scope.achPaymentBean.feeAmount = $scope.indListingFee;
        $scope.achPaymentBean.procFeeAmount = $scope.achFedListingFee;
        $scope.submittingPayment = false;
        AchService.recordIndependentAchPayment($scope.achPaymentBean)
          .then(function(res) {
            var result = res.data;
            var msg = result.message.split(',');
            if (msg.length > 1) {
              $scope.successPayment.id = msg[1].trim();
            }
            $scope.successPayment.feeType = 'Listing';
            $scope.successPayment.type = 'INDEPENDENT';
            $scope.successPayment.userId = $scope.achPaymentBean.user.id;
            $scope.successPayment.paymentMethod = 'ACH Push';
            $scope.successPayment.year = $scope.achPaymentBean.paymentDate.getFullYear();

            $scope.achPaymentError = '';
            if (result.statusCode === 'SAVE_SUCCESS') {
              $scope.achPaymentBean.paymentDate = $filter('date')(
                $scope.achPaymentBean.paymentDate,
                'MM/dd/yyyy'
              );
              $scope.successMsg =
                'This payment has been received and this listing is submitted.';
              $('#achpaymentthankyou').modal();
            } else if (result.statusCode === 'IND_LISTING_ACH_PAYMENT_DONE') {
              $scope.showErrorMessage(
                'ACH Payment is already made for this listing'
              );
              $scope.achPaymentBean.paymentDate = $filter('date')(
                $scope.achPaymentBean.paymentDate,
                'MM/dd/yyyy'
              );
            }
          })
          .catch(function(err) {
            $scope.achPaymentBean.paymentDate = $filter('date')(
              $scope.achPaymentBean.paymentDate,
              'MM/dd/yyyy'
            );
            var error =
              'Error while adding record ACH Payment. Please try again';
          });
      };
    }
  ])
  .controller('RecordAchFedListingPaymentCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    '$locale',
    '$http',
    'BrowserEventService',
    'AchService',
    '$filter',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      $locale,
      $http,
      BrowserEventService,
      AchService,
      $filter
    ) {
      $scope.setModuleName('CfcCharity.recordachpayment');
      $scope.fedListingAchPayment = true;
      $scope.achPaymentBean = {};
      var campaignId = $state.params.campaignId;
      var listingId = $state.params.listingId;
      var federationId = $state.params.federationId;
      $scope.cfcCode = $state.params.cfcCode;
      $scope.name = $state.params.federationName;
      var totalMemberListingFee = $state.params.totalMemberListingFee;
      var listingIdList = $state.params.listingIdList;
      $scope.achPaymentBean.transactionType = 'Choose type:';
      $scope.achPaymentBean.transactionNumber = '';
      $scope.achPaymentBean.paymentDate = '';
      $scope.campaignFee = {};
      $scope.showErrorMessage = function(error) {
        $scope.achPaymentError = error;
      };
      if (campaignId) {
        AchService.getCampaignFee(campaignId).then(function(res) {
          var result = res.data;
          if (result !== null && result !== '') {
            $scope.totalMemberListingFee = totalMemberListingFee;
            $scope.federationListingFee = result.federationListingFee;
            $scope.achFedListingPercent =
              result.federationAchProcessingFeePercentage;
            $scope.totalListingFeePlusMemberFee =
              parseFloat($scope.totalMemberListingFee) +
              parseFloat($scope.federationListingFee);
            $scope.achFedListingFee =
              (parseFloat($scope.totalListingFeePlusMemberFee) *
                parseFloat($scope.achFedListingPercent)) /
              100;
            $scope.achPaymentBean.transactionAmount =
              parseFloat($scope.totalListingFeePlusMemberFee) +
              parseFloat($scope.achFedListingFee);
          }
        });
      }
      $scope.cancelClicked = function() {
        $state.go('federationlistingfee', {
          cfcCode: $scope.cfcCode,
          pledgeYear: BrowserEventService.getPledgeYear()
        });
      };
      $scope.gotoDashboard = function() {
        $('.modal-backdrop').remove();
        $state.go('federationlisting', {
          charityDashboardCfcCode: $scope.cfcCode,
          pledgeYear: BrowserEventService.getPledgeYear()
        });
      };
      $scope.submittingPayment = false;
      $scope.recordPayment = function() {
        if ($scope.achPaymentBean.transactionType === 'Choose type:') {
          $scope.showErrorMessage('Please select Transaction Type');
          return;
        }
        if ($scope.achPaymentBean.transactionNumber === '') {
          $scope.showErrorMessage('Please enter Transaction number');
          return;
        }
        if ($scope.achPaymentBean.paymentDate === '') {
          $scope.showErrorMessage('Please select payment date');
          return;
        }
        var hasNonalphas = /\W/.test($scope.achPaymentBean.transactionNumber);
        if (hasNonalphas) {
          $scope.showErrorMessage(
            'Only Alphanumerics are allowed for Transaction Number'
          );
          return;
        }
        var datePattern = /^[0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4}$/.test(
          $scope.achPaymentBean.paymentDate
        );
        if (!datePattern) {
          $scope.showErrorMessage(
            'Please enter valid payment date, The date format should be MM/dd/yyyy'
          );
          return;
        }
        $scope.achPaymentBean.listingId = listingId;
        $scope.achPaymentBean.federationId = federationId;
        $scope.achPaymentBean.campaignId = campaignId;
        $scope.achPaymentBean.cfcCode = $scope.cfcCode;
        $scope.achPaymentBean.user = $scope.getCurrentUser();
        $scope.achPaymentBean.paymentDate = new Date(
          $scope.achPaymentBean.paymentDate
        );
        $scope.achPaymentBean.feeType = 'LISTING';
        $scope.achPaymentBean.feeAmount = $scope.totalListingFeePlusMemberFee;
        $scope.achPaymentBean.procFeeAmount = $scope.achFedListingFee;
        $scope.achPaymentBean.listingIdList = listingIdList;
        $scope.submittingPayment = true;
        AchService.recordFederationAchPayment($scope.achPaymentBean)
          .then(function(res) {
            var result = res.data;

            $scope.achPaymentBean.paymentDate = $filter('date')(
              $scope.achPaymentBean.paymentDate,
              'MM/dd/yyyy'
            );
            $scope.achPaymentError = '';
            if (result.statusCode === 'SAVE_SUCCESS') {
              $scope.successMsg =
                'This payment has been received and all listings are submitted.';
              $('#achpaymentthankyou').modal();
            } else if (result.statusCode === 'FED_LISTING_ACH_PAYMENT_DONE') {
              $scope.showErrorMessage(
                'ACH Payment is already made for this listing'
              );
              $scope.achPaymentBean.paymentDate = $filter('date')(
                $scope.achPaymentBean.paymentDate,
                'MM/dd/yyyy'
              );
            }
          })
          .finally(function() {
            $scope.submittingPayment = false;
          })
          .catch(function(err) {
            $scope.achPaymentBean.paymentDate = $filter('date')(
              $scope.achPaymentBean.paymentDate,
              'MM/dd/yyyy'
            );
            var error =
              'Error while adding record ACH Payment. Please try again';
          });
      };
    }
  ])
  .controller('FedAppAchPaymentCharityUser', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    '$locale',
    '$http',
    'BrowserEventService',
    'AchService',
    'FederationApplicationStep2Service',
    '$filter',
    'CubeSvc',
    'FederationDashboardService',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      $locale,
      $http,
      BrowserEventService,
      AchService,
      FederationApplicationStep2Service,
      $filter,
      CubeSvc,
      FederationDashboardService
    ) {
      $scope.setModuleName('CfcCharity.recordachpayment');
      $scope.federationApplicationFee = {};
      var charityType = {};
      $scope.applicationPayment = true;
      $scope.listingPayment = false;
      var campaignId = $state.params.campaignId;
      var applicationId = $state.params.applicationId;
      $scope.cfcCode = $state.params.cfcCode;
      $scope.name = $state.params.federationName;
      var totalMemberApplications = $state.params.totalMemberApplications;
      $scope.campaignFee = {};
      $scope.successPayment = {};
      $scope.showErrorMessage = function(error) {
        $scope.achPaymentError = error;
      };
      if (applicationId && campaignId) {
        FederationApplicationStep2Service.federationApplicationStep2Details(
          applicationId
        ).then(function(res) {
          var result = res.data.value;
          if (result && result.charityType) {
            charityType = result.charityType;
            AchService.getCampaignFee(campaignId).then(function(res) {
              var result = res.data;

              if (result !== null && result !== '') {
                $scope.totalMemberApplications = totalMemberApplications;
                $scope.fedApplicationFee = BrowserEventService.getFederationApplicationFee();
                $scope.memberAppFee = BrowserEventService.getMemberApplicationFee();
                $scope.totalMemberApplicationsFee =
                  $scope.totalMemberApplications * $scope.memberAppFee;
                $scope.achFedAppPercent =
                  result.federationAchProcessingFeePercentage;
                $scope.achMemberAppPercent =
                  result.memberAchProcessingFeePercentage;
                $scope.achFedAppPercentAmt =
                  ($scope.fedApplicationFee *
                    parseFloat($scope.achFedAppPercent)) /
                  100;
                $scope.achMemberAppPercentAmt =
                  ($scope.totalMemberApplicationsFee *
                    parseFloat($scope.achMemberAppPercent)) /
                  100;
                $scope.fedAppFeeWithAchPercent =
                  $scope.fedApplicationFee + $scope.achFedAppPercentAmt;
                $scope.totalMemAppFeeWithAchPercent =
                  $scope.totalMemberApplicationsFee +
                  $scope.achMemberAppPercentAmt;
                $scope.totalAmt =
                  parseFloat($scope.fedAppFeeWithAchPercent) +
                  parseFloat($scope.totalMemAppFeeWithAchPercent);
              }
            });
          }
        });
      }

      $scope.showErrorMessage = function(error) {
        $scope.achPaymentError = error;
      };
      $scope.skipClicked = function() {
        $state.go('federationapplication', {
          year: BrowserEventService.getUserApplicationDataYear(),
          cfcCode: $scope.cfcCode,
          fedAppId: applicationId
        });
      };

      $scope.gotoDashboard = function() {
        $('.modal-backdrop').remove();
        var userId = $scope.getCurrentUser().id;
        var year = BrowserEventService.getUserApplicationDataYear();
        var cfcCode = $scope.cfcCode;
        var fedAppId = applicationId;

        if (year) {
          gotoDashboard(userId, year, cfcCode, fedAppId);
        } else {
          FederationDashboardService.getFederationApplication(
            applicationId
          ).then(function(response) {
            var campaignId = response.data.value.campaignId;
            var url =
              '/CfcCharity/api/private/admin/editcampaign/' + campaignId;
            CubeSvc.get(url).then(function(response) {
              var year = response.data.campaignPeriodBean.year;
              gotoDashboard(userId, year, cfcCode, fedAppId);
            });
          });
        }
      };

      $scope.gotoPaymentReceipt = function() {
        $('.modal-backdrop').remove();
        $state.go('paymentreceipt', {
          userId: $scope.successPayment.userId,
          id: $scope.successPayment.id,
          type: $scope.successPayment.type,
          feeType: $scope.successPayment.feeType,
          paymentMethod: $scope.successPayment.paymentMethod,
          year: $scope.successPayment.year
        });
      };

      function gotoDashboard(userId, year, cfcCode, fedAppId) {
        $state.go('federationapplication', {
          userId: userId,
          year: year,
          cfcCode: cfcCode,
          fedAppId
        });
      }
      $scope.submittingPayment = false;
      $scope.recordPayment = function() {
        $scope.fieldsMissing = [];
        if (!$scope.federationApplicationFee) {
          $scope.fieldsMissing.push('Name on Account is required.');
          $scope.fieldsMissing.push('Routing number is required.');
          $scope.fieldsMissing.push('Account number is required.');
          $scope.fieldsMissing.push('Bank Name is required.');
        } else {
          if (!$scope.federationApplicationFee.nameOnAccount) {
            $scope.fieldsMissing.push('Name on card is required.');
          }
          if (!$scope.federationApplicationFee.routingNo) {
            $scope.fieldsMissing.push('Routing Number is required.');
          }

          var accounNoDigit = /^\d+$/.test(
            $scope.federationApplicationFee.accountNo
          );
          if (!$scope.federationApplicationFee.accountNo) {
            $scope.fieldsMissing.push('Account Number is required.');
          } else if (!accounNoDigit) {
            $scope.fieldsMissing.push('Account Number must be numeric.');
          }

          var routingNineDigit = /^[0-9]{9}$/.test(
            $scope.federationApplicationFee.routingNo
          );
          if (
            ($scope.federationApplicationFee.routingNo &&
              $scope.federationApplicationFee.routingNo.length !== 9) ||
            !routingNineDigit
          ) {
            $scope.fieldsMissing.push('Routing Number should be 9 digits.');
          }
          if (!$scope.federationApplicationFee.bankName) {
            $scope.fieldsMissing.push('Bank Name is required.');
          }
        }
        if ($scope.fieldsMissing.length > 0) {
          $('#missingInfo').modal();
        } else {
          $scope.federationApplicationFee.applicationId = applicationId;
          $scope.federationApplicationFee.applicationFee = $scope.totalAmt;
          $scope.federationApplicationFee.federationApplicationFee =
            $scope.fedAppFeeWithAchPercent;
          $scope.federationApplicationFee.noOfMembers = totalMemberApplications;
          $scope.federationApplicationFee.totalMemberApplicationFee =
            $scope.totalMemAppFeeWithAchPercent;
          $scope.federationApplicationFee.cfcFee =
            $scope.fedApplicationFee + $scope.totalMemberApplicationsFee;
          $scope.federationApplicationFee.processingFee =
            $scope.achFedAppPercentAmt + $scope.achMemberAppPercentAmt;
          var federationApplicationPaymentURI =
            '/CfcCharity/api/private/users/federation/application/achpayment';
          $scope.submittingPayment = true;
          CubeSvc.post(
            federationApplicationPaymentURI,
            $scope.federationApplicationFee
          )
            .then(function(res) {
              var data = res.data;
              $scope.successPayment = data;
              var msg = data.message.split(',');
              $scope.successPayment.id = msg[1].trim();
              $scope.successPayment.feeType = 'Application';
              $scope.successPayment.type = 'FEDERATION';
              $scope.successPayment.userId = $scope.getCurrentUser().id;
              $scope.successPayment.paymentMethod = 'ACH Pull';
              $scope.successPayment.year = BrowserEventService.getUserApplicationDataYear();

              if (msg[0] === 'SAVE_SUCCESS') {
                $scope.successMsg =
                  'This payment has been received and all applications are submitted for review.';
                $('#achFedAppCharityUserpaymentthankyou').modal();
              }
            })
            .finally(function() {
              $scope.submittingPayment = false;
            })
            .catch(function(err) {
              /*Todo: Log error*/
            });
        }
      };
    }
  ])
  .controller('FedListingAchPaymentCharityUser', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    '$locale',
    '$http',
    'BrowserEventService',
    'AchService',
    '$filter',
    'CubeSvc',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      $locale,
      $http,
      BrowserEventService,
      AchService,
      $filter,
      CubeSvc
    ) {
      $scope.setModuleName('CfcCharity.recordachpayment');
      $scope.federationApplicationFee = {};
      $scope.applicationPayment = false;
      $scope.listingPayment = true;
      var campaignId = $state.params.campaignId;
      var listingId = $state.params.listingId;
      var federationId = $state.params.federationId;
      $scope.cfcCode = $state.params.cfcCode;
      $scope.name = $state.params.federationName;
      var totalMemberListings = $state.params.totalMemberListings;
      var listingIdList = $state.params.listingIdList;
      var pledgeYear = null;
      $scope.campaignFee = {};
      $scope.successPayment = {};
      $scope.showErrorMessage = function(error) {
        $scope.achPaymentError = error;
      };
      if (campaignId) {
        AchService.getCampaignFee(campaignId).then(function(res) {
          var result = res.data;
          if (result !== null && result !== '') {
            $scope.totalMemberListings = totalMemberListings;
            $scope.fedListingFee = result.federationListingFee;
            $scope.memberListingFee = result.memberListingFee;
            $scope.totalMemberListingsFee =
              $scope.totalMemberListings * $scope.memberListingFee;
            $scope.achFedAppPercent =
              result.federationAchProcessingFeePercentage;
            $scope.achMemberAppPercent =
              result.memberAchProcessingFeePercentage;
            $scope.achFedAppPercentAmt =
              ($scope.fedListingFee * parseFloat($scope.achFedAppPercent)) /
              100;
            $scope.achMemberAppPercentAmt =
              ($scope.totalMemberListingsFee *
                parseFloat($scope.achMemberAppPercent)) /
              100;
            $scope.fedListingFeeWithAchPercent =
              $scope.fedListingFee + $scope.achFedAppPercentAmt;
            $scope.totalMemListingFeeWithAchPercent =
              $scope.totalMemberListingsFee + $scope.achMemberAppPercentAmt;
            $scope.totalAmt =
              parseFloat($scope.fedListingFeeWithAchPercent) +
              parseFloat($scope.totalMemListingFeeWithAchPercent);
          }
        });
      }
      $scope.showErrorMessage = function(error) {
        $scope.achPaymentError = error;
      };
      $scope.skipClicked = function() {
        $state.go('federationlisting', {
          charityDashboardCfcCode: $scope.cfcCode,
          pledgeYear: BrowserEventService.getPledgeYear()
        });
      };
      $scope.gotoDashboard = function() {
        $('.modal-backdrop').remove();
        $state.go('federationlisting', {
          charityDashboardCfcCode: $scope.cfcCode,
          pledgeYear: BrowserEventService.getPledgeYear()
        });
      };
      $scope.gotoPaymentReceipt = function() {
        $('.modal-backdrop').remove();
        $state.go('paymentreceipt', {
          userId: $scope.successPayment.userId,
          id: $scope.successPayment.id,
          type: $scope.successPayment.type,
          feeType: $scope.successPayment.feeType,
          paymentMethod: $scope.successPayment.paymentMethod,
          year: $scope.successPayment.year
        });
      };
      $scope.submittingPayment = false;
      $scope.recordPayment = function() {
        $scope.fieldsMissing = [];
        if (!$scope.federationApplicationFee) {
          $scope.fieldsMissing.push('Name on Account is required.');
          $scope.fieldsMissing.push('Routing number is required.');
          $scope.fieldsMissing.push('Account number is required.');
          $scope.fieldsMissing.push('Bank Name is required.');
        } else {
          if (!$scope.federationApplicationFee.nameOnAccount) {
            $scope.fieldsMissing.push('Name on card is required.');
          }
          if (!$scope.federationApplicationFee.routingNo) {
            $scope.fieldsMissing.push('Routing Number is required.');
          }
          var routingNineDigit = /^[0-9]{9}$/.test(
            $scope.federationApplicationFee.routingNo
          );
          if (
            ($scope.federationApplicationFee.routingNo &&
              $scope.federationApplicationFee.routingNo.length !== 9) ||
            !routingNineDigit
          ) {
            $scope.fieldsMissing.push('Routing Number should be 9 digits.');
          }
          var accounNoDigit = /^\d+$/.test(
            $scope.federationApplicationFee.accountNo
          );
          if (!$scope.federationApplicationFee.accountNo) {
            $scope.fieldsMissing.push('Account Number is required.');
          } else if (!accounNoDigit) {
            $scope.fieldsMissing.push('Account Number must be numeric.');
          }
          if (!$scope.federationApplicationFee.bankName) {
            $scope.fieldsMissing.push('Bank Name is required.');
          }
        }
        if ($scope.fieldsMissing.length > 0) {
          $('#missingInfo').modal();
        } else {
          $scope.federationApplicationFee.listingId = listingId;
          $scope.federationApplicationFee.federationId = federationId;
          $scope.federationApplicationFee.campaignId = campaignId;
          $scope.federationApplicationFee.listingFee = $scope.totalAmt;
          $scope.federationApplicationFee.federationListingFee =
            $scope.fedListingFeeWithAchPercent;
          $scope.federationApplicationFee.noOfMembers = totalMemberListings;
          $scope.federationApplicationFee.totalMemberListingFee =
            $scope.totalMemListingFeeWithAchPercent;
          $scope.federationApplicationFee.listingIdList = listingIdList;
          $scope.federationApplicationFee.cfcFee =
            $scope.fedListingFee + $scope.totalMemberListingsFee;
          $scope.federationApplicationFee.processingFee =
            $scope.achFedAppPercentAmt + $scope.achMemberAppPercentAmt;
          var federationApplicationPaymentURI =
            '/CfcCharity/api/private/users/federation/listing/achpayment';
          $scope.submittingPayment = true;
          CubeSvc.post(
            federationApplicationPaymentURI,
            $scope.federationApplicationFee
          )
            .then(function(res) {
              var data = res.data;
              $scope.successPayment = data;
              var msg = data.message.split(',');
              $scope.successPayment.id = msg[1].trim();
              $scope.successPayment.feeType = 'Listing';
              $scope.successPayment.type = 'FEDERATION';
              $scope.successPayment.userId = $scope.getCurrentUser().id;
              $scope.successPayment.paymentMethod = 'ACH Pull';
              $scope.successPayment.year = BrowserEventService.getUserApplicationDataYear();
              if (msg[0] === 'SAVE_SUCCESS') {
                $scope.successMsg =
                  'This payment has been received and all Listings are submitted for review.';
                $('#achFedAppCharityUserpaymentthankyou').modal();
              }
            })
            .finally(function() {
              $scope.submittingPayment = false;
            })
            .catch(function(err) {
              /*Todo: Log error*/
            });
        }
      };
    }
  ]);
