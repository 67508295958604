angular
  .module('CfcCharity.application', [
    'ui.router',
    'CfcCharity.security',
    'creditCardInput',
    'ngResource',
    'ui.mask',
    'CfcCharity.common'
  ])
  .constant('DATES', {
    START_DATE: '01/01/',
    FISCAL_START_DATE: '06/30/',
    END_DATE: '12/31/'
  })
  .config([
    '$stateProvider',
    'USER_ROLES',
    function myAppConfig($stateProvider, USER_ROLES) {
      $stateProvider
        .state('createApplication', {
          url: '/createApplication',
          views: {
            main: {
              template: require('../views/private/application/createApplication.html'),
              controller: 'CreateApplicationCtrl'
            }
          },
          data: {
            pageTitle: 'Create Application Page',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.charity_user]
          }
        })
        .state('step1', {
          url: '/step1',
          views: {
            main: {
              template: require('../views/private/application/step1.html'),
              controller: 'ApplicationStep1Ctrl'
            }
          },
          data: {
            breadcrumbs: function(ApplicationService) {
              var appFedId =
                ApplicationService.getApplication().federationId || null;
              var isMember =
                (appFedId ||
                  ApplicationService.getParentFederationApplicationId()) !==
                null;
              if (isMember !== true) {
                return [
                  {
                    crumbName:
                      '%pledgeYear% #%cfcCode% Independent Application Dashboard',
                    crumbRoute: 'applicationDashboard',
                    crumbParams: '{applicationId: %applicationId%}'
                  }
                ];
              } else {
                return [
                  {
                    crumbName:
                      '%pledgeYear% #%federationCfc% Federation Package',
                    crumbRoute: 'federationapplication',
                    crumbParams:
                      '{year: %pledgeYear%, cfcCode: %federationCfc%, fedAppId: %federationId%}'
                  },
                  {
                    crumbName: 'Member Applications',
                    crumbRoute: 'memberapplication',
                    crumbParams:
                      '{userId: %userId%, year: %pledgeYear%, cfcCode: %federationCfc%}'
                  },
                  {
                    crumbName:
                      '%pledgeYear% #%cfcCode% Member Application Dashboard',
                    crumbRoute: 'applicationDashboard',
                    crumbParams: '{applicationId: %applicationId%}'
                  }
                ];
              }
            },
            pageTitle: 'Application Step1 Page',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.charity_user]
          },
          params: {
            mode: null,
            applicationId: null,
            parentFedAppId: null
          },
          resolve: {
            tokenData: function(
              $http,
              $stateParams,
              BreadcrumbTokenDataService,
              ApplicationService
            ) {
              //clearing the session storage for previously viewed items
              BreadcrumbTokenDataService.clearTokenData();
              BreadcrumbTokenDataService.setIsFederation(false);
              // set parentFedAppId in ApplicationService
              ApplicationService.setParentFederationApplicationId(
                $stateParams.parentFedAppId
              );
              var applicationId =
                $stateParams.applicationId ||
                ApplicationService.getApplicationId();
              return BreadcrumbTokenDataService.setApplicationReviewTokenData(
                applicationId
              );
            }
          }
        })
        .state('step2', {
          url: '/step2/:fromPage',
          views: {
            main: {
              template: require('../views/private/application/step2.html'),
              controller: 'ApplicationStep2Ctrl'
            }
          },
          data: {
            breadcrumbs: function(ApplicationService) {
              var appFedId =
                ApplicationService.getApplication().federationId || null;
              var isMember =
                (appFedId ||
                  ApplicationService.getParentFederationApplicationId()) !==
                null;
              if (isMember !== true) {
                return [
                  {
                    crumbName:
                      '%pledgeYear% #%cfcCode% Independent Application Dashboard',
                    crumbRoute: 'applicationDashboard',
                    crumbParams: '{applicationId: %applicationId%}'
                  }
                ];
              } else {
                return [
                  {
                    crumbName:
                      '%pledgeYear% #%federationCfc% Federation Package',
                    crumbRoute: 'federationapplication',
                    crumbParams:
                      '{year: %pledgeYear%, cfcCode: %federationCfc%, fedAppId: %federationId%}'
                  },
                  {
                    crumbName: 'Member Applications',
                    crumbRoute: 'memberapplication',
                    crumbParams:
                      '{userId: %userId%, year: %pledgeYear%, cfcCode: %federationCfc%}'
                  },
                  {
                    crumbName:
                      '%pledgeYear% #%cfcCode% Member Application Dashboard',
                    crumbRoute: 'applicationDashboard',
                    crumbParams: '{applicationId: %applicationId%}'
                  }
                ];
              }
            },
            pageTitle: 'Application Step2 Page',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.charity_user]
          },
          params: {
            mode: null,
            applicationId: null,
            parentFedAppId: null
          },
          resolve: {
            tokenData: function(
              $http,
              $stateParams,
              BreadcrumbTokenDataService,
              ApplicationService
            ) {
              // set parentFedAppId in ApplicationService
              ApplicationService.setParentFederationApplicationId(
                $stateParams.parentFedAppId
              );
              var applicationId =
                $stateParams.applicationId ||
                ApplicationService.getApplicationId();
              return BreadcrumbTokenDataService.setApplicationReviewTokenData(
                applicationId
              );
            }
          }
        })
        .state('step2Search', {
          url: '/step2Search',
          views: {
            main: {
              template: require('../views/private/application/step2Search.html'),
              controller: 'ApplicationStep2SearchCtrl'
            }
          },
          data: {
            pageTitle: 'Application Step2 Federation Search Page',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.charity_user]
          }
        })
        .state('step2SearchResults', {
          url: '/step2SearchResults/:paramName/:paramValue',
          views: {
            main: {
              template: require('../views/private/application/step2SearchResults.html'),
              controller: 'ApplicationStep2SearchResultCtrl'
            }
          },
          data: {
            pageTitle: 'Application Step2 Federation Search Results Page',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.charity_user]
          }
        })
        .state('step2a', {
          url: '/step2a',
          views: {
            main: {
              template: require('../views/private/application/step2A.html'),
              controller: 'ApplicationStep2ACtrl'
            }
          },
          data: {
            breadcrumbs: function(ApplicationService) {
              var appFedId =
                ApplicationService.getApplication().federationId || null;
              var isMember =
                (appFedId ||
                  ApplicationService.getParentFederationApplicationId()) !==
                null;
              if (isMember !== true) {
                return [
                  {
                    crumbName:
                      '%pledgeYear% #%cfcCode% Independent Application Dashboard',
                    crumbRoute: 'applicationDashboard',
                    crumbParams: '{applicationId: %applicationId%}'
                  }
                ];
              } else {
                return [
                  {
                    crumbName:
                      '%pledgeYear% #%federationCfc% Federation Package',
                    crumbRoute: 'federationapplication',
                    crumbParams:
                      '{year: %pledgeYear%, cfcCode: %federationCfc%, fedAppId: %federationId%}'
                  },
                  {
                    crumbName: 'Member Applications',
                    crumbRoute: 'memberapplication',
                    crumbParams:
                      '{userId: %userId%, year: %pledgeYear%, cfcCode: %federationCfc%}'
                  },
                  {
                    crumbName:
                      '%pledgeYear% #%cfcCode% Member Application Dashboard',
                    crumbRoute: 'applicationDashboard',
                    crumbParams: '{applicationId: %applicationId%}'
                  }
                ];
              }
            },
            pageTitle: 'Application Step2A Page',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.charity_user]
          },
          params: {
            mode: null,
            applicationId: null,
            parentFedAppId: null
          },
          resolve: {
            tokenData: function(
              $http,
              $stateParams,
              BreadcrumbTokenDataService,
              ApplicationService
            ) {
              // set parentFedAppId in ApplicationService
              ApplicationService.setParentFederationApplicationId(
                $stateParams.parentFedAppId
              );
              var applicationId =
                $stateParams.applicationId ||
                ApplicationService.getApplicationId();
              return BreadcrumbTokenDataService.setApplicationReviewTokenData(
                applicationId
              );
            }
          }
        })
        .state('step3', {
          url: '/step3',
          views: {
            main: {
              template: require('../views/private/application/step3.html'),
              controller: 'ApplicationStep3Ctrl'
            }
          },
          data: {
            breadcrumbs: function(ApplicationService) {
              var appFedId =
                ApplicationService.getApplication().federationId || null;
              var isMember =
                (appFedId ||
                  ApplicationService.getParentFederationApplicationId()) !==
                null;
              if (isMember !== true) {
                return [
                  {
                    crumbName:
                      '%pledgeYear% #%cfcCode% Independent Application Dashboard',
                    crumbRoute: 'applicationDashboard',
                    crumbParams: '{applicationId: %applicationId%}'
                  }
                ];
              } else {
                return [
                  {
                    crumbName:
                      '%pledgeYear% #%federationCfc% Federation Package',
                    crumbRoute: 'federationapplication',
                    crumbParams:
                      '{year: %pledgeYear%, cfcCode: %federationCfc%, fedAppId: %federationId%}'
                  },
                  {
                    crumbName: 'Member Applications',
                    crumbRoute: 'memberapplication',
                    crumbParams:
                      '{userId: %userId%, year: %pledgeYear%, cfcCode: %federationCfc%}'
                  },
                  {
                    crumbName:
                      '%pledgeYear% #%cfcCode% Member Application Dashboard',
                    crumbRoute: 'applicationDashboard',
                    crumbParams: '{applicationId: %applicationId%}'
                  }
                ];
              }
            },
            pageTitle: 'Application Step3 Page',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.charity_user]
          },
          params: {
            mode: null,
            applicationId: null,
            parentFedAppId: null
          },
          resolve: {
            tokenData: function(
              $http,
              $stateParams,
              BreadcrumbTokenDataService,
              ApplicationService
            ) {
              // set parentFedAppId in ApplicationService
              ApplicationService.setParentFederationApplicationId(
                $stateParams.parentFedAppId
              );
              var applicationId =
                $stateParams.applicationId ||
                ApplicationService.getApplicationId();
              return BreadcrumbTokenDataService.setApplicationReviewTokenData(
                applicationId
              );
            }
          }
        })
        .state('step4', {
          url: '/step4/:fromPage',
          views: {
            main: {
              template: require('../views/private/application/step4.html'),
              controller: 'ApplicationStep4Ctrl'
            }
          },
          data: {
            breadcrumbs: function(ApplicationService) {
              var appFedId =
                ApplicationService.getApplication().federationId || null;
              var isMember =
                (appFedId ||
                  ApplicationService.getParentFederationApplicationId()) !==
                null;
              if (isMember !== true) {
                return [
                  {
                    crumbName:
                      '%pledgeYear% #%cfcCode% Independent Application Dashboard',
                    crumbRoute: 'applicationDashboard',
                    crumbParams: '{applicationId: %applicationId%}'
                  }
                ];
              } else {
                return [
                  {
                    crumbName:
                      '%pledgeYear% #%federationCfc% Federation Package',
                    crumbRoute: 'federationapplication',
                    crumbParams:
                      '{year: %pledgeYear%, cfcCode: %federationCfc%, fedAppId: %federationId%}'
                  },
                  {
                    crumbName: 'Member Applications',
                    crumbRoute: 'memberapplication',
                    crumbParams:
                      '{userId: %userId%, year: %pledgeYear%, cfcCode: %federationCfc%}'
                  },
                  {
                    crumbName:
                      '%pledgeYear% #%cfcCode% Member Application Dashboard',
                    crumbRoute: 'applicationDashboard',
                    crumbParams: '{applicationId: %applicationId%}'
                  }
                ];
              }
            },
            pageTitle: 'Application Step4 Page',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.charity_user]
          },
          params: {
            mode: null,
            applicaationId: null,
            parentFedAppId: null
          },
          resolve: {
            tokenData: function(
              $http,
              $stateParams,
              BreadcrumbTokenDataService,
              ApplicationService
            ) {
              // set parentFedAppId in ApplicationService
              ApplicationService.setParentFederationApplicationId(
                $stateParams.parentFedAppId
              );
              var applicationId =
                $stateParams.applicationId ||
                ApplicationService.getApplicationId();
              return BreadcrumbTokenDataService.setApplicationReviewTokenData(
                applicationId
              );
            }
          }
        })
        .state('step4LocalAttachment', {
          url: '/step4LocalAttachment/:id',
          views: {
            main: {
              template: require('../views/private/application/step4LocalAttachment.html'),
              controller: 'Step4AttachmentCtrl'
            }
          },
          data: {
            breadcrumbs: function(ApplicationService) {
              var appFedId =
                ApplicationService.getApplication().federationId || null;
              var isMember =
                (appFedId ||
                  ApplicationService.getParentFederationApplicationId()) !==
                null;
              if (isMember !== true) {
                return [
                  {
                    crumbName:
                      '%pledgeYear% #%cfcCode% Independent Application Dashboard',
                    crumbRoute: 'applicationDashboard',
                    crumbParams: '{applicationId: %applicationId%}'
                  }
                ];
              } else {
                return [
                  {
                    crumbName:
                      '%pledgeYear% #%federationCfc% Federation Package',
                    crumbRoute: 'federationapplication',
                    crumbParams:
                      '{year: %pledgeYear%, cfcCode: %federationCfc%, fedAppId: %federationId%}'
                  },
                  {
                    crumbName: 'Member Applications',
                    crumbRoute: 'memberapplication',
                    crumbParams:
                      '{userId: %userId%, year: %pledgeYear%, cfcCode: %federationCfc%}'
                  },
                  {
                    crumbName:
                      '%pledgeYear% #%cfcCode% Member Application Dashboard',
                    crumbRoute: 'applicationDashboard',
                    crumbParams: '{applicationId: %applicationId%}'
                  }
                ];
              }
            },
            pageTitle: 'Application Step4 Local Attachment Page',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.charity_user]
          },
          params: {
            mode: null,
            applicationId: null,
            parentFedAppId: null
          },
          resolve: {
            tokenData: function(
              $http,
              $stateParams,
              BreadcrumbTokenDataService,
              ApplicationService
            ) {
              // set parentFedAppId in ApplicationService
              ApplicationService.setParentFederationApplicationId(
                $stateParams.parentFedAppId
              );
              var applicationId =
                $stateParams.applicationId ||
                ApplicationService.getApplicationId();
              return BreadcrumbTokenDataService.setApplicationReviewTokenData(
                applicationId
              );
            }
          }
        })
        .state('step4NationalAttachment', {
          url: '/step4NationalAttachment/:id',
          views: {
            main: {
              template: require('../views/private/application/step4NationalAttachment.html'),
              controller: 'Step4AttachmentCtrl'
            }
          },
          data: {
            breadcrumbs: function(ApplicationService) {
              var appFedId =
                ApplicationService.getApplication().federationId || null;
              var isMember =
                (appFedId ||
                  ApplicationService.getParentFederationApplicationId()) !==
                null;
              if (isMember !== true) {
                return [
                  {
                    crumbName:
                      '%pledgeYear% #%cfcCode% Independent Application Dashboard',
                    crumbRoute: 'applicationDashboard',
                    crumbParams: '{applicationId: %applicationId%}'
                  }
                ];
              } else {
                return [
                  {
                    crumbName:
                      '%pledgeYear% #%federationCfc% Federation Package',
                    crumbRoute: 'federationapplication',
                    crumbParams:
                      '{year: %pledgeYear%, cfcCode: %federationCfc%, fedAppId: %federationId%}'
                  },
                  {
                    crumbName: 'Member Applications',
                    crumbRoute: 'memberapplication',
                    crumbParams:
                      '{userId: %userId%, year: %pledgeYear%, cfcCode: %federationCfc%}'
                  },
                  {
                    crumbName:
                      '%pledgeYear% #%cfcCode% Member Application Dashboard',
                    crumbRoute: 'applicationDashboard',
                    crumbParams: '{applicationId: %applicationId%}'
                  }
                ];
              }
            },
            pageTitle: 'Application Step4 National Attachment Page',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.charity_user]
          },
          params: {
            mode: null,
            applicationId: null,
            parentFedAppId: null
          },
          resolve: {
            tokenData: function(
              $http,
              $stateParams,
              BreadcrumbTokenDataService,
              ApplicationService
            ) {
              // set parentFedAppId in ApplicationService
              ApplicationService.setParentFederationApplicationId(
                $stateParams.parentFedAppId
              );
              var applicationId =
                $stateParams.applicationId ||
                ApplicationService.getApplicationId();
              return BreadcrumbTokenDataService.setApplicationReviewTokenData(
                applicationId
              );
            }
          }
        })
        .state('aosLocalAttachment', {
          url: '/aosLocalAttachment/:id',
          views: {
            main: {
              template: require('../views/private/application/aosLocalAttachment.html'),
              controller: 'AOSAttachmentCtrl'
            }
          },
          data: {
            breadcrumbs: function(ApplicationService) {
              var appFedId =
                ApplicationService.getApplication().federationId || null;
              var isMember =
                (appFedId ||
                  ApplicationService.getParentFederationApplicationId()) !==
                null;
              var user = JSON.parse(sessionStorage.getItem('USER_OBJ'));
              if (
                user.secondaryRoleList.indexOf('OPM_ADMIN') >= 0 ||
                user.secondaryRoleList.indexOf('OPM_STAFF') >= 0 ||
                user.secondaryRoleList.indexOf('OPM_AUDITOR') >= 0
              ) {
                if (isMember !== true) {
                  return [
                    {
                      crumbName:
                        '%pledgeYear% #%cfcCode% Independent Application Dashboard',
                      crumbRoute: 'applicationDashboard',
                      crumbParams: '{applicationId: %applicationId%}'
                    },
                    {
                      crumbName: 'Appeal',
                      crumbRoute: 'admin-appeal-dashboard',
                      crumbParams:
                        '{applicationId: %applicationId%, isFederation: false}'
                    }
                  ];
                } else {
                  return [
                    {
                      crumbName:
                        '%pledgeYear% #%federationCfc% Federation Package',
                      crumbRoute: 'federationapplication',
                      crumbParams:
                        '{year: %pledgeYear%, cfcCode: %federationCfc%, fedAppId: %federationId%}'
                    },
                    {
                      crumbName: 'Member Applications',
                      crumbRoute: 'memberapplication',
                      crumbParams:
                        '{userId: %userId%, year: %pledgeYear%, cfcCode: %federationCfc%}'
                    },
                    {
                      crumbName:
                        '%pledgeYear% #%cfcCode% Member Application Dashboard',
                      crumbRoute: 'applicationDashboard',
                      crumbParams: '{applicationId: %applicationId%}'
                    },
                    {
                      crumbName: 'Appeal',
                      crumbRoute: 'admin-appeal-dashboard',
                      crumbParams:
                        '{applicationId: %applicationId%, isFederation: false}'
                    }
                  ];
                }
              } else {
                if (isMember !== true) {
                  return [
                    {
                      crumbName:
                        '%pledgeYear% #%cfcCode% Independent Application Dashboard',
                      crumbRoute: 'applicationDashboard',
                      crumbParams: '{applicationId: %applicationId%}'
                    },
                    {
                      crumbName: 'Appeal',
                      crumbRoute: 'appeal-dashboard',
                      crumbParams:
                        '{applicationId: %applicationId%, isFederation: false}'
                    }
                  ];
                } else {
                  return [
                    {
                      crumbName:
                        '%pledgeYear% #%federationCfc% Federation Package',
                      crumbRoute: 'federationapplication',
                      crumbParams:
                        '{year: %pledgeYear%, cfcCode: %federationCfc%, fedAppId: %federationId%}'
                    },
                    {
                      crumbName: 'Member Applications',
                      crumbRoute: 'memberapplication',
                      crumbParams:
                        '{userId: %userId%, year: %pledgeYear%, cfcCode: %federationCfc%}'
                    },
                    {
                      crumbName:
                        '%pledgeYear% #%cfcCode% Member Application Dashboard',
                      crumbRoute: 'applicationDashboard',
                      crumbParams: '{applicationId: %applicationId%}'
                    },
                    {
                      crumbName: 'Appeal',
                      crumbRoute: 'appeal-dashboard',
                      crumbParams:
                        '{applicationId: %applicationId%, isFederation: false}'
                    }
                  ];
                }
              }
            },
            pageTitle: 'Application Appeal AOS Local Attachment Page',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.charity_user]
          },
          params: {
            mode: null,
            applicationId: null,
            id: null,
            parentFedAppId: null,
            isAppealAOS: null,
            charityType: null,
            cfcCode: null,
            pledgeYear: null,
            reviewStatus: null
          },
          resolve: {
            tokenData: function(
              $http,
              $stateParams,
              BreadcrumbTokenDataService,
              ApplicationService,
              BrowserEventService
            ) {
              // set parentFedAppId in ApplicationService
              ApplicationService.setParentFederationApplicationId(
                $stateParams.parentFedAppId
              );
              var applicationId =
                $stateParams.applicationId ||
                ApplicationService.getApplicationId();
              return BreadcrumbTokenDataService.setApplicationReviewTokenData(
                applicationId
              );
            }
          }
        })
        .state('aosNationalAttachment', {
          url: '/aosNationalAttachment/:id',
          views: {
            main: {
              template: require('../views/private/application/aosNationalAttachment.html'),
              controller: 'AOSAttachmentCtrl'
            }
          },
          data: {
            breadcrumbs: function(ApplicationService) {
              var appFedId =
                ApplicationService.getApplication().federationId || null;
              var isMember =
                (appFedId ||
                  ApplicationService.getParentFederationApplicationId()) !==
                null;
              var user = JSON.parse(sessionStorage.getItem('USER_OBJ'));
              if (
                user.secondaryRoleList.indexOf('OPM_ADMIN') >= 0 ||
                user.secondaryRoleList.indexOf('OPM_STAFF') >= 0 ||
                user.secondaryRoleList.indexOf('OPM_AUDITOR') >= 0
              ) {
                if (isMember !== true) {
                  return [
                    {
                      crumbName:
                        '%pledgeYear% #%cfcCode% Independent Application Dashboard',
                      crumbRoute: 'applicationDashboard',
                      crumbParams: '{applicationId: %applicationId%}'
                    },
                    {
                      crumbName: 'Appeal',
                      crumbRoute: 'admin-appeal-dashboard',
                      crumbParams:
                        '{applicationId: %applicationId%, isFederation: false}'
                    }
                  ];
                } else {
                  return [
                    {
                      crumbName:
                        '%pledgeYear% #%federationCfc% Federation Package',
                      crumbRoute: 'federationapplication',
                      crumbParams:
                        '{year: %pledgeYear%, cfcCode: %federationCfc%, fedAppId: %federationId%}'
                    },
                    {
                      crumbName: 'Member Applications',
                      crumbRoute: 'memberapplication',
                      crumbParams:
                        '{userId: %userId%, year: %pledgeYear%, cfcCode: %federationCfc%}'
                    },
                    {
                      crumbName:
                        '%pledgeYear% #%cfcCode% Member Application Dashboard',
                      crumbRoute: 'applicationDashboard',
                      crumbParams: '{applicationId: %applicationId%}'
                    },
                    {
                      crumbName: 'Appeal',
                      crumbRoute: 'admin-appeal-dashboard',
                      crumbParams:
                        '{applicationId: %applicationId%, isFederation: false}'
                    }
                  ];
                }
              } else {
                if (isMember !== true) {
                  return [
                    {
                      crumbName:
                        '%pledgeYear% #%cfcCode% Independent Application Dashboard',
                      crumbRoute: 'applicationDashboard',
                      crumbParams: '{applicationId: %applicationId%}'
                    },
                    {
                      crumbName: 'Appeal',
                      crumbRoute: 'appeal-dashboard',
                      crumbParams:
                        '{applicationId: %applicationId%, isFederation: false}'
                    }
                  ];
                } else {
                  return [
                    {
                      crumbName:
                        '%pledgeYear% #%federationCfc% Federation Package',
                      crumbRoute: 'federationapplication',
                      crumbParams:
                        '{year: %pledgeYear%, cfcCode: %federationCfc%, fedAppId: %federationId%}'
                    },
                    {
                      crumbName: 'Member Applications',
                      crumbRoute: 'memberapplication',
                      crumbParams:
                        '{userId: %userId%, year: %pledgeYear%, cfcCode: %federationCfc%}'
                    },
                    {
                      crumbName:
                        '%pledgeYear% #%cfcCode% Member Application Dashboard',
                      crumbRoute: 'applicationDashboard',
                      crumbParams: '{applicationId: %applicationId%}'
                    },
                    {
                      crumbName: 'Appeal',
                      crumbRoute: 'appeal-dashboard',
                      crumbParams:
                        '{applicationId: %applicationId%, isFederation: false}'
                    }
                  ];
                }
              }
            },
            pageTitle: 'Application Appeal AOS National Attachment Page',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.charity_user]
          },
          params: {
            mode: null,
            applicationId: null,
            id: null,
            parentFedAppId: null,
            isAppealAOS: null,
            charityType: null,
            cfcCode: null,
            pledgeYear: null,
            reviewStatus: null
          },
          resolve: {
            tokenData: function(
              $http,
              $stateParams,
              BreadcrumbTokenDataService,
              ApplicationService
            ) {
              // set parentFedAppId in ApplicationService
              ApplicationService.setParentFederationApplicationId(
                $stateParams.parentFedAppId
              );
              var applicationId =
                $stateParams.applicationId ||
                ApplicationService.getApplicationId();
              return BreadcrumbTokenDataService.setApplicationReviewTokenData(
                applicationId
              );
            }
          }
        })
        .state('step5', {
          url: '/step5',
          views: {
            main: {
              template: require('../views/private/application/step5.html'),
              controller: 'ApplicationStep5Ctrl'
            }
          },
          data: {
            breadcrumbs: function(ApplicationService) {
              var appFedId =
                ApplicationService.getApplication().federationId || null;
              var isMember =
                (appFedId ||
                  ApplicationService.getParentFederationApplicationId()) !==
                null;
              if (isMember !== true) {
                return [
                  {
                    crumbName:
                      '%pledgeYear% #%cfcCode% Independent Application Dashboard',
                    crumbRoute: 'applicationDashboard',
                    crumbParams: '{applicationId: %applicationId%}'
                  }
                ];
              } else {
                return [
                  {
                    crumbName:
                      '%pledgeYear% #%federationCfc% Federation Package',
                    crumbRoute: 'federationapplication',
                    crumbParams:
                      '{year: %pledgeYear%, cfcCode: %federationCfc%, fedAppId: %federationId%}'
                  },
                  {
                    crumbName: 'Member Applications',
                    crumbRoute: 'memberapplication',
                    crumbParams:
                      '{userId: %userId%, year: %pledgeYear%, cfcCode: %federationCfc%}'
                  },
                  {
                    crumbName:
                      '%pledgeYear% #%cfcCode% Member Application Dashboard',
                    crumbRoute: 'applicationDashboard',
                    crumbParams: '{applicationId: %applicationId%}'
                  }
                ];
              }
            },
            pageTitle: 'Application Step5 Page',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.charity_user]
          },
          params: {
            mode: null,
            applicationId: null,
            parentFedAppId: null
          },
          resolve: {
            tokenData: function(
              $http,
              $stateParams,
              BreadcrumbTokenDataService,
              ApplicationService
            ) {
              // set parentFedAppId in ApplicationService
              ApplicationService.setParentFederationApplicationId(
                $stateParams.parentFedAppId
              );
              var applicationId =
                $stateParams.applicationId ||
                ApplicationService.getApplicationId();
              return BreadcrumbTokenDataService.setApplicationReviewTokenData(
                applicationId
              );
            }
          }
        })
        .state('step6', {
          url: '/step6',
          views: {
            main: {
              template: require('../views/private/application/step6.html'),
              controller: 'ApplicationStep6Ctrl'
            }
          },
          data: {
            breadcrumbs: function(ApplicationService) {
              var appFedId =
                ApplicationService.getApplication().federationId || null;
              var isMember =
                (appFedId ||
                  ApplicationService.getParentFederationApplicationId()) !==
                null;
              if (isMember !== true) {
                return [
                  {
                    crumbName:
                      '%pledgeYear% #%cfcCode% Independent Application Dashboard',
                    crumbRoute: 'applicationDashboard',
                    crumbParams: '{applicationId: %applicationId%}'
                  }
                ];
              } else {
                return [
                  {
                    crumbName:
                      '%pledgeYear% #%federationCfc% Federation Package',
                    crumbRoute: 'federationapplication',
                    crumbParams:
                      '{year: %pledgeYear%, cfcCode: %federationCfc%, fedAppId: %federationId%}'
                  },
                  {
                    crumbName: 'Member Applications',
                    crumbRoute: 'memberapplication',
                    crumbParams:
                      '{userId: %userId%, year: %pledgeYear%, cfcCode: %federationCfc%}'
                  },
                  {
                    crumbName:
                      '%pledgeYear% #%cfcCode% Member Application Dashboard',
                    crumbRoute: 'applicationDashboard',
                    crumbParams: '{applicationId: %applicationId%}'
                  }
                ];
              }
            },
            pageTitle: 'Application Step6 Page',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.charity_user]
          },
          params: {
            mode: null,
            applicationId: null,
            parentFedAppId: null
          },
          resolve: {
            tokenData: function(
              $http,
              $stateParams,
              BreadcrumbTokenDataService,
              ApplicationService
            ) {
              // set parentFedAppId in ApplicationService
              ApplicationService.setParentFederationApplicationId(
                $stateParams.parentFedAppId
              );
              var applicationId =
                $stateParams.applicationId ||
                ApplicationService.getApplicationId();
              return BreadcrumbTokenDataService.setApplicationReviewTokenData(
                applicationId
              );
            }
          }
        })
        .state('step7', {
          url: '/step7',
          views: {
            main: {
              template: require('../views/private/application/step7.html'),
              controller: 'ApplicationStep7Ctrl'
            }
          },
          data: {
            breadcrumbs: function(ApplicationService) {
              var appFedId =
                ApplicationService.getApplication().federationId || null;
              var isMember =
                (appFedId ||
                  ApplicationService.getParentFederationApplicationId()) !==
                null;
              if (isMember !== true) {
                return [
                  {
                    crumbName:
                      '%pledgeYear% #%cfcCode% Independent Application Dashboard',
                    crumbRoute: 'applicationDashboard',
                    crumbParams: '{applicationId: %applicationId%}'
                  }
                ];
              } else {
                return [
                  {
                    crumbName:
                      '%pledgeYear% #%federationCfc% Federation Package',
                    crumbRoute: 'federationapplication',
                    crumbParams:
                      '{year: %pledgeYear%, cfcCode: %federationCfc%, fedAppId: %federationId%}'
                  },
                  {
                    crumbName: 'Member Applications',
                    crumbRoute: 'memberapplication',
                    crumbParams:
                      '{userId: %userId%, year: %pledgeYear%, cfcCode: %federationCfc%}'
                  },
                  {
                    crumbName:
                      '%pledgeYear% #%cfcCode% Member Application Dashboard',
                    crumbRoute: 'applicationDashboard',
                    crumbParams: '{applicationId: %applicationId%}'
                  }
                ];
              }
            },
            pageTitle: 'Application Step7 Page',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.charity_user]
          },
          params: {
            mode: null,
            applicationId: null,
            parentFedAppId: null
          },
          resolve: {
            tokenData: function(
              $http,
              $stateParams,
              BreadcrumbTokenDataService,
              ApplicationService
            ) {
              // set parentFedAppId in ApplicationService
              ApplicationService.setParentFederationApplicationId(
                $stateParams.parentFedAppId
              );
              var applicationId =
                $stateParams.applicationId ||
                ApplicationService.getApplicationId();
              return BreadcrumbTokenDataService.setApplicationReviewTokenData(
                applicationId
              );
            }
          }
        })
        .state('step8', {
          url: '/step8',
          views: {
            main: {
              template: require('../views/private/application/step8.html'),
              controller: 'ApplicationStep8Ctrl'
            }
          },
          data: {
            breadcrumbs: function(ApplicationService) {
              var appFedId =
                ApplicationService.getApplication().federationId || null;
              var isMember =
                (appFedId ||
                  ApplicationService.getParentFederationApplicationId()) !==
                null;
              if (isMember !== true) {
                return [
                  {
                    crumbName:
                      '%pledgeYear% #%cfcCode% Independent Application Dashboard',
                    crumbRoute: 'applicationDashboard',
                    crumbParams: '{applicationId: %applicationId%}'
                  }
                ];
              } else {
                return [
                  {
                    crumbName:
                      '%pledgeYear% #%federationCfc% Federation Package',
                    crumbRoute: 'federationapplication',
                    crumbParams:
                      '{year: %pledgeYear%, cfcCode: %federationCfc%, fedAppId: %federationId%}'
                  },
                  {
                    crumbName: 'Member Applications',
                    crumbRoute: 'memberapplication',
                    crumbParams:
                      '{userId: %userId%, year: %pledgeYear%, cfcCode: %federationCfc%}'
                  },
                  {
                    crumbName:
                      '%pledgeYear% #%cfcCode% Member Application Dashboard',
                    crumbRoute: 'applicationDashboard',
                    crumbParams: '{applicationId: %applicationId%}'
                  }
                ];
              }
            },
            pageTitle: 'Application Step8 Page',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.charity_user]
          },
          params: {
            mode: null,
            applicationId: null,
            parentFedAppId: null
          },
          resolve: {
            tokenData: function(
              $http,
              $stateParams,
              BreadcrumbTokenDataService,
              ApplicationService
            ) {
              // set parentFedAppId in ApplicationService
              ApplicationService.setParentFederationApplicationId(
                $stateParams.parentFedAppId
              );
              var applicationId =
                $stateParams.applicationId ||
                ApplicationService.getApplicationId();
              return BreadcrumbTokenDataService.setApplicationReviewTokenData(
                applicationId
              );
            }
          }
        })
        .state('step9', {
          url: '/step9',
          views: {
            main: {
              template: require('../views/private/application/step9.html'),
              controller: 'ApplicationStep9Ctrl'
            }
          },
          data: {
            breadcrumbs: function(ApplicationService) {
              var appFedId =
                ApplicationService.getApplication().federationId || null;
              var isMember =
                (appFedId ||
                  ApplicationService.getParentFederationApplicationId()) !==
                null;
              if (isMember !== true) {
                return [
                  {
                    crumbName:
                      '%pledgeYear% #%cfcCode% Independent Application Dashboard',
                    crumbRoute: 'applicationDashboard',
                    crumbParams: '{applicationId: %applicationId%}'
                  }
                ];
              } else {
                return [
                  {
                    crumbName:
                      '%pledgeYear% #%federationCfc% Federation Package',
                    crumbRoute: 'federationapplication',
                    crumbParams:
                      '{year: %pledgeYear%, cfcCode: %federationCfc%, fedAppId: %federationId%}'
                  },
                  {
                    crumbName: 'Member Applications',
                    crumbRoute: 'memberapplication',
                    crumbParams:
                      '{userId: %userId%, year: %pledgeYear%, cfcCode: %federationCfc%}'
                  },
                  {
                    crumbName:
                      '%pledgeYear% #%cfcCode% Member Application Dashboard',
                    crumbRoute: 'applicationDashboard',
                    crumbParams: '{applicationId: %applicationId%}'
                  }
                ];
              }
            },
            pageTitle: 'Application Step9 Page',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.charity_user]
          },
          params: {
            mode: null,
            applicationId: null,
            parentFedAppId: null
          },
          resolve: {
            tokenData: function(
              $http,
              $stateParams,
              BreadcrumbTokenDataService,
              ApplicationService
            ) {
              // set parentFedAppId in ApplicationService
              ApplicationService.setParentFederationApplicationId(
                $stateParams.parentFedAppId
              );
              var applicationId =
                $stateParams.applicationId ||
                ApplicationService.getApplicationId();
              return BreadcrumbTokenDataService.setApplicationReviewTokenData(
                applicationId
              );
            }
          }
        })
        .state('step10', {
          url: '/step10',
          views: {
            main: {
              template: require('../views/private/application/step10.html'),
              controller: 'ApplicationStep10Ctrl'
            }
          },
          data: {
            breadcrumbs: function(ApplicationService) {
              var appFedId =
                ApplicationService.getApplication().federationId || null;
              var isMember =
                (appFedId ||
                  ApplicationService.getParentFederationApplicationId()) !==
                null;
              if (isMember !== true) {
                return [
                  {
                    crumbName:
                      '%pledgeYear% #%cfcCode% Independent Application Dashboard',
                    crumbRoute: 'applicationDashboard',
                    crumbParams: '{applicationId: %applicationId%}'
                  }
                ];
              } else {
                return [
                  {
                    crumbName:
                      '%pledgeYear% #%federationCfc% Federation Package',
                    crumbRoute: 'federationapplication',
                    crumbParams:
                      '{year: %pledgeYear%, cfcCode: %federationCfc%, fedAppId: %federationId%}'
                  },
                  {
                    crumbName: 'Member Applications',
                    crumbRoute: 'memberapplication',
                    crumbParams:
                      '{userId: %userId%, year: %pledgeYear%, cfcCode: %federationCfc%}'
                  },
                  {
                    crumbName:
                      '%pledgeYear% #%cfcCode% Member Application Dashboard',
                    crumbRoute: 'applicationDashboard',
                    crumbParams: '{applicationId: %applicationId%}'
                  }
                ];
              }
            },
            pageTitle: 'Application Step10 Page',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.charity_user]
          },
          params: {
            mode: null,
            applicationId: null,
            parentFedAppId: null
          },
          resolve: {
            tokenData: function(
              $http,
              $stateParams,
              BreadcrumbTokenDataService,
              ApplicationService
            ) {
              // set parentFedAppId in ApplicationService
              ApplicationService.setParentFederationApplicationId(
                $stateParams.parentFedAppId
              );
              var applicationId =
                $stateParams.applicationId ||
                ApplicationService.getApplicationId();
              return BreadcrumbTokenDataService.setApplicationReviewTokenData(
                applicationId
              );
            }
          }
        })
        .state('step11', {
          url: '/step11',
          views: {
            main: {
              template: require('../views/private/application/step11.html'),
              controller: 'ApplicationStep11Ctrl'
            }
          },
          data: {
            breadcrumbs: function(ApplicationService) {
              var appFedId =
                ApplicationService.getApplication().federationId || null;
              var isMember =
                (appFedId ||
                  ApplicationService.getParentFederationApplicationId()) !==
                null;
              if (isMember !== true) {
                return [
                  {
                    crumbName:
                      '%pledgeYear% #%cfcCode% Independent Application Dashboard',
                    crumbRoute: 'applicationDashboard',
                    crumbParams: '{applicationId: %applicationId%}'
                  }
                ];
              } else {
                return [
                  {
                    crumbName:
                      '%pledgeYear% #%federationCfc% Federation Package',
                    crumbRoute: 'federationapplication',
                    crumbParams:
                      '{year: %pledgeYear%, cfcCode: %federationCfc%, fedAppId: %federationId%}'
                  },
                  {
                    crumbName: 'Member Applications',
                    crumbRoute: 'memberapplication',
                    crumbParams:
                      '{userId: %userId%, year: %pledgeYear%, cfcCode: %federationCfc%}'
                  },
                  {
                    crumbName:
                      '%pledgeYear% #%cfcCode% Member Application Dashboard',
                    crumbRoute: 'applicationDashboard',
                    crumbParams: '{applicationId: %applicationId%}'
                  }
                ];
              }
            },
            pageTitle: 'Application Step11 Page',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.charity_user]
          },
          params: {
            mode: null,
            applicationId: null,
            parentFedAppId: null
          },
          resolve: {
            tokenData: function(
              $http,
              $stateParams,
              BreadcrumbTokenDataService,
              ApplicationService
            ) {
              // set parentFedAppId in ApplicationService
              ApplicationService.setParentFederationApplicationId(
                $stateParams.parentFedAppId
              );
              var applicationId =
                $stateParams.applicationId ||
                ApplicationService.getApplicationId();
              return BreadcrumbTokenDataService.setApplicationReviewTokenData(
                applicationId
              );
            }
          }
        })
        .state('allSteps', {
          url: '/allSteps',
          views: {
            main: {
              template: require('../views/private/application/allSteps.html'),
              controller: 'ApplicationAllStepsCtrl'
            }
          },
          data: {
            breadcrumbs: function(ApplicationService) {
              var appFedId =
                ApplicationService.getApplication().federationId ||
                ApplicationService.getApplication().federationNumber;
              var isMember =
                (appFedId ||
                  ApplicationService.getParentFederationApplicationId()) !==
                null;
              if (isMember !== true) {
                return [
                  {
                    crumbName:
                      '%pledgeYear% #%cfcCode% Independent Application Dashboard',
                    crumbRoute: 'applicationDashboard',
                    crumbParams: '{applicationId: %applicationId%}'
                  }
                ];
              } else {
                return [
                  {
                    crumbName:
                      '%pledgeYear% #%cfcCode% Member Application Dashboard',
                    crumbRoute: 'applicationDashboard',
                    crumbParams: '{applicationId: %applicationId%}'
                  }
                ];
              }
            },
            pageTitle: 'Application AllSteps Page',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.charity_user]
          },
          params: {
            mode: null,
            applicationId: null,
            parentFedAppId: null
          },
          resolve: {
            tokenData: function(
              $http,
              $stateParams,
              BreadcrumbTokenDataService,
              ApplicationService
            ) {
              // set parentFedAppId in ApplicationService
              ApplicationService.setParentFederationApplicationId(
                $stateParams.parentFedAppId
              );
              var applicationId =
                $stateParams.applicationId ||
                ApplicationService.getApplicationId();
              return BreadcrumbTokenDataService.setApplicationReviewTokenData(
                applicationId
              );
            }
          }
        })
        .state('review', {
          url: '/review/:applicationId/:mode',
          views: {
            main: {
              template: require('../views/private/application/applicationReview.html'),
              controller: 'ApplicationReviewCtrl'
            }
          },
          data: {
            breadcrumbs: [
              {
                crumbName: 'Admin Home',
                crumbRoute: 'administrationcenter',
                crumbParams: '{userId: %userId%}'
              },
              {
                crumbName: 'Zone %zoneCode%',
                crumbRoute: 'zoneCenter',
                crumbParams: '{zoneId: %zoneId%}'
              },
              {
                crumbName: '%zoneCode% Applications',
                crumbRoute: 'zoneApplications',
                crumbParams: '{zoneId: %zoneId%, pledgeYear: %pledgeYear%}'
              },
              {
                crumbName: 'reviewLevel',
                crumbRoute: 'zonereviewlevel3',
                crumbParams: '{zoneId: %zoneId%, pledgeYear: %pledgeYear%}'
              },
              {
                crumbName: 'Charity %cfcCode% Review Dashboard',
                crumbRoute: 'charityAuditDashboard',
                crumbParams: '{applicationId: %applicationId%}'
              },
              { crumbName: 'Review', crumbRoute: '', crumbParams: '' }
            ],
            pageTitle: 'Charity Application Review Page',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.charity_user]
          },
          params: {
            fromPage: null
          },
          resolve: {
            tokenData: function(
              $http,
              $stateParams,
              BreadcrumbTokenDataService,
              ApplicationService
            ) {
              var applicationId =
                $stateParams.applicationId ||
                ApplicationService.getApplicationId();
              return BreadcrumbTokenDataService.setApplicationReviewTokenData(
                applicationId
              );
            }
          }
        })
        .state('signature', {
          url: '/signature',
          views: {
            main: {
              template: require('../views/private/application/signature.html'),
              controller: 'ApplicationSignatureCtrl'
            }
          },
          data: {
            pageTitle: 'Application Signature Page',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.charity_user, USER_ROLES.system_user]
          }
        })
        .state('applicationPayment', {
          url: '/applicationPayment',
          views: {
            main: {
              template: require('../views/private/application/applicationPayment.html'),
              controller: 'ApplicationPaymentCtrl'
            }
          },
          data: {
            pageTitle: 'Application Payment Page',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.charity_user]
          },
          params: {
            charityApplicationId: null
          },
          resolve: {
            charityApplicationData: function(
              $http,
              $stateParams,
              ApplicationService,
              CubeSvc
            ) {
              if ($stateParams.charityApplicationId != null) {
                var charityApplicationId = $stateParams.charityApplicationId;
                ApplicationService.reset();
                var url =
                  '/CfcCharity/api/private/application/details/' +
                  charityApplicationId;
                return CubeSvc.get(url).then(function(res) {
                  ApplicationService.setApplicationId(charityApplicationId);
                  return res.data.value;
                });
              }
            }
          }
        })
        .state('thankYou', {
          url: '/thankYou',
          views: {
            main: {
              template: require('../views/private/application/thankYou.html'),
              controller: 'ApplicationThankyouCtrl'
            }
          },
          data: {
            pageTitle: 'Application Thank You Page',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.charity_user]
          },
          params: {
            charityApplicationId: null,
            paymentUserid: null,
            paymentid: null,
            paymenttype: null,
            paymentfeeType: null,
            paymentMethod: null,
            paymentyear: null
          },
          resolve: {
            charityApplicationData: function($stateParams) {
              var charityAppData = null;
              if ($stateParams.charityApplicationId != null) {
                var charityApplicationId = $stateParams.charityApplicationId;
                charityAppData = {
                  charityApplicationId: charityApplicationId,
                  paymentUserid: $stateParams.paymentUserid,
                  paymentid: $stateParams.paymentid,
                  paymenttype: $stateParams.paymenttype,
                  paymentfeeType: $stateParams.paymentfeeType,
                  paymentMethod: $stateParams.paymentMethod,
                  paymentyear: $stateParams.paymentyear
                };
              }
              return charityAppData;
            }
          }
        });
    }
  ])
  .controller('CreateApplicationCtrl', [
    '$log',
    '$state',
    '$scope',
    'MODULE_NAME',
    'UserCharityRole',
    'ApplicationService',
    function(
      $log,
      $state,
      $scope,
      MODULE_NAME,
      UserCharityRole,
      ApplicationService
    ) {
      $scope.setModuleName('CfcCharity.application');
      $scope.createApplication = function() {
        var pledgeYear = $scope.newApplication.pledgeYear;
        var cfcCode = $scope.newApplication.cfcCode;
        ApplicationService.setPledgeYear(pledgeYear);
        ApplicationService.setCfcCode(cfcCode);
        $scope.setAppPledgeYear(pledgeYear);
        $scope.setAppCfcCode(cfcCode);
        var application = ApplicationService.getApplication();
        application.cfcCode = cfcCode;
        application.pledgeYear = pledgeYear;
        application.applicationStatus = 'IN_PROGRESS';
        application.createdDate = ApplicationService.toUTCDate(new Date());
        var userSession = ApplicationService.getUserSession();
        userSession.cfcCode = cfcCode;
        application
          .$save(application)
          .then(function(response) {
            userSession.applicationId = response.id;
            var role = UserCharityRole.get(
              {
                cfcCode: cfcCode
              },
              function() {
                userSession.userRole = role.userRole;
                userSession.lastAccessedBeginDate = ApplicationService.toUTCDate(
                  new Date()
                );
                ApplicationService.saveUserSession()
                  .then(function(response) {
                    $scope.applicationStep = 1;
                    $state.go('step1', {
                      mode: 'EDIT',
                      applicationId: application.id,
                      parentFederationId: application.federationId
                    });
                  })
                  .catch(function(failedResponse) {
                    $log.error(
                      'User Application Session Failed.....' +
                        JSON.stringify(failedResponse)
                    );
                  });
              }
            );
          })
          .catch(function(failedResponse) {
            $log.error(
              'Application Failed.....' + JSON.stringify(failedResponse)
            );
          });
      };
    }
  ])
  .controller('ApplicationStep1Ctrl', [
    '$state',
    '$scope',
    '$location',
    'CakeSvc',
    'MODULE_NAME',
    'APPLICATION_STEP_STATUS',
    'UserCharityRole',
    'ApplicationService',
    'ValidationService',
    'BrowserEventService',
    'CharityService',
    'APPLICATION_TYPE',
    'tokenData',
    '$log',
    'urlAccess',
    function(
      $state,
      $scope,
      $location,
      CakeSvc,
      MODULE_NAME,
      APPLICATION_STEP_STATUS,
      UserCharityRole,
      ApplicationService,
      ValidationService,
      BrowserEventService,
      CharityService,
      APPLICATION_TYPE,
      tokenData,
      $log,
      urlAccess
    ) {
      $scope.setAppStep(1);
      function init() {
        $scope.appLabel = ApplicationService.getAppLabel();
        $scope.saveExit = false;
      }
      if ($state.params.applicationId) {
        ApplicationService.editApplication($state.params.applicationId);
      }
      $scope.currentPledgeYear = sessionStorage.getItem(
        'CREATE_APPLICATION_CURRENT_PLEDGE_YEAR'
      );
      $scope.mode = $state.params.mode
        ? $state.params.mode
        : BrowserEventService.mode;
      BrowserEventService.setMode($scope.mode);
      $location.hash('top-container');
      $scope.gotoPageSection = function(divId) {
        $location.hash(divId);
      };
      ApplicationService.getStates(function(data) {
        $scope.states = data;
      });
      ApplicationService.getUserSession().step1AccessBeginDate = ApplicationService.toUTCDate(
        new Date()
      );
      if (!$scope.applicationStep1) {
        $scope.applicationStep1 = ApplicationService.getApplicationStep1();
        if (angular.equals($scope.applicationStep1, {})) {
          $scope.applicationStep1.applicationId = ApplicationService.getApplicationId();
          $scope.applicationStep1.cfcCode = ApplicationService.getCfcCode();
          $scope.applicationStep1.$get(
            function(res) {
              var tmp = CakeSvc.transformGenericResponseToFlatResourceResponse(
                res
              );
              if (!angular.equals(tmp, {})) {
                $scope.applicationStep1 = tmp;
              } else {
                $scope.applicationStep1.applicationId = ApplicationService.getApplicationId();
                $scope.applicationStep1.cfcCode = ApplicationService.getCfcCode();
              }
            },
            function(err) {
              $log.error('STEP1 failed to load');
            }
          );
        }
      }
      //check the access to show the breadcrumb links for member applications
      if (tokenData.federationId != null) {
        if ($scope.$parent.$$childHead.breadcrumbs.length > 0) {
          urlAccess
            .getFederationApplicationAccess(
              tokenData.pledgeYear,
              tokenData.federationCfc,
              $scope.$parent.$$childHead.breadcrumbs[0]
            )
            .then(function(res) {
              $scope.$parent.$$childHead.breadcrumbs[0] = res;
            });
          urlAccess
            .getMemberApplicationAccess(
              tokenData.pledgeYear,
              tokenData.federationCfc,
              $scope.$parent.$$childHead.breadcrumbs[1]
            )
            .then(function(res) {
              $scope.$parent.$$childHead.breadcrumbs[1] = res;
            });
        }
      }
      $scope.validateApplicationStep = function(exit) {
        $scope.fieldsMissing = [];
        $scope.missingSecondEmail = false;
        var missingEmail = false;
        if (!$scope.applicationStep1.city) {
          $scope.fieldsMissing.push('City is not filled in');
        }
        if (!$scope.applicationStep1.contactAddress1) {
          $scope.fieldsMissing.push('Contact Address1 is not filled in');
        }
        if (!$scope.applicationStep1.contactEmailAddress) {
          missingEmail = true;
          $scope.fieldsMissing.push('Contact Email is not filled in');
        }

        if ($scope.applicationStep1.contactEmailAddress) {
          var p = $scope.applicationStep1.contactEmailAddress.split(';');
          if (p.length < 2) {
            $scope.fieldsMissing.push(
              'You have only provided one email address.  To ensure the CFC can contact you about important information related to the campaign or your application, we highly recommend you supply multiple points of contact. Your multiple points of contact can include both personal and work email addresses.'
            );
            $scope.missingSecondEmail = true;
          } else if (
            p.length === 2 &&
            (p[0] === '' ||
              p[1] === '' ||
              p[0] === null ||
              p[1] === null ||
              angular.isUndefined(p[0]) ||
              angular.isUndefined(p[1]))
          ) {
            $scope.fieldsMissing.push(
              'You have only provided one email address.  To ensure the CFC can contact you about important information related to the campaign or your application, we highly recommend you supply multiple points of contact. Your multiple points of contact can include both personal and work email addresses.'
            );
            $scope.missingSecondEmail = true;
          }
        }
        if (!$scope.applicationStep1.contactPerson) {
          $scope.fieldsMissing.push('Contact Person is not filled in');
        }
        if (!$scope.applicationStep1.contactPhoneNumber) {
          $scope.fieldsMissing.push('Contact Phone is not filled in');
        }
        if (!$scope.applicationStep1.contactTitle) {
          $scope.fieldsMissing.push('Contact Title is not filled in');
        }
        if (!$scope.applicationStep1.state) {
          $scope.fieldsMissing.push('Contact State is not filled in');
        }
        if (!$scope.applicationStep1.zip) {
          $scope.fieldsMissing.push('Contact Zip is not filled in');
        }
        var validateContactPerson = ValidationService.validateAlphaNumericPunctuation(
          'Contact Person',
          $scope.applicationStep1.contactPerson
        );
        if (validateContactPerson) {
          $scope.fieldsMissing.push(validateContactPerson);
        }
        var validateEmail = ValidationService.validateMultipleEmails(
          'Contact Email',
          $scope.applicationStep1.contactEmailAddress
        );

        $scope.contactEmailAddressesFailedValidation =
          !!validateEmail || missingEmail;

        if (validateEmail) {
          $scope.fieldsMissing.unshift(validateEmail);
        }
        if (
          ValidationService.validatePhoneNumber(
            'Contact Phone',
            $scope.applicationStep1.contactPhoneNumber
          )
        ) {
          $scope.fieldsMissing.push(
            ValidationService.validatePhoneNumber(
              'Contact Phone',
              $scope.applicationStep1.contactPhoneNumb
            )
          );
        }
        if (
          ValidationService.validateZip(
            'Contact Zip',
            $scope.applicationStep1.zip
          )
        ) {
          $scope.fieldsMissing.push(
            ValidationService.validateZip(
              'Contact Zip',
              $scope.applicationStep1.zip
            )
          );
        }
        var validateWebsite = ValidationService.validateWebsiteAddress(
          'Website Address',
          $scope.applicationStep1.websiteAddress
        );
        if (validateWebsite) {
          $scope.fieldsMissing.push(validateWebsite);
        }
        if (
          ValidationService.validatePhoneNumber(
            'Contact Fax',
            $scope.applicationStep1.contactFaxNumber
          )
        ) {
          $scope.fieldsMissing.push(
            ValidationService.validatePhoneNumber(
              'Contact Fax',
              $scope.applicationStep1.contactFaxNumber
            )
          );
        }
        if ($scope.fieldsMissing.length > 0) {
          if ($scope.fieldsMissing.length === 1 && $scope.missingSecondEmail) {
            $scope.applicationStep1.stepStatus =
              APPLICATION_STEP_STATUS.complete;
          } else {
            $scope.applicationStep1.stepStatus =
              APPLICATION_STEP_STATUS.incomplete;
          }
          if (exit) {
            $scope.saveApplicationStep(exit);
          } else {
            $('#missingInfo').modal();
          }
        } else {
          $scope.applicationStep1.stepStatus = APPLICATION_STEP_STATUS.complete;
          if (exit) {
            $scope.saveApplicationStep(exit);
          } else {
            $scope.saveApplicationStep();
          }
        }
      };
      $scope.saveApplicationStep = function(exit) {
        var currentTime = ApplicationService.toUTCDate(new Date());
        var dateDifferenceInSeconds = 0;
        var userSession = ApplicationService.getUserSession();
        userSession.step1AccessEndDate = currentTime;
        dateDifferenceInSeconds = ApplicationService.getDateDifference(
          currentTime,
          userSession.step1AccessBeginDate
        );
        userSession.step1TimeSpentInSeconds = dateDifferenceInSeconds;
        if (exit) {
          userSession.lastAccessedEndDate = currentTime;
          userSession.totalTimeSpentInSeconds = ApplicationService.getDateDifference(
            currentTime,
            userSession.lastAccessedBeginDate
          );
        }
        ApplicationService.saveStep($scope.applicationStep1, function(
          response
        ) {
          ApplicationService.saveUserSession().then(function(response) {
            var overallStatus = 'Complete';
            ApplicationService.getApplicationStatus(
              ApplicationService.getApplicationId(),
              function(data) {
                for (var i = 0; i < data.length; i++) {
                  if (data[i].stepStatus === 'Incomplete') {
                    overallStatus = 'Incomplete';
                    break;
                  }
                }
                var app = ApplicationService.getApplication();
                app.overallApplicationStatus = overallStatus;
                ApplicationService.saveTable(app, function(response) {
                  if (exit) {
                    $scope.setAppStep(0);
                    $state.go('applicationDashboard', {
                      applicationId: ApplicationService.getApplicationId()
                    });
                  } else {
                    $('.modal-backdrop').remove();
                    $state.go('step2', {
                      applicationId: ApplicationService.getApplicationId(),
                      parentFedAppId: ApplicationService.getParentFederationApplicationId()
                    });
                  }
                });
              }
            );
          });
        });
      };

      $('#missingInfo').on('show.bs.modal', function(e) {
        $scope.goBack = false;
      });
      $('#missingInfo').on('hidden.bs.modal', function(e) {
        if ($scope.goBack) {
          return;
        }
        $scope.saveApplicationStep($scope.saveExit);
      });

      init();
    }
  ])
  .controller('ApplicationStep2Ctrl', [
    '$state',
    '$scope',
    '$location',
    'CakeSvc',
    'MODULE_NAME',
    'CHARITY_TYPE',
    'APPLICATION_TYPE',
    'APPLICATION_STEP_STATUS',
    'APPLICATION_STATUS',
    'UserCharityRole',
    'Step2CharitySearch',
    'ApplicationService',
    'FederationApplicationStep2Service',
    'ApplicationDashboardService',
    'BrowserEventService',
    'CharityService',
    'tokenData',
    '$log',
    'urlAccess',
    'CubeSvc',
    function(
      $state,
      $scope,
      $location,
      CakeSvc,
      MODULE_NAME,
      CHARITY_TYPE,
      APPLICATION_TYPE,
      APPLICATION_STEP_STATUS,
      APPLICATION_STATUS,
      UserCharityRole,
      Step2CharitySearch,
      ApplicationService,
      FederationApplicationStep2Service,
      ApplicationDashboardService,
      BrowserEventService,
      CharityService,
      tokenData,
      $log,
      urlAccess,
      CubeSvc
    ) {
      $scope.setAppStep(2);

      $location.hash('top-container');
      $scope.gotoPageSection = function(divId) {
        $location.hash(divId);
      };
      $scope.currentPledgeYear = sessionStorage.getItem(
        'CREATE_APPLICATION_CURRENT_PLEDGE_YEAR'
      );
      $scope.previousFysaFyspWfr = false;
      $scope.userId = $scope.getCurrentUser().id;
      //check the access to show the breadcrumb links for member applications
      if (tokenData.federationId != null) {
        if ($scope.$parent.$$childHead.breadcrumbs.length > 0) {
          urlAccess
            .getFederationApplicationAccess(
              tokenData.pledgeYear,
              tokenData.federationCfc,
              $scope.$parent.$$childHead.breadcrumbs[0]
            )
            .then(function(res) {
              $scope.$parent.$$childHead.breadcrumbs[0] = res;
            });
          urlAccess
            .getMemberApplicationAccess(
              tokenData.pledgeYear,
              tokenData.federationCfc,
              $scope.$parent.$$childHead.breadcrumbs[1]
            )
            .then(function(res) {
              $scope.$parent.$$childHead.breadcrumbs[1] = res;
            });
        }
      }

      $scope.charityFromFederation = false;
      $scope.mode = $state.params.mode
        ? $state.params.mode
        : BrowserEventService.mode;
      BrowserEventService.setMode($scope.mode);
      if (!$state.params.fromPage) {
        ApplicationService.getUserSession().step2AccessBeginDate = ApplicationService.toUTCDate(
          new Date()
        );
      }
      var role = UserCharityRole.get(
        {
          cfcCode: ApplicationService.getCfcCode()
        },
        function() {
          $scope.userCharityRole = role.userRole;
        },
        function(failedResponse) {
          /*Todo: Log error*/
        }
      );
      var application = ApplicationService.getApplication();
      var federationId =
        application.federationId || application.federationNumber;
      if (!$scope.applicationStep2) {
        $scope.applicationStep2 = ApplicationService.getApplicationStep2();

        if ($scope.applicationStep2.fysaFyspWfr) {
          if (!$scope.applicationStep2.militaryBaseLocation) {
            $scope.applicationStep2.militaryBaseLocation = ApplicationService.getPreApplicationDetails().militaryBaseLocation;
          }
        }

        if (angular.equals($scope.applicationStep2, {})) {
          $scope.applicationStep2.applicationId = ApplicationService.getApplicationId();
          $scope.applicationStep2.cfcCode = ApplicationService.getCfcCode();
          $scope.applicationStep2.$get(
            function(res) {
              var tmp = CakeSvc.transformGenericResponseToFlatResourceResponse(
                res
              );
              if (!angular.equals(tmp, {})) {
                $scope.applicationStep2 = tmp;
              } else {
                $scope.applicationStep2.applicationId = ApplicationService.getApplicationId();
                $scope.applicationStep2.cfcCode = ApplicationService.getCfcCode();
              }
              $scope.previousFysaFyspWfr = $scope.applicationStep2.fysaFyspWfr;
            },
            function(err) {
              $log.error('STEP2 failed to load');
            }
          );
        }

        if ($scope.applicationStep2.applicationId === undefined) {
          $scope.applicationStep2.applicationId = ApplicationService.getApplicationId();
        }
        ApplicationDashboardService.getCharityApplication(
          $scope.applicationStep2.applicationId
        ).then(function(res) {
          var charityApplication = res.data.value;
          federationId = charityApplication.federationId;
          if (federationId) {
            FederationApplicationStep2Service.retrieveFederationApplicationStep2DetailsByFedAndMemberId(
              federationId,
              $scope.applicationStep2.applicationId
            ).then(function(resp) {
              var federationStep2 = resp.data.value;
              if (federationStep2) {
                $scope.applicationStep2.applicationType = 'MEMBER';
                $scope.orginalCharityType = $scope.applicationStep2.charityType;
                $scope.originalDODcheck = $scope.applicationStep2.fysaFyspWfr;
                $scope.originalMilitaryBaseLocation =
                  $scope.applicationStep2.militaryBaseLocation;
                $scope.applicationStep2.parentCfcCode = federationStep2.cfcCode;
                if (
                  $scope.applicationStep2.charityType === null ||
                  $scope.applicationStep2.charityType === undefined
                ) {
                  $scope.applicationStep2.charityType =
                    federationStep2.charityType;
                  $scope.orginalCharityType = federationStep2.charityType;
                }
                $scope.setFromMemberCharitiesApplication(true);
                $scope.charityFromFederation = true;
              }
              if (
                $scope.applicationStep2 &&
                $scope.applicationStep2.applicationType
              ) {
                $scope.applicationPreviousType =
                  $scope.applicationStep2.applicationType;
              }
              if (
                $scope.applicationStep2 &&
                $scope.applicationStep2.parentCfcCode
              ) {
                $scope.applicationPreviousParentCfcCode =
                  $scope.applicationStep2.parentCfcCode;
              }
            });
          }
        });
        //} //end if federationId is null
      }
      $scope.gotoStep2Search = function() {
        $scope.applicationStep2.charityType = $scope.charityType;
        $scope.applicationStep2.applicationType = $scope.applicationType;
        $state.go('step2Search');
      };

      $scope.validateApplicationStep = function(exit) {
        $scope.fieldsMissing = [];
        $scope.error = false;
        $scope.mismatch = false;
        if (!$scope.applicationStep2.charityType) {
          $scope.fieldsMissing.push('Charity Type');
        }
        if (!$scope.applicationStep2.applicationType) {
          $scope.fieldsMissing.push('Application Type');
        }
        if (
          $scope.applicationStep2.charityType != null &&
          $scope.applicationStep2.applicationType != null
        ) {
          if (
            $scope.applicationStep2.applicationType ===
            APPLICATION_TYPE.member_of_federation
          ) {
            if (!$scope.applicationStep2.parentCfcCode) {
              $scope.fieldsMissing.push(
                'You have not filled in parent federation code'
              );
              $scope.error = true;
            }
          }
        }
        if ($scope.applicationStep2.fysaFyspWfr) {
          if (!$scope.applicationStep2.militaryBaseLocation) {
            $scope.fieldsMissing.push('Military Base Location');
          }
        }
        if ($scope.fieldsMissing.length > 0) {
          $scope.applicationStep2.stepStatus =
            APPLICATION_STEP_STATUS.incomplete;
          if (exit && $scope.fieldsMissing.length <= 0) {
            $scope.saveApplicationStep(exit);
          } else {
            $('#missingInfo').modal();
          }
        } else {
          if (
            $scope.applicationStep2.applicationType ===
            APPLICATION_TYPE.member_of_federation
          ) {
            var preAppDetails = ApplicationService.getPreApplicationDetails();
            var pledgeYear = preAppDetails.pledgeYear;
            ApplicationService.findCurrentCharityInfo(
              $scope.applicationStep2.parentCfcCode,
              pledgeYear
            ).then(function(value) {
              if (!value) {
                $scope.fieldsMissing.push(
                  'You cannot create a member application for the selected parent charity because the parent charity is not accepting applications at this time due to its current application status.'
                );
                $scope.error = true;
                $('#missingInfo').modal();
                return;
              }

              var applicationStatus = value.applicationStatus;
              var charityType = value.charityType;

              if (value.applicationId) {
                ApplicationService.getApplication().federationId =
                  value.applicationId;
              }

              switch (applicationStatus) {
                case APPLICATION_STATUS.withdrwan:
                  $scope.fieldsMissing.push(
                    'Selected Parent CFC code is Withdrawn.'
                  );
                  $scope.error = true;
                  break;
                case APPLICATION_STATUS.disbanded:
                  $scope.fieldsMissing.push(
                    'Selected Parent CFC code is Disbanded.'
                  );
                  $scope.error = true;
                  break;
                case APPLICATION_STATUS.submitted:
                case APPLICATION_STATUS.approved:
                case APPLICATION_STATUS.rejected:
                case APPLICATION_STATUS.review:
                case APPLICATION_STATUS.moreinfo:
                  $scope.fieldsMissing.push(
                    'The parent charity application has already been submitted and you cannot create a member application for the selected parent charity.'
                  );
                  $scope.error = true;
                  break;
              }

              if (
                charityType === CHARITY_TYPE.local &&
                $scope.applicationStep2.charityType !== CHARITY_TYPE.local
              ) {
                $scope.fieldsMissing.push(
                  'Your Charity Organization Type must match the Organization Type of the Federation you are attempting to join.'
                );
                $scope.error = true;
                $scope.mismatch = true;
              }

              if (
                charityType !== CHARITY_TYPE.local &&
                $scope.applicationStep2.charityType === CHARITY_TYPE.local
              ) {
                $scope.fieldsMissing.push(
                  'Your Charity Organization Type must match the Organization Type of the Federation you are attempting to join.'
                );
                $scope.error = true;
                $scope.mismatch = true;
              }

              if ($scope.fieldsMissing.length > 0) {
                $scope.applicationStep2.stepStatus =
                  APPLICATION_STEP_STATUS.incomplete;
                if (exit && !$scope.error) {
                  $scope.saveApplicationStep(exit);
                } else {
                  $('#missingInfo').modal();
                }
              } else {
                $scope.applicationStep2.stepStatus =
                  APPLICATION_STEP_STATUS.complete;
                if (exit) {
                  $scope.saveApplicationStep(exit);
                } else {
                  $scope.saveApplicationStep();
                }
              }
            });
          } else {
            ApplicationService.getApplication().federationId = null;
            $scope.applicationStep2.parentCfcCode = null;
            $scope.applicationStep2.stepStatus =
              APPLICATION_STEP_STATUS.complete;
            // only allow to change the type if the user is the charity owner or editor
            CubeSvc.get(
              '/CfcCharity/api/private/isCharityOwnerOrEditor/' +
                $scope.applicationStep2.cfcCode,
              null,
              false
            ).then(function(res) {
              var isMemberFederationOwner = res.data.value;
              if (isMemberFederationOwner) {
                if (exit) {
                  $scope.confirmMemberToIndepentApplication(exit);
                  // $scope.saveApplicationStep(exit);
                } else {
                  $scope.confirmMemberToIndepentApplication();
                  //  $scope.saveApplicationStep();
                }
              } else {
                $('#charityOwner').modal();
              }
            });
          }
        }
      };

      $scope.gotoMemberApplications = function() {
        $('.modal-backdrop').remove();
        $state.go('memberapplication', {
          userId: $scope.userId,
          year: $scope.currentPledgeYear,
          cfcCode: $scope.applicationPreviousParentCfcCode
        });
      };

      $scope.confirmMemberToIndepentApplication = function(exit) {
        //  member to independent Application confirmation
        if (
          $scope.applicationPreviousType ===
            APPLICATION_TYPE.member_of_federation &&
          $scope.applicationStep2.applicationType ===
            APPLICATION_TYPE.independent
        ) {
          $scope.applicationStepExit = exit;
          $('#memberToIndependentConfirmation').modal();
        } else {
          if (exit) {
            $scope.saveApplicationStep(exit);
          } else {
            $scope.saveApplicationStep();
          }
        }
      }; // end confirmMemberToIndepentApplication function

      $scope.resetApplicationTypeToMember = function() {
        $scope.applicationStep2.applicationType =
          APPLICATION_TYPE.member_of_federation;
        $scope.applicationStep2.parentCfcCode =
          $scope.applicationPreviousParentCfcCode;
        $scope.applicationStep2.charityType = $scope.orginalCharityType;
        $scope.applicationStep2.fysaFyspWfr = $scope.originalDODcheck;
        $scope.applicationStep2.militaryBaseLocation =
          $scope.originalMilitaryBaseLocation;
      }; // end resetApplicationTypeToMember function

      $scope.gotoNextStep = function(mode) {
        if ($scope.applicationStep2.fysaFyspWfr) {
          $state.go('step2a', {
            mode: mode,
            parentFedAppId: ApplicationService.getParentFederationApplicationId()
          });
        } else {
          $state.go('step3', {
            mode: mode,
            parentFedAppId: ApplicationService.getParentFederationApplicationId()
          });
        }
      };

      $scope.saveApplicationStep = function(exit) {
        $scope.applicationStep2.cfcCode = ApplicationService.getCfcCode();
        var pledgeYear = ApplicationService.getPledgeYear();
        var currentTime = ApplicationService.toUTCDate(new Date());
        var dateDifferenceInSeconds = 0;
        var userSession = ApplicationService.getUserSession();
        userSession.step2AccessEndDate = currentTime;
        dateDifferenceInSeconds = ApplicationService.getDateDifference(
          currentTime,
          userSession.step2AccessBeginDate
        );
        userSession.step2TimeSpentInSeconds = dateDifferenceInSeconds;
        if (exit) {
          userSession.lastAccessedEndDate = currentTime;
          userSession.totalTimeSpentInSeconds = ApplicationService.getDateDifference(
            currentTime,
            userSession.lastAccessedBeginDate
          );
        }
        ApplicationService.saveStep($scope.applicationStep2, function(
          response
        ) {
          var app = ApplicationService.getApplication();
          if (response.fysaFyspWfr !== $scope.previousFysaFyspWfr) {
            app.overallApplicationStatus = 'Incomplete';
          }

          ApplicationService.saveUserSession().then(function(response) {
            var overallStatus = 'Complete';
            ApplicationService.getApplicationStatus(
              ApplicationService.getApplicationId(),
              function(data) {
                for (var i = 0; i < data.length; i++) {
                  if (data[i].stepStatus === 'Incomplete') {
                    overallStatus = 'Incomplete';
                    break;
                  }
                }
                var app = ApplicationService.getApplication();
                app.overallApplicationStatus = overallStatus;
                ApplicationService.saveTable(app, function(response) {
                  if (response.federationId === null) {
                    ApplicationService.setParentFederationApplicationId(null);
                  } else {
                    ApplicationService.setParentFederationApplicationId(
                      response.federationId
                    );
                  }
                  CharityService.getPreApplicationDetails(
                    $scope.applicationStep2.cfcCode,
                    pledgeYear
                  ).then(function(res) {
                    var data = res.data.value;
                    ApplicationService.setPreApplicationDetails(data);
                  });
                  CubeSvc.performManualCacheRelease();
                  if (exit) {
                    $('.modal-backdrop').remove();
                    $scope.setAppStep(0);
                    $state.go('applicationDashboard', {
                      applicationId: ApplicationService.getApplicationId()
                    });
                  } else {
                    $('.modal-backdrop').remove();
                    $scope.setFysa($scope.applicationStep2.fysaFyspWfr);
                    if ($scope.applicationStep2.fysaFyspWfr) {
                      $state.go('step2a', {
                        applicationId: ApplicationService.getApplicationId(),
                        parentFedAppId: ApplicationService.getParentFederationApplicationId()
                      });
                    } else {
                      $state.go('step3', {
                        applicationId: ApplicationService.getApplicationId(),
                        parentFedAppId: ApplicationService.getParentFederationApplicationId()
                      });
                    }
                  }
                });
              }
            );
          });
        });
      };

      function init() {
        $scope.appLabel = ApplicationService.getAppLabel();
      }

      init();
    }
  ])
  .controller('ApplicationStep2SearchCtrl', [
    '$state',
    '$scope',
    '$location',
    'MODULE_NAME',
    'APPLICATION_TYPE',
    'APPLICATION_STEP_STATUS',
    'Step2CharitySearch',
    'ApplicationService',
    'ValidationService',
    'BrowserEventService',
    function(
      $state,
      $scope,
      $location,
      MODULE_NAME,
      APPLICATION_TYPE,
      APPLICATION_STEP_STATUS,
      Step2CharitySearch,
      ApplicationService,
      ValidationService,
      BrowserEventService
    ) {
      $scope.mode = $state.params.mode
        ? $state.params.mode
        : BrowserEventService.mode;
      BrowserEventService.setMode($scope.mode);

      $location.hash('top-container');
      $scope.gotoPageSection = function(divId) {
        $location.hash(divId);
      };

      $scope.searchFederation = function() {
        $scope.searchFederationError = '';
        if ($scope.einSearch || $scope.federationNameSearch) {
          if ($scope.einSearch) {
            var isEINStartingWithZero = ValidationService.validateStartingZeroFormat(
              'EIN',
              $scope.einSearch
            );
            if (!isEINStartingWithZero) {
              $state.go('step2SearchResults', {
                paramName: 'Ein',
                paramValue: $scope.einSearch
              });
            } else {
              $scope.searchFederationError = isEINStartingWithZero;
            }
          } else {
            $state.go('step2SearchResults', {
              paramName: 'charityName',
              paramValue: $scope.federationNameSearch
            });
          }
        } else {
          $scope.searchFederationError = 'Please enter EIN or Federation name.';
        }
      };
    }
  ])
  .controller('ApplicationStep2SearchResultCtrl', [
    '$state',
    '$scope',
    '$location',
    'MODULE_NAME',
    'APPLICATION_TYPE',
    'APPLICATION_STEP_STATUS',
    'Step2CharitySearch',
    'ApplicationService',
    'BrowserEventService',
    function(
      $state,
      $scope,
      $location,
      MODULE_NAME,
      APPLICATION_TYPE,
      APPLICATION_STEP_STATUS,
      Step2CharitySearch,
      ApplicationService,
      BrowserEventService
    ) {
      $scope.mode = $state.params.mode
        ? $state.params.mode
        : BrowserEventService.mode;
      BrowserEventService.setMode($scope.mode);

      $location.hash('top-container');
      $scope.gotoPageSection = function(divId) {
        $location.hash(divId);
      };

      if ($state.params.paramName === 'Ein') {
        Step2CharitySearch.findCharityWithEIN($state.params.paramValue).then(
          function(res) {
            var result = res.data;
            $scope.charities = result;
          }
        );
      } else {
        Step2CharitySearch.findCharityWithName($state.params.paramValue).then(
          function(res) {
            var result = res.data;
            $scope.charities = result;
          }
        );
      }
      $scope.selectFederation = function(cfcCode) {
        ApplicationService.getApplicationStep2().parentCfcCode = cfcCode;
        $state.go('step2', {
          fromPage: 'step2Search'
        });
      };
    }
  ])
  .controller('ApplicationStep2ACtrl', [
    '$state',
    '$scope',
    '$http',
    '$location',
    'CakeSvc',
    'MODULE_NAME',
    'APPLICATION_STEP_STATUS',
    'UserCharityRole',
    'ApplicationService',
    'ApplicationDashboardService',
    'BrowserEventService',
    'APPLICATION_TYPE',
    'CharityService',
    'DownloadService',
    'tokenData',
    '$log',
    'urlAccess',
    function(
      $state,
      $scope,
      $http,
      $location,
      CakeSvc,
      MODULE_NAME,
      APPLICATION_STEP_STATUS,
      UserCharityRole,
      ApplicationService,
      ApplicationDashboardService,
      BrowserEventService,
      APPLICATION_TYPE,
      CharityService,
      DownloadService,
      tokenData,
      $log,
      urlAccess
    ) {
      $http
        .get(
          `/CfcCharity/api/private/application/step2a/${ApplicationService.getApplicationId()}`
        )
        .then(function(response) {
          $scope.step2A_s3_pdf_link = response.data.value
            ? response.data.value.s3Link
            : null;
        })
        .catch(function(error) {
          console.error('Error: ', error);
        });
      $scope.setAppStep('2a');

      $location.hash('top-container');
      $scope.gotoPageSection = function(divId) {
        $location.hash(divId);
      };

      $scope.currentPledgeYear = sessionStorage.getItem(
        'CREATE_APPLICATION_CURRENT_PLEDGE_YEAR'
      );

      //check the access to show the breadcrumb links for member applications
      if (tokenData.federationId != null) {
        if ($scope.$parent.$$childHead.breadcrumbs.length > 0) {
          urlAccess
            .getFederationApplicationAccess(
              tokenData.pledgeYear,
              tokenData.federationCfc,
              $scope.$parent.$$childHead.breadcrumbs[0]
            )
            .then(function(res) {
              $scope.$parent.$$childHead.breadcrumbs[0] = res;
            });
          urlAccess
            .getMemberApplicationAccess(
              tokenData.pledgeYear,
              tokenData.federationCfc,
              $scope.$parent.$$childHead.breadcrumbs[1]
            )
            .then(function(res) {
              $scope.$parent.$$childHead.breadcrumbs[1] = res;
            });
        }
      }
      $scope.mode = $state.params.mode
        ? $state.params.mode
        : BrowserEventService.mode;
      BrowserEventService.setMode($scope.mode);
      ApplicationService.getUserSession().step2aAccessBeginDate = ApplicationService.toUTCDate(
        new Date()
      );
      if (!$scope.applicationStep2A) {
        $scope.applicationStep2A = ApplicationService.getApplicationStep2A();
        if (angular.equals($scope.applicationStep2A, {})) {
          $scope.applicationStep2A.applicationId = ApplicationService.getApplicationId();
          $scope.applicationStep2A.cfcCode = ApplicationService.getCfcCode();
          $scope.applicationStep2A.$get(
            function(res) {
              var tmp = CakeSvc.transformGenericResponseToFlatResourceResponse(
                res
              );
              if (!angular.equals(tmp, {})) {
                $scope.applicationStep2A = tmp;
              } else {
                $scope.applicationStep2A.applicationId = ApplicationService.getApplicationId();
                $scope.applicationStep2A.cfcCode = ApplicationService.getCfcCode();
              }
            },
            function(err) {
              $log.error('STEP2A failed to load');
            }
          );
        }
      }
      $scope.validateApplicationStep = function(exit) {
        $scope.fieldsMissing = [];
        $scope.fileError = false;
        if (
          !$scope.applicationStep2A.supportingFile &&
          !$scope.applicationStep2A.fileName
        ) {
          $scope.fieldsMissing.push('Base Commander Letter');
        } else {
          if (
            $scope.applicationStep2A.supportingFile &&
            $scope.applicationStep2A.supportingFile.type !== 'application/pdf'
          ) {
            $scope.fieldsMissing.push(
              'Base Commander Letter: Only PDF file is allowed.'
            );
            $scope.fileError = true;
          } else {
            $scope.fileError = false;
          }
        }

        if ($scope.fieldsMissing.length > 0) {
          $scope.applicationStep2A.stepStatus =
            APPLICATION_STEP_STATUS.incomplete;
          if (exit && !$scope.fileError) {
            $scope.saveApplicationStep(exit);
          } else {
            $('#missingInfo').modal();
          }
        } else {
          $scope.applicationStep2A.stepStatus =
            APPLICATION_STEP_STATUS.complete;
          if (exit) {
            $scope.saveApplicationStep(exit);
          } else {
            $scope.saveApplicationStep();
          }
        }
      };
      $scope.continue = false;
      $scope.saveApplicationStep = function(exit) {
        var currentTime = ApplicationService.toUTCDate(new Date());
        var dateDifferenceInSeconds = 0;
        var userSession = ApplicationService.getUserSession();
        userSession.step2aAccessEndDate = currentTime;
        dateDifferenceInSeconds = ApplicationService.getDateDifference(
          currentTime,
          userSession.step2aAccessBeginDate
        );
        userSession.step2aTimeSpentInSeconds = dateDifferenceInSeconds;
        if (exit) {
          userSession.lastAccessedEndDate = currentTime;
          userSession.totalTimeSpentInSeconds = ApplicationService.getDateDifference(
            currentTime,
            userSession.lastAccessedBeginDate
          );
        }
        ApplicationService.saveFileStep2A($scope.applicationStep2A, function(
          response
        ) {
          if (
            response.message &&
            response.message.type === 'ERROR' &&
            !$scope.continue
          ) {
            $('input[type="file"]').val(null);
            $scope.applicationStep2A.supportingFile = null;
            $('#modalUpload').modal();
            return;
          }

          if ((!response || response.errorCode) && !$scope.continue) {
            $scope.applicationStep2A.supportingFile = null;
            $('#modalUpload').modal();
            return;
          }
          if (response) {
            $scope.applicationStep2A.fileName = response.fileName;
          }
          ApplicationService.saveUserSession().then(function(response) {
            var overallStatus = 'Complete';
            ApplicationService.getApplicationStatus(
              ApplicationService.getApplicationId(),
              function(data) {
                for (var i = 0; i < data.length; i++) {
                  if (data[i].stepStatus === 'Incomplete') {
                    overallStatus = 'Incomplete';
                    break;
                  }
                }
                var app = ApplicationService.getApplication();
                app.overallApplicationStatus = overallStatus;

                ApplicationService.saveTable(app, function(response) {
                  if (exit) {
                    $scope.setAppStep(0);
                    $state.go('applicationDashboard', {
                      applicationId: ApplicationService.getApplicationId()
                    });
                  } else {
                    $('.modal-backdrop').remove();
                    $scope.setAppStep(0);
                    $state.go('applicationDashboard', {
                      applicationId: ApplicationService.getApplicationId()
                    });
                  }
                });
              }
            );
          });
        });
      };

      function init() {
        $scope.appLabel = ApplicationService.getAppLabel();
      }

      init();
    }
  ])
  .controller('ApplicationStep3Ctrl', [
    '$state',
    '$scope',
    '$location',
    '$log',
    'CakeSvc',
    'MODULE_NAME',
    'APPLICATION_STEP_STATUS',
    'UserCharityRole',
    'ApplicationService',
    'BrowserEventService',
    'APPLICATION_TYPE',
    'CharityService',
    'tokenData',
    'urlAccess',
    function(
      $state,
      $scope,
      $location,
      $log,
      CakeSvc,
      MODULE_NAME,
      APPLICATION_STEP_STATUS,
      UserCharityRole,
      ApplicationService,
      BrowserEventService,
      APPLICATION_TYPE,
      CharityService,
      tokenData,
      urlAccess
    ) {
      $scope.setAppStep(3);

      $location.hash('top-container');
      $scope.gotoPageSection = function(divId) {
        $location.hash(divId);
      };

      $scope.currentPledgeYear = sessionStorage.getItem(
        'CREATE_APPLICATION_CURRENT_PLEDGE_YEAR'
      );

      //check the access to show the breadcrumb links for member applications
      if (tokenData.federationId != null) {
        if ($scope.$parent.$$childHead.breadcrumbs.length > 0) {
          urlAccess
            .getFederationApplicationAccess(
              tokenData.pledgeYear,
              tokenData.federationCfc,
              $scope.$parent.$$childHead.breadcrumbs[0]
            )
            .then(function(res) {
              $scope.$parent.$$childHead.breadcrumbs[0] = res;
            });
          urlAccess
            .getMemberApplicationAccess(
              tokenData.pledgeYear,
              tokenData.federationCfc,
              $scope.$parent.$$childHead.breadcrumbs[1]
            )
            .then(function(res) {
              $scope.$parent.$$childHead.breadcrumbs[1] = res;
            });
        }
      }

      $scope.mode = $state.params.mode
        ? $state.params.mode
        : BrowserEventService.mode;
      BrowserEventService.setMode($scope.mode);
      ApplicationService.getUserSession().step3AccessBeginDate = ApplicationService.toUTCDate(
        new Date()
      );
      if (!$scope.applicationStep3) {
        $scope.applicationStep3 = ApplicationService.getApplicationStep3();
        if (angular.equals($scope.applicationStep3, {})) {
          $scope.applicationStep3.applicationId = ApplicationService.getApplicationId();
          $scope.applicationStep3.cfcCode = ApplicationService.getCfcCode();
          $scope.applicationStep3.$get(
            function(res) {
              var tmp = CakeSvc.transformGenericResponseToFlatResourceResponse(
                res
              );
              // only if record exists, apply its existing values
              if (!angular.equals(tmp, {})) {
                $scope.applicationStep3 = tmp;
              } else {
                $scope.applicationStep3.applicationId = ApplicationService.getApplicationId();
                $scope.applicationStep3.cfcCode = ApplicationService.getCfcCode();
              }
            },
            function(err) {
              $log.error('STEP3 failed to load');
            }
          );
        }
      }
      $scope.validateApplicationStep = function(exit) {
        $scope.fieldsMissing = [];
        if (!$scope.applicationStep3.certifyWelfare) {
          $scope.fieldsMissing.push(
            'Certify human health and welfare services.'
          );
        }
        if ($scope.fieldsMissing.length > 0) {
          $scope.applicationStep3.stepStatus =
            APPLICATION_STEP_STATUS.incomplete;
          if (exit) {
            $scope.saveApplicationStep(exit);
          } else {
            $('#missingInfo').modal();
          }
        } else {
          $scope.applicationStep3.stepStatus = APPLICATION_STEP_STATUS.complete;
          if (exit) {
            $scope.saveApplicationStep(exit);
          } else {
            $scope.saveApplicationStep();
          }
        }
      };
      $scope.saveApplicationStep = function(exit) {
        var currentTime = ApplicationService.toUTCDate(new Date());
        var dateDifferenceInSeconds = 0;
        if (!$scope.applicationStep3.applicationId) {
          $scope.applicationStep3.applicationId = ApplicationService.getApplicationId();
        }
        if (!$scope.applicationStep3.cfcCode) {
          $scope.applicationStep3.cfcCode = ApplicationService.getCfcCode();
        }
        var userSession = ApplicationService.getUserSession();
        userSession.step3AccessEndDate = currentTime;
        dateDifferenceInSeconds = ApplicationService.getDateDifference(
          currentTime,
          userSession.step3AccessBeginDate
        );
        userSession.step3TimeSpentInSeconds = dateDifferenceInSeconds;
        if (exit) {
          userSession.lastAccessedEndDate = currentTime;
          userSession.totalTimeSpentInSeconds = ApplicationService.getDateDifference(
            currentTime,
            userSession.lastAccessedBeginDate
          );
        }
        ApplicationService.saveStep($scope.applicationStep3, function(
          response
        ) {
          ApplicationService.saveUserSession().then(function(response) {
            var overallStatus = 'Complete';
            ApplicationService.getApplicationStatus(
              ApplicationService.getApplicationId(),
              function(data) {
                for (var i = 0; i < data.length; i++) {
                  if (data[i].stepStatus === 'Incomplete') {
                    overallStatus = 'Incomplete';
                    break;
                  }
                }
                var app = ApplicationService.getApplication();
                app.overallApplicationStatus = overallStatus;
                ApplicationService.saveTable(app, function(response) {
                  if (exit) {
                    $scope.setAppStep(0);
                    $state.go('applicationDashboard', {
                      applicationId: ApplicationService.getApplicationId()
                    });
                  } else {
                    $('.modal-backdrop').remove();
                    $state.go('step4', {
                      applicationId: ApplicationService.getApplicationId(),
                      parentFedAppId: ApplicationService.getParentFederationApplicationId()
                    });
                  }
                });
              }
            );
          });
        });
      };
      function init() {
        $scope.appLabel = ApplicationService.getAppLabel();
      }
      init();
    }
  ])
  .controller('ApplicationStep4Ctrl', [
    '$state',
    '$scope',
    '$http',
    '$location',
    '$log',
    '$q',
    'CakeSvc',
    'MODULE_NAME',
    'CHARITY_TYPE',
    'APPLICATION_STEP_STATUS',
    '$stateParams',
    'UserCharityRole',
    'ApplicationService',
    'Step4Details',
    'CharityService',
    'BrowserEventService',
    'APPLICATION_TYPE',
    'tokenData',
    'urlAccess',
    'AreaOfServiceReviewService',
    'AdminReviewSectionStepDeterminationSessionService',
    'reviewLevelConstants',
    'ValidationUtil',
    'PLEDGE_YEARS',
    'DownloadService',
    '$rootScope',
    'DenialReasonsService',
    function(
      $state,
      $scope,
      $http,
      $location,
      $log,
      $q,
      CakeSvc,
      MODULE_NAME,
      CHARITY_TYPE,
      APPLICATION_STEP_STATUS,
      $stateParams,
      UserCharityRole,
      ApplicationService,
      Step4Details,
      CharityService,
      BrowserEventService,
      APPLICATION_TYPE,
      tokenData,
      urlAccess,
      AreaOfServiceReviewService,
      AdminReviewSectionStepDeterminationSessionService,
      reviewLevelConstants,
      ValidationUtil,
      PLEDGE_YEARS,
      DownloadService
    ) {
      function init() {
        $scope.setAppStep(4);
        $scope.displayForPriorYears = false;

        if ($scope.isSecondaryRoleOPMAdmin()) {
          AreaOfServiceReviewService.getStep4ReviewStatus(
            ApplicationService.getApplicationId()
          ).then(res => {
            $scope.aosCurrentReviewStatus = res.data.status;
          });
        }

        $location.hash('top-container');
        $scope.gotoPageSection = function(divId) {
          $location.hash(divId);
        };

        var step4_history_url =
          '/CfcCharity/api/private/application/step4/' +
          ApplicationService.getApplicationId() +
          '/uploadhistory';
        $http
          .get(step4_history_url)
          .then(function(response) {
            $scope.webBasedServiceHistoryList =
              response.data.webBasedServiceHistoryList;
          })
          .catch(function(error) {
            console.error('Error: ', error);
          });

        $scope.getWebBasedServiceHistoryList = function() {
          return $scope.webBasedServiceHistoryList;
        };

        $scope.currentPledgeYear = sessionStorage.getItem(
          'CREATE_APPLICATION_CURRENT_PLEDGE_YEAR'
        );

        $scope.pledgeYear = tokenData.pledgeYear;
        if (
          $scope.pledgeYear &&
          $scope.pledgeYear < PLEDGE_YEARS.PLEDGE_YEAR_2022
        ) {
          $scope.displayForPriorYears = true;
        }
        //check the access to show the breadcrumb links for member applications
        if (tokenData.federationId != null) {
          if ($scope.$parent.$$childHead.breadcrumbs.length > 0) {
            urlAccess
              .getFederationApplicationAccess(
                tokenData.pledgeYear,
                tokenData.federationCfc,
                $scope.$parent.$$childHead.breadcrumbs[0]
              )
              .then(function(res) {
                $scope.$parent.$$childHead.breadcrumbs[0] = res;
              });
            urlAccess
              .getMemberApplicationAccess(
                tokenData.pledgeYear,
                tokenData.federationCfc,
                $scope.$parent.$$childHead.breadcrumbs[1]
              )
              .then(function(res) {
                $scope.$parent.$$childHead.breadcrumbs[1] = res;
              });
          }
        }

        $scope.mode = $state.params.mode
          ? $state.params.mode
          : BrowserEventService.mode;
        BrowserEventService.setMode($scope.mode);
        if (
          !$stateParams.fromPage &&
          $stateParams.fromPage !== 'step4Attachments'
        ) {
          ApplicationService.getUserSession().step4AccessBeginDate = ApplicationService.toUTCDate(
            new Date()
          );
        }
        if (!$scope.applicationStep4) {
          $scope.applicationStep4 = ApplicationService.getApplicationStep4();
          $scope.applicationStep4.applicationId = ApplicationService.getApplicationId();
          $scope.applicationStep4.cfcCode = ApplicationService.getCfcCode();
          $scope.applicationStep4.$get(
            function(res) {
              var tmp = CakeSvc.transformGenericResponseToFlatResourceResponse(
                res
              );
              if (!angular.equals(tmp, {})) {
                $scope.applicationStep4 = tmp;
              } else {
                $scope.applicationStep4.applicationId = ApplicationService.getApplicationId();
                $scope.applicationStep4.cfcCode = ApplicationService.getCfcCode();
              }
              if (
                $scope.applicationStep4.webBasedService === null ||
                $scope.applicationStep4.webBasedService === undefined ||
                $scope.applicationStep4.webBasedService === false
              ) {
                $scope.applicationStep4.webBasedService = 'No';
              } else {
                $scope.applicationStep4.webBasedService = 'Yes';
              }
              if (
                $scope.applicationStep4.changedFileName != null ||
                $scope.applicationStep4.changedFileName != undefined
              ) {
                var us = $scope.applicationStep4.changedFileName.lastIndexOf(
                  '_'
                );
                $scope.currentFileVersion = parseInt(
                  $scope.applicationStep4.changedFileName
                    .substring(
                      us,
                      $scope.applicationStep4.changedFileName.length
                    )
                    .replace('_ver', '')
                    .replace(/\.[^\.]+$/, '')
                );
              }
            },
            function(err) {
              $log.error('STEP4 failed to load');
            }
          );
        }
        $scope.applicationStep2 = ApplicationService.getApplicationStep2();
        $q(function(resolve, reject) {
          $scope.applicationStep2.applicationId = ApplicationService.getApplicationId();
          $scope.applicationStep2.cfcCode = ApplicationService.getCfcCode();
          $scope.applicationStep2.$get(
            function(res) {
              $scope.applicationStep2 = CakeSvc.transformGenericResponseToFlatResourceResponse(
                res
              );
              resolve(res);
            },
            function(err) {
              $log.error('STEP2 data in STEP4 failed to load');
              reject(err);
            }
          );
        })
          .then(function() {
            $scope.charity_type = $scope.applicationStep2.charityType;
            if (
              $scope.applicationStep2.stepStatus ===
              APPLICATION_STEP_STATUS.complete
            ) {
              $scope.applicationStep4.step2status = true;
            } else {
              $scope.applicationStep4.step2status = false;
            }
          })
          .finally(() => {
            $scope.appLabel = ApplicationService.getAppLabel();
            getAreaOfServices();
          });

        $scope.isOffYear = ApplicationService.getPreApplicationDetails().isOffYear;
      }

      $scope.setAosCurrentReviewStatus = function(status) {
        AreaOfServiceReviewService.setStep4ReviewStatus(
          $scope.applicationStep4.applicationId,
          status
        )
          .then(async res => {
            if (res.status === 200) {
              $scope.aosCurrentReviewStatus = status;
            }
          })
          .catch(err => {
            $scope.setErrorMessage(`Unable to set status:  ${err}`);
          });
      };

      $scope.getFileList = function() {
        return new Array($scope.currentFileVersion);
      };

      $scope.addAttachment = function(parFedAppId) {
        if ($scope.charity_type === CHARITY_TYPE.local) {
          $state.go('step4LocalAttachment', { parentFedAppId: parFedAppId });
        } else {
          $state.go('step4NationalAttachment', { parentFedAppId: parFedAppId });
        }
      };
      $scope.editAttachment = function(step4Id, parFedAppId) {
        if ($scope.charity_type === CHARITY_TYPE.local) {
          $state.go('step4LocalAttachment', {
            id: step4Id,
            parentFedAppId: parFedAppId
          });
        } else {
          $state.go('step4NationalAttachment', {
            id: step4Id,
            parentFedAppId: parFedAppId
          });
        }
      };
      $scope.deleteAttachmentDlg = function(step4Id) {
        $scope.attachmentId = step4Id;
        $('#deleteAttachment').modal('show');
      };
      $scope.deleteAttachment = function(attachmentId) {
        Step4Details.deleteStep4Detail(
          $scope.applicationStep4.applicationId,
          attachmentId
        ).then(function() {
          Step4Details.queryStep4Details(
            ApplicationService.getApplicationId()
          ).then(function(res) {
            const localIntlAttachmentLimit = 3;
            const natlAttachmentLimit = 45;
            $scope.services = $scope.services.filter(
              service => service.id !== attachmentId
            );
            if (
              [CHARITY_TYPE.local, CHARITY_TYPE.international].includes(
                $scope.charity_type
              )
            ) {
              $scope.maximumAttachmentReached =
                $scope.services.length >= localIntlAttachmentLimit;
            } else {
              $scope.maximumAttachmentReached =
                $scope.services.length >= natlAttachmentLimit;
            }
          });
        });
      };
      $scope.validateApplicationStep = function(exit) {
        $scope.fieldsMissing = [];
        $scope.certificationError = false;
        $scope.webServiceError = false;
        $scope.hrsOfOp = false;
        if (
          $scope.attachmentA !== 'optional' &&
          ($scope.applicationStep4.certifyLocalPresence == null ||
            !$scope.applicationStep4.certifyLocalPresence)
        ) {
          $scope.certificationError = true;
          $scope.fieldsMissing.push(
            'Please make sure Certification is checked.'
          );
        }
        if (ApplicationService.getPreApplicationDetails().reviewRequired) {
          if ($scope.charity_type === CHARITY_TYPE.local) {
            //if full application and  local
            if (
              !$scope.displayForPriorYears &&
              !$scope.isOffYear &&
              ValidationUtil.isStringEmpty(
                $scope.applicationStep4.hoursOfOperation
              )
            ) {
              $scope.fieldsMissing.push('Please enter Hours of Operation.');
              $scope.hrsOfOp = true;
            }
            if (
              !angular.isArray($scope.services) ||
              $scope.services.length < 1
            ) {
              $scope.localError = true;
              $scope.fieldsMissing.push(
                'Please submit Area of service information. Local Charities must submit 1 attachment.'
              );
            }
          }
          if ($scope.charity_type === CHARITY_TYPE.national) {
            $scope.currentYearExplanationError = true;
            var interN = false;
            var allStates = [];
            angular.forEach($scope.services, function(value, index) {
              if (value.officeCountry !== 'US') {
                interN = true;
              } else {
                allStates.push(value.officeState);
              }
              if (
                $scope.currentYearExplanationError &&
                (ValidationUtil.isStringEmpty(value.serviceDescHow) &&
                  ValidationUtil.isStringEmpty(value.serviceDescWho) &&
                  ValidationUtil.isStringEmpty(value.serviceDescWhat) &&
                  ValidationUtil.isStringEmpty(value.serviceDescWhere) &&
                  ValidationUtil.isStringEmpty(value.serviceDescWhen)) === false
              ) {
                $scope.currentYearExplanationError = false;
              }
            });
            if (!interN && allStates.unique2().length < 15) {
              $scope.nationalError = true;
              var msg =
                'Please make sure that descriptions were provided for services in a minimum of 15 states and/or one foreign country and that at least one eligible service was reported in any state or foreign country in calendar year ' +
                ($scope.pledgeYear - 1) +
                '. Each service description must include the number of beneficiaries and/or monetary value of the services.';
              $scope.fieldsMissing.push(msg);
            }
            if (
              $scope.currentYearExplanationError &&
              $scope.nationalError === undefined
            ) {
              var msg =
                'Please make sure that descriptions were provided for services in a minimum of 15 states and/or one foreign country and that at least one eligible service was reported in any state or foreign country in calendar year ' +
                ($scope.pledgeYear - 1) +
                '. Each service description must include the number of beneficiaries and/or monetary value of the services.';
              $scope.fieldsMissing.push(msg);
            }
          }
          if ($scope.charity_type === CHARITY_TYPE.international) {
            $scope.currentYearExplanationInternationalError = true;
            if (
              !angular.isArray($scope.services) ||
              $scope.services.length < 1
            ) {
              $scope.internationalError = true;
              var msg =
                'Please make sure that description(s) were provided for services in a minimum of one foreign country and that at least one eligible service was reported for calendar year ' +
                ($scope.pledgeYear - 1) +
                '. Each service description must include the number of beneficiaries and/or monetary value of the services.';
              $scope.fieldsMissing.push(msg);
            }
            angular.forEach($scope.services, function(value, index) {
              if (
                $scope.currentYearExplanationInternationalError &&
                (ValidationUtil.isStringEmpty(value.serviceDescHow) &&
                  ValidationUtil.isStringEmpty(value.serviceDescWho) &&
                  ValidationUtil.isStringEmpty(value.serviceDescWhat) &&
                  ValidationUtil.isStringEmpty(value.serviceDescWhere) &&
                  ValidationUtil.isStringEmpty(value.serviceDescWhen)) === false
              ) {
                $scope.currentYearExplanationInternationalError = false;
              }
            });
            if (
              $scope.currentYearExplanationInternationalError &&
              $scope.internationalError === undefined
            ) {
              var msg =
                'Please make sure that description(s) were provided for services in a minimum of one foreign country and that at least one eligible service was reported for calendar year ' +
                ($scope.pledgeYear - 1) +
                '. Each service description must include the number of beneficiaries and/or monetary value of the services.';
              $scope.fieldsMissing.push(msg);
            }
          }
        }
        if ($scope.services && $scope.services.length > 0) {
          for (var i = 0; i < $scope.services.length; i++) {
            if ($scope.services[i].status !== 'Pass') {
              $scope.attachmentError = true;
              break;
            } else {
              $scope.attachmentError = false;
            }
          }
        }
        if (
          $scope.applicationStep4.webBasedService === 'Yes' &&
          ($scope.applicationStep4.changedFileName === null ||
            $scope.applicationStep4.changedFileName === undefined) &&
          !$scope.applicationStep4.supportingFile
        ) {
          $scope.fieldsMissing.push(
            '"Yes" was selected that your organization provided web-based services, however, additional documentation was not provided as required. "No" should be selected if your organization is not reporting web-based services.'
          );
          $scope.webServiceError = true;
        }
        if (
          $scope.applicationStep4.webBasedService === 'Yes' &&
          $scope.applicationStep4.supportingFile &&
          $scope.applicationStep4.supportingFile.type !== 'application/pdf'
        ) {
          $scope.fileError = true;
          $scope.fieldsMissing.push(
            'Web-Based Services: Only PDF file is allowed.'
          );
        } else {
          $scope.fileError = false;
        }
        if (
          $scope.localError ||
          $scope.nationalError ||
          $scope.internationalError ||
          $scope.certificationError ||
          $scope.currentYearExplanationError ||
          $scope.currentYearExplanationInternationalError ||
          $scope.webServiceError ||
          $scope.hrsOfOp
        ) {
          $scope.applicationStep4.stepStatus =
            APPLICATION_STEP_STATUS.incomplete;
          if (exit) {
            $scope.saveApplicationStep(exit);
          } else {
            $('#missingInfo').modal();
          }
        } else {
          if ($scope.attachmentError) {
            $scope.applicationStep4.stepStatus =
              APPLICATION_STEP_STATUS.incomplete;
          } else {
            $scope.applicationStep4.stepStatus =
              APPLICATION_STEP_STATUS.complete;
          }
          if (exit) {
            $scope.saveApplicationStep(exit);
          } else {
            $scope.saveApplicationStep();
          }
        }
      };
      $scope.saveApplicationStep = function(exit) {
        var currentTime = ApplicationService.toUTCDate(new Date());
        var dateDifferenceInSeconds = 0;
        var userSession = ApplicationService.getUserSession();
        userSession.step4AccessEndDate = currentTime;
        dateDifferenceInSeconds = ApplicationService.getDateDifference(
          currentTime,
          userSession.step4AccessBeginDate
        );
        userSession.step4TimeSpentInSeconds = dateDifferenceInSeconds;
        if (exit) {
          userSession.lastAccessedEndDate = currentTime;
          userSession.totalTimeSpentInSeconds = ApplicationService.getDateDifference(
            currentTime,
            userSession.lastAccessedBeginDate
          );
        }
        if ($scope.applicationStep4.webBasedService === 'Yes') {
          $scope.applicationStep4.webBasedService = true;
        } else {
          $scope.applicationStep4.webBasedService = false;
        }
        ApplicationService.saveFileStep4($scope.applicationStep4, function(
          response
        ) {
          ApplicationService.saveUserSession().then(function(response) {
            var overallStatus = 'Complete';
            ApplicationService.getApplicationStatus(
              ApplicationService.getApplicationId(),
              function(data) {
                for (var i = 0; i < data.length; i++) {
                  if (data[i].stepStatus === 'Incomplete') {
                    overallStatus = 'Incomplete';
                    break;
                  }
                }
                var app = ApplicationService.getApplication();
                app.overallApplicationStatus = overallStatus;
                ApplicationService.saveTable(app, function(response) {
                  if (exit) {
                    $scope.setAppStep(0);
                    $state.go('applicationDashboard', {
                      applicationId: ApplicationService.getApplicationId()
                    });
                  } else {
                    $('.modal-backdrop').remove();
                    $state.go('step5', {
                      applicationId: ApplicationService.getApplicationId(),
                      parentFedAppId: ApplicationService.getParentFederationApplicationId()
                    });
                  }
                });
              }
            );
          });
        });
      };

      Array.prototype.unique2 = function() {
        var n = {},
          r = [];
        for (var i = 0; i < this.length; i++) {
          if (!n[this[i]]) {
            n[this[i]] = true;
            r.push(this[i]);
          }
        }
        return r;
      };

      function getAreaOfServices() {
        Step4Details.queryStep4Details(ApplicationService.getApplicationId())
          .then(function(res) {
            const localIntlAttachmentLimit = 3;
            const natlAttachmentLimit = 45;
            var services = res.data.value;
            $scope.maximumAttachmentReached = false;
            $scope.charity_type = $scope.applicationStep2.charityType;
            var preAppDetails = ApplicationService.getPreApplicationDetails();
            if (preAppDetails != null && preAppDetails.reviewRequired) {
              if ($scope.charity_type === CHARITY_TYPE.local) {
                $scope.attachmentA = CHARITY_TYPE.local;
                $scope.maximumAttachmentReached =
                  services.length >= localIntlAttachmentLimit;
              } else if ($scope.charity_type === CHARITY_TYPE.national) {
                $scope.attachmentA = CHARITY_TYPE.national;
                $scope.maximumAttachmentReached =
                  services.length >= natlAttachmentLimit;
              } else if ($scope.charity_type === CHARITY_TYPE.international) {
                $scope.attachmentA = CHARITY_TYPE.international;
                $scope.maximumAttachmentReached =
                  services.length >= localIntlAttachmentLimit;
              }
            } else {
              $scope.attachmentA = 'optional';
            }
            $scope.applicationStep4.isOffYear = preAppDetails.isOffYear;

            return services;
          })
          .then(function(services) {
            if (Array.isArray(services) && services.length) {
              AreaOfServiceReviewService.getAppReviewPermission(
                ApplicationService.getApplicationId(),
                $scope.applicationStep2.applicationType
              )
                .then(function(res) {
                  var editClaim = res.data.value === 'EDIT';
                  var viewClaim = res.data.value === 'VIEW';
                  if (!viewClaim && !editClaim) {
                    $scope.services = services;
                    return $q.reject();
                  }
                  $scope.showReviewStatus = viewClaim || editClaim;
                  return AreaOfServiceReviewService.getDeterminationCodes();
                })
                .then(function(res) {
                  var determinationCodes = res.data;
                  angular.forEach(services, function(service) {
                    AreaOfServiceReviewService.getStep4ReviewDetail(
                      service.applicationId,
                      service.id
                    ).then(function(res) {
                      var reviewDetail = res.data.value;
                      var reviewDetailStatus = setReviewDetailStatus(
                        reviewDetail,
                        determinationCodes
                      );
                      service.areaOfServiceReviewStatusHtml =
                        '<i class="' +
                        reviewDetailStatus.statusIcon +
                        '" aria-hidden="true"></i> ' +
                        reviewDetailStatus.status;
                    });
                  });
                  $scope.services = services;
                })
                .catch(function() {
                  // error handling provided by CubeSvc
                });
            }
          })
          .catch(function() {});
      }

      function getDeterminationCode(determinationCodeId, determinationCodes) {
        if (!determinationCodeId) {
          return {
            lookupName: 'Not Set',
            auxiliary: 'fa fa-circle-o fa-lg text-grey'
          };
        }
        return determinationCodes.find(function(determinationCode) {
          return determinationCode.id === determinationCodeId;
        });
      }

      function setReviewDetailStatus(areaOfServiceReview, determinationCodes) {
        var reviewDetailStatus = {
          status: null,
          statusIcon: null
        };
        var currentReviewLevel = currentReviewLevelOverrideMap(
          areaOfServiceReview.currentReviewLevel
        );

        switch (currentReviewLevel) {
          case 'Completeness':
            var determinationCode = getDeterminationCode(
              areaOfServiceReview.completenessReviewStatus,
              determinationCodes
            );
            reviewDetailStatus.status = determinationCode.lookupName;
            reviewDetailStatus.statusIcon = determinationCode.auxiliary;
            break;
          case 'Preliminary':
            var determinationCode = getDeterminationCode(
              areaOfServiceReview.preliminaryReviewStatus,
              determinationCodes
            );
            reviewDetailStatus.status = determinationCode.lookupName;
            reviewDetailStatus.statusIcon = determinationCode.auxiliary;
            break;
          case 'Staff':
            var determinationCode = getDeterminationCode(
              areaOfServiceReview.staffReviewStatus,
              determinationCodes
            );
            reviewDetailStatus.status = determinationCode.lookupName;
            reviewDetailStatus.statusIcon = determinationCode.auxiliary;
            break;
          case 'Final':
            var determinationCode = getDeterminationCode(
              areaOfServiceReview.finalReviewStatus,
              determinationCodes
            );
            reviewDetailStatus.status = determinationCode.lookupName;
            reviewDetailStatus.statusIcon = determinationCode.auxiliary;
            break;
          default:
            var notSetCode = determinationCodes.find(function(
              determinationCode
            ) {
              return determinationCode.lookupCd === 'NOT_SET';
            });
            reviewDetailStatus.status = notSetCode.lookupName;
            reviewDetailStatus.statusIcon = notSetCode.auxiliary;
        }
        return reviewDetailStatus;
      }
      function currentReviewLevelOverrideMap(currentReviewLevel) {
        // Override the application's current review level with reviewer's manually selection.
        var sessionReviewLevel = AdminReviewSectionStepDeterminationSessionService.getCurrentReviewLevel();
        switch (sessionReviewLevel) {
          case reviewLevelConstants.Completeness:
            return 'Completeness';
          case reviewLevelConstants.StaffEligibility:
            return 'Staff';
          case reviewLevelConstants.PreliminaryEligibility:
            return 'Preliminary';
          case reviewLevelConstants.FinalEligibility:
            return 'Final';
          default:
            // current review level was not overriden
            return currentReviewLevel;
        }
      }
      init();
    }
  ])
  .controller('Step4AttachmentCtrl', [
    '$state',
    '$scope',
    '$location',
    '$log',
    '$q',
    '$stateParams',
    'CakeSvc',
    'MODULE_NAME',
    'CHARITY_TYPE',
    'UserCharityRole',
    'ApplicationService',
    'Step4Details',
    'CharityService',
    'BrowserEventService',
    'ValidationUtil',
    'APPLICATION_STEP_STATUS',
    'Step4',
    'tokenData',
    'urlAccess',
    'PLEDGE_YEARS',
    'DATES',
    '$filter',
    function(
      $state,
      $scope,
      $location,
      $log,
      $q,
      $stateParams,
      CakeSvc,
      MODULE_NAME,
      CHARITY_TYPE,
      UserCharityRole,
      ApplicationService,
      Step4Details,
      CharityService,
      BrowserEventService,
      ValidationUtil,
      APPLICATION_STEP_STATUS,
      Step4,
      tokenData,
      urlAccess,
      PLEDGE_YEARS,
      DATES,
      $filter
    ) {
      $scope.setAppStep(4);
      $('#serverError').hide();
      $location.hash('top-container');
      $scope.gotoPageSection = function(divId) {
        $location.hash(divId);
      };
      $scope.displayForPriorYears = false;
      $scope.currentPledgeYear = sessionStorage.getItem(
        'CREATE_APPLICATION_CURRENT_PLEDGE_YEAR'
      );

      //check the access to show the breadcrumb links for member applications
      if (tokenData.federationId != null) {
        if ($scope.$parent.$$childHead.breadcrumbs.length > 0) {
          urlAccess
            .getFederationApplicationAccess(
              tokenData.pledgeYear,
              tokenData.federationCfc,
              $scope.$parent.$$childHead.breadcrumbs[0]
            )
            .then(function(res) {
              $scope.$parent.$$childHead.breadcrumbs[0] = res;
            });
          urlAccess
            .getMemberApplicationAccess(
              tokenData.pledgeYear,
              tokenData.federationCfc,
              $scope.$parent.$$childHead.breadcrumbs[1]
            )
            .then(function(res) {
              $scope.$parent.$$childHead.breadcrumbs[1] = res;
            });
        }
      }

      Step4Details.queryStep4Details(
        ApplicationService.getApplicationId()
      ).then(response => {
        $scope.applicationAttachments = response.data.value;
      });
      $scope.maximumAttachmentReached = false;
      $scope.pledgeYear = ApplicationService.getPledgeYear();
      if (
        $scope.pledgeYear &&
        $scope.pledgeYear < PLEDGE_YEARS.PLEDGE_YEAR_2022
      ) {
        $scope.displayForPriorYears = true;
      }
      $scope.mode = $state.params.mode
        ? $state.params.mode
        : BrowserEventService.mode;
      BrowserEventService.setMode($scope.mode);
      var applicationStep2 = ApplicationService.getApplicationStep2();
      // CCA-3730 and friends, the rest of this is chained with a promise via $q
      $q(function(resolve, reject) {
        if (angular.equals(applicationStep2, {})) {
          applicationStep2.applicationId = ApplicationService.getApplicationId();
          applicationStep2.cfcCode = ApplicationService.getCfcCode();
          applicationStep2.$get(
            function(res) {
              applicationStep2 = CakeSvc.transformGenericResponseToFlatResourceResponse(
                res
              );
              resolve(applicationStep2);
            },
            function(err) {
              $log.error('STEP2 data in STEP4 failed to load');
              reject(err);
            }
          );
        } else {
          resolve(applicationStep2);
        }
      })
        .then(function(step2) {
          $scope.charity_type = step2.charityType;
        })
        .then(() => {
          if (!$stateParams.id) {
            $scope.applicationStep4 = {};
            var found = false;
            $scope.applicationStep4.applicationId = ApplicationService.getApplicationId();
            $scope.applicationStep4.cfcCode = ApplicationService.getCfcCode();
            $scope.applicationStep4.useNewAddress = false;

            if ($scope.charity_type === CHARITY_TYPE.national) {
              if (!$scope.applicationStep4.officeCountry) {
                $scope.applicationStep4.officeCountry = 'US';
              }
            }
          } else {
            $scope.step4DetailId = $stateParams.id;
            Step4Details.getStep4Detail(
              ApplicationService.getApplicationId(),
              $stateParams.id
            ).then(function(res) {
              var response = res.data.value;
              var found = false;
              $scope.applicationStep4 = response;
              $scope.applicationStep4.applicationId = ApplicationService.getApplicationId();
              $scope.applicationStep4.cfcCode = ApplicationService.getCfcCode();
              if ($scope.applicationStep4.serviceDescWhenStartDate != null) {
                var localDate = new Date(
                  $scope.applicationStep4.serviceDescWhenStartDate
                );
                var localTime = localDate.getTime();
                var localOffset = localDate.getTimezoneOffset() * 60000;
                $scope.sDate = new Date(localTime + localOffset);
                $scope.applicationStep4.serviceDescWhenStartDate = $filter(
                  'date'
                )(new Date($scope.sDate), 'MM/dd/yyyy');
              }
              if ($scope.applicationStep4.serviceDescWhenEndDate != null) {
                var localDate1 = new Date(
                  $scope.applicationStep4.serviceDescWhenEndDate
                );
                var localTime1 = localDate1.getTime();
                var localOffset1 = localDate1.getTimezoneOffset() * 60000;
                $scope.eDate = new Date(localTime1 + localOffset1);
                $scope.applicationStep4.serviceDescWhenEndDate = $filter(
                  'date'
                )(new Date($scope.eDate), 'MM/dd/yyyy');
                $scope.serviceEndDate =
                  $scope.applicationStep4.serviceDescWhenEndDate;
              }
              if (
                $scope.applicationStep4.serviceDescPrevYearWhenStartDate != null
              ) {
                var localDate2 = new Date(
                  $scope.applicationStep4.serviceDescPrevYearWhenStartDate
                );
                var localTime2 = localDate2.getTime();
                var localOffset2 = localDate2.getTimezoneOffset() * 60000;
                $scope.spDate = new Date(localTime2 + localOffset2);
                $scope.applicationStep4.serviceDescPrevYearWhenStartDate = $filter(
                  'date'
                )(new Date($scope.spDate), 'MM/dd/yyyy');
              }
              if (
                $scope.applicationStep4.serviceDescPrevYearWhenEndDate != null
              ) {
                var localDate3 = new Date(
                  $scope.applicationStep4.serviceDescPrevYearWhenEndDate
                );
                var localTime3 = localDate3.getTime();
                var localOffset3 = localDate3.getTimezoneOffset() * 60000;
                $scope.epDate = new Date(localTime3 + localOffset3);
                $scope.applicationStep4.serviceDescPrevYearWhenEndDate = $filter(
                  'date'
                )(new Date($scope.epDate), 'MM/dd/yyyy');
                $scope.servicePrevEndDate =
                  $scope.applicationStep4.serviceDescPrevYearWhenEndDate;
              }
              if (
                $scope.applicationStep4.serviceDescPrev2YearWhenStartDate !=
                null
              ) {
                var localDate4 = new Date(
                  $scope.applicationStep4.serviceDescPrev2YearWhenStartDate
                );
                var localTime4 = localDate4.getTime();
                var localOffset4 = localDate4.getTimezoneOffset() * 60000;
                $scope.sp2Date = new Date(localTime4 + localOffset4);
                $scope.applicationStep4.serviceDescPrev2YearWhenStartDate = $filter(
                  'date'
                )(new Date($scope.sp2Date), 'MM/dd/yyyy');
              }
              if (
                $scope.applicationStep4.serviceDescPrev2YearWhenEndDate != null
              ) {
                var localDate5 = new Date(
                  $scope.applicationStep4.serviceDescPrev2YearWhenEndDate
                );
                var localTime5 = localDate5.getTime();
                var localOffset5 = localDate5.getTimezoneOffset() * 60000;
                $scope.ep2Date = new Date(localTime5 + localOffset5);
                $scope.applicationStep4.serviceDescPrev2YearWhenEndDate = $filter(
                  'date'
                )(new Date($scope.ep2Date), 'MM/dd/yyyy');
                $scope.servicePrev2EndDate =
                  $scope.applicationStep4.serviceDescPrev2YearWhenEndDate;
              }

              if ($scope.charity_type === CHARITY_TYPE.national) {
                if (!$scope.applicationStep4.officeCountry) {
                  $scope.applicationStep4.officeCountry = 'US';
                }
              }
            });
          }
          if (
            ApplicationService.getPreApplicationDetails() != null &&
            ApplicationService.getPreApplicationDetails().reviewRequired
          ) {
            if ($scope.charity_type === CHARITY_TYPE.local) {
              $scope.attachmentA = CHARITY_TYPE.local;
            } else if ($scope.charity_type === CHARITY_TYPE.national) {
              $scope.attachmentA = CHARITY_TYPE.national;
            } else if ($scope.charity_type === CHARITY_TYPE.international) {
              $scope.attachmentA = CHARITY_TYPE.international;
            }
          } else {
            $scope.attachmentA = 'optional';
          }
          CharityService.findCharityWithCFC(
            ApplicationService.getCfcCode()
          ).then(function(res) {
            var result = res.data;
            if (result) {
              $scope.charity = result;
              CharityService.loadStates().then(function(res) {
                var states = res.data;
                $scope.states = states;
                if ($scope.charity_type !== CHARITY_TYPE.local) {
                  CharityService.loadCountries().then(function(res) {
                    var countries = res.data.filter(
                      item =>
                        !(
                          item.lookupCd === 'US' &&
                          $scope.charity_type === CHARITY_TYPE.international
                        )
                    );
                    $scope.countries = countries;
                  });
                }
              });
            }
          });
        });
      $scope.selectionChange = function() {
        const attachmentLimit = 3;
        const attachmentCount = $scope.applicationAttachments.filter(
          attachment => {
            if ($scope.applicationStep4.officeCountry !== 'US') {
              $scope.errorMessageOption = 'option';
              return (
                attachment.officeCountry ===
                $scope.applicationStep4.officeCountry
              );
            }
            $scope.errorMessageOption = 'state';
            return (
              attachment.officeState === $scope.applicationStep4.officeState
            );
          }
        ).length;
        if (attachmentCount >= attachmentLimit) {
          $scope.maximumAttachmentReached = true;
        } else {
          $scope.maximumAttachmentReached = false;
        }
      };
      $scope.editAddress = function() {
        $('#addAddress').modal('show');
      };
      $scope.removeAddress = function() {
        $scope.applicationStep4.officeAddress1 = null;
        $scope.applicationStep4.officeAddress2 = null;
        $scope.applicationStep4.officeCity = null;
        $scope.applicationStep4.officeState = null;
        $scope.applicationStep4.officeZip = null;
      };
      $scope.saveAddress = function() {
        if ($scope.addressForm.$valid) {
          $('#addAddress').modal('hide');
        } else {
          $scope.errors = true;
        }
      };

      $scope.cbOptionChanged = function() {
        if (
          $scope.serviceEndDate === undefined ||
          $scope.serviceEndDate === null
        ) {
          $scope.serviceEndDate =
            $scope.applicationStep4.serviceDescWhenEndDate;
        }
        if (
          $scope.servicePrevEndDate === undefined ||
          $scope.servicePrevEndDate === null
        ) {
          $scope.servicePrevEndDate =
            $scope.applicationStep4.serviceDescPrevYearWhenEndDate;
        }
        if (
          $scope.servicePrev2EndDate === undefined ||
          $scope.servicePrev2EndDate === null
        ) {
          $scope.servicePrev2EndDate =
            $scope.applicationStep4.serviceDescPrev2YearWhenEndDate;
        }
        if ($scope.applicationStep4.serviceDescOnetime) {
          $scope.applicationStep4.serviceDescWhenEndDate = null;
        } else {
          $scope.applicationStep4.serviceDescWhenEndDate =
            $scope.serviceEndDate;
        }
        if ($scope.applicationStep4.serviceDescOnetimePrev) {
          $scope.applicationStep4.serviceDescPrevYearWhenEndDate = null;
        } else {
          $scope.applicationStep4.serviceDescPrevYearWhenEndDate =
            $scope.servicePrevEndDate;
        }
        if ($scope.applicationStep4.serviceDescOnetimePrev2) {
          $scope.applicationStep4.serviceDescPrev2YearWhenEndDate = null;
        } else {
          $scope.applicationStep4.serviceDescPrev2YearWhenEndDate =
            $scope.servicePrev2EndDate;
        }
      };

      function isCurrentYearDataEmpty() {
        return (
          ValidationUtil.isStringEmpty(
            $scope.applicationStep4.serviceDescHow
          ) &&
          ValidationUtil.isStringEmpty(
            $scope.applicationStep4.serviceDescWho
          ) &&
          ValidationUtil.isStringEmpty(
            $scope.applicationStep4.serviceDescWhat
          ) &&
          ValidationUtil.isStringEmpty(
            $scope.applicationStep4.serviceDescWhere
          ) &&
          (!$scope.applicationStep4.serviceDescOnetime &&
            (!$scope.applicationStep4.serviceDescWhenStartDate &&
              !$scope.applicationStep4.serviceDescWhenEndDate)) &&
          ValidationUtil.isStringEmpty(
            $scope.applicationStep4.currentYearPeopleServed
          ) &&
          ValidationUtil.isStringEmpty(
            $scope.applicationStep4.currentYearDollarSpent
          )
        );
      }

      function isPrevYearDataEmpty() {
        return (
          ValidationUtil.isStringEmpty(
            $scope.applicationStep4.serviceDescPrevYearHow
          ) &&
          ValidationUtil.isStringEmpty(
            $scope.applicationStep4.serviceDescPrevYearWho
          ) &&
          ValidationUtil.isStringEmpty(
            $scope.applicationStep4.serviceDescPrevYearWhat
          ) &&
          ValidationUtil.isStringEmpty(
            $scope.applicationStep4.serviceDescPrevYearWhere
          ) &&
          (!$scope.applicationStep4.serviceDescPrevOnetime &&
            (!$scope.applicationStep4.serviceDescPrevYearWhenStartDate &&
              !$scope.applicationStep4.serviceDescPrevYearWhenEndDate)) &&
          ValidationUtil.isStringEmpty(
            $scope.applicationStep4.prevYearPeopleServed
          ) &&
          ValidationUtil.isStringEmpty(
            $scope.applicationStep4.prevYearDollarSpent
          )
        );
      }

      function isPrev2YearDataEmpty() {
        return (
          ValidationUtil.isStringEmpty(
            $scope.applicationStep4.serviceDescPrev2YearHow
          ) &&
          ValidationUtil.isStringEmpty(
            $scope.applicationStep4.serviceDescPrev2YearWho
          ) &&
          ValidationUtil.isStringEmpty(
            $scope.applicationStep4.serviceDescPrev2YearWhat
          ) &&
          ValidationUtil.isStringEmpty(
            $scope.applicationStep4.serviceDescPrev2YearWhere
          ) &&
          (!$scope.applicationStep4.serviceDescPrev2Onetime &&
            (!$scope.applicationStep4.serviceDescPrev2YearWhenStartDate &&
              !$scope.applicationStep4.serviceDescPrev2YearWhenEndDate)) &&
          ValidationUtil.isStringEmpty(
            $scope.applicationStep4.prev2yearPeopleServed
          ) &&
          ValidationUtil.isStringEmpty(
            $scope.applicationStep4.prev2yearDollarSpent
          )
        );
      }

      var isCurrentYearExplanationComplete = false;
      var isPrevYearExplanationComplete = false;
      var isPrev2YearExplanationComplete = false;
      function isCurrentYearExplanationValid() {
        return (
          !ValidationUtil.isStringEmpty(
            $scope.applicationStep4.serviceDescHow
          ) &&
          !ValidationUtil.isStringEmpty(
            $scope.applicationStep4.serviceDescWho
          ) &&
          !ValidationUtil.isStringEmpty(
            $scope.applicationStep4.serviceDescWhat
          ) &&
          !ValidationUtil.isStringEmpty(
            $scope.applicationStep4.serviceDescWhere
          )
        );
      }

      function isPrevYearExplanationValid() {
        return (
          !ValidationUtil.isStringEmpty(
            $scope.applicationStep4.serviceDescPrevYearHow
          ) &&
          !ValidationUtil.isStringEmpty(
            $scope.applicationStep4.serviceDescPrevYearWho
          ) &&
          !ValidationUtil.isStringEmpty(
            $scope.applicationStep4.serviceDescPrevYearWhat
          ) &&
          !ValidationUtil.isStringEmpty(
            $scope.applicationStep4.serviceDescPrevYearWhere
          )
        );
      }

      function isPrev2YearExplanationValid() {
        return (
          !ValidationUtil.isStringEmpty(
            $scope.applicationStep4.serviceDescPrev2YearHow
          ) &&
          !ValidationUtil.isStringEmpty(
            $scope.applicationStep4.serviceDescPrev2YearWho
          ) &&
          !ValidationUtil.isStringEmpty(
            $scope.applicationStep4.serviceDescPrev2YearWhat
          ) &&
          !ValidationUtil.isStringEmpty(
            $scope.applicationStep4.serviceDescPrev2YearWhere
          )
        );
      }

      $scope.validateDatesForServiceDescription = function(
        oneTime,
        startDate,
        endDate,
        pledgeYear
      ) {
        var errMsg = [];
        var validDate = true;
        var validPeriod = true;
        if (oneTime && !startDate) {
          var msg = 'Please enter the start date for ' + pledgeYear + ' year.';
          errMsg.push(msg);
        }
        if (!oneTime && (!startDate || !endDate)) {
          var msg = 'Please enter the dates for ' + pledgeYear + ' year.';
          errMsg.push(msg);
        }
        if (oneTime && startDate && !ValidationUtil.validateDate(startDate)) {
          validDate = false;
          var msg = pledgeYear + ' year date is not valid.';
          errMsg.push(msg);
        }
        if (!oneTime && startDate && endDate) {
          var dateValidationErrors = ValidationUtil.validateStartEndDates(
            startDate,
            endDate
          );
          if (dateValidationErrors.length > 0) {
            validPeriod = false;
            angular.forEach(dateValidationErrors, function(obj) {
              obj = obj.replaceAll('Document', '');
              obj = obj + 'for ' + pledgeYear + ' service description';
              errMsg.push(obj);
            });
          }
        }
        if (validDate && validPeriod && (startDate || endDate)) {
          var valid = ValidationUtil.validateDateInRespectedPeriod(
            startDate,
            endDate,
            DATES.START_DATE + pledgeYear,
            DATES.END_DATE + pledgeYear
          );
          if (!valid) {
            var msg = '';
            if ($scope.charity_type === CHARITY_TYPE.local) {
              msg =
                'The dates for the reported service(s) are not for an acceptable period.  Local organizations must only report services provided during calendar year ' +
                pledgeYear +
                '. Services reported based on a fiscal year will not be accepted.';
            } else {
              msg =
                'The dates for the reported service(s) in ' +
                pledgeYear +
                ' service description are not for an acceptable period. Acceptable periods must be on a calendar year (and not fiscal year). Services reported based on a fiscal year will not be accepted.';
            }
            errMsg.push(msg);
          }
        }
        return errMsg;
      };

      $scope.validateApplicationStep = function() {
        $scope.fieldsMissing = [];

        if ($scope.charity_type === CHARITY_TYPE.local) {
          if ($scope.displayForPriorYears) {
            if (
              $scope.displayForPriorYears &&
              ValidationUtil.isStringEmpty(
                $scope.applicationStep4.hoursOfOperation
              )
            ) {
              $scope.fieldsMissing.push('Please enter Hours of Operation.');
            }
            if (
              $scope.displayForPriorYears &&
              ValidationUtil.isStringEmpty(
                $scope.applicationStep4.officePhoneNumber
              )
            ) {
              $scope.fieldsMissing.push('Please enter a valid Phone Number.');
            }
          }

          if (isCurrentYearExplanationValid()) {
            //check the dates now
            var serviceDescDatesErr = $scope.validateDatesForServiceDescription(
              $scope.applicationStep4.serviceDescOnetime,
              $scope.applicationStep4.serviceDescWhenStartDate,
              $scope.applicationStep4.serviceDescWhenEndDate,
              $scope.pledgeYear - 1
            );
            if (serviceDescDatesErr.length > 0) {
              if (
                $scope.applicationStep4.serviceDescOnetime ||
                (!$scope.applicationStep4.serviceDescOnetime &&
                  $scope.applicationStep4.serviceDescWhenStartDate &&
                  $scope.applicationStep4.serviceDescWhenEndDate)
              ) {
                $scope.fieldsMissing.push(...serviceDescDatesErr);
              } else {
                var msg =
                  'Please make sure that all descriptions were provided for services that were delivered in calendar year ' +
                  ($scope.pledgeYear - 1) +
                  ' in the local zone to which the organization applied.';
                $scope.fieldsMissing.push(msg);
              }
            }
          } else {
            var msg =
              'Please make sure that all descriptions were provided for services that were delivered in calendar year ' +
              ($scope.pledgeYear - 1) +
              ' in the local zone to which the organization applied.';
            $scope.fieldsMissing.push(msg);
          }
          if (
            ValidationUtil.isStringEmpty(
              $scope.applicationStep4.currentYearDollarSpent
            ) &&
            ValidationUtil.isStringEmpty(
              $scope.applicationStep4.currentYearPeopleServed
            )
          ) {
            $scope.fieldsMissing.push(
              'Each service description must include the number of beneficiaries and/or monetary value of the services.'
            );
          }

          if ($scope.applicationStep4.useNewAddress) {
            if (
              ValidationUtil.isStringEmpty(
                $scope.applicationStep4.officeAddress1
              )
            ) {
              $scope.fieldsMissing.push(
                'Please enter new organizational address.'
              );
            }
            if (
              ValidationUtil.isStringEmpty($scope.applicationStep4.officeCity)
            ) {
              $scope.fieldsMissing.push(
                'Please enter new organizational city.'
              );
            }
            if (
              ValidationUtil.isStringEmpty($scope.applicationStep4.officeState)
            ) {
              $scope.fieldsMissing.push(
                'Please enter new organizational state.'
              );
            }
            if (
              ValidationUtil.isStringEmpty(
                $scope.applicationStep4.officeZipCode
              )
            ) {
              $scope.fieldsMissing.push(
                'Please enter new organizational zip code.'
              );
            }
          } else {
            $scope.applicationStep4.officeAddress1 = null;
            $scope.applicationStep4.officeAddress2 = null;
            $scope.applicationStep4.officeCity = null;
            $scope.applicationStep4.officeState = null;
            $scope.applicationStep4.officeZipCode = null;
            $scope.applicationStep4.officeCountry = null;
            $scope.applicationStep4.officeCounty = null;
          }
        } else if (
          $scope.charity_type === CHARITY_TYPE.national ||
          $scope.charity_type === CHARITY_TYPE.international
        ) {
          if (
            ValidationUtil.isStringEmpty($scope.applicationStep4.officeCountry)
          ) {
            $scope.fieldsMissing.push('Country is required.');
            $scope.applicationStep4.officeState = null;
          } else if ($scope.applicationStep4.officeCountry === 'US') {
            if (
              ValidationUtil.isStringEmpty($scope.applicationStep4.officeState)
            ) {
              $scope.fieldsMissing.push('State is required.');
            }
          } else {
            $scope.applicationStep4.officeState = null;
          }
          if (
            $scope.displayForPriorYears &&
            ValidationUtil.isStringEmpty(
              $scope.applicationStep4.officePhoneNumber
            )
          ) {
            $scope.fieldsMissing.push('Please enter a valid Phone Number.');
          }

          const validationMessageAll =
            'Please make sure that a complete service description is entered for at least one year (how, who, what, where, when). The service description must include the number of beneficiaries and/or monetary value of the services. The start and end dates must fall in the respective years.';
          const validationMessageDescriptionForCurrentYear =
            'Please make sure that a complete service description for ' +
            ($scope.pledgeYear - 1) +
            ' is entered (how, who, what, where, when). ';
          const validationMessageDescriptionForPrevYear =
            'Please make sure that a complete service description for ' +
            ($scope.pledgeYear - 2) +
            ' is entered (how, who, what, where, when). ';
          const validationMessageDescriptionForPrev2Year =
            'Please make sure that a complete service description for ' +
            ($scope.pledgeYear - 3) +
            ' is entered (how, who, what, where, when). ';
          const validationMessageValues =
            'The service description must include the number of beneficiaries and/or monetary value of the services for ' +
            ($scope.pledgeYear - 1) +
            ' year.';
          const validationMessageValues1 =
            'The service description must include the number of beneficiaries and/or monetary value of the services for ' +
            ($scope.pledgeYear - 2) +
            ' year.';
          const validationMessageValues2 =
            'The service description must include the number of beneficiaries and/or monetary value of the services for ' +
            ($scope.pledgeYear - 3) +
            ' year.';

          if (
            isCurrentYearDataEmpty() &&
            isPrevYearDataEmpty() &&
            isPrev2YearDataEmpty()
          ) {
            addMessageToMissingFieldsArray(validationMessageAll);
          } else {
            if (!isCurrentYearDataEmpty()) {
              if (isCurrentYearExplanationValid()) {
                //check the dates now
                var serviceDescDatesErr = $scope.validateDatesForServiceDescription(
                  $scope.applicationStep4.serviceDescOnetime,
                  $scope.applicationStep4.serviceDescWhenStartDate,
                  $scope.applicationStep4.serviceDescWhenEndDate,
                  $scope.pledgeYear - 1
                );
                if (serviceDescDatesErr.length > 0) {
                  if (
                    $scope.applicationStep4.serviceDescOnetime ||
                    (!$scope.applicationStep4.serviceDescOnetime &&
                      $scope.applicationStep4.serviceDescWhenStartDate &&
                      $scope.applicationStep4.serviceDescWhenEndDate)
                  ) {
                    $scope.fieldsMissing.push(...serviceDescDatesErr);
                  } else {
                    addMessageToMissingFieldsArray(
                      validationMessageDescriptionForCurrentYear
                    );
                  }
                }
                if (
                  ValidationUtil.isStringEmpty(
                    $scope.applicationStep4.currentYearDollarSpent
                  ) &&
                  ValidationUtil.isStringEmpty(
                    $scope.applicationStep4.currentYearPeopleServed
                  )
                ) {
                  addMessageToMissingFieldsArray(validationMessageValues);
                }
              } else {
                addMessageToMissingFieldsArray(
                  validationMessageDescriptionForCurrentYear
                );
                if (
                  ValidationUtil.isStringEmpty(
                    $scope.applicationStep4.currentYearDollarSpent
                  ) &&
                  ValidationUtil.isStringEmpty(
                    $scope.applicationStep4.currentYearPeopleServed
                  )
                ) {
                  addMessageToMissingFieldsArray(validationMessageValues);
                }
              }
            }

            if (!isPrevYearDataEmpty()) {
              if (isPrevYearExplanationValid()) {
                //prev year
                var serviceDescDates1Err = $scope.validateDatesForServiceDescription(
                  $scope.applicationStep4.serviceDescOnetimePrev,
                  $scope.applicationStep4.serviceDescPrevYearWhenStartDate,
                  $scope.applicationStep4.serviceDescPrevYearWhenEndDate,
                  $scope.pledgeYear - 2
                );
                if (serviceDescDates1Err.length > 0) {
                  if (
                    $scope.applicationStep4.serviceDescOnetimePrev ||
                    (!$scope.applicationStep4.serviceDescOnetimePrev &&
                      $scope.applicationStep4
                        .serviceDescPrevYearWhenStartDate &&
                      $scope.applicationStep4.serviceDescPrevYearWhenEndDate)
                  ) {
                    $scope.fieldsMissing.push(...serviceDescDates1Err);
                  } else {
                    addMessageToMissingFieldsArray(
                      validationMessageDescriptionForPrevYear
                    );
                  }
                }
                // isPrevYearExplanationComplete = true;
                if (
                  ValidationUtil.isStringEmpty(
                    $scope.applicationStep4.prevYearDollarSpent
                  ) &&
                  ValidationUtil.isStringEmpty(
                    $scope.applicationStep4.prevYearPeopleServed
                  )
                ) {
                  addMessageToMissingFieldsArray(validationMessageValues1);
                }
              } else {
                addMessageToMissingFieldsArray(
                  validationMessageDescriptionForPrevYear
                );
                if (
                  ValidationUtil.isStringEmpty(
                    $scope.applicationStep4.prevYearDollarSpent
                  ) &&
                  ValidationUtil.isStringEmpty(
                    $scope.applicationStep4.prevYearPeopleServed
                  )
                ) {
                  addMessageToMissingFieldsArray(validationMessageValues1);
                }
              }
            }

            if (!isPrev2YearDataEmpty()) {
              if (isPrev2YearExplanationValid()) {
                var serviceDescDates2Err = $scope.validateDatesForServiceDescription(
                  $scope.applicationStep4.serviceDescOnetimePrev2,
                  $scope.applicationStep4.serviceDescPrev2YearWhenStartDate,
                  $scope.applicationStep4.serviceDescPrev2YearWhenEndDate,
                  $scope.pledgeYear - 3
                );
                if (serviceDescDates2Err.length > 0) {
                  if (
                    $scope.applicationStep4.serviceDescOnetimePrev2 ||
                    (!$scope.applicationStep4.serviceDescOnetimePrev2 &&
                      $scope.applicationStep4
                        .serviceDescPrev2YearWhenStartDate &&
                      $scope.applicationStep4.serviceDescPrev2YearWhenEndDate)
                  ) {
                    $scope.fieldsMissing.push(...serviceDescDates2Err);
                  } else {
                    addMessageToMissingFieldsArray(
                      validationMessageDescriptionForPrev2Year
                    );
                  }
                }
                if (
                  ValidationUtil.isStringEmpty(
                    $scope.applicationStep4.prev2yearDollarSpent
                  ) &&
                  ValidationUtil.isStringEmpty(
                    $scope.applicationStep4.prev2yearPeopleServed
                  )
                ) {
                  addMessageToMissingFieldsArray(validationMessageValues2);
                }
              } else {
                addMessageToMissingFieldsArray(
                  validationMessageDescriptionForPrev2Year
                );
                if (
                  ValidationUtil.isStringEmpty(
                    $scope.applicationStep4.prev2yearDollarSpent
                  ) &&
                  ValidationUtil.isStringEmpty(
                    $scope.applicationStep4.prev2yearPeopleServed
                  )
                ) {
                  addMessageToMissingFieldsArray(validationMessageValues2);
                }
              }
            }
          }
        }
        if (
          !ValidationUtil.isStringEmpty(
            $scope.applicationStep4.currentYearDollarSpent
          ) &&
          !ValidationUtil.isNumeric(
            $scope.applicationStep4.currentYearDollarSpent
          )
        ) {
          $scope.fieldsMissing.push(
            'Monetary Value of Benefit for ' +
              ($scope.pledgeYear - 1) +
              ' must be numeric.'
          );
        }

        if (
          ValidationUtil.containsDecimal(
            $scope.applicationStep4.currentYearDollarSpent
          )
        ) {
          $scope.fieldsMissing.push(
            'Monetary Value of Benefit for ' +
              ($scope.pledgeYear - 1) +
              ' cannot be a decimal.'
          );
        }

        if (
          !ValidationUtil.isStringEmpty(
            $scope.applicationStep4.currentYearPeopleServed
          ) &&
          !ValidationUtil.isNumeric(
            $scope.applicationStep4.currentYearPeopleServed
          )
        ) {
          $scope.fieldsMissing.push(
            'Number of Beneficiaries for ' +
              ($scope.pledgeYear - 1) +
              ' must be numeric.'
          );
        }

        if (
          ValidationUtil.containsDecimal(
            $scope.applicationStep4.currentYearPeopleServed
          )
        ) {
          $scope.fieldsMissing.push(
            'Number of Beneficiaries for ' +
              ($scope.pledgeYear - 1) +
              ' cannot be a decimal.'
          );
        }

        if (
          !ValidationUtil.isStringEmpty(
            $scope.applicationStep4.prevYearDollarSpent
          ) &&
          !ValidationUtil.isNumeric($scope.applicationStep4.prevYearDollarSpent)
        ) {
          $scope.fieldsMissing.push(
            'Monetary Value of Benefit for ' +
              ($scope.pledgeYear - 2) +
              ' must be numeric.'
          );
        }

        if (
          ValidationUtil.containsDecimal(
            $scope.applicationStep4.prevYearDollarSpent
          )
        ) {
          $scope.fieldsMissing.push(
            'Monetary Value of Benefit for ' +
              ($scope.pledgeYear - 2) +
              ' cannot be a decimal.'
          );
        }

        if (
          !ValidationUtil.isStringEmpty(
            $scope.applicationStep4.prevYearPeopleServed
          ) &&
          !ValidationUtil.isNumeric(
            $scope.applicationStep4.prevYearPeopleServed
          )
        ) {
          $scope.fieldsMissing.push(
            'Number of Beneficiaries for ' +
              ($scope.pledgeYear - 2) +
              ' must be numeric.'
          );
        }

        if (
          ValidationUtil.containsDecimal(
            $scope.applicationStep4.prevYearPeopleServed
          )
        ) {
          $scope.fieldsMissing.push(
            'Number of Beneficiaries for ' +
              ($scope.pledgeYear - 2) +
              ' cannot be a decimal.'
          );
        }

        if (
          !ValidationUtil.isStringEmpty(
            $scope.applicationStep4.prev2yearDollarSpent
          ) &&
          !ValidationUtil.isNumeric(
            $scope.applicationStep4.prev2yearDollarSpent
          )
        ) {
          $scope.fieldsMissing.push(
            'Monetary Value of Benefit for ' +
              ($scope.pledgeYear - 3) +
              ' must be numeric.'
          );
        }

        if (
          ValidationUtil.containsDecimal(
            $scope.applicationStep4.prev2yearDollarSpent
          )
        ) {
          $scope.fieldsMissing.push(
            'Monetary Value of Benefit for ' +
              ($scope.pledgeYear - 3) +
              ' cannot be a decimal.'
          );
        }

        if (
          !ValidationUtil.isStringEmpty(
            $scope.applicationStep4.prev2yearPeopleServed
          ) &&
          !ValidationUtil.isNumeric(
            $scope.applicationStep4.prev2yearPeopleServed
          )
        ) {
          $scope.fieldsMissing.push(
            'Number of Beneficiaries for ' +
              ($scope.pledgeYear - 3) +
              ' must be numeric.'
          );
        }

        if (
          ValidationUtil.containsDecimal(
            $scope.applicationStep4.prev2yearPeopleServed
          )
        ) {
          $scope.fieldsMissing.push(
            'Number of Beneficiaries for ' +
              ($scope.pledgeYear - 3) +
              ' cannot be a decimal.'
          );
        }

        if ($scope.fieldsMissing.length > 0) {
          return false;
        } else {
          return true;
        }

        // prevent duplicate messages
        function addMessageToMissingFieldsArray(msg) {
          if ($scope.fieldsMissing.indexOf(msg) > -1) {
            return;
          }
          $scope.fieldsMissing.push(msg);
        }
      };
      $scope.saveAttachment = function() {
        if (!$scope.validateApplicationStep()) {
          $scope.applicationStep4.status = 'Fail';
          $('#missingInfo').modal();
        } else {
          $scope.applicationStep4.status = 'Pass';
          this.step4_ContinueToAttachments();
        }

        $location.hash('pagetitle');
      };
      $scope.closeStep4Attachments = function() {
        $state.transitionTo('step4', {
          fromPage: 'step4Attachments',
          parentFedAppId: ApplicationService.getParentFederationApplicationId()
        });
        $location.hash('pagetitle');
      };
      $scope.step4_ContinueToAttachments = function() {
        if ($scope.applicationStep4.serviceDescWhenStartDate != null) {
          $scope.applicationStep4.serviceDescWhenStartDate = new Date(
            $scope.applicationStep4.serviceDescWhenStartDate
          );
        }
        if ($scope.applicationStep4.serviceDescWhenEndDate != null) {
          $scope.applicationStep4.serviceDescWhenEndDate = new Date(
            $scope.applicationStep4.serviceDescWhenEndDate
          );
        }
        if (
          !ValidationUtil.isNumeric(
            $scope.applicationStep4.currentYearPeopleServed
          ) ||
          ValidationUtil.containsDecimal(
            $scope.applicationStep4.currentYearPeopleServed
          )
        ) {
          $scope.applicationStep4.currentYearPeopleServed = null;
        }
        if (
          !ValidationUtil.isNumeric(
            $scope.applicationStep4.currentYearDollarSpent
          ) ||
          ValidationUtil.containsDecimal(
            $scope.applicationStep4.currentYearDollarSpent
          )
        ) {
          $scope.applicationStep4.currentYearDollarSpent = null;
        }
        if ($scope.applicationStep4.serviceDescPrevYearWhenStartDate != null) {
          $scope.applicationStep4.serviceDescPrevYearWhenStartDate = new Date(
            $scope.applicationStep4.serviceDescPrevYearWhenStartDate
          );
        }
        if ($scope.applicationStep4.serviceDescPrevYearWhenEndDate != null) {
          $scope.applicationStep4.serviceDescPrevYearWhenEndDate = new Date(
            $scope.applicationStep4.serviceDescPrevYearWhenEndDate
          );
        }
        if (
          !ValidationUtil.isNumeric(
            $scope.applicationStep4.prevYearPeopleServed
          ) ||
          ValidationUtil.containsDecimal(
            $scope.applicationStep4.prevYearPeopleServed
          )
        ) {
          $scope.applicationStep4.prevYearPeopleServed = null;
        }
        if (
          !ValidationUtil.isNumeric(
            $scope.applicationStep4.prevYearDollarSpent
          ) ||
          ValidationUtil.containsDecimal(
            $scope.applicationStep4.prevYearDollarSpent
          )
        ) {
          $scope.applicationStep4.prevYearDollarSpent = null;
        }
        if ($scope.applicationStep4.serviceDescPrev2YearWhenStartDate != null) {
          $scope.applicationStep4.serviceDescPrev2YearWhenStartDate = new Date(
            $scope.applicationStep4.serviceDescPrev2YearWhenStartDate
          );
        }
        if ($scope.applicationStep4.serviceDescPrev2YearWhenEndDate != null) {
          $scope.applicationStep4.serviceDescPrev2YearWhenEndDate = new Date(
            $scope.applicationStep4.serviceDescPrev2YearWhenEndDate
          );
        }

        if (
          !ValidationUtil.isNumeric(
            $scope.applicationStep4.prev2yearPeopleServed
          ) ||
          ValidationUtil.containsDecimal(
            $scope.applicationStep4.prev2yearPeopleServed
          )
        ) {
          $scope.applicationStep4.prev2yearPeopleServed = null;
        }
        if (
          !ValidationUtil.isNumeric(
            $scope.applicationStep4.prev2yearDollarSpent
          ) ||
          ValidationUtil.containsDecimal(
            $scope.applicationStep4.prev2yearDollarSpent
          )
        ) {
          $scope.applicationStep4.prev2yearDollarSpent = null;
        }
        if (!$stateParams.id) {
          Step4Details.createStep4Detail($scope.applicationStep4)
            .then(function(res) {
              var response = res.data.value;
              $('.modal-backdrop').remove();
              queryStep4Details(ApplicationService.getApplicationId());
              $state.transitionTo('step4', {
                fromPage: 'step4Attachments',
                parentFedAppId: ApplicationService.getParentFederationApplicationId()
              });
            })
            .catch(function(err) {
              $('#serverError').show();
            });
        } else {
          Step4Details.updateStep4Detail($scope.applicationStep4)
            .then(function(res) {
              var response = res.data.value;
              $('.modal-backdrop').remove();
              queryStep4Details(ApplicationService.getApplicationId());
              $state.transitionTo('step4', {
                fromPage: 'step4Attachments',
                parentFedAppId: ApplicationService.getParentFederationApplicationId()
              });
            })
            .catch(function(err) {
              $('#serverError').show();
            });
        }

        $location.hash('pagetitle');
      };
      queryStep4Details = function(applicationId) {
        Step4Details.queryStep4Details(applicationId)
          .then(function(res) {
            var response = res.data.value;
            services = response;
            if (services && services.length > 0) {
              //setting the overall step status as incomeplte as at this point we still not checked/unchecked the certification
              var status = APPLICATION_STEP_STATUS.incomplete;
              for (var i = 0; i < services.length; i++) {
                if (services[i].status !== 'Pass') {
                  status = APPLICATION_STEP_STATUS.incomplete;
                  break;
                }
              }
              $scope.applicationStep4 = ApplicationService.getApplicationStep4();
              $scope.applicationStep4.applicationId = applicationId;
              $scope.applicationStep4.cfcCode = ApplicationService.getCfcCode();
              $scope.applicationStep4.stepStatus = status;
              if (
                $scope.applicationStep4.webBasedService === null ||
                $scope.applicationStep4.webBasedService === undefined ||
                $scope.applicationStep4.webBasedService === 'No'
              ) {
                $scope.applicationStep4.webBasedService = false;
              } else {
                $scope.applicationStep4.webBasedService = true;
              }
              Step4.updateApplicationStep4($scope.applicationStep4)
                .then(function(res) {
                  var response = res.data.value;
                })
                .catch(function(err) {
                  $('#serverError').show();
                });
            }
          })
          .catch(function(err) {
            $('#serverError').show();
          });
      };
      $scope.goToCharityDashboard = function(cfcCode) {
        $state.go('charitydashboard', {
          charityDashboardCfcCode: cfcCode
        });
      };
    }
  ])
  .controller('AOSAttachmentCtrl', [
    '$state',
    '$scope',
    '$location',
    '$log',
    '$q',
    '$stateParams',
    'CakeSvc',
    'MODULE_NAME',
    'UserCharityRole',
    'ApplicationService',
    'AOSDetails',
    'CharityService',
    'BrowserEventService',
    'ValidationUtil',
    'tokenData',
    'urlAccess',
    'CubeSvc',
    'CHARITY_TYPE',
    'PLEDGE_YEARS',
    'DATES',
    '$filter',
    function(
      $state,
      $scope,
      $location,
      $log,
      $q,
      $stateParams,
      CakeSvc,
      MODULE_NAME,
      UserCharityRole,
      ApplicationService,
      AOSDetails,
      CharityService,
      BrowserEventService,
      ValidationUtil,
      tokenData,
      urlAccess,
      CubeSvc,
      CHARITY_TYPE,
      PLEDGE_YEARS,
      DATES,
      $filter
    ) {
      $('#serverError').hide();
      $location.hash('top-container');
      $scope.gotoPageSection = function(divId) {
        $location.hash(divId);
      };
      $scope.displayForPriorYears = false;

      $scope.step4Detail = {};
      $scope.aosDetail = {};
      $scope.newAOS = false;
      var secondaryRoleList = BrowserEventService.getCurrentUser()
        .secondaryRoleList;
      //check the access to show the breadcrumb links for member applications
      if (tokenData.federationId != null) {
        if ($scope.$parent.$$childHead.breadcrumbs.length > 0) {
          urlAccess
            .getFederationApplicationAccess(
              tokenData.pledgeYear,
              tokenData.federationCfc,
              $scope.$parent.$$childHead.breadcrumbs[0]
            )
            .then(function(res) {
              $scope.$parent.$$childHead.breadcrumbs[0] = res;
            });
          urlAccess
            .getMemberApplicationAccess(
              tokenData.pledgeYear,
              tokenData.federationCfc,
              $scope.$parent.$$childHead.breadcrumbs[1]
            )
            .then(function(res) {
              $scope.$parent.$$childHead.breadcrumbs[1] = res;
            });
        }
      }

      $scope.pledgeYear = $state.params.pledgeYear;
      if (
        $scope.pledgeYear &&
        $scope.pledgeYear < PLEDGE_YEARS.PLEDGE_YEAR_2022
      ) {
        $scope.displayForPriorYears = true;
      }

      $scope.mode = $state.params.mode
        ? $state.params.mode
        : BrowserEventService.mode;
      BrowserEventService.setMode($scope.mode);
      $scope.charity_type = $state.params.charityType;
      $scope.isAppealAOS = $state.params.isAppealAOS;
      $scope.reviewStatus = $state.params.reviewStatus;
      if ($stateParams.parentFedAppId) {
        $scope.applicationType = 'MEMBER';
      } else {
        $scope.applicationType = 'INDEPENDENT';
      }

      if (!$state.params.id) {
        $scope.aosDetail = {};
        $scope.aosDetail.applicationId = $state.params.applicationId;
        $scope.aosDetail.cfcCode = $state.params.cfcCode;
        $scope.aosDetail.useNewAddress = false;
      } else {
        $scope.step4DetailId = $stateParams.id;
        var url =
          '/CfcCharity/api/private/charity/appeal/aos/detail/' +
          $scope.step4DetailId +
          '/' +
          $scope.isAppealAOS +
          '/' +
          $state.params.cfcCode;
        CubeSvc.get(url)
          .then(function(res) {
            var result = res.data.value;
            $scope.step4Detail = result.step4;
            $scope.aosDetail = result.aosDetail;
            if ($scope.aosDetail) {
              if ($scope.aosDetail.serviceDescWhenStartDate != null) {
                var localDate = new Date(
                  $scope.aosDetail.serviceDescWhenStartDate
                );
                var localTime = localDate.getTime();
                var localOffset = localDate.getTimezoneOffset() * 60000;
                $scope.sDate = new Date(localTime + localOffset);
                $scope.aosDetail.serviceDescWhenStartDate = $filter('date')(
                  new Date($scope.sDate),
                  'MM/dd/yyyy'
                );
              }
              if ($scope.aosDetail.serviceDescWhenEndDate != null) {
                var localDate1 = new Date(
                  $scope.aosDetail.serviceDescWhenEndDate
                );
                var localTime1 = localDate1.getTime();
                var localOffset1 = localDate1.getTimezoneOffset() * 60000;
                $scope.eDate = new Date(localTime1 + localOffset1);
                $scope.aosDetail.serviceDescWhenEndDate = $filter('date')(
                  new Date($scope.eDate),
                  'MM/dd/yyyy'
                );
                $scope.serviceEndDate = $scope.aosDetail.serviceDescWhenEndDate;
              }

              if ($scope.aosDetail.serviceDescPrevYearWhenStartDate != null) {
                var localDate2 = new Date(
                  $scope.aosDetail.serviceDescPrevYearWhenStartDate
                );
                var localTime2 = localDate2.getTime();
                var localOffset2 = localDate2.getTimezoneOffset() * 60000;
                $scope.spDate = new Date(localTime2 + localOffset2);
                $scope.aosDetail.serviceDescPrevYearWhenStartDate = $filter(
                  'date'
                )(new Date($scope.spDate), 'MM/dd/yyyy');
              }
              if ($scope.aosDetail.serviceDescPrevYearWhenEndDate != null) {
                var localDate3 = new Date(
                  $scope.aosDetail.serviceDescPrevYearWhenEndDate
                );
                var localTime3 = localDate3.getTime();
                var localOffset3 = localDate3.getTimezoneOffset() * 60000;
                $scope.epDate = new Date(localTime3 + localOffset3);
                $scope.aosDetail.serviceDescPrevYearWhenEndDate = $filter(
                  'date'
                )(new Date($scope.epDate), 'MM/dd/yyyy');
                $scope.servicePrevEndDate =
                  $scope.aosDetail.serviceDescPrevYearWhenEndDate;
              }
              if ($scope.aosDetail.serviceDescPrev2YearWhenStartDate != null) {
                var localDate4 = new Date(
                  $scope.aosDetail.serviceDescPrev2YearWhenStartDate
                );
                var localTime4 = localDate4.getTime();
                var localOffset4 = localDate4.getTimezoneOffset() * 60000;
                $scope.sp2Date = new Date(localTime4 + localOffset4);
                $scope.aosDetail.serviceDescPrev2YearWhenStartDate = $filter(
                  'date'
                )(new Date($scope.sp2Date), 'MM/dd/yyyy');
              }
              if ($scope.aosDetail.serviceDescPrev2YearWhenEndDate != null) {
                var localDate5 = new Date(
                  $scope.aosDetail.serviceDescPrev2YearWhenEndDate
                );
                var localTime5 = localDate5.getTime();
                var localOffset5 = localDate5.getTimezoneOffset() * 60000;
                $scope.ep2Date = new Date(localTime5 + localOffset5);
                $scope.aosDetail.serviceDescPrev2YearWhenEndDate = $filter(
                  'date'
                )(new Date($scope.ep2Date), 'MM/dd/yyyy');
                $scope.servicePrev2EndDate =
                  $scope.aosDetail.serviceDescPrev2YearWhenEndDate;
              }
            }
            if (!$scope.aosDetail) {
              $scope.aosDetail = {};
              $scope.aosDetail.step4DetailId = $stateParams.id;
              $scope.aosDetail.applicationId = $state.params.applicationId;
              $scope.aosDetail.cfcCode = $state.params.cfcCode;
              $scope.aosDetail.useNewAddress = $scope.step4Detail.useNewAddress;
              $scope.aosDetail.hoursOfOperation =
                $scope.step4Detail.hoursOfOperation;
              $scope.aosDetail.officePhoneNumber =
                $scope.step4Detail.officePhoneNumber;
              $scope.aosDetail.currentYearExplanation =
                $scope.step4Detail.currentYearExplanation;
              $scope.aosDetail.currentYearDollarSpent =
                $scope.step4Detail.currentYearDollarSpent;
              $scope.aosDetail.currentYearPeopleServed =
                $scope.step4Detail.currentYearPeopleServed;
              $scope.aosDetail.officeState = $scope.step4Detail.officeState;
              $scope.aosDetail.officeCountry = $scope.step4Detail.officeCountry;
              $scope.aosDetail.officeAddress1 =
                $scope.step4Detail.officeAddress1;
              $scope.aosDetail.officeAddress2 =
                $scope.step4Detail.officeAddress2;
              $scope.aosDetail.officeCity = $scope.step4Detail.officeCity;
              $scope.aosDetail.officeZipCode = $scope.step4Detail.officeZipCode;
              $scope.aosDetail.prevYearExplanation =
                $scope.step4Detail.prevYearExplanation;
              $scope.aosDetail.prevYearDollarSpent =
                $scope.step4Detail.prevYearDollarSpent;
              $scope.aosDetail.prevYearPeopleServed =
                $scope.step4Detail.prevYearPeopleServed;
              $scope.aosDetail.prev2yearExplanation =
                $scope.step4Detail.prev2yearExplanation;
              $scope.aosDetail.prev2yearDollarSpent =
                $scope.step4Detail.prev2yearDollarSpent;
              $scope.aosDetail.prev2yearPeopleServed =
                $scope.step4Detail.prev2yearPeopleServed;
              $scope.aosDetail.serviceDescHow =
                $scope.step4Detail.serviceDescHow;
              $scope.aosDetail.serviceDescWhat =
                $scope.step4Detail.serviceDescWhat;
              $scope.aosDetail.serviceDescWho =
                $scope.step4Detail.serviceDescWho;
              $scope.aosDetail.serviceDescWhere =
                $scope.step4Detail.serviceDescWhere;
              $scope.aosDetail.serviceDescOnetime =
                $scope.step4Detail.serviceDescOnetime;
              if ($scope.step4Detail.serviceDescWhenStartDate != null) {
                var localDate = new Date(
                  $scope.step4Detail.serviceDescWhenStartDate
                );
                var localTime = localDate.getTime();
                var localOffset = localDate.getTimezoneOffset() * 60000;
                $scope.sDate = new Date(localTime + localOffset);
                $scope.aosDetail.serviceDescWhenStartDate = $filter('date')(
                  new Date($scope.sDate),
                  'MM/dd/yyyy'
                );
              }
              if ($scope.step4Detail.serviceDescWhenEndDate != null) {
                var localDate1 = new Date(
                  $scope.step4Detail.serviceDescWhenEndDate
                );
                var localTime1 = localDate1.getTime();
                var localOffset1 = localDate1.getTimezoneOffset() * 60000;
                $scope.eDate = new Date(localTime1 + localOffset1);
                $scope.aosDetail.serviceDescWhenEndDate = $filter('date')(
                  new Date($scope.eDate),
                  'MM/dd/yyyy'
                );
                $scope.serviceEndDate = $scope.aosDetail.serviceDescWhenEndDate;
              }
              $scope.aosDetail.serviceDescPrevYearHow =
                $scope.step4Detail.serviceDescPrevYearHow;
              $scope.aosDetail.serviceDescPrevYearWhat =
                $scope.step4Detail.serviceDescPrevYearWhat;
              $scope.aosDetail.serviceDescPrevYearWho =
                $scope.step4Detail.serviceDescPrevYearWho;
              $scope.aosDetail.serviceDescPrevYearWhere =
                $scope.step4Detail.serviceDescPrevYearWhere;
              $scope.aosDetail.serviceDescOnetimePrev =
                $scope.step4Detail.serviceDescOnetimePrev;
              if ($scope.step4Detail.serviceDescPrevYearWhenStartDate != null) {
                var localDate2 = new Date(
                  $scope.step4Detail.serviceDescPrevYearWhenStartDate
                );
                var localTime2 = localDate2.getTime();
                var localOffset2 = localDate2.getTimezoneOffset() * 60000;
                $scope.spDate = new Date(localTime2 + localOffset2);
                $scope.aosDetail.serviceDescPrevYearWhenStartDate = $filter(
                  'date'
                )(new Date($scope.spDate), 'MM/dd/yyyy');
              }
              if ($scope.step4Detail.serviceDescPrevYearWhenEndDate != null) {
                var localDate3 = new Date(
                  $scope.step4Detail.serviceDescPrevYearWhenEndDate
                );
                var localTime3 = localDate3.getTime();
                var localOffset3 = localDate3.getTimezoneOffset() * 60000;
                $scope.epDate = new Date(localTime3 + localOffset3);
                $scope.aosDetail.serviceDescPrevYearWhenEndDate = $filter(
                  'date'
                )(new Date($scope.epDate), 'MM/dd/yyyy');
                $scope.servicePrevEndDate =
                  $scope.aosDetail.serviceDescPrevYearWhenEndDate;
              }
              $scope.aosDetail.serviceDescPrev2YearHow =
                $scope.step4Detail.serviceDescPrev2YearHow;
              $scope.aosDetail.serviceDescPrev2YearWhat =
                $scope.step4Detail.serviceDescPrev2YearWhat;
              $scope.aosDetail.serviceDescPrev2YearWho =
                $scope.step4Detail.serviceDescPrev2YearWho;
              $scope.aosDetail.serviceDescPrev2YearWhere =
                $scope.step4Detail.serviceDescPrev2YearWhere;
              $scope.aosDetail.serviceDescOnetimePrev2 =
                $scope.step4Detail.serviceDescOnetimePrev2;
              if (
                $scope.step4Detail.serviceDescPrev2YearWhenStartDate != null
              ) {
                var localDate4 = new Date(
                  $scope.step4Detail.serviceDescPrev2YearWhenStartDate
                );
                var localTime4 = localDate4.getTime();
                var localOffset4 = localDate4.getTimezoneOffset() * 60000;
                $scope.sp2Date = new Date(localTime4 + localOffset4);
                $scope.aosDetail.serviceDescPrev2YearWhenStartDate = $filter(
                  'date'
                )(new Date($scope.sp2Date), 'MM/dd/yyyy');
              }
              if ($scope.step4Detail.serviceDescPrev2YearWhenEndDate != null) {
                var localDate5 = new Date(
                  $scope.step4Detail.serviceDescPrev2YearWhenEndDate
                );
                var localTime5 = localDate5.getTime();
                var localOffset5 = localDate5.getTimezoneOffset() * 60000;
                $scope.ep2Date = new Date(localTime5 + localOffset5);
                $scope.aosDetail.serviceDescPrev2YearWhenEndDate = $filter(
                  'date'
                )(new Date($scope.ep2Date), 'MM/dd/yyyy');
                $scope.servicePrev2EndDate =
                  $scope.aosDetail.serviceDescPrev2YearWhenEndDate;
              }
            }
            if ($scope.step4Detail.serviceDescWhenStartDate != null) {
              var localDate = new Date(
                $scope.step4Detail.serviceDescWhenStartDate
              );
              var localTime = localDate.getTime();
              var localOffset = localDate.getTimezoneOffset() * 60000;
              $scope.sDate = new Date(localTime + localOffset);
              $scope.step4Detail.serviceDescWhenStartDate = $filter('date')(
                new Date($scope.sDate),
                'MM/dd/yyyy'
              );
            }
            if ($scope.step4Detail.serviceDescWhenEndDate != null) {
              var localDate1 = new Date(
                $scope.step4Detail.serviceDescWhenEndDate
              );
              var localTime1 = localDate1.getTime();
              var localOffset1 = localDate1.getTimezoneOffset() * 60000;
              $scope.eDate = new Date(localTime1 + localOffset1);
              $scope.step4Detail.serviceDescWhenEndDate = $filter('date')(
                new Date($scope.eDate),
                'MM/dd/yyyy'
              );
            }

            if ($scope.step4Detail.serviceDescPrevYearWhenStartDate != null) {
              var localDate2 = new Date(
                $scope.step4Detail.serviceDescPrevYearWhenStartDate
              );
              var localTime2 = localDate2.getTime();
              var localOffset2 = localDate2.getTimezoneOffset() * 60000;
              $scope.spDate = new Date(localTime2 + localOffset2);
              $scope.step4Detail.serviceDescPrevYearWhenStartDate = $filter(
                'date'
              )(new Date($scope.spDate), 'MM/dd/yyyy');
            }
            if ($scope.step4Detail.serviceDescPrevYearWhenEndDate != null) {
              var localDate3 = new Date(
                $scope.step4Detail.serviceDescPrevYearWhenEndDate
              );
              var localTime3 = localDate3.getTime();
              var localOffset3 = localDate3.getTimezoneOffset() * 60000;
              $scope.epDate = new Date(localTime3 + localOffset3);
              $scope.step4Detail.serviceDescPrevYearWhenEndDate = $filter(
                'date'
              )(new Date($scope.epDate), 'MM/dd/yyyy');
            }
            if ($scope.step4Detail.serviceDescPrev2YearWhenStartDate != null) {
              var localDate4 = new Date(
                $scope.step4Detail.serviceDescPrev2YearWhenStartDate
              );
              var localTime4 = localDate4.getTime();
              var localOffset4 = localDate4.getTimezoneOffset() * 60000;
              $scope.sp2Date = new Date(localTime4 + localOffset4);
              $scope.step4Detail.serviceDescPrev2YearWhenStartDate = $filter(
                'date'
              )(new Date($scope.sp2Date), 'MM/dd/yyyy');
            }
            if ($scope.step4Detail.serviceDescPrev2YearWhenEndDate != null) {
              var localDate5 = new Date(
                $scope.step4Detail.serviceDescPrev2YearWhenEndDate
              );
              var localTime5 = localDate5.getTime();
              var localOffset5 = localDate5.getTimezoneOffset() * 60000;
              $scope.ep2Date = new Date(localTime5 + localOffset5);
              $scope.step4Detail.serviceDescPrev2YearWhenEndDate = $filter(
                'date'
              )(new Date($scope.ep2Date), 'MM/dd/yyyy');
            }
          })
          .catch(function(error) {
            if (error.data !== undefined) {
              $log.error(error.data.errorMessage);
            }
          });
      }

      if ($scope.charity_type === CHARITY_TYPE.local) {
        $scope.attachmentA = CHARITY_TYPE.local;
      } else if ($scope.charity_type === CHARITY_TYPE.national) {
        $scope.attachmentA = CHARITY_TYPE.national;
      } else if ($scope.charity_type === CHARITY_TYPE.international) {
        $scope.attachmentA = CHARITY_TYPE.international;
      }

      CharityService.findCharityWithCFC($stateParams.cfcCode).then(function(
        res
      ) {
        var result = res.data;
        if (result) {
          $scope.charity = result;
          CharityService.loadStates().then(function(res) {
            var states = res.data;
            $scope.states = states;
            if ($scope.charity_type !== CHARITY_TYPE.local) {
              CharityService.loadCountries().then(function(res) {
                var countries = res.data;
                $scope.countries = countries;
              });
            }
          });
        }
      });

      $scope.removeAddress = function() {
        $scope.aosDetail.officeAddress1 = null;
        $scope.aosDetail.officeAddress2 = null;
        $scope.aosDetail.officeCity = null;
        $scope.aosDetail.officeState = null;
        $scope.aosDetail.officeZip = null;
      };

      $scope.saveAddress = function() {
        if ($scope.addressForm.$valid) {
          $('#editAddress').modal('hide');
        } else {
          $scope.errors = true;
        }
      };

      $scope.editAddress = function() {
        $('#editAddress').modal('show');
      };

      $scope.cbOptionChanged = function() {
        if (
          $scope.serviceEndDate === undefined ||
          $scope.serviceEndDate === null
        ) {
          $scope.serviceEndDate = $scope.aosDetail.serviceDescWhenEndDate;
        }
        if (
          $scope.servicePrevEndDate === undefined ||
          $scope.servicePrevEndDate === null
        ) {
          $scope.servicePrevEndDate =
            $scope.aosDetail.serviceDescPrevYearWhenEndDate;
        }
        if (
          $scope.servicePrev2EndDate === undefined ||
          $scope.servicePrev2EndDate === null
        ) {
          $scope.servicePrev2EndDate =
            $scope.aosDetail.serviceDescPrev2YearWhenEndDate;
        }
        if ($scope.aosDetail.serviceDescOnetime) {
          $scope.aosDetail.serviceDescWhenEndDate = null;
        } else {
          $scope.aosDetail.serviceDescWhenEndDate = $scope.serviceEndDate;
        }
        if ($scope.aosDetail.serviceDescOnetimePrev) {
          $scope.aosDetail.serviceDescPrevYearWhenEndDate = null;
        } else {
          $scope.aosDetail.serviceDescPrevYearWhenEndDate =
            $scope.servicePrevEndDate;
        }
        if ($scope.aosDetail.serviceDescOnetimePrev2) {
          $scope.aosDetail.serviceDescPrev2YearWhenEndDate = null;
        } else {
          $scope.aosDetail.serviceDescPrev2YearWhenEndDate =
            $scope.servicePrev2EndDate;
        }
      };

      function isCurrentYearDataEmpty() {
        return (
          ValidationUtil.isStringEmpty($scope.aosDetail.serviceDescHow) &&
          ValidationUtil.isStringEmpty($scope.aosDetail.serviceDescWho) &&
          ValidationUtil.isStringEmpty($scope.aosDetail.serviceDescWhat) &&
          ValidationUtil.isStringEmpty($scope.aosDetail.serviceDescWhere) &&
          (!$scope.aosDetail.serviceDescOnetime &&
            (!$scope.aosDetail.serviceDescWhenStartDate &&
              !$scope.aosDetail.serviceDescWhenEndDate)) &&
          ValidationUtil.isStringEmpty(
            $scope.aosDetail.currentYearPeopleServed
          ) &&
          ValidationUtil.isStringEmpty($scope.aosDetail.currentYearDollarSpent)
        );
      }

      function isPrevYearDataEmpty() {
        return (
          ValidationUtil.isStringEmpty(
            $scope.aosDetail.serviceDescPrevYearHow
          ) &&
          ValidationUtil.isStringEmpty(
            $scope.aosDetail.serviceDescPrevYearWho
          ) &&
          ValidationUtil.isStringEmpty(
            $scope.aosDetail.serviceDescPrevYearWhat
          ) &&
          ValidationUtil.isStringEmpty(
            $scope.aosDetail.serviceDescPrevYearWhere
          ) &&
          (!$scope.aosDetail.serviceDescPrevOnetime &&
            (!$scope.aosDetail.serviceDescPrevYearWhenStartDate &&
              !$scope.aosDetail.serviceDescPrevYearWhenEndDate)) &&
          ValidationUtil.isStringEmpty($scope.aosDetail.prevYearPeopleServed) &&
          ValidationUtil.isStringEmpty($scope.aosDetail.prevYearDollarSpent)
        );
      }

      function isPrev2YearDataEmpty() {
        return (
          ValidationUtil.isStringEmpty(
            $scope.aosDetail.serviceDescPrev2YearHow
          ) &&
          ValidationUtil.isStringEmpty(
            $scope.aosDetail.serviceDescPrev2YearWho
          ) &&
          ValidationUtil.isStringEmpty(
            $scope.aosDetail.serviceDescPrev2YearWhat
          ) &&
          ValidationUtil.isStringEmpty(
            $scope.aosDetail.serviceDescPrev2YearWhere
          ) &&
          (!$scope.aosDetail.serviceDescPrev2Onetime &&
            (!$scope.aosDetail.serviceDescPrev2YearWhenStartDate &&
              !$scope.aosDetail.serviceDescPrev2YearWhenEndDate)) &&
          ValidationUtil.isStringEmpty(
            $scope.aosDetail.prev2yearPeopleServed
          ) &&
          ValidationUtil.isStringEmpty($scope.aosDetail.prev2yearDollarSpent)
        );
      }

      function isCurrentYearExplanationValid() {
        return (
          !ValidationUtil.isStringEmpty($scope.aosDetail.serviceDescHow) &&
          !ValidationUtil.isStringEmpty($scope.aosDetail.serviceDescWho) &&
          !ValidationUtil.isStringEmpty($scope.aosDetail.serviceDescWhat) &&
          !ValidationUtil.isStringEmpty($scope.aosDetail.serviceDescWhere)
        );
      }

      function isPrevYearExplanationValid() {
        return (
          !ValidationUtil.isStringEmpty(
            $scope.aosDetail.serviceDescPrevYearHow
          ) &&
          !ValidationUtil.isStringEmpty(
            $scope.aosDetail.serviceDescPrevYearWho
          ) &&
          !ValidationUtil.isStringEmpty(
            $scope.aosDetail.serviceDescPrevYearWhat
          ) &&
          !ValidationUtil.isStringEmpty(
            $scope.aosDetail.serviceDescPrevYearWhere
          )
        );
      }

      function isPrev2YearExplanationValid() {
        return (
          !ValidationUtil.isStringEmpty(
            $scope.aosDetail.serviceDescPrev2YearHow
          ) &&
          !ValidationUtil.isStringEmpty(
            $scope.aosDetail.serviceDescPrev2YearWho
          ) &&
          !ValidationUtil.isStringEmpty(
            $scope.aosDetail.serviceDescPrev2YearWhat
          ) &&
          !ValidationUtil.isStringEmpty(
            $scope.aosDetail.serviceDescPrev2YearWhere
          )
        );
      }

      $scope.validateDatesForServiceDescription = function(
        oneTime,
        startDate,
        endDate,
        pledgeYear
      ) {
        var errMsg = [];
        var validDate = true;
        var validPeriod = true;
        if (oneTime && !startDate) {
          var msg = 'Please enter the start date for ' + pledgeYear + ' year.';
          errMsg.push(msg);
        }
        if (!oneTime && (!startDate || !endDate)) {
          var msg = 'Please enter the dates for ' + pledgeYear + ' year.';
          errMsg.push(msg);
        }
        if (oneTime && startDate && !ValidationUtil.validateDate(startDate)) {
          validDate = false;
          var msg = pledgeYear + ' year date is not valid.';
          errMsg.push(msg);
        }
        if (!oneTime && startDate && endDate) {
          var dateValidationErrors = ValidationUtil.validateStartEndDates(
            startDate,
            endDate
          );
          if (dateValidationErrors.length > 0) {
            validPeriod = false;
            angular.forEach(dateValidationErrors, function(obj) {
              obj = obj.replaceAll('Document', '');
              obj = obj + 'for ' + pledgeYear + ' service description';
              errMsg.push(obj);
            });
          }
        }
        if (validDate && validPeriod && (startDate || endDate)) {
          var valid = ValidationUtil.validateDateInRespectedPeriod(
            startDate,
            endDate,
            DATES.START_DATE + pledgeYear,
            DATES.END_DATE + pledgeYear
          );
          if (!valid) {
            var msg = '';
            if ($scope.charity_type === CHARITY_TYPE.local) {
              msg =
                'The dates for the reported service(s) are not for an acceptable period.  Local organizations must only report services provided during calendar year ' +
                pledgeYear +
                '. Services reported based on a fiscal year will not be accepted.';
            } else {
              msg =
                'The dates for the reported service(s) in ' +
                pledgeYear +
                ' service description are not for an acceptable period. Acceptable periods must be on a calendar year (and not fiscal year). Services reported based on a fiscal year will not be accepted.';
            }
            errMsg.push(msg);
          }
        }
        return errMsg;
      };

      $scope.validateApplicationStep = function() {
        $scope.fieldsMissing = [];

        if ($scope.charity_type === CHARITY_TYPE.local) {
          if ($scope.displayForPriorYears) {
            if (
              ValidationUtil.isStringEmpty($scope.aosDetail.hoursOfOperation)
            ) {
              $scope.fieldsMissing.push('Please enter Hours of Operation.');
            }
            if (
              ValidationUtil.isStringEmpty($scope.aosDetail.officePhoneNumber)
            ) {
              $scope.fieldsMissing.push('Please enter a valid Phone Number.');
            }
          }

          if (isCurrentYearExplanationValid()) {
            //check the dates now
            var serviceDescDatesErr = $scope.validateDatesForServiceDescription(
              $scope.aosDetail.serviceDescOnetime,
              $scope.aosDetail.serviceDescWhenStartDate,
              $scope.aosDetail.serviceDescWhenEndDate,
              $scope.pledgeYear - 1
            );
            if (serviceDescDatesErr.length > 0) {
              if (
                $scope.aosDetail.serviceDescOnetime ||
                (!$scope.aosDetail.serviceDescOnetime &&
                  $scope.aosDetail.serviceDescWhenStartDate &&
                  $scope.aosDetail.serviceDescWhenEndDate)
              ) {
                $scope.fieldsMissing.push(...serviceDescDatesErr);
              } else {
                var msg =
                  'Please make sure that all descriptions were provided for services that were delivered in calendar year ' +
                  ($scope.pledgeYear - 1) +
                  ' in the local zone to which the organization applied.';
                addMessageToMissingFieldsArray(msg);
              }
            }
          } else {
            var msg =
              'Please make sure that all descriptions were provided for services that were delivered in calendar year ' +
              ($scope.pledgeYear - 1) +
              ' in the local zone to which the organization applied.';
            addMessageToMissingFieldsArray(msg);
          }

          if (
            ValidationUtil.isStringEmpty(
              $scope.aosDetail.currentYearDollarSpent
            ) &&
            ValidationUtil.isStringEmpty(
              $scope.aosDetail.currentYearPeopleServed
            )
          ) {
            $scope.fieldsMissing.push(
              'Each service description must include the number of beneficiaries and/or monetary value of the services.'
            );
          }

          if ($scope.aosDetail.useNewAddress) {
            if (ValidationUtil.isStringEmpty($scope.aosDetail.officeAddress1)) {
              $scope.fieldsMissing.push(
                'Please enter new organizational address.'
              );
            }
            if (ValidationUtil.isStringEmpty($scope.aosDetail.officeCity)) {
              $scope.fieldsMissing.push(
                'Please enter new organizational city.'
              );
            }
            if (ValidationUtil.isStringEmpty($scope.aosDetail.officeState)) {
              $scope.fieldsMissing.push(
                'Please enter new organizational state.'
              );
            }
            if (ValidationUtil.isStringEmpty($scope.aosDetail.officeZipCode)) {
              $scope.fieldsMissing.push(
                'Please enter new organizational zip code.'
              );
            }
          } else {
            $scope.aosDetail.officeAddress1 = null;
            $scope.aosDetail.officeAddress2 = null;
            $scope.aosDetail.officeCity = null;
            $scope.aosDetail.officeState = null;
            $scope.aosDetail.officeZipCode = null;
            $scope.aosDetail.officeCountry = null;
            $scope.aosDetail.officeCounty = null;
          }
        } else if (
          $scope.charity_type === CHARITY_TYPE.national ||
          $scope.charity_type === CHARITY_TYPE.international
        ) {
          if (ValidationUtil.isStringEmpty($scope.aosDetail.officeCountry)) {
            $scope.fieldsMissing.push('Country is required.');
            $scope.aosDetail.officeState = null;
          } else if ($scope.aosDetail.officeCountry === 'US') {
            if (ValidationUtil.isStringEmpty($scope.aosDetail.officeState)) {
              $scope.fieldsMissing.push('State is required.');
            }
          } else {
            $scope.aosDetail.officeState = null;
          }
          if ($scope.displayForPriorYears) {
            if (
              ValidationUtil.isStringEmpty($scope.aosDetail.officePhoneNumber)
            ) {
              $scope.fieldsMissing.push('Please enter a valid Phone Number.');
            }
          }

          const validationMessageAll =
            'Please make sure that a complete service description is entered for at least one year (how, who, what, where, when). The service description must include the number of beneficiaries and/or monetary value of the services. The start and end dates must fall in the respective years.';
          const validationMessageDescriptionForCurrentYear =
            'Please make sure that a complete service description for ' +
            ($scope.pledgeYear - 1) +
            ' is entered (how, who, what, where, when). ';
          const validationMessageDescriptionForPrevYear =
            'Please make sure that a complete service description for ' +
            ($scope.pledgeYear - 2) +
            ' is entered (how, who, what, where, when). ';
          const validationMessageDescriptionForPrev2Year =
            'Please make sure that a complete service description for ' +
            ($scope.pledgeYear - 3) +
            ' is entered (how, who, what, where, when). ';
          const validationMessageValues =
            'The service description must include the number of beneficiaries and/or monetary value of the services for ' +
            ($scope.pledgeYear - 1) +
            ' year.';
          const validationMessageValues1 =
            'The service description must include the number of beneficiaries and/or monetary value of the services for ' +
            ($scope.pledgeYear - 2) +
            ' year.';
          const validationMessageValues2 =
            'The service description must include the number of beneficiaries and/or monetary value of the services for ' +
            ($scope.pledgeYear - 3) +
            ' year.';

          if (
            isCurrentYearDataEmpty() &&
            isPrevYearDataEmpty() &&
            isPrev2YearDataEmpty()
          ) {
            addMessageToMissingFieldsArray(validationMessageAll);
          } else {
            if (!isCurrentYearDataEmpty()) {
              if (isCurrentYearExplanationValid()) {
                //check the dates now
                var serviceDescDatesErr = $scope.validateDatesForServiceDescription(
                  $scope.aosDetail.serviceDescOnetime,
                  $scope.aosDetail.serviceDescWhenStartDate,
                  $scope.aosDetail.serviceDescWhenEndDate,
                  $scope.pledgeYear - 1
                );
                if (serviceDescDatesErr.length > 0) {
                  if (
                    $scope.aosDetail.serviceDescOnetime ||
                    (!$scope.aosDetail.serviceDescOnetime &&
                      $scope.aosDetail.serviceDescWhenStartDate &&
                      $scope.aosDetail.serviceDescWhenEndDate)
                  ) {
                    $scope.fieldsMissing.push(...serviceDescDatesErr);
                  } else {
                    addMessageToMissingFieldsArray(
                      validationMessageDescriptionForCurrentYear
                    );
                  }
                }
                if (
                  ValidationUtil.isStringEmpty(
                    $scope.aosDetail.currentYearDollarSpent
                  ) &&
                  ValidationUtil.isStringEmpty(
                    $scope.aosDetail.currentYearPeopleServed
                  )
                ) {
                  addMessageToMissingFieldsArray(validationMessageValues);
                }
              } else {
                addMessageToMissingFieldsArray(
                  validationMessageDescriptionForCurrentYear
                );
                if (
                  ValidationUtil.isStringEmpty(
                    $scope.aosDetail.currentYearDollarSpent
                  ) &&
                  ValidationUtil.isStringEmpty(
                    $scope.aosDetail.currentYearPeopleServed
                  )
                ) {
                  addMessageToMissingFieldsArray(validationMessageValues);
                }
              }
            }

            if (!isPrevYearDataEmpty()) {
              if (isPrevYearExplanationValid()) {
                //prev year
                var serviceDescDates1Err = $scope.validateDatesForServiceDescription(
                  $scope.aosDetail.serviceDescOnetimePrev,
                  $scope.aosDetail.serviceDescPrevYearWhenStartDate,
                  $scope.aosDetail.serviceDescPrevYearWhenEndDate,
                  $scope.pledgeYear - 2
                );
                if (serviceDescDates1Err.length > 0) {
                  if (
                    $scope.aosDetail.serviceDescOnetimePrev ||
                    (!$scope.aosDetail.serviceDescOnetimePrev &&
                      $scope.aosDetail.serviceDescPrevYearWhenStartDate &&
                      $scope.aosDetail.serviceDescPrevYearWhenEndDate)
                  ) {
                    $scope.fieldsMissing.push(...serviceDescDates1Err);
                  } else {
                    addMessageToMissingFieldsArray(
                      validationMessageDescriptionForPrevYear
                    );
                  }
                }
                if (
                  ValidationUtil.isStringEmpty(
                    $scope.aosDetail.prevYearDollarSpent
                  ) &&
                  ValidationUtil.isStringEmpty(
                    $scope.aosDetail.prevYearPeopleServed
                  )
                ) {
                  addMessageToMissingFieldsArray(validationMessageValues1);
                }
              } else {
                addMessageToMissingFieldsArray(
                  validationMessageDescriptionForPrevYear
                );
                if (
                  ValidationUtil.isStringEmpty(
                    $scope.aosDetail.prevYearDollarSpent
                  ) &&
                  ValidationUtil.isStringEmpty(
                    $scope.aosDetail.prevYearPeopleServed
                  )
                ) {
                  addMessageToMissingFieldsArray(validationMessageValues1);
                }
              }
            }

            if (!isPrev2YearDataEmpty()) {
              if (isPrev2YearExplanationValid()) {
                var serviceDescDates2Err = $scope.validateDatesForServiceDescription(
                  $scope.aosDetail.serviceDescOnetimePrev2,
                  $scope.aosDetail.serviceDescPrev2YearWhenStartDate,
                  $scope.aosDetail.serviceDescPrev2YearWhenEndDate,
                  $scope.pledgeYear - 3
                );
                if (serviceDescDates2Err.length > 0) {
                  if (
                    $scope.aosDetail.serviceDescOnetimePrev2 ||
                    (!$scope.aosDetail.serviceDescOnetimePrev2 &&
                      $scope.aosDetail.serviceDescPrev2YearWhenStartDate &&
                      $scope.aosDetail.serviceDescPrev2YearWhenEndDate)
                  ) {
                    $scope.fieldsMissing.push(...serviceDescDates2Err);
                  } else {
                    addMessageToMissingFieldsArray(
                      validationMessageDescriptionForPrev2Year
                    );
                  }
                }
                if (
                  ValidationUtil.isStringEmpty(
                    $scope.aosDetail.prev2yearDollarSpent
                  ) &&
                  ValidationUtil.isStringEmpty(
                    $scope.aosDetail.prev2yearPeopleServed
                  )
                ) {
                  addMessageToMissingFieldsArray(validationMessageValues2);
                }
              } else {
                addMessageToMissingFieldsArray(
                  validationMessageDescriptionForPrev2Year
                );
                if (
                  ValidationUtil.isStringEmpty(
                    $scope.aosDetail.prev2yearDollarSpent
                  ) &&
                  ValidationUtil.isStringEmpty(
                    $scope.aosDetail.prev2yearPeopleServed
                  )
                ) {
                  addMessageToMissingFieldsArray(validationMessageValues2);
                }
              }
            }
          }
        }

        if (
          !ValidationUtil.isStringEmpty(
            $scope.aosDetail.currentYearDollarSpent
          ) &&
          !ValidationUtil.isNumeric($scope.aosDetail.currentYearDollarSpent)
        ) {
          $scope.fieldsMissing.push(
            'Monetary Value of Benefit for current year must be numeric.'
          );
        }

        if (
          !ValidationUtil.isStringEmpty(
            $scope.aosDetail.currentYearPeopleServed
          ) &&
          !ValidationUtil.isNumeric($scope.aosDetail.currentYearPeopleServed)
        ) {
          $scope.fieldsMissing.push(
            'Number of Beneficiaries for current year must be numeric.'
          );
        }

        if (
          !ValidationUtil.isStringEmpty($scope.aosDetail.prevYearDollarSpent) &&
          !ValidationUtil.isNumeric($scope.aosDetail.prevYearDollarSpent)
        ) {
          $scope.fieldsMissing.push(
            'Monetary Value of Benefit for previous year must be numeric.'
          );
        }

        if (
          !ValidationUtil.isStringEmpty(
            $scope.aosDetail.prevYearPeopleServed
          ) &&
          !ValidationUtil.isNumeric($scope.aosDetail.prevYearPeopleServed)
        ) {
          $scope.fieldsMissing.push(
            'Number of Beneficiaries for previous year must be numeric.'
          );
        }

        if (
          !ValidationUtil.isStringEmpty(
            $scope.aosDetail.prev2yearDollarSpent
          ) &&
          !ValidationUtil.isNumeric($scope.aosDetail.prev2yearDollarSpent)
        ) {
          $scope.fieldsMissing.push(
            'Monetary Value of Benefit for previous year must be numeric.'
          );
        }

        if (
          !ValidationUtil.isStringEmpty(
            $scope.aosDetail.prev2yearPeopleServed
          ) &&
          !ValidationUtil.isNumeric($scope.aosDetail.prev2yearPeopleServed)
        ) {
          $scope.fieldsMissing.push(
            'Number of Beneficiaries for previous year must be numeric.'
          );
        }

        if ($scope.fieldsMissing.length > 0) {
          return false;
        } else {
          return true;
        }

        // prevent duplicate messages
        function addMessageToMissingFieldsArray(msg) {
          if ($scope.fieldsMissing.indexOf(msg) > -1) {
            return;
          }
          $scope.fieldsMissing.push(msg);
        }
      };

      $scope.saveAttachment = function() {
        if (!$scope.validateApplicationStep()) {
          $scope.aosDetail.status = 'Fail';
          $('#missingInfo').modal();
        } else {
          $scope.aosDetail.status = 'Pass';
          this.aos_ContinueToAttachments();
        }

        $location.hash('pagetitle');
      };

      $scope.closeAOS = function() {
        if (
          secondaryRoleList.indexOf('OPM_ADMIN') >= 0 ||
          secondaryRoleList.indexOf('OPM_STAFF') >= 0 ||
          secondaryRoleList.indexOf('OPM_AUDITOR') >= 0
        ) {
          $state.go('admin-appeal-dashboard', {
            applicationType: $scope.applicationType,
            applicationId: $state.params.applicationId
          });
        } else {
          $state.go('appeal-dashboard', {
            isFederation: 'false',
            applicationId: $state.params.applicationId
          });
        }
        $location.hash('pagetitle');
      };

      $scope.aos_ContinueToAttachments = function() {
        if ($scope.aosDetail.serviceDescWhenStartDate != null) {
          $scope.aosDetail.serviceDescWhenStartDate = new Date(
            $scope.aosDetail.serviceDescWhenStartDate
          );
        }
        if ($scope.aosDetail.serviceDescWhenEndDate != null) {
          $scope.aosDetail.serviceDescWhenEndDate = new Date(
            $scope.aosDetail.serviceDescWhenEndDate
          );
        }
        if ($scope.aosDetail.serviceDescPrevYearWhenStartDate != null) {
          $scope.aosDetail.serviceDescPrevYearWhenStartDate = new Date(
            $scope.aosDetail.serviceDescPrevYearWhenStartDate
          );
        }
        if ($scope.aosDetail.serviceDescPrevYearWhenEndDate != null) {
          $scope.aosDetail.serviceDescPrevYearWhenEndDate = new Date(
            $scope.aosDetail.serviceDescPrevYearWhenEndDate
          );
        }
        if ($scope.aosDetail.serviceDescPrev2YearWhenStartDate != null) {
          $scope.aosDetail.serviceDescPrev2YearWhenStartDate = new Date(
            $scope.aosDetail.serviceDescPrev2YearWhenStartDate
          );
        }
        if ($scope.aosDetail.serviceDescPrev2YearWhenEndDate != null) {
          $scope.aosDetail.serviceDescPrev2YearWhenEndDate = new Date(
            $scope.aosDetail.serviceDescPrev2YearWhenEndDate
          );
        }
        var url = '/CfcCharity/api/private/charity/appeal/aos/detail';
        AOSDetails.createOrUpdateAOSDetail($scope.aosDetail)
          .then(function(res) {
            var response = res.data.value;
            $('.modal-backdrop').remove();
            if (
              secondaryRoleList.indexOf('OPM_ADMIN') >= 0 ||
              secondaryRoleList.indexOf('OPM_STAFF') >= 0 ||
              secondaryRoleList.indexOf('OPM_AUDITOR') >= 0
            ) {
              $state.go('admin-appeal-dashboard', {
                applicationType: $scope.applicationType,
                applicationId: $state.params.applicationId
              });
            } else {
              $state.go('appeal-dashboard', {
                isFederation: 'false',
                applicationId: $state.params.applicationId
              });
            }
          })
          .catch(function(err) {
            $('#serverError').show();
          });
        $location.hash('pagetitle');
      };

      $scope.goToCharityDashboard = function(cfcCode) {
        $state.go('charitydashboard', {
          charityDashboardCfcCode: cfcCode
        });
      };
    }
  ])
  .controller('ApplicationStep5Ctrl', [
    '$state',
    '$scope',
    '$location',
    '$log',
    'CakeSvc',
    'MODULE_NAME',
    'EXEMPTION_STATUS',
    'APPLICATION_STEP_STATUS',
    'UserCharityRole',
    'ApplicationService',
    'ApplicationDashboardService',
    'BrowserEventService',
    'CharityService',
    'APPLICATION_TYPE',
    'DownloadService',
    'tokenData',
    'urlAccess',
    'CubeSvc',
    'PLEDGE_YEARS',
    '$q',
    function(
      $state,
      $scope,
      $location,
      $log,
      CakeSvc,
      MODULE_NAME,
      EXEMPTION_STATUS,
      APPLICATION_STEP_STATUS,
      UserCharityRole,
      ApplicationService,
      ApplicationDashboardService,
      BrowserEventService,
      CharityService,
      APPLICATION_TYPE,
      DownloadService,
      tokenData,
      urlAccess,
      CubeSvc,
      PLEDGE_YEARS,
      $q
    ) {
      $scope.setAppStep(5);
      $location.hash('top-container');
      $scope.gotoPageSection = function(divId) {
        $location.hash(divId);
      };

      $scope.currentPledgeYear = sessionStorage.getItem(
        'CREATE_APPLICATION_CURRENT_PLEDGE_YEAR'
      );

      //check the access to show the breadcrumb links for member applications
      if (tokenData.federationId != null) {
        if ($scope.$parent.$$childHead.breadcrumbs.length > 0) {
          urlAccess
            .getFederationApplicationAccess(
              tokenData.pledgeYear,
              tokenData.federationCfc,
              $scope.$parent.$$childHead.breadcrumbs[0]
            )
            .then(function(res) {
              $scope.$parent.$$childHead.breadcrumbs[0] = res;
            });
          urlAccess
            .getMemberApplicationAccess(
              tokenData.pledgeYear,
              tokenData.federationCfc,
              $scope.$parent.$$childHead.breadcrumbs[1]
            )
            .then(function(res) {
              $scope.$parent.$$childHead.breadcrumbs[1] = res;
            });
        }
      }

      $scope.mode = $state.params.mode
        ? $state.params.mode
        : BrowserEventService.mode;
      BrowserEventService.setMode($scope.mode);
      ApplicationService.getUserSession().step5AccessBeginDate = ApplicationService.toUTCDate(
        new Date()
      );
      if (!$scope.applicationStep5) {
        $scope.applicationStep5 = ApplicationService.getApplicationStep5();
        $q(function(resolve, reject) {
          $scope.applicationStep5.applicationId = ApplicationService.getApplicationId();
          $scope.applicationStep5.cfcCode = ApplicationService.getCfcCode();
          $scope.applicationStep5.isOffYear = ApplicationService.getPreApplicationDetails().isOffYear;
          $scope.applicationStep5.$get(
            function(res) {
              var tmp = CakeSvc.transformGenericResponseToFlatResourceResponse(
                res
              );
              if (!angular.equals(tmp, {})) {
                $scope.applicationStep5 = tmp;
              } else {
                $scope.applicationStep5.applicationId = ApplicationService.getApplicationId();
                $scope.applicationStep5.cfcCode = ApplicationService.getCfcCode();
                $scope.applicationStep5.isOffYear = ApplicationService.getPreApplicationDetails().isOffYear;
              }
              resolve($scope.applicationStep5);
            },
            function(err) {
              $log.error('STEP5 failed to load');
              reject(err);
            },
            function(err) {
              $log.error('STEP5 failed to load');
            }
          );
        });
      }
      $scope.groupExemption = false;
      $scope.affliate = false;
      $scope.displayForPriorYears =
        tokenData.pledgeYear &&
        tokenData.pledgeYear < PLEDGE_YEARS.PLEDGE_YEAR_2023;
      if (
        ApplicationService.getPreApplicationDetails() != null &&
        ApplicationService.getPreApplicationDetails().groupExemption &&
        ApplicationService.getPreApplicationDetails().foundation
      ) {
        if (
          ApplicationService.getPreApplicationDetails().groupExemption !==
            '0' &&
          ApplicationService.getPreApplicationDetails().foundation !== '10'
        ) {
          $scope.groupExemption = true;
        }
      }
      if (
        ApplicationService.getPreApplicationDetails() != null &&
        ApplicationService.getPreApplicationDetails().parentName
      ) {
        $scope.affliate = true;
        $scope.parentName = ApplicationService.getPreApplicationDetails().parentName;
        $scope.parentEin = ApplicationService.getPreApplicationDetails().parentEin;
        $scope.parentCfcCode = ApplicationService.getPreApplicationDetails().parentCfcCode;
      }
      $scope.isFileExist = function(optionValue) {
        if ($scope.applicationStep5.exemptionStatus === optionValue) {
          if (
            !$scope.applicationStep5.supportingFile &&
            $scope.applicationStep5.fileName
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      };
      $scope.validateApplicationStep = function(exit) {
        $scope.fieldsMissing = [];
        $scope.fileError = false;
        if (!$scope.applicationStep5.exemptionStatus) {
          $scope.fieldsMissing.push('Exemption Status');
        }
        if ($scope.applicationStep5.exemptionStatus) {
          if (
            $scope.applicationStep5.exemptionStatus === EXEMPTION_STATUS.option2
          ) {
            if (!$scope.isOffYear) {
              if ($scope.applicationStep5.religiousAffiliation === null) {
                $scope.fieldsMissing.push(
                  'You must select whether or not your organization is affiliated with a place of worship and/or religious organization.'
                );
              } else if ($scope.applicationStep5.religiousAffiliation) {
                if (
                  !$scope.applicationStep5.supportingFile &&
                  !$scope.applicationStep5.fileName
                ) {
                  $scope.fieldsMissing.push('Current List of Subordinates');
                } else {
                  if (
                    $scope.applicationStep5.supportingFile &&
                    $scope.applicationStep5.supportingFile.type !==
                      'application/pdf'
                  ) {
                    $scope.fieldsMissing.push(
                      'Current List of Subordinates: Only PDF file is allowed.'
                    );
                    $scope.fileError = true;
                  } else {
                    $scope.fileError = false;
                  }
                }
              }
            }
          } else if (
            $scope.applicationStep5.exemptionStatus === EXEMPTION_STATUS.option3
          ) {
            if (
              !$scope.applicationStep5.supportingFile &&
              !$scope.applicationStep5.fileName &&
              !$scope.isOffYear
            ) {
              $scope.fieldsMissing.push('Organization\u2019s Letter');
            } else {
              if (
                $scope.applicationStep5.supportingFile &&
                $scope.applicationStep5.supportingFile.type !==
                  'application/pdf'
              ) {
                $scope.fieldsMissing.push(
                  'Organization\u2019s Letter: Only PDF file is allowed.'
                );
                $scope.fileError = true;
              } else {
                $scope.fileError = false;
              }
            }
          }
        }
        if ($scope.fieldsMissing.length > 0) {
          $scope.applicationStep5.stepStatus =
            APPLICATION_STEP_STATUS.incomplete;
          if (exit && !$scope.fileError) {
            $scope.saveApplicationStep(exit);
          } else {
            $('#missingInfo').modal();
          }
        } else {
          $scope.applicationStep5.stepStatus = APPLICATION_STEP_STATUS.complete;
          if (exit) {
            $scope.saveApplicationStep(exit);
          } else {
            $scope.saveApplicationStep();
          }
        }
      };
      $scope.continue = false;
      $scope.saveApplicationStep = function(exit) {
        if ($scope.applicationStep5.exemptionStatus === 'OPTION_1') {
          $scope.applicationStep5.supportingFile = null;
          $scope.applicationStep5.religiousAffiliation = null;
        } else if ($scope.applicationStep5.exemptionStatus !== 'OPTION_2') {
          $scope.applicationStep5.religiousAffiliation = null;
        }
        var currentTime = ApplicationService.toUTCDate(new Date());
        var dateDifferenceInSeconds = 0;
        var userSession = ApplicationService.getUserSession();
        userSession.step5AccessEndDate = currentTime;
        dateDifferenceInSeconds = ApplicationService.getDateDifference(
          currentTime,
          userSession.step5AccessBeginDate
        );
        userSession.step5TimeSpentInSeconds = dateDifferenceInSeconds;
        if (exit) {
          userSession.lastAccessedEndDate = currentTime;
          userSession.totalTimeSpentInSeconds = ApplicationService.getDateDifference(
            currentTime,
            userSession.lastAccessedBeginDate
          );
        }
        ApplicationService.saveFileStep5($scope.applicationStep5, function(
          response
        ) {
          if (
            response.message &&
            response.message.type === 'ERROR' &&
            !$scope.continue
          ) {
            $('input[type="file"]').val(null);
            $scope.applicationStep5.supportingFile = null;
            $('#modalUpload').modal();
            return;
          }
          if ((!response || response.errorCode) && !$scope.continue) {
            $scope.applicationStep5.supportingFile = null;
            $('#modalUpload').modal();
            return;
          }
          if (response) {
            $scope.applicationStep5.fileName = response.fileName;
          }
          ApplicationService.saveUserSession().then(function(response) {
            var overallStatus = 'Complete';
            ApplicationService.getApplicationStatus(
              ApplicationService.getApplicationId(),
              function(data) {
                for (var i = 0; i < data.length; i++) {
                  if (data[i].stepStatus === 'Incomplete') {
                    overallStatus = 'Incomplete';
                    break;
                  }
                }
                var app = ApplicationService.getApplication();
                app.overallApplicationStatus = overallStatus;
                ApplicationService.saveTable(app, function(response) {
                  if (exit) {
                    $scope.setAppStep(0);
                    $state.go('applicationDashboard', {
                      applicationId: ApplicationService.getApplicationId()
                    });
                  } else {
                    $('.modal-backdrop').remove();
                    $state.go('step6', {
                      applicationId: ApplicationService.getApplicationId(),
                      parentFedAppId: ApplicationService.getParentFederationApplicationId()
                    });
                  }
                });
              }
            );
          });
        });
      };
      function init() {
        $scope.appLabel = ApplicationService.getAppLabel();
        CubeSvc.get(
          '/CfcCharity/api/private/application/details/' +
            ApplicationService.getApplicationId(),
          null,
          false
        ).then(function(res) {
          if (res.data.value) {
            $scope.isOffYear = res.data.value.isOffYear;
          }
        });
      }

      $scope.optionChanged = function() {
        $('input[type="file"]').val(null);
        $scope.applicationStep5.supportingFile = null;
      };

      init();
    }
  ])
  .controller('ApplicationStep6Ctrl', [
    '$state',
    '$scope',
    '$location',
    '$http',
    '$log',
    'CakeSvc',
    'MODULE_NAME',
    'APPLICATION_STEP_STATUS',
    'UserCharityRole',
    'ApplicationService',
    'ApplicationDashboardService',
    'BrowserEventService',
    'CharityDashboardService',
    'ListingOverrideAfterCloseService',
    'DownloadService',
    'blockUI',
    'CharityService',
    'APPLICATION_TYPE',
    'tokenData',
    'urlAccess',
    '$rootScope',
    '$q',
    function(
      $state,
      $scope,
      $location,
      $http,
      $log,
      CakeSvc,
      MODULE_NAME,
      APPLICATION_STEP_STATUS,
      UserCharityRole,
      ApplicationService,
      ApplicationDashboardService,
      BrowserEventService,
      CharityDashboardService,
      ListingOverrideAfterCloseService,
      DownloadService,
      blockUI,
      CharityService,
      APPLICATION_TYPE,
      tokenData,
      urlAccess,
      $rootScope,
      $q
    ) {
      $scope.dba_auth_s3_link = undefined;
      $scope.setAppStep(6);
      var user = $scope.getCurrentUser();
      var secondaryRoleList = $scope.getCurrentUser().secondaryRoleList;
      $location.hash('top-container');
      $scope.gotoPageSection = function(divId) {
        $location.hash(divId);
      };
      $scope.currentPledgeYear = sessionStorage.getItem(
        'CREATE_APPLICATION_CURRENT_PLEDGE_YEAR'
      );
      //check the access to show the breadcrumb links for member applications
      if (tokenData.federationId != null) {
        if ($scope.$parent.$$childHead.breadcrumbs.length > 0) {
          urlAccess
            .getFederationApplicationAccess(
              tokenData.pledgeYear,
              tokenData.federationCfc,
              $scope.$parent.$$childHead.breadcrumbs[0]
            )
            .then(function(res) {
              $scope.$parent.$$childHead.breadcrumbs[0] = res;
            });
          urlAccess
            .getMemberApplicationAccess(
              tokenData.pledgeYear,
              tokenData.federationCfc,
              $scope.$parent.$$childHead.breadcrumbs[1]
            )
            .then(function(res) {
              $scope.$parent.$$childHead.breadcrumbs[1] = res;
            });
        }
      }

      $scope.selectedPledgeYearObject = null;
      $scope.allowEditListingInfo = true;
      $scope.secondaryRoleList = secondaryRoleList;
      $scope.charityInformation = null;
      $scope.charityDbaAuthLetters = null;
      $scope.charityListingPageInfo = null;
      $scope.dbaletter = {};
      $scope.showListingPeriodErrorMessage = false;
      $scope.campaignId = null;
      $scope.charityListingSuccessMsg = '';
      $scope.listingPeriodEndedMessage = '';
      $scope.canEditDbaInfo = false;
      $scope.isApplicationPeriodOpen = false;
      $scope.showIrsCharityStatus = false;

      $scope.mode = $state.params.mode
        ? $state.params.mode
        : BrowserEventService.mode;
      BrowserEventService.setMode($scope.mode);
      ApplicationService.getUserSession().step6AccessBeginDate = ApplicationService.toUTCDate(
        new Date()
      );
      if (!$scope.applicationStep6) {
        $scope.applicationStep6 = ApplicationService.getApplicationStep6();
        $q(function(resolve, reject) {
          $scope.applicationStep6.applicationId = ApplicationService.getApplicationId();
          $scope.applicationStep6.cfcCode = ApplicationService.getCfcCode();
          $scope.applicationStep6.$get(
            function(res) {
              var tmp = CakeSvc.transformGenericResponseToFlatResourceResponse(
                res
              );
              if (!angular.equals(tmp, {})) {
                $scope.applicationStep6 = tmp;
              } else {
                $scope.applicationStep6.applicationId = ApplicationService.getApplicationId();
                $scope.applicationStep6.cfcCode = ApplicationService.getCfcCode();
              }
              resolve($scope.applicationStep6);
              $scope.irs_determination_s3_link = $scope.applicationStep6.s3Link;
            },
            function(err) {
              $log.error('STEP6 failed to load');
              reject(err);
            }
          );
        });

        var stepApplicable = true;
        if (
          ApplicationService.getPreApplicationDetails() != null &&
          ApplicationService.getPreApplicationDetails().charityEin &&
          ApplicationService.getPreApplicationDetails().charityEin !== '0'
        ) {
          stepApplicable = false;
        }
        $scope.stepApplicable = stepApplicable;
      }

      $scope.cfcCode = ApplicationService.getCfcCode();
      $scope.pledgeYear = ApplicationService.getPledgeYear();

      if (
        user != null &&
        user.secondaryRoleList != null &&
        user.secondaryRoleList.indexOf('OPM_AUDITOR') >= 0 &&
        !$scope.isListingEditable
      ) {
        $scope.allowEditListingInfo = false;
      }

      function checkIfEnrollmentIsOpen() {
        CharityService.findEnrollmentStatus().then(function(res) {
          $scope.isApplicationPeriodOpen = res.data;
        });
      }

      var loadCharityListingPageInfo = function() {
        if ($scope.pledgeYear && $scope.cfcCode)
          if (
            user.secondaryRoleList.indexOf('ZONE_MANAGER') < 0 &&
            user.secondaryRoleList.indexOf('REVIEWER') < 0
          ) {
            CharityDashboardService.getCharityListingPageInfo(
              $scope.pledgeYear,
              $scope.cfcCode
            ).then(function(resp) {
              var result = resp.data.value;
              if (result !== null && result !== '') {
                $scope.charityListingPageInfo = result;

                $scope.charityListingViewPageInfo = angular.copy(
                  $scope.charityListingPageInfo
                );
                if (
                  $scope.charityListingPageInfo &&
                  $scope.charityListingPageInfo.dashboardListingList
                ) {
                  CharityDashboardService.getCharityDbaAuthLetters(
                    $scope.pledgeYear,
                    $scope.cfcCode,
                    $scope.charityListingPageInfo.id
                  ).then(function(resp) {
                    if (resp.data.value != null) {
                      $scope.charityDbaAuthLetters = resp.data.value;
                      if (resp.data.value[0] !== undefined) {
                        $scope.dba_auth_s3_link = resp.data.value[0].s3Link;
                      }
                    }
                  });
                  angular.forEach(
                    $scope.charityListingPageInfo.dashboardListingList,
                    function(obj) {
                      if ($scope.pledgeYear === obj.pledgeYear) {
                        $scope.selectedPledgeYearObject = obj;
                        $scope.pledgeYearId = obj.pledgeYearId;
                      }
                    }
                  );
                }
                /*if ($scope.charityListingPageInfo.applicationStatus === 'IN_PROGRESS') {
                                $scope.applicationInProgress = true;
                            }*/
              }
            });
          }
      };

      /* populate listing information */
      CharityDashboardService.findCharityWithCFC($scope.cfcCode).then(function(
        resp
      ) {
        var result = resp.data;
        if (result !== null && result !== '') {
          $scope.charityInformation = result;
          $scope.charityStatus = result.charityStatus;
        }
      });

      CharityService.getCharityUserRole($scope.cfcCode).then(function(resp) {
        var result = resp.data;
        if (result !== null && result !== '') {
          if (
            result.userRole === 'CHARITY_OWNER' ||
            result.userRole === 'CHARITY_EDITOR' ||
            result.userRole === 'FEDERATION_OWNER' ||
            result.userRole === 'FEDERATION_EDITOR'
          ) {
            $scope.canEditDbaInfo = true;
          }
        }
      });

      loadCharityListingPageInfo();
      checkIfEnrollmentIsOpen();

      if ($scope.pledgeYear && $scope.cfcCode) {
        // Show IRS Charity Status for all roles except Charity Owners/Editors
        if (user.secondaryRoleList != null && user.secondaryRoleList != '') {
          $scope.showIrsCharityStatus = true;
        }
        if (
          user.secondaryRoleList.indexOf('ZONE_MANAGER') < 0 &&
          user.secondaryRoleList.indexOf('REVIEWER') < 0
        ) {
          CharityDashboardService.getCharityListingPageInfo(
            $scope.pledgeYear,
            $scope.cfcCode
          ).then(function(resp) {
            var result = resp.data.value;
            if (result !== null && result !== '') {
              $scope.charityListingPageInfo = result;

              if (
                $scope.charityListingPageInfo &&
                $scope.charityListingPageInfo.dashboardListingList
              ) {
                CharityDashboardService.getCharityDbaAuthLetters(
                  $scope.pledgeYear,
                  $scope.cfcCode,
                  $scope.charityListingPageInfo.id
                ).then(function(resp) {
                  if (resp.data.value != null) {
                    $scope.charityDbaAuthLetters = resp.data.value;
                    if (resp.data.value[0] !== undefined) {
                      $scope.dba_auth_s3_link = resp.data.value[0].s3Link;
                    }
                  }
                });
                angular.forEach(
                  $scope.charityListingPageInfo.dashboardListingList,
                  function(obj) {
                    if ($scope.pledgeYear === obj.pledgeYear) {
                      $scope.selectedPledgeYearObject = obj;
                      $scope.pledgeYearId = obj.pledgeYearId;
                    }
                  }
                );
              }
            }
          });
        }
      }

      if ($scope.pledgeYear && $scope.cfcCode) {
        if (
          user.secondaryRoleList.indexOf('ZONE_MANAGER') < 0 &&
          user.secondaryRoleList.indexOf('REVIEWER') < 0
        ) {
          CharityDashboardService.getCharityListingObject(
            $scope.pledgeYear,
            $scope.cfcCode
          ).then(function(resp) {
            var result = resp.data.value;
            if (result !== null && result !== '') {
              $scope.charityListingObject = result;

              $scope.charityListingViewObject = angular.copy(
                $scope.charityListingObject
              );
              $scope.campaignId = result.campaignId;

              loadListingEditableInfo();
            }
          });
        }
      }

      var loadListingEditableInfo = function() {
        ListingOverrideAfterCloseService.isListingAvailableWithOverride(
          $scope.campaignId,
          $scope.cfcCode
        ).then(function(res) {
          $scope.isListingEditable = res.data;
        });
      };

      $scope.loadEditObject = function() {
        $scope.charityListingObject = angular.copy(
          $scope.charityListingViewObject
        );
      };

      /**
       * Reusable method to Show success message on the screen.
       */
      $scope.setSuccessMessage = function(message) {
        $scope.listingPeriodEndedMessage = '';
        $scope.charityListingSuccessMsg = message;
      };
      /**
       * Reusable method to Show error message on the screen
       */
      $scope.setErrorMessage = function(message) {
        $scope.listingPeriodEndedMessage = message;
        $scope.charityListingSuccessMsg = '';
      };

      var updateListingObject = function() {
        if (
          user.secondaryRoleList.indexOf('ZONE_MANAGER') < 0 &&
          user.secondaryRoleList.indexOf('REVIEWER') < 0
        ) {
          CharityDashboardService.getCharityListingObject(
            $scope.pledgeYear,
            $scope.cfcCode
          ).then(function(resp) {
            var result = resp.data.value;
            if (result !== null && result !== '') {
              $scope.charityListingObject = result;

              $scope.charityListingViewObject = angular.copy(
                $scope.charityListingObject
              );
            }
          });
        }
      };

      $scope.updateDBAName = function(charityListing) {
        if (charityListing) {
          CharityDashboardService.updateDBAName(
            charityListing.cfcCode,
            charityListing.campaignId,
            charityListing.charityDBAName,
            $scope.charityInformation.charityIrsName
          ).then(function(resp) {
            var result = resp.data.value;
            $('#editDBANameCancelId').click();
            updateListingObject();
            if (
              user.secondaryRoleList &&
              Array.isArray(user.secondaryRoleList)
            ) {
              if (
                user.secondaryRoleList.indexOf('OPM_ADMIN') >= 0 ||
                user.secondaryRoleList.indexOf('OPM_STAFF') >= 0 ||
                user.secondaryRoleList.indexOf('ZONE_MANAGER') >= 0 ||
                user.secondaryRoleList.indexOf('REVIEWER') >= 0 ||
                user.secondaryRoleList.indexOf('OPM_AUDITOR') >= 0
              ) {
                $scope.$broadcast('reloadCfcAdminReview');
              }
            }
            checkIfEnrollmentIsOpen();
            $rootScope.$broadcast('editDbaName', resp.data);
          });
        }
      };

      // Handle upload of authorized DBA file.
      $scope.uploadAuthorizedDBA = function() {
        blockUI.start();
        $scope.errorMsg = '';
        $scope.fileUploadStatusMessage = '';
        if (event.target.files.length > 0) {
          if (
            typeof event.target.files[0].type !== 'undefined' &&
            event.target.files[0].type !== null &&
            event.target.files[0].type !== 'application/pdf'
          ) {
            $scope.errorMsg = 'File upload must be PDF format.';
            blockUI.stop();
          } else {
            var fd = new FormData();
            for (i = 0; i < event.target.files.length; i++) {
              fd.append('supportingFile', event.target.files[i]);
            }
            var uploadUrl =
              '/CfcCharity/api/private/charitylisting/' +
              $scope.cfcCode +
              '/' +
              $scope.campaignId +
              '/uploadDBA';
            $http
              .post(uploadUrl, fd, {
                transformRequest: angular.identity,
                headers: {
                  'Content-Type': undefined,
                  'X-AUTH-TOKEN': $scope.xAuthToken
                }
              })
              .then(function(res) {
                var data = res.data.value;
                if (
                  data &&
                  data.statusCode !== undefined &&
                  data.statusCode === 'ERROR'
                ) {
                  $scope.fileUploadStatusMessage = data.message;
                  $('#fileUploadStatusMessage').modal();
                  $('#DBAauthorization').val('');
                } else if (data !== null && data !== '') {
                  $scope.fileUploadStatusMessage =
                    'File uploaded successfully.';
                  $('#fileUploadStatusMessage').modal();
                  $scope.charityListingPageInfo.charityDBAAuthUploaded = true;
                  $scope.listingPeriodEndedMessage = '';
                  $scope.charityListingObject = data;
                  if (
                    $scope.charityListingPageInfo.paymentStatus === 'Unpaid' &&
                    $scope.charityListingPageInfo.applicationType ===
                      'Independent' &&
                    $scope.charityListingPageInfo.applicationStatus ===
                      'APPROVED'
                  ) {
                    $scope.charityListingPageInfo.showPayListingFee = true;
                  }
                  $scope.charityListingViewObject = angular.copy(
                    $scope.charityListingObject
                  );
                  CharityDashboardService.getCharityDbaAuthLetters(
                    $scope.pledgeYear,
                    $scope.cfcCode,
                    $scope.charityListingPageInfo.id
                  ).then(function(resp) {
                    if (resp.data.value != null) {
                      $scope.charityDbaAuthLetters = resp.data.value;
                      if (resp.data.value[0] !== undefined) {
                        $scope.dba_auth_s3_link = resp.data.value[0].s3Link;
                      }
                    }
                  });
                }
              })
              .catch(function(err) {
                $('#DBAauthorization').val('');
                $scope.fileUploadStatusMessage =
                  'There is a problem with your file. Your file may be corrupted or be password protected. Please check and fix your file and upload it again.';
                $('#fileUploadStatusMessage').modal();
              });
            blockUI.stop();
          }
        }
        blockUI.stop();
      };

      // Handle deletion of authorized DBA file.
      $scope.deleteDbaAuthLetter = function(dbaAuthLetterId) {
        var url =
          '/CfcCharity/api/public/delete/charity/dbaAuth/' +
          $scope.cfcCode +
          '/' +
          $scope.charityListingPageInfo.campaignId +
          '/' +
          dbaAuthLetterId;
        $http({
          method: 'POST',
          url: url,
          data: {
            cfcCode: $scope.cfcCode,
            campaignId: $scope.charityListingPageInfo.campaignId,
            dbaAuthLetterId: dbaAuthLetterId
          }
        })
          .then(function(resp) {
            var result = resp.data;
            if (result) {
              CharityDashboardService.getCharityDbaAuthLetters(
                $scope.pledgeYear,
                $scope.cfcCode,
                $scope.charityListingPageInfo.id
              ).then(function(resp) {
                if (resp.data.value != null) {
                  $scope.charityDbaAuthLetters = resp.data.value;
                  if (resp.data.value[0] !== undefined) {
                    $scope.dba_auth_s3_link = resp.data.value[0].s3Link;
                  }
                }
              });
            }
          })
          .catch(function(error) {
            // $('#DBAauthorization').val('');
            $scope.fileDeleteStatusMessage =
              'There was an issue when trying to delete your file. Please try again later.';
            $('#fileDeleteStatusMessage').modal();
          });
      };

      $scope.validateApplicationStep = function(exit) {
        $scope.fieldsMissing = [];
        $scope.fileError = false;
        if ($scope.stepApplicable) {
          if (!$scope.applicationStep6.certifyIrsDetermination) {
            $scope.fieldsMissing.push('Certify IRS Determination');
          }
          if (
            !$scope.applicationStep6.supportingFile &&
            !$scope.applicationStep6.fileName
          ) {
            $scope.fieldsMissing.push(
              'You have not uploaded the IRS Determination Letter.'
            );
          } else {
            if (
              $scope.applicationStep6.supportingFile &&
              $scope.applicationStep6.supportingFile.type !== 'application/pdf'
            ) {
              $scope.fieldsMissing.push(
                'IRS determination letter: Only PDF file is allowed.'
              );
              $scope.fileError = true;
            } else {
              $scope.fileError = false;
            }
          }
        }
        if (
          $scope.charityListingViewObject.dbanameChangeStatus === 'Pending' &&
          ($scope.charityDbaAuthLetters.length === 0 ||
            $scope.charityDbaAuthLetters[0].markedToDelete === true)
        ) {
          $scope.fieldsMissing.push(
            'You have not uploaded the State Letter Authorizing DBA'
          );
        }
        if (exit) {
          if ($scope.fieldsMissing.length > 0) {
            $scope.applicationStep6.stepStatus =
              APPLICATION_STEP_STATUS.incomplete;
            if (!$scope.fileError) {
              $scope.saveApplicationStep(exit);
            } else {
              $('#missingInfo').modal();
            }
          } else {
            $scope.applicationStep6.stepStatus =
              APPLICATION_STEP_STATUS.complete;
            $scope.saveApplicationStep(exit);
          }
        } else {
          if ($scope.fieldsMissing.length > 0) {
            $scope.applicationStep6.stepStatus =
              APPLICATION_STEP_STATUS.incomplete;
          } else {
            $scope.applicationStep6.stepStatus =
              APPLICATION_STEP_STATUS.complete;
          }
          $('#missingInfo').modal();
        }
      };
      $scope.continue = false;
      $scope.saveApplicationStep = function(exit) {
        var currentTime = ApplicationService.toUTCDate(new Date());
        var dateDifferenceInSeconds = 0;
        var userSession = ApplicationService.getUserSession();
        userSession.step6AccessEndDate = currentTime;
        dateDifferenceInSeconds = ApplicationService.getDateDifference(
          currentTime,
          userSession.step6AccessBeginDate
        );
        userSession.step6TimeSpentInSeconds = dateDifferenceInSeconds;
        if (exit) {
          userSession.lastAccessedEndDate = currentTime;
          userSession.totalTimeSpentInSeconds = ApplicationService.getDateDifference(
            currentTime,
            userSession.lastAccessedBeginDate
          );
        }
        if (!$scope.applicationStep6.certifyIrsDetermination) {
          $scope.applicationStep6.certifyIrsDetermination = false;
        }
        ApplicationService.saveFileStep6($scope.applicationStep6, function(
          response
        ) {
          if (
            response.message &&
            response.message.type === 'ERROR' &&
            !$scope.continue
          ) {
            $('#determinationLetter').val(null);
            $scope.applicationStep6.supportingFile = null;
            $('#missingInfo').modal('hide');
            $('#modalUpload').modal();
            return;
          }
          // this handles exception with a response
          if ((!response || response.errorCode) && !$scope.continue) {
            $scope.applicationStep6.supportingFile = null;
            $('#modalUpload').modal();
            return;
          }
          if (response) {
            $scope.applicationStep6.fileName = response.fileName;
          }
          ApplicationService.saveUserSession().then(function(response) {
            var overallStatus = 'Complete';
            ApplicationService.getApplicationStatus(
              ApplicationService.getApplicationId(),
              function(data) {
                for (var i = 0; i < data.length; i++) {
                  if (data[i].stepStatus === 'Incomplete') {
                    overallStatus = 'Incomplete';
                    break;
                  }
                }
                var app = ApplicationService.getApplication();
                app.overallApplicationStatus = overallStatus;
                ApplicationService.saveTable(app, function(response) {
                  if (exit) {
                    $scope.setAppStep(0);
                    $state.go('applicationDashboard', {
                      applicationId: ApplicationService.getApplicationId()
                    });
                  } else {
                    $('.modal-backdrop').remove();
                    $state.go('step7', {
                      applicationId: ApplicationService.getApplicationId(),
                      parentFedAppId: ApplicationService.getParentFederationApplicationId()
                    });
                  }
                });
              }
            );
          });
        });
      };

      function init() {
        $scope.appLabel = ApplicationService.getAppLabel();
        ApplicationService.setPledgeYear($scope.$parent.appNavbar.pledgeYear);
      }

      init();
    }
  ])
  .controller('ApplicationStep7Ctrl', [
    '$state',
    '$scope',
    '$location',
    '$log',
    '$q',
    'CakeSvc',
    'MODULE_NAME',
    'REVENUE_OPTION',
    'APPLICATION_STEP_STATUS',
    'UserCharityRole',
    'ApplicationService',
    'ApplicationDashboardService',
    'BrowserEventService',
    'CharityService',
    'APPLICATION_TYPE',
    'CharityDashboardService',
    'ZoneCenterService',
    'DownloadService',
    'tokenData',
    'urlAccess',
    'CubeSvc',
    '$filter',
    'PLEDGE_YEARS',
    'ValidationUtil',
    'DATES',
    function(
      $state,
      $scope,
      $location,
      $log,
      $q,
      CakeSvc,
      MODULE_NAME,
      REVENUE_OPTION,
      APPLICATION_STEP_STATUS,
      UserCharityRole,
      ApplicationService,
      ApplicationDashboardService,
      BrowserEventService,
      CharityService,
      APPLICATION_TYPE,
      CharityDashboardService,
      ZoneCenterService,
      DownloadService,
      tokenData,
      urlAccess,
      CubeSvc,
      $filter,
      PLEDGE_YEARS,
      ValidationUtil,
      DATES
    ) {
      $scope.setAppStep(7);

      $location.hash('top-container');
      $scope.gotoPageSection = function(divId) {
        $location.hash(divId);
      };
      $scope.displayForPriorYears = false;
      $scope.currentPledgeYear = sessionStorage.getItem(
        'CREATE_APPLICATION_CURRENT_PLEDGE_YEAR'
      );
      $scope.pledgeYear = tokenData.pledgeYear;
      if (
        $scope.pledgeYear &&
        $scope.pledgeYear < PLEDGE_YEARS.PLEDGE_YEAR_2022
      ) {
        $scope.displayForPriorYears = true;
      }
      //check the access to show the breadcrumb links for member applications
      if (tokenData.federationId != null) {
        if ($scope.$parent.$$childHead.breadcrumbs.length > 0) {
          urlAccess
            .getFederationApplicationAccess(
              tokenData.pledgeYear,
              tokenData.federationCfc,
              $scope.$parent.$$childHead.breadcrumbs[0]
            )
            .then(function(res) {
              $scope.$parent.$$childHead.breadcrumbs[0] = res;
            });
          urlAccess
            .getMemberApplicationAccess(
              tokenData.pledgeYear,
              tokenData.federationCfc,
              $scope.$parent.$$childHead.breadcrumbs[1]
            )
            .then(function(res) {
              $scope.$parent.$$childHead.breadcrumbs[1] = res;
            });
        }
      }

      var userOb = JSON.parse(sessionStorage.getItem('USER_OBJ'));
      $scope.mode = $state.params.mode
        ? $state.params.mode
        : BrowserEventService.mode;
      BrowserEventService.setMode($scope.mode);
      ApplicationService.getUserSession().step7AccessBeginDate = ApplicationService.toUTCDate(
        new Date()
      );
      if (!$scope.applicationStep7) {
        $scope.applicationStep7 = ApplicationService.getApplicationStep7();
        $scope.applicationStep7.applicationId = ApplicationService.getApplicationId();
        $scope.applicationStep7.cfcCode = ApplicationService.getCfcCode();
        $scope.applicationStep7.isOffYear = ApplicationService.getPreApplicationDetails().isOffYear;
        $scope.applicationStep7.pledgeYear = ApplicationService.getPledgeYear();
        $q(function(resolve, reject) {
          $scope.applicationStep7.$get(
            function(res) {
              var tmp = CakeSvc.transformGenericResponseToFlatResourceResponse(
                res
              );
              if (!angular.equals(tmp, {})) {
                $scope.applicationStep7 = tmp;
              } else {
                // need a minimum while $resource resolves... later
                $scope.applicationStep7.applicationId = ApplicationService.getApplicationId();
                $scope.applicationStep7.cfcCode = ApplicationService.getCfcCode();
                $scope.applicationStep7.isOffYear = ApplicationService.getPreApplicationDetails().isOffYear;
                $scope.applicationStep7.pledgeYear = ApplicationService.getPledgeYear();
              }
              resolve($scope.applicationStep7);
              if ($scope.applicationStep7.s3Link) {
                $scope.audited_financial_s3_link =
                  $scope.applicationStep7.s3Link;
              }
              if ($scope.applicationStep7.documentEndDate) {
                var localDate = new Date(
                  $scope.applicationStep7.documentEndDate
                );
                var localTime = localDate.getTime();
                var localOffset = localDate.getTimezoneOffset() * 60000;
                $scope.mydate = new Date(localTime + localOffset);
                $scope.applicationStep7.documentEndDate = $filter('date')(
                  new Date($scope.mydate),
                  'MM/dd/yyyy'
                );
              }
            },
            function(err) {
              $log.error('STEP7 failed to load');
              reject(err);
            }
          );
        }).then(function() {
          CharityDashboardService.getCharityDashboardData(
            $scope.applicationStep7.cfcCode
          ).then(function(resp) {
            var result = resp.data.value;
            if (result !== null && result !== '') {
              $scope.applicationStep7.charityEin = result.einNumber;
            }
          });

          if (
            $scope.applicationStep7.pledgeYear &&
            $scope.applicationStep7.cfcCode
          ) {
            if (
              userOb.secondaryRoleList.indexOf('ZONE_MANAGER') < 0 &&
              userOb.secondaryRoleList.indexOf('REVIEWER') < 0
            ) {
              CharityDashboardService.getCharityListingObject(
                $scope.applicationStep7.pledgeYear,
                $scope.applicationStep7.cfcCode
              ).then(function(resp) {
                var result = resp.data.value;
                if (result !== null && result !== '') {
                  $scope.charityListingObject = result;

                  $scope.applicationStep7.charityListingViewObject = angular.copy(
                    $scope.charityListingObject
                  );
                }
              });
            }
          }

          /* populate listing information */
          CharityDashboardService.findCharityWithCFC(
            $scope.applicationStep7.cfcCode
          ).then(function(res) {
            if (res.data !== null && res.data !== '') {
              $scope.charityInformation = res.data;
            }
          });
        });
      }

      $scope.applicationStep9 = ApplicationService.getApplicationStep9();
      $q(function(resolve, reject) {
        if (angular.equals($scope.applicationStep9, {})) {
          $scope.applicationStep9.applicationId = tokenData.applicationId;
          $scope.applicationStep9.cfcCode = tokenData.cfcCode;
          $scope.applicationStep9.$get(
            function(res) {
              $scope.applicationStep9 = CakeSvc.transformGenericResponseToFlatResourceResponse(
                res
              );
              resolve(res);
              $scope.totalRevenue = $scope.applicationStep9.totalRevenue;
            },
            function(err) {
              $log.error('STEP4 data in STEP9 failed to load');
              reject(err);
            }
          );
        } else {
          resolve($scope.applicationStep9);
          $scope.totalRevenue = $scope.applicationStep9.totalRevenue;
        }
      });

      $scope.validateApplicationStep = function(exit) {
        $scope.fieldsMissing = [];
        $scope.fileError = false;
        if (!$scope.applicationStep7.revenueOption) {
          $scope.fieldsMissing.push('Revenue Option');
        }
        if ($scope.applicationStep7.revenueOption) {
          if (
            $scope.applicationStep7.revenueOption === REVENUE_OPTION.option1
          ) {
            if (
              !$scope.applicationStep7.supportingFile &&
              !$scope.applicationStep7.fileName &&
              !$scope.isOffYear
            ) {
              $scope.fieldsMissing.push(
                'You have not uploaded the Auditors Report, attachment B.'
              );
            } else {
              if (
                $scope.applicationStep7.supportingFile &&
                $scope.applicationStep7.supportingFile.type !==
                  'application/pdf'
              ) {
                $scope.fieldsMissing.push(
                  'Attachment B: Only PDF file is allowed.'
                );
                $scope.fileError = true;
              } else {
                $scope.fileError = false;
              }
            }

            //check the validation from step 9 if they added step9 first and then came back to step7
            if (
              $scope.totalRevenue != undefined &&
              $scope.totalRevenue != null &&
              ValidationUtil.isNumeric($scope.totalRevenue) &&
              !($scope.totalRevenue > 250000)
            ) {
              var msg =
                'The organization’s total revenue must be entered based on what was reported in the IRS Form 990, or pro forma IRS Form 990, on Part VIII, Line 12. Based on your organization’s total revenue, verify that the correct statement was certified to on Step 7. An organization that is required to submit a full application and with a total revenue above $100,000 must submit either reviewed or audited financial statements, and those above $250,000 must report audited financial statements. The financial statements must be prepared by an independent, certified public accountant and must be for a period that ended on or after June 30, ' +
                ($scope.pledgeYear - 2) +
                '. Compiled financial statements will not be accepted. ';
              $scope.fieldsMissing.push(msg);
            }
          } else if (
            $scope.applicationStep7.revenueOption === REVENUE_OPTION.option2
          ) {
            if (
              !$scope.applicationStep7.supportingFile &&
              !$scope.applicationStep7.fileName &&
              !$scope.isOffYear
            ) {
              $scope.fieldsMissing.push(
                'You have not uploaded the Auditors Report, attachment B.'
              );
            } else {
              if (
                $scope.applicationStep7.supportingFile &&
                $scope.applicationStep7.supportingFile.type !==
                  'application/pdf'
              ) {
                $scope.fieldsMissing.push(
                  'Attachment B: Only PDF file is allowed.'
                );
                $scope.fileError = true;
              } else {
                $scope.fileError = false;
              }
            }

            if (
              $scope.totalRevenue != undefined &&
              $scope.totalRevenue != null &&
              ValidationUtil.isNumeric($scope.totalRevenue) &&
              !($scope.totalRevenue >= 100000 && $scope.totalRevenue < 250000)
            ) {
              var msg =
                'The organization’s total revenue must be entered based on what was reported in the IRS Form 990, or pro forma IRS Form 990, on Part VIII, Line 12. Based on your organization’s total revenue, verify that the correct statement was certified to on Step 7. An organization that is required to submit a full application and with a total revenue above $100,000 must submit either reviewed or audited financial statements, and those above $250,000 must report audited financial statements. The financial statements must be prepared by an independent, certified public accountant and must be for a period that ended on or after June 30, ' +
                ($scope.pledgeYear - 2) +
                '. Compiled financial statements will not be accepted. ';
              $scope.fieldsMissing.push(msg);
            }
          }
          if (
            $scope.applicationStep7.revenueOption === REVENUE_OPTION.option3 &&
            $scope.totalRevenue != undefined &&
            $scope.totalRevenue != null &&
            ValidationUtil.isNumeric($scope.totalRevenue) &&
            !($scope.totalRevenue < 100000)
          ) {
            var msg =
              'The organization’s total revenue must be entered based on what was reported in the IRS Form 990, or pro forma IRS Form 990, on Part VIII, Line 12. Based on your organization’s total revenue, verify that the correct statement was certified to on Step 7. An organization that is required to submit a full application and with a total revenue above $100,000 must submit either reviewed or audited financial statements, and those above $250,000 must report audited financial statements. The financial statements must be prepared by an independent, certified public accountant and must be for a period that ended on or after June 30, ' +
              ($scope.pledgeYear - 2) +
              '. Compiled financial statements will not be accepted. ';
            $scope.fieldsMissing.push(msg);
          }

          if (
            !$scope.displayForPriorYears &&
            !$scope.isOffYear &&
            $scope.applicationStep7.revenueOption !== REVENUE_OPTION.option3
          ) {
            if (!$scope.applicationStep7.documentEndDate) {
              $scope.fieldsMissing.push('Please enter Fiscal Period end date.');
            }
            if ($scope.applicationStep7.documentEndDate) {
              var valid = ValidationUtil.validateDate(
                $scope.applicationStep7.documentEndDate
              );
              if (!valid) {
                $scope.fileError = true;
                $scope.fieldsMissing.push('Date is not valid');
              }
              var documentValidStartDate =
                DATES.FISCAL_START_DATE + ($scope.pledgeYear - 2);
              var documentValidEndDate =
                DATES.END_DATE + ($scope.pledgeYear - 1);
              if (
                !ValidationUtil.validateDateInRespectedPeriod(
                  null,
                  $scope.applicationStep7.documentEndDate,
                  documentValidStartDate,
                  documentValidEndDate
                )
              ) {
                $scope.fieldsMissing.push(
                  'The date for the financial statements is for an unacceptable period. For the ' +
                    $scope.pledgeYear +
                    ' campaign, financial statements must be for a fiscal period that ended on or after June 30, ' +
                    ($scope.pledgeYear - 2)
                );
              }
            }
          }
        }
        if ($scope.fieldsMissing.length > 0) {
          $scope.applicationStep7.stepStatus =
            APPLICATION_STEP_STATUS.incomplete;
          if (exit && !$scope.fileError) {
            $scope.saveApplicationStep(exit);
          } else {
            $('#missingInfo').modal();
          }
        } else {
          $scope.applicationStep7.stepStatus = APPLICATION_STEP_STATUS.complete;
          if (exit) {
            $scope.saveApplicationStep(exit);
          } else {
            $scope.saveApplicationStep();
          }
        }
      };
      $scope.continue = false;
      $scope.saveApplicationStep = function(exit) {
        if ($scope.applicationStep7.revenueOption === 'OPTION_3') {
          $scope.applicationStep7.supportingFile = null;
          $scope.applicationStep7.documentStartDate = null;
          $scope.applicationStep7.documentEndDate = null;
        } else {
          $scope.applicationStep7.documentStartDate = $scope.applicationStep7
            .documentStartDate
            ? new Date($scope.applicationStep7.documentStartDate)
            : null;
          $scope.applicationStep7.documentEndDate = $scope.applicationStep7
            .documentEndDate
            ? new Date($scope.applicationStep7.documentEndDate)
            : null;
        }
        var currentTime = ApplicationService.toUTCDate(new Date());
        var dateDifferenceInSeconds = 0;
        var userSession = ApplicationService.getUserSession();
        userSession.step7AccessEndDate = currentTime;
        dateDifferenceInSeconds = ApplicationService.getDateDifference(
          currentTime,
          userSession.step7AccessBeginDate
        );
        userSession.step7TimeSpentInSeconds = dateDifferenceInSeconds;
        if (exit) {
          userSession.lastAccessedEndDate = currentTime;
          userSession.totalTimeSpentInSeconds = ApplicationService.getDateDifference(
            currentTime,
            userSession.lastAccessedBeginDate
          );
        }
        ApplicationService.saveFileStep7($scope.applicationStep7, function(
          response
        ) {
          if (
            response.message &&
            response.message.type === 'ERROR' &&
            !$scope.continue
          ) {
            $('input[type="file"]').val(null);
            $scope.applicationStep7.supportingFile = null;
            $('#modalUpload').modal();
            return;
          }

          // this handles exception with a response
          if ((!response || response.errorCode) && !$scope.continue) {
            $scope.applicationStep7.supportingFile = null;
            $('#modalUpload').modal();
            return;
          }
          if (response) {
            $scope.applicationStep7.fileName = response.fileName;
          }
          ApplicationService.saveUserSession().then(function(response) {
            var overallStatus = 'Complete';
            ApplicationService.getApplicationStatus(
              ApplicationService.getApplicationId(),
              function(data) {
                for (var i = 0; i < data.length; i++) {
                  if (data[i].stepStatus === 'Incomplete') {
                    overallStatus = 'Incomplete';
                    break;
                  }
                }
                var app = ApplicationService.getApplication();
                app.overallApplicationStatus = overallStatus;
                ApplicationService.saveTable(app, function(response) {
                  if (exit) {
                    $scope.setAppStep(0);
                    $state.go('applicationDashboard', {
                      applicationId: ApplicationService.getApplicationId()
                    });
                  } else {
                    $('.modal-backdrop').remove();
                    $state.go('step8', {
                      applicationId: ApplicationService.getApplicationId(),
                      parentFedAppId: ApplicationService.getParentFederationApplicationId()
                    });
                  }
                });
              }
            );
          });
        });
      };
      $scope.optionChanged = function() {
        $scope.applicationStep7.supportingFile = null;
        $('input[type="file"]').val(null);
      };

      $scope.resetData = function() {
        $scope.applicationStep7.documentStartDate = null;
        $scope.applicationStep7.documentEndDate = null;
      };

      function init() {
        $scope.appLabel = ApplicationService.getAppLabel();
        $scope.isOpmAdmin =
          userOb.secondaryRoleList.indexOf('OPM_ADMIN') >= 0 || false;
        $scope.isZoneManager =
          userOb.secondaryRoleList.indexOf('ZONE_MANAGER') >= 0 || false;
        $scope.isOpmStaff =
          userOb.secondaryRoleList.indexOf('OPM_STAFF') >= 0 || false;
        $scope.isZoneReviewer =
          userOb.secondaryRoleList.indexOf('ZONE_REVIEWER') >= 0 || false;
        $scope.isOpmAuditor =
          userOb.secondaryRoleList.indexOf('OPM_AUDITOR') >= 0 || false;
        if (
          $scope.isOpmAdmin ||
          $scope.isZoneManager ||
          $scope.isOpmStaff ||
          $scope.isZoneReviewer ||
          $scope.isOpmAuditor
        ) {
          $scope.canViewMsg = true;
        } else {
          $scope.canViewMsg = false;
        }
        CubeSvc.get(
          '/CfcCharity/api/private/application/details/' +
            ApplicationService.getApplicationId(),
          null,
          false
        ).then(function(res) {
          if (res.data.value) {
            $scope.isOffYear = res.data.value.isOffYear;
          }
        });

        //revenue mismatch check
        CubeSvc.get(
          '/CfcCharity/api/private/application/revenuedetails/' +
            ApplicationService.getApplicationId(),
          null,
          false
        ).then(function(res) {
          $scope.validRevenue = res.data.value;
        });
      }

      init();
    }
  ])
  .controller('ApplicationStep8Ctrl', [
    '$state',
    '$scope',
    '$location',
    '$log',
    'CakeSvc',
    'MODULE_NAME',
    'IRS_FORM990_OPTION',
    'APPLICATION_STEP_STATUS',
    'UserCharityRole',
    'ApplicationService',
    'ApplicationDashboardService',
    'BrowserEventService',
    'CharityService',
    'APPLICATION_TYPE',
    'CharityDashboardService',
    'ZoneCenterService',
    'DownloadService',
    'tokenData',
    'urlAccess',
    '$q',
    'PLEDGE_YEARS',
    'ValidationUtil',
    '$filter',
    'CubeSvc',
    'DATES',
    function(
      $state,
      $scope,
      $location,
      $log,
      CakeSvc,
      MODULE_NAME,
      IRS_FORM990_OPTION,
      APPLICATION_STEP_STATUS,
      UserCharityRole,
      ApplicationService,
      ApplicationDashboardService,
      BrowserEventService,
      CharityService,
      APPLICATION_TYPE,
      CharityDashboardService,
      ZoneCenterService,
      DownloadService,
      tokenData,
      urlAccess,
      $q,
      PLEDGE_YEARS,
      ValidationUtil,
      $filter,
      CubeSvc,
      DATES
    ) {
      $scope.setAppStep(8);
      $location.hash('top-container');
      $scope.gotoPageSection = function(divId) {
        $location.hash(divId);
      };
      $scope.currentPledgeYear = sessionStorage.getItem(
        'CREATE_APPLICATION_CURRENT_PLEDGE_YEAR'
      );

      $scope.displayForPriorYears = false;
      $scope.pledgeYear = tokenData.pledgeYear;
      $scope.isOffYear = ApplicationService.getPreApplicationDetails().isOffYear;

      if (
        $scope.pledgeYear &&
        $scope.pledgeYear < PLEDGE_YEARS.PLEDGE_YEAR_2022
      ) {
        $scope.displayForPriorYears = true;
      }
      //check the access to show the breadcrumb links for member applications
      if (tokenData.federationId != null) {
        if ($scope.$parent.$$childHead.breadcrumbs.length > 0) {
          urlAccess
            .getFederationApplicationAccess(
              tokenData.pledgeYear,
              tokenData.federationCfc,
              $scope.$parent.$$childHead.breadcrumbs[0]
            )
            .then(function(res) {
              $scope.$parent.$$childHead.breadcrumbs[0] = res;
            });
          urlAccess
            .getMemberApplicationAccess(
              tokenData.pledgeYear,
              tokenData.federationCfc,
              $scope.$parent.$$childHead.breadcrumbs[1]
            )
            .then(function(res) {
              $scope.$parent.$$childHead.breadcrumbs[1] = res;
            });
        }
      }

      $scope.mode = $state.params.mode
        ? $state.params.mode
        : BrowserEventService.mode;
      BrowserEventService.setMode($scope.mode);
      ApplicationService.getUserSession().step8AccessBeginDate = ApplicationService.toUTCDate(
        new Date()
      );
      $scope.currentFileVersion = 1;
      function init() {
        if (!$scope.applicationStep8) {
          $scope.applicationStep8 = ApplicationService.getApplicationStep8();
          $scope.applicationStep8.applicationId = ApplicationService.getApplicationId();
          $scope.applicationStep8.cfcCode = ApplicationService.getCfcCode();
          $scope.applicationStep8.pledgeYear = ApplicationService.getPledgeYear();
          $q(function(resolve, reject) {
            $scope.applicationStep8.$get(
              function(res) {
                var tmp = CakeSvc.transformGenericResponseToFlatResourceResponse(
                  res
                );
                if (!angular.equals(tmp, {})) {
                  $scope.applicationStep8 = tmp;
                } else {
                  $scope.applicationStep8.applicationId = ApplicationService.getApplicationId();
                  $scope.applicationStep8.cfcCode = ApplicationService.getCfcCode();
                  $scope.applicationStep8.pledgeYear = ApplicationService.getPledgeYear();
                }
                if (
                  $scope.applicationStep8.changedFileName != null ||
                  $scope.applicationStep8.changedFileName != undefined
                ) {
                  var us = $scope.applicationStep8.changedFileName.lastIndexOf(
                    '_'
                  );
                  $scope.currentFileVersion = parseInt(
                    $scope.applicationStep8.changedFileName
                      .substring(
                        us,
                        $scope.applicationStep8.changedFileName.length
                      )
                      .replace('_ver', '')
                      .replace(/\.[^\.]+$/, '')
                  );
                }
                if ($scope.applicationStep8.signed === true) {
                  $scope.applicationStep8.signed = 'Yes';
                } else if ($scope.applicationStep8.signed === false) {
                  $scope.applicationStep8.signed = 'No';
                } else {
                  $scope.applicationStep8.signed = null;
                }
                if ($scope.applicationStep8.startDate != null) {
                  var localDate = new Date($scope.applicationStep8.startDate);
                  var localTime = localDate.getTime();
                  var localOffset = localDate.getTimezoneOffset() * 60000;
                  $scope.mydate = new Date(localTime + localOffset);
                  $scope.applicationStep8.startDate = $filter('date')(
                    new Date($scope.mydate),
                    'MM/dd/yyyy'
                  );
                }
                if ($scope.applicationStep8.endDate != null) {
                  var localDate1 = new Date($scope.applicationStep8.endDate);
                  var localTime1 = localDate1.getTime();
                  var localOffset1 = localDate1.getTimezoneOffset() * 60000;
                  $scope.mydate1 = new Date(localTime1 + localOffset1);
                  $scope.applicationStep8.endDate = $filter('date')(
                    new Date($scope.mydate1),
                    'MM/dd/yyyy'
                  );
                }
                if ($scope.applicationStep8.accountingMethod === null) {
                  $scope.applicationStep8.accountingMethod = '--';
                }
                resolve($scope.applicationStep8);
              },
              function(err) {
                $log.error('STEP8 failed to load');
                reject(err);
              }
            );
          });

          var userOb = JSON.parse(sessionStorage.getItem('USER_OBJ'));
          $scope.isOpmAdmin =
            userOb.secondaryRoleList.indexOf('OPM_ADMIN') >= 0 || false;
          $scope.isZoneManager =
            userOb.secondaryRoleList.indexOf('ZONE_MANAGER') >= 0 || false;
          $scope.isOpmStaff =
            userOb.secondaryRoleList.indexOf('OPM_STAFF') >= 0 || false;
          $scope.isZoneReviewer =
            userOb.secondaryRoleList.indexOf('ZONE_REVIEWER') >= 0 || false;
          $scope.isOpmAuditor =
            userOb.secondaryRoleList.indexOf('OPM_AUDITOR') >= 0 || false;

          CharityDashboardService.getCharityDashboardData(
            $scope.applicationStep8.cfcCode
          ).then(function(resp) {
            var result = resp.data.value;
            if (result !== null && result !== '') {
              $scope.applicationStep8.charityEin = result.einNumber;
            }
            if (
              $scope.applicationStep8.changedFileName != null ||
              $scope.applicationStep8.changedFileName != undefined
            ) {
              var us = $scope.applicationStep8.changedFileName.lastIndexOf('_');
              $scope.currentFileVersion = parseInt(
                $scope.applicationStep8.changedFileName
                  .substring(us, $scope.applicationStep8.changedFileName.length)
                  .replace('_ver', '')
                  .replace(/\.[^\.]+$/, '')
              );
            }
          });

          if (
            $scope.applicationStep8.pledgeYear &&
            $scope.applicationStep8.cfcCode
          ) {
            if (
              userOb.secondaryRoleList.indexOf('ZONE_MANAGER') < 0 &&
              userOb.secondaryRoleList.indexOf('REVIEWER') < 0
            ) {
              CharityDashboardService.getCharityListingObject(
                $scope.applicationStep8.pledgeYear,
                $scope.applicationStep8.cfcCode
              ).then(function(resp) {
                var result = resp.data.value;
                if (result !== null && result !== '') {
                  $scope.charityListingObject = result;

                  $scope.applicationStep8.charityListingViewObject = angular.copy(
                    $scope.charityListingObject
                  );
                  //$scope.campaignId = result.campaignId;
                }
              });
            }
          }
          /* populate listing information */
          CharityDashboardService.findCharityWithCFC(
            $scope.applicationStep8.cfcCode
          ).then(function(res) {
            if (res.data !== null && res.data !== '') {
              $scope.charityInformation = res.data;
            }
          });
        }

        if (!$scope.isOffYear) {
          $scope.applicationStep9 = ApplicationService.getApplicationStep9();
          $q(function(resolve, reject) {
            if (angular.equals($scope.applicationStep9, {})) {
              $scope.applicationStep9.applicationId = tokenData.applicationId;
              $scope.applicationStep9.cfcCode = tokenData.cfcCode;
              $scope.applicationStep9.$get(
                function(res) {
                  $scope.applicationStep9 = CakeSvc.transformGenericResponseToFlatResourceResponse(
                    res
                  );
                  resolve(res);
                  $scope.totalRevenue = $scope.applicationStep9.totalRevenue;
                },
                function(err) {
                  $log.error('STEP9 data in STEP8 failed to load');
                  reject(err);
                }
              );
            } else {
              resolve($scope.applicationStep9);
              $scope.totalRevenue = $scope.applicationStep9.totalRevenue;
            }
          });
        }

        $scope.appLabel = ApplicationService.getAppLabel();
      }

      $scope.getFileList = function() {
        return new Array($scope.currentFileVersion);
      };

      $scope.showModal = function() {
        if ($scope.applicationStep8.signed === 'No') {
          $('#signatureRequired').modal('show');
        }
      };

      $scope.openFilesModal = function() {
        if (!$scope.displayForPriorYears) {
          var url =
            '/CfcCharity/api/private/application/step8/uploadedfiles/' +
            $scope.applicationStep8.applicationId;
          CubeSvc.get(url, null, false).then(function(res) {
            $scope.uploadedFileData = res.data.value;
            $scope.irsHistory = $scope.uploadedFileData.irsHistory;
            $scope.eSignHistory = $scope.uploadedFileData.eSignHistory;
          });
        }
        $('#allFiles').modal();
      };

      $scope.validateApplicationStep = function(exit) {
        $scope.fieldsMissing = [];
        $scope.fileError = false;
        if (!$scope.applicationStep8.irsForm990Option) {
          $scope.fieldsMissing.push('You have not selected an option.');
        }
        if ($scope.applicationStep8.irsForm990Option) {
          if (
            $scope.applicationStep8.irsForm990Option ===
            IRS_FORM990_OPTION.option1
          ) {
            if (
              !$scope.applicationStep8.supportingFile &&
              !$scope.applicationStep8.fileName
            ) {
              $scope.fieldsMissing.push('Attachment C Form 990');
            } else {
              if (
                $scope.applicationStep8.supportingFile &&
                $scope.applicationStep8.supportingFile.type !==
                  'application/pdf'
              ) {
                $scope.fieldsMissing.push(
                  'Attachment C Form 990: Only PDF file is allowed.'
                );
                $scope.fileError = true;
              } else {
                $scope.fileError = false;
              }
            }
          } else if (
            $scope.applicationStep8.irsForm990Option ===
            IRS_FORM990_OPTION.option2
          ) {
            if (
              !$scope.applicationStep8.supportingFile &&
              !$scope.applicationStep8.fileName
            ) {
              $scope.fieldsMissing.push('Attachment C Form Pro Forma 990');
            } else {
              if (
                $scope.applicationStep8.supportingFile &&
                $scope.applicationStep8.supportingFile.type !==
                  'application/pdf'
              ) {
                $scope.fieldsMissing.push(
                  'Attachment C Form Pro Forma 990: Only PDF file is allowed.'
                );
                $scope.fileError = true;
              } else {
                $scope.fileError = false;
              }
            }
          }
        }
        if (!$scope.displayForPriorYears) {
          if (!$scope.applicationStep8.startDate) {
            $scope.fieldsMissing.push('Document start date is missing.');
          }
          if (!$scope.applicationStep8.endDate) {
            $scope.fieldsMissing.push('Document end date is missing.');
          }
          var dateValidationErrors = ValidationUtil.validateStartEndDates(
            $scope.applicationStep8.startDate,
            $scope.applicationStep8.endDate
          );
          if (dateValidationErrors.length > 0) {
            $scope.fileError = true;
            $scope.fieldsMissing.push(...dateValidationErrors);
          }
          if (
            $scope.applicationStep8.startDate &&
            $scope.applicationStep8.endDate
          ) {
            var documentValidStartDate =
              DATES.FISCAL_START_DATE + ($scope.pledgeYear - 2);
            var documentValidEndDate = DATES.END_DATE + ($scope.pledgeYear - 1);
            if (
              !ValidationUtil.validateDateInRespectedPeriod(
                null,
                $scope.applicationStep8.endDate,
                documentValidStartDate,
                documentValidEndDate
              )
            ) {
              $scope.fieldsMissing.push(
                'The date for the IRS Form 990, or pro forma IRS Form 990, is for an unacceptable period. For the ' +
                  $scope.pledgeYear +
                  ' campaign, tax returns must be for a fiscal period that ended on or after June 30, ' +
                  ($scope.pledgeYear - 2) +
                  '. The fiscal period must be for a 12-month duration.'
              );
            }
          }
          if (
            !$scope.applicationStep8.accountingMethod ||
            $scope.applicationStep8.accountingMethod === '--'
          ) {
            $scope.fieldsMissing.push('Accounting Method not added.');
          }
          if (
            $scope.applicationStep8.accountingMethod === 'Other' &&
            !$scope.applicationStep8.accountingNotes
          ) {
            $scope.fieldsMissing.push('Other Type is not mentioned.');
          }
          if (!$scope.applicationStep8.signed) {
            $scope.fieldsMissing.push('Signature selection is not made.');
          }
          if (
            $scope.applicationStep8.signed === 'No' &&
            !$scope.applicationStep8.certify
          ) {
            $scope.fieldsMissing.push(
              'Signature of Officer is marked as "No" and has not been added electronically'
            );
          }
          if (
            !$scope.isOffYear &&
            $scope.totalRevenue != undefined &&
            $scope.totalRevenue != null &&
            ValidationUtil.isNumeric($scope.totalRevenue) &&
            $scope.totalRevenue >= 100000 &&
            $scope.applicationStep8.accountingMethod != 'Accrual'
          ) {
            $scope.fieldsMissing.push(
              'An organization with a total revenue above $100,000 must use the “Accrual” accounting method (Part XII, Line 1) to prepare its IRS Form 990, or pro forma IRS Form 990.'
            );
          }
        }
        if ($scope.fieldsMissing.length > 0) {
          $scope.applicationStep8.stepStatus =
            APPLICATION_STEP_STATUS.incomplete;
          if (exit && !$scope.fileError) {
            $scope.saveApplicationStep(exit);
          } else {
            $('#missingInfo').modal();
          }
        } else {
          $scope.applicationStep8.stepStatus = APPLICATION_STEP_STATUS.complete;
          if (exit) {
            $scope.saveApplicationStep(exit);
          } else {
            $('#missingInfo').modal();
          }
        }
      };
      $scope.continue = false;
      $scope.saveApplicationStep = function(exit) {
        var currentTime = ApplicationService.toUTCDate(new Date());
        var dateDifferenceInSeconds = 0;
        var userSession = ApplicationService.getUserSession();
        userSession.step8AccessEndDate = currentTime;
        dateDifferenceInSeconds = ApplicationService.getDateDifference(
          currentTime,
          userSession.step8AccessBeginDate
        );
        userSession.step8TimeSpentInSeconds = dateDifferenceInSeconds;
        if (exit) {
          userSession.lastAccessedEndDate = currentTime;
          userSession.totalTimeSpentInSeconds = ApplicationService.getDateDifference(
            currentTime,
            userSession.lastAccessedBeginDate
          );
        }
        if ($scope.applicationStep8.accountingMethod === '--') {
          $scope.applicationStep8.accountingMethod = null;
          $scope.applicationStep8.accountingNotes = null;
        }
        if ($scope.applicationStep8.signed === 'Yes') {
          $scope.applicationStep8.signed = true;
        } else if ($scope.applicationStep8.signed === 'No') {
          $scope.applicationStep8.signed = false;
        }
        if ($scope.applicationStep8.startDate === '') {
          $scope.applicationStep8.startDate = null;
        }
        if ($scope.applicationStep8.endDate === '') {
          $scope.applicationStep8.endDate = null;
        }
        ApplicationService.saveFileStep8($scope.applicationStep8, function(
          response
        ) {
          if (
            response.message &&
            response.message.type === 'ERROR' &&
            !$scope.continue
          ) {
            $('input[type="file"]').val(null);
            $scope.applicationStep8.supportingFile = null;
            $('#modalUpload').modal();
            return;
          }

          if ((!response || response.errorCode) && !$scope.continue) {
            $scope.applicationStep8.supportingFile = null;
            $('#modalUpload').modal();
            return;
          }
          if (response) {
            $scope.applicationStep8.fileName = response.fileName;
          }
          ApplicationService.saveUserSession().then(function(response) {
            var overallStatus = 'Complete';
            ApplicationService.getApplicationStatus(
              ApplicationService.getApplicationId(),
              function(data) {
                for (var i = 0; i < data.length; i++) {
                  if (data[i].stepStatus === 'Incomplete') {
                    overallStatus = 'Incomplete';
                    break;
                  }
                }
                var app = ApplicationService.getApplication();
                app.overallApplicationStatus = overallStatus;
                ApplicationService.saveTable(app, function(response) {
                  if (exit) {
                    $scope.setAppStep(0);
                    $state.go('applicationDashboard', {
                      applicationId: ApplicationService.getApplicationId()
                    });
                  } else {
                    $('.modal-backdrop').remove();
                    $state.go('step9', {
                      applicationId: ApplicationService.getApplicationId(),
                      parentFedAppId: ApplicationService.getParentFederationApplicationId()
                    });
                  }
                });
              }
            );
          });
        });
      };

      $scope.resetData = function() {
        $scope.applicationStep8.startDate = null;
        $scope.applicationStep8.endDate = null;
        $scope.applicationStep8.accountingMethod = '--';
        $scope.applicationStep8.accountingNotes = null;
        $scope.applicationStep8.signed = null;
        $scope.applicationStep8.fullName = null;
        $scope.applicationStep8.initials = null;
        $scope.applicationStep8.title = null;
        $scope.applicationStep8.certify = null;
      };

      $scope.accountingMethodChange = function() {
        $scope.applicationStep8.accountingNotes = null;
      };

      $scope.saveSignature = function() {
        if (!$scope.applicationStep8.certify) {
          $scope.signError = '*You are missing the cetify checkbox.';
        } else {
          $('.modal-backdrop').hide();
          $('#signatureRequired').hide();
        }
      };

      $scope.openModal = function() {
        $('#signatureRequired').show();
      };

      $scope.downloadPDFfromS3 = function(fileName) {
        var url = '/CfcCharity/api/private/s3/pdf/' + fileName;
        DownloadService.getFileDownload(url)
          .then(function(res) {
            //Nothing needed after success
          })
          .catch(function(err) {
            $log.error(err);
          });
      };
      $scope.optionChanged = function() {
        $('input[type="file"]').val(null);
        $scope.applicationStep8.supportingFile = null;
      };
      init();
    }
  ])
  .controller('ApplicationStep9Ctrl', [
    '$state',
    '$scope',
    '$location',
    '$log',
    'CakeSvc',
    'MODULE_NAME',
    'APPLICATION_STEP_STATUS',
    'UserCharityRole',
    'ApplicationService',
    'BrowserEventService',
    'CharityService',
    'APPLICATION_TYPE',
    'tokenData',
    'urlAccess',
    'CubeSvc',
    '$q',
    function(
      $state,
      $scope,
      $location,
      $log,
      CakeSvc,
      MODULE_NAME,
      APPLICATION_STEP_STATUS,
      UserCharityRole,
      ApplicationService,
      BrowserEventService,
      CharityService,
      APPLICATION_TYPE,
      tokenData,
      urlAccess,
      CubeSvc,
      $q
    ) {
      $scope.setAppStep(9);
      $scope.pledgeYear = tokenData.pledgeYear;

      $location.hash('top-container');
      $scope.gotoPageSection = function(divId) {
        $location.hash(divId);
      };

      $scope.currentPledgeYear = sessionStorage.getItem(
        'CREATE_APPLICATION_CURRENT_PLEDGE_YEAR'
      );

      $scope.pledgeYear = tokenData.pledgeYear;

      //check the access to show the breadcrumb links for member applications
      if (tokenData.federationId != null) {
        if ($scope.$parent.$$childHead.breadcrumbs.length > 0) {
          urlAccess
            .getFederationApplicationAccess(
              tokenData.pledgeYear,
              tokenData.federationCfc,
              $scope.$parent.$$childHead.breadcrumbs[0]
            )
            .then(function(res) {
              $scope.$parent.$$childHead.breadcrumbs[0] = res;
            });
          urlAccess
            .getMemberApplicationAccess(
              tokenData.pledgeYear,
              tokenData.federationCfc,
              $scope.$parent.$$childHead.breadcrumbs[1]
            )
            .then(function(res) {
              $scope.$parent.$$childHead.breadcrumbs[1] = res;
            });
        }
      }

      $scope.mode = $state.params.mode
        ? $state.params.mode
        : BrowserEventService.mode;
      BrowserEventService.setMode($scope.mode);
      ApplicationService.getUserSession().step9AccessBeginDate = ApplicationService.toUTCDate(
        new Date()
      );
      if (!$scope.applicationStep9) {
        $scope.applicationStep9 = ApplicationService.getApplicationStep9();
        if (angular.equals($scope.applicationStep9, {})) {
          $scope.applicationStep9.applicationId = ApplicationService.getApplicationId();
          $scope.applicationStep9.cfcCode = ApplicationService.getCfcCode();
          $scope.applicationStep9.$get(
            function(res) {
              var tmp = CakeSvc.transformGenericResponseToFlatResourceResponse(
                res
              );
              if (!angular.equals(tmp, {})) {
                $scope.applicationStep9 = tmp;
              } else {
                $scope.applicationStep9.applicationId = ApplicationService.getApplicationId();
                $scope.applicationStep9.cfcCode = ApplicationService.getCfcCode();
              }
            },
            function(err) {
              $log.error('STEP9 failed to load');
            }
          );
        }
      }

      $scope.applicationStep8 = ApplicationService.getApplicationStep8();
      $q(function(resolve, reject) {
        if (angular.equals($scope.applicationStep8, {})) {
          $scope.applicationStep8.applicationId = ApplicationService.getApplicationId();
          $scope.applicationStep8.cfcCode = ApplicationService.getCfcCode();
          $scope.applicationStep8.$get(
            function(res) {
              $scope.applicationStep8 = CakeSvc.transformGenericResponseToFlatResourceResponse(
                res
              );
              resolve(res);
              $scope.accountingMethod =
                $scope.applicationStep8.accountingMethod;
            },
            function(err) {
              $log.error('STEP4 data in STEP9 failed to load');
              reject(err);
            }
          );
        } else {
          resolve($scope.applicationStep8);
          $scope.accountingMethod = $scope.applicationStep8.accountingMethod;
        }
      });

      $scope.applicationStep7 = ApplicationService.getApplicationStep7();
      $q(function(resolve, reject) {
        if (angular.equals($scope.applicationStep7, {})) {
          $scope.applicationStep7.applicationId = ApplicationService.getApplicationId();
          $scope.applicationStep7.cfcCode = ApplicationService.getCfcCode();
          $scope.applicationStep7.$get(
            function(res) {
              $scope.applicationStep7 = CakeSvc.transformGenericResponseToFlatResourceResponse(
                res
              );
              resolve(res);
            },
            function(err) {
              $log.error('STEP7 data in STEP9 failed to load');
              reject(err);
            }
          );
        } else {
          resolve($scope.applicationStep7);
        }
      });

      $scope.validateApplicationStep = function(exit) {
        $scope.fieldsMissing = [];
        $scope.fullYearOver100kPopup = false;
        $scope.step7Mismatch = false;
        $scope.step8Mismatch = false;
        if (!isNumeric($scope.applicationStep9.managementExpenses)) {
          $scope.fieldsMissing.push('Management & General Expenses');
        }
        if (!isNumeric($scope.applicationStep9.fundraisingExpense)) {
          $scope.fieldsMissing.push('Fundraising Expenses');
        }
        if (!isNumeric($scope.applicationStep9.fundraisingRate)) {
          $scope.fieldsMissing.push('Fundraising Rate');
        }
        if (!isNumeric($scope.applicationStep9.totalRevenue)) {
          $scope.fieldsMissing.push('Total Revenue');
        }
        if (!$scope.applicationStep9.certifyFundraisingRate) {
          $scope.fieldsMissing.push(
            'You have not selected the checkbox to confirm your fundraising rate.'
          );
        }
        if (
          isNumeric($scope.applicationStep9.totalRevenue) &&
          !$scope.isOffYear &&
          $scope.applicationStep9.totalRevenue >= 100000
        ) {
          if ($scope.applicationStep9.totalRevenue > 100000) {
            $scope.fullYearOver100kPopup = true;
          }
          if (
            $scope.applicationStep9.totalRevenue >= 100000 &&
            $scope.accountingMethod != 'Accrual'
          ) {
            $scope.step8Mismatch = true;
            $scope.fieldsMissing.push(
              'An organization with a total revenue above $100,000 must use the “Accrual” accounting method (Part XII, Line 1) to prepare its IRS Form 990, or pro forma IRS Form 990.'
            );
          }
        }
        if (!$scope.applicationStep7) {
          var msg =
            'The organization’s total revenue must be entered based on what was reported in the IRS Form 990, or pro forma IRS Form 990, on Part VIII, Line 12. The total revenue entered must rounded to the nearest dollar. The system does not accept decimals. Based on your organization’s total revenue, verify that the correct statement was certified to on Step 7. An organization that is required to submit a full application and with a total revenue above $100,000 must submit either reviewed or audited financial statements, and those above $250,000 must report audited financial statements. The financial statements must be prepared by an independent, certified public accountant and must be for a period that ended on or after June 30, ' +
            ($scope.pledgeYear - 2) +
            '. Compiled financial statements will not be accepted.';
          $scope.fieldsMissing.push(msg);
          $scope.step7Mismatch = true;
        }
        if ($scope.applicationStep7) {
          var msg = null;
          var msg1 =
            'The organization’s total revenue must be entered based on what was reported in the IRS Form 990, or pro forma IRS Form 990, on Part VIII, Line 12. The total revenue entered must rounded to the nearest dollar. The system does not accept decimals. Based on your organization’s total revenue, verify that the correct statement was certified to on Step 7. An organization that is required to submit a full application and with a total revenue above $100,000 must submit either reviewed or audited financial statements, and those above $250,000 must report audited financial statements. The financial statements must be prepared by an independent, certified public accountant and must be for a period that ended on or after June 30, ' +
            ($scope.pledgeYear - 2) +
            '. Compiled financial statements will not be accepted.';
          if (
            $scope.applicationStep7.revenueOption != null &&
            $scope.applicationStep7.revenueOption === 'OPTION_1'
          ) {
            if (
              !(
                $scope.applicationStep9.totalRevenue &&
                $scope.applicationStep9.totalRevenue >= 250000
              )
            ) {
              msg = msg1;
              $scope.step7Mismatch = true;
            }
          } else if (
            $scope.applicationStep7.revenueOption != null &&
            $scope.applicationStep7.revenueOption === 'OPTION_2'
          ) {
            if (
              !(
                $scope.applicationStep9.totalRevenue &&
                $scope.applicationStep9.totalRevenue < 250000 &&
                $scope.applicationStep9.totalRevenue >= 100000
              )
            ) {
              msg = msg1;
              $scope.step7Mismatch = true;
            }
          } else if (
            $scope.applicationStep7.revenueOption != null &&
            $scope.applicationStep7.revenueOption === 'OPTION_3'
          ) {
            if (
              !(
                $scope.applicationStep9.totalRevenue &&
                $scope.applicationStep9.totalRevenue < 100000
              )
            ) {
              msg = msg1;
              $scope.step7Mismatch = true;
            }
          } else if (
            !$scope.applicationStep7.revenueOption ||
            !$scope.applicationStep9.totalRevenue
          ) {
            msg = msg1;
            $scope.step7Mismatch = true;
          }
          if (msg != null) {
            $scope.fieldsMissing.push(msg);
          }
        }

        if ($scope.fieldsMissing.length > 0 || $scope.fullYearOver100kPopup) {
          if (
            $scope.fieldsMissing.length === 0 &&
            $scope.fullYearOver100kPopup
          ) {
            $scope.applicationStep9.stepStatus =
              APPLICATION_STEP_STATUS.complete;
          } else if (
            $scope.fieldsMissing.length === 1 &&
            $scope.step7Mismatch
          ) {
            $scope.applicationStep9.stepStatus =
              APPLICATION_STEP_STATUS.complete;
          } else if (
            $scope.fieldsMissing.length === 1 &&
            $scope.step8Mismatch
          ) {
            $scope.applicationStep9.stepStatus =
              APPLICATION_STEP_STATUS.complete;
          } else if (
            $scope.fieldsMissing.length === 2 &&
            $scope.step7Mismatch &&
            $scope.step8Mismatch
          ) {
            $scope.applicationStep9.stepStatus =
              APPLICATION_STEP_STATUS.complete;
          } else {
            $scope.applicationStep9.stepStatus =
              APPLICATION_STEP_STATUS.incomplete;
          }
          if (exit) {
            $scope.saveApplicationStep(exit);
          } else {
            $('#missingInfo').modal();
          }
        } else {
          $scope.applicationStep9.stepStatus = APPLICATION_STEP_STATUS.complete;
          if (exit) {
            $scope.saveApplicationStep(exit);
          } else {
            $scope.saveApplicationStep();
          }
        }
      };

      $scope.onChangeStep9NumericValues = function() {
        var mgmtExpense = $scope.applicationStep9.managementExpenses;
        var fundraisingExpense = $scope.applicationStep9.fundraisingExpense;
        var revenue = $scope.applicationStep9.totalRevenue;

        if (
          !isNumeric(mgmtExpense) ||
          !isNumeric(fundraisingExpense) ||
          !isNumeric(revenue)
        ) {
          $scope.applicationStep9.fundraisingRate = null;
          return;
        }

        ApplicationService.calculateAfr(
          mgmtExpense,
          fundraisingExpense,
          revenue
        ).then(function(res) {
          $scope.applicationStep9.fundraisingRate = res.data.value;
        });

        $scope.applicationStep9.applicationId = ApplicationService.getApplicationId();
        $scope.applicationStep9.cfcCode = ApplicationService.getCfcCode();
      };

      $scope.saveApplicationStep = function(exit) {
        var currentTime = ApplicationService.toUTCDate(new Date());
        var dateDifferenceInSeconds = 0;
        var userSession = ApplicationService.getUserSession();
        userSession.step9AccessEndDate = currentTime;
        dateDifferenceInSeconds = ApplicationService.getDateDifference(
          currentTime,
          userSession.step9AccessBeginDate
        );
        userSession.step9TimeSpentInSeconds = dateDifferenceInSeconds;
        if (exit) {
          userSession.lastAccessedEndDate = currentTime;
          userSession.totalTimeSpentInSeconds = ApplicationService.getDateDifference(
            currentTime,
            userSession.lastAccessedBeginDate
          );
        }
        ApplicationService.saveStep($scope.applicationStep9, function(
          response
        ) {
          ApplicationService.saveUserSession().then(function(response) {
            var overallStatus = 'Complete';
            ApplicationService.getApplicationStatus(
              ApplicationService.getApplicationId(),
              function(data) {
                for (var i = 0; i < data.length; i++) {
                  if (data[i].stepStatus === 'Incomplete') {
                    overallStatus = 'Incomplete';
                    break;
                  }
                }
                var app = ApplicationService.getApplication();
                app.overallApplicationStatus = overallStatus;
                ApplicationService.saveTable(app, function(response) {
                  if (exit) {
                    $scope.setAppStep(0);
                    $state.go('applicationDashboard', {
                      applicationId: ApplicationService.getApplicationId()
                    });
                  } else {
                    $('.modal-backdrop').remove();
                    $state.go('step10', {
                      applicationId: ApplicationService.getApplicationId(),
                      parentFedAppId: ApplicationService.getParentFederationApplicationId()
                    });
                  }
                });
              }
            );
          });
        });
      };

      $scope.$watch(
        'applicationStep9.managementExpenses',
        function(newValue, oldValue) {
          if (newValue === undefined || newValue == null) {
            return;
          }

          $scope.applicationStep9.managementExpenses = customNumberFormatter(
            newValue
          );
        },
        true
      );

      $scope.$watch(
        'applicationStep9.fundraisingExpense',
        function(newValue, oldValue) {
          if (newValue === undefined || newValue == null) {
            return;
          }
          $scope.applicationStep9.fundraisingExpense = customNumberFormatter(
            newValue
          );
        },
        true
      );

      $scope.$watch(
        'applicationStep9.totalRevenue',
        function(newValue, oldValue) {
          if (newValue === undefined || newValue == null) {
            return;
          }
          $scope.applicationStep9.totalRevenue = customNumberFormatter(
            newValue
          );
        },
        true
      );

      function customNumberFormatter(n) {
        // allow only digits and minus sign
        var n = n.toString().replace(/[^\d|\-+]/g, '');
        // only allow minus sign at index 0
        if (n.length === 1 && n.substr(0) === '-') {
          return '-';
        }
        // don't allow multiple minus signs
        if (n.length > 1 && n.substr(n.length - 1) === '-') {
          n = n.slice(0, -1);
        }
        if (isNumeric(n)) return parseInt(n, 10);
      }

      function isNumeric(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
      }
      function init() {
        $scope.appLabel = ApplicationService.getAppLabel();
        var userOb = JSON.parse(sessionStorage.getItem('USER_OBJ'));
        $scope.canViewMsg =
          userOb.secondaryRoleList.indexOf('OPM_ADMIN') >= 0 ||
          userOb.secondaryRoleList.indexOf('OPM_STAFF') >= 0 ||
          userOb.secondaryRoleList.indexOf('ZONE_MANAGER') >= 0 ||
          userOb.secondaryRoleList.indexOf('REVIEWER') >= 0 ||
          userOb.secondaryRoleList.indexOf('OPM_AUDITOR') >= 0;

        //revenue mismatch check
        CubeSvc.get(
          '/CfcCharity/api/private/application/revenuedetails/' +
            ApplicationService.getApplicationId(),
          null,
          false
        ).then(function(res) {
          $scope.validRevenue = res.data.value;
        });

        //full application and accounting method check
        $scope.isOffYear = ApplicationService.getPreApplicationDetails().isOffYear;
      }
      init();
    }
  ])
  .controller('ApplicationStep10Ctrl', [
    '$state',
    '$scope',
    '$location',
    '$log',
    '$q',
    'CakeSvc',
    'MODULE_NAME',
    'APPLICATION_STEP_STATUS',
    'UserCharityRole',
    'ApplicationService',
    'BrowserEventService',
    'CharityService',
    'APPLICATION_TYPE',
    'CharityDashboardService',
    'ZoneCenterService',
    'tokenData',
    'urlAccess',
    'CubeSvc',
    function(
      $state,
      $scope,
      $location,
      $log,
      $q,
      CakeSvc,
      MODULE_NAME,
      APPLICATION_STEP_STATUS,
      UserCharityRole,
      ApplicationService,
      BrowserEventService,
      CharityService,
      APPLICATION_TYPE,
      CharityDashboardService,
      ZoneCenterService,
      tokenData,
      urlAccess,
      CubeSvc
    ) {
      $scope.setAppStep(10);

      $location.hash('top-container');
      $scope.gotoPageSection = function(divId) {
        $location.hash(divId);
      };

      $scope.currentPledgeYear = sessionStorage.getItem(
        'CREATE_APPLICATION_CURRENT_PLEDGE_YEAR'
      );
      $scope.cfcCode = tokenData.cfcCode;
      $scope.pledgeYear = tokenData.pledgeYear;
      //check the access to show the breadcrumb links for member applications
      if (tokenData.federationId != null) {
        if ($scope.$parent.$$childHead.breadcrumbs.length > 0) {
          urlAccess
            .getFederationApplicationAccess(
              tokenData.pledgeYear,
              tokenData.federationCfc,
              $scope.$parent.$$childHead.breadcrumbs[0]
            )
            .then(function(res) {
              $scope.$parent.$$childHead.breadcrumbs[0] = res;
            });
          urlAccess
            .getMemberApplicationAccess(
              tokenData.pledgeYear,
              tokenData.federationCfc,
              $scope.$parent.$$childHead.breadcrumbs[1]
            )
            .then(function(res) {
              $scope.$parent.$$childHead.breadcrumbs[1] = res;
            });
        }
      }

      $scope.mode = $state.params.mode
        ? $state.params.mode
        : BrowserEventService.mode;
      BrowserEventService.setMode($scope.mode);
      ApplicationService.getUserSession().step10AccessBeginDate = ApplicationService.toUTCDate(
        new Date()
      );
      if (!$scope.applicationStep10) {
        $scope.applicationStep10 = ApplicationService.getApplicationStep10();
        if (angular.equals($scope.applicationStep10, {})) {
          $scope.applicationStep10.applicationId = ApplicationService.getApplicationId();
          $scope.applicationStep10.cfcCode = ApplicationService.getCfcCode();
          $scope.applicationStep10.pledgeYear = ApplicationService.getPledgeYear();
          $q(function(resolve, reject) {
            $scope.applicationStep10.$get(
              function(res) {
                var tmp = CakeSvc.transformGenericResponseToFlatResourceResponse(
                  res
                );
                if (!angular.equals(tmp, {})) {
                  $scope.applicationStep10 = tmp;
                } else {
                  $scope.applicationStep10.applicationId = ApplicationService.getApplicationId();
                  $scope.applicationStep10.cfcCode = ApplicationService.getCfcCode();
                  $scope.applicationStep10.pledgeYear = ApplicationService.getPledgeYear();
                }
                resolve($scope.applicationStep10);
              },
              function(err) {
                $log.error('STEP10 failed to load');
                reject(err);
              }
            );
          }).then(function() {
            var userOb = JSON.parse(sessionStorage.getItem('USER_OBJ'));
            $scope.isOpmAdmin =
              userOb.secondaryRoleList.indexOf('OPM_ADMIN') >= 0 || false;
            $scope.isZoneManager =
              userOb.secondaryRoleList.indexOf('ZONE_MANAGER') >= 0 || false;
            $scope.isOpmStaff =
              userOb.secondaryRoleList.indexOf('OPM_STAFF') >= 0 || false;
            $scope.isZoneReviewer =
              userOb.secondaryRoleList.indexOf('ZONE_REVIEWER') >= 0 || false;
            $scope.isOpmAuditor =
              userOb.secondaryRoleList.indexOf('OPM_AUDITOR') >= 0 || false;

            CharityDashboardService.getCharityDashboardData(
              $scope.applicationStep10.cfcCode
            ).then(function(resp) {
              var result = resp.data.value;
              if (result !== null && result !== '') {
                $scope.applicationStep10.charityEin = result.einNumber;
              }
            });

            if (
              $scope.applicationStep10.pledgeYear &&
              $scope.applicationStep10.cfcCode
            ) {
              if (
                userOb.secondaryRoleList.indexOf('ZONE_MANAGER') < 0 &&
                userOb.secondaryRoleList.indexOf('REVIEWER') < 0
              ) {
                CharityDashboardService.getCharityListingObject(
                  $scope.applicationStep10.pledgeYear,
                  $scope.applicationStep10.cfcCode
                ).then(function(resp) {
                  var result = resp.data.value;
                  if (result !== null && result !== '') {
                    $scope.charityListingObject = result;

                    $scope.applicationStep10.charityListingViewObject = angular.copy(
                      $scope.charityListingObject
                    );
                  }
                });
              }
            }

            /* populate listing information */
            CharityDashboardService.findCharityWithCFC(
              $scope.applicationStep10.cfcCode
            ).then(function(res) {
              if (res.data !== null && res.data !== '') {
                $scope.charityInformation = res.data;
              }
            });
          });
        }
      }
      $scope.validateApplicationStep = function(exit) {
        $scope.fieldsMissing = [];
        $scope.showPopupMsg = false;
        if (!$scope.applicationStep10.numberTrustees) {
          if (
            typeof $scope.applicationStep10.numberTrustees === 'number' &&
            $scope.applicationStep10.numberTrustees >= 0
          ) {
            // $log.debug('Number of trustees: ' + $scope.applicationStep10.numberTrustees);
            Function.prototype;
          } else {
            $scope.fieldsMissing.push(
              'Number of Trustees / Institutional Trustees'
            );
          }
        }
        if (!$scope.applicationStep10.numberVotingMembers) {
          if (
            typeof $scope.applicationStep10.numberVotingMembers === 'number' &&
            $scope.applicationStep10.numberVotingMembers >= 0
          ) {
            // $log.debug('Number of voting members: ' + $scope.applicationStep10.numberVotingMembers);
            Function.prototype;
          } else {
            $scope.fieldsMissing.push('Number of Voting Members of the Board');
          }
        }
        if (!$scope.applicationStep10.certifyGoverningBody) {
          $scope.fieldsMissing.push(
            'You have not selected the checkbox to certify governing body.'
          );
        }
        if (
          $scope.applicationStep10.numberVotingMembers &&
          $scope.applicationStep10.numberTrustees &&
          $scope.applicationStep10.numberVotingMembers >
            $scope.applicationStep10.numberTrustees
        ) {
          if (!$scope.applicationStep10.explanationDiscrepancy) {
            $scope.fieldsMissing.push(
              'You must provide an explanation acceptable to the Eligibility Committee to justify the discrepancy'
            );
          }
        }
        if ($scope.canSeeMsg) {
          $scope.showPopupMsg = true;
        }
        if ($scope.fieldsMissing.length > 0 || $scope.showPopupMsg) {
          if ($scope.fieldsMissing.length > 0) {
            $scope.applicationStep10.stepStatus =
              APPLICATION_STEP_STATUS.incomplete;
          } else {
            $scope.applicationStep10.stepStatus =
              APPLICATION_STEP_STATUS.complete;
          }
          if (exit) {
            $scope.saveApplicationStep(exit);
          } else {
            $('#missingInfo').modal();
          }
        } else {
          $scope.applicationStep10.stepStatus =
            APPLICATION_STEP_STATUS.complete;
          if (exit) {
            $scope.saveApplicationStep(exit);
          } else {
            $scope.saveApplicationStep();
          }
        }
      };
      $scope.saveApplicationStep = function(exit) {
        var currentTime = ApplicationService.toUTCDate(new Date());
        var dateDifferenceInSeconds = 0;
        var userSession = ApplicationService.getUserSession();
        userSession.step10AccessEndDate = currentTime;
        dateDifferenceInSeconds = ApplicationService.getDateDifference(
          currentTime,
          userSession.step10AccessBeginDate
        );
        userSession.step10TimeSpentInSeconds = dateDifferenceInSeconds;
        if (exit) {
          userSession.lastAccessedEndDate = currentTime;
          userSession.totalTimeSpentInSeconds = ApplicationService.getDateDifference(
            currentTime,
            userSession.lastAccessedBeginDate
          );
        }
        ApplicationService.saveStep($scope.applicationStep10, function(
          response
        ) {
          ApplicationService.saveUserSession().then(function(response) {
            var overallStatus = 'Complete';
            ApplicationService.getApplicationStatus(
              ApplicationService.getApplicationId(),
              function(data) {
                for (var i = 0; i < data.length; i++) {
                  if (data[i].stepStatus === 'Incomplete') {
                    overallStatus = 'Incomplete';
                    break;
                  }
                }
                var app = ApplicationService.getApplication();
                app.overallApplicationStatus = overallStatus;
                ApplicationService.saveTable(app, function(response) {
                  if (exit) {
                    $scope.setAppStep(0);
                    $state.go('applicationDashboard', {
                      applicationId: ApplicationService.getApplicationId()
                    });
                  } else {
                    $('.modal-backdrop').remove();
                    $state.go('step11', {
                      applicationId: ApplicationService.getApplicationId(),
                      parentFedAppId: ApplicationService.getParentFederationApplicationId()
                    });
                  }
                });
              }
            );
          });
        });
      };
      function init() {
        $scope.appLabel = ApplicationService.getAppLabel();
        CharityService.getCharityUserRole($scope.cfcCode).then(function(resp) {
          var result = resp.data;
          if (result !== null && result !== '') {
            if (
              result.userRole === 'CHARITY_OWNER' ||
              result.userRole === 'CHARITY_EDITOR' ||
              result.userRole === 'FEDERATION_OWNER' ||
              result.userRole === 'FEDERATION_EDITOR'
            ) {
              $scope.canSeeMsg = true;
            }
          }
          if (result === '') {
            CubeSvc.get(
              '/CfcCharity/api/private/users/charitydashboard/memberauthbyfeduser/' +
                $scope.pledgeYear +
                '/' +
                $scope.cfcCode,
              null,
              false
            ).then(function(res) {
              var isMemberFederationOwner = res.data;
              if (isMemberFederationOwner) {
                $scope.canSeeMsg = true;
              }
            });
          }
        });
      }
      init();
    }
  ])
  .controller('ApplicationStep11Ctrl', [
    '$state',
    '$scope',
    '$location',
    '$log',
    'CakeSvc',
    'MODULE_NAME',
    'APPLICATION_STEP_STATUS',
    'UserCharityRole',
    'ApplicationService',
    'BrowserEventService',
    'CharityService',
    'APPLICATION_TYPE',
    'tokenData',
    'urlAccess',
    function(
      $state,
      $scope,
      $location,
      $log,
      CakeSvc,
      MODULE_NAME,
      APPLICATION_STEP_STATUS,
      UserCharityRole,
      ApplicationService,
      BrowserEventService,
      CharityService,
      APPLICATION_TYPE,
      tokenData,
      urlAccess
    ) {
      $scope.setAppStep(11);
      $scope.mode = $state.params.mode
        ? $state.params.mode
        : BrowserEventService.mode;
      BrowserEventService.setMode($scope.mode);

      $location.hash('top-container');
      $scope.gotoPageSection = function(divId) {
        $location.hash(divId);
      };
      $scope.currentPledgeYear = sessionStorage.getItem(
        'CREATE_APPLICATION_CURRENT_PLEDGE_YEAR'
      );
      //check the access to show the breadcrumb links for member applications
      if (tokenData.federationId != null) {
        if ($scope.$parent.$$childHead.breadcrumbs.length > 0) {
          urlAccess
            .getFederationApplicationAccess(
              tokenData.pledgeYear,
              tokenData.federationCfc,
              $scope.$parent.$$childHead.breadcrumbs[0]
            )
            .then(function(res) {
              $scope.$parent.$$childHead.breadcrumbs[0] = res;
            });
          urlAccess
            .getMemberApplicationAccess(
              tokenData.pledgeYear,
              tokenData.federationCfc,
              $scope.$parent.$$childHead.breadcrumbs[1]
            )
            .then(function(res) {
              $scope.$parent.$$childHead.breadcrumbs[1] = res;
            });
        }
      }

      ApplicationService.getUserSession().step11AccessBeginDate = ApplicationService.toUTCDate(
        new Date()
      );

      if (!$scope.applicationStep11) {
        $scope.applicationStep11 = ApplicationService.getApplicationStep11();
        if (angular.equals($scope.applicationStep11, {})) {
          $scope.applicationStep11.applicationId = ApplicationService.getApplicationId();
          $scope.applicationStep11.cfcCode = ApplicationService.getCfcCode();
          $scope.applicationStep11.$get(
            function(res) {
              var tmp = CakeSvc.transformGenericResponseToFlatResourceResponse(
                res
              );
              if (!angular.equals(tmp, {})) {
                $scope.applicationStep11 = tmp;
              } else {
                $scope.applicationStep11.applicationId = ApplicationService.getApplicationId();
                $scope.applicationStep11.cfcCode = ApplicationService.getCfcCode();
              }
            },
            function(err) {
              $log.error('STEP11 failed to load');
            }
          );
        }
      }

      $scope.validateApplicationStep = function(exit) {
        $scope.fieldsMissing = [];
        if (
          !$scope.applicationStep11.certifyStatement1 ||
          !$scope.applicationStep11.certifyStatement2 ||
          !$scope.applicationStep11.certifyStatement3 ||
          !$scope.applicationStep11.certifyStatement4
        ) {
          $scope.fieldsMissing.push(
            'Not all verifying statements have been confirmed.'
          );
        }

        if ($scope.fieldsMissing.length > 0) {
          $scope.applicationStep11.stepStatus =
            APPLICATION_STEP_STATUS.incomplete;
          if (exit) {
            $scope.saveApplicationStep(exit);
          } else {
            $('#missingInfo').modal();
          }
        } else {
          $scope.applicationStep11.stepStatus =
            APPLICATION_STEP_STATUS.complete;
          if (exit) {
            $scope.saveApplicationStep(exit);
          } else {
            $scope.saveApplicationStep();
          }
        }
      };
      $scope.saveApplicationStep = function(exit) {
        var currentTime = ApplicationService.toUTCDate(new Date());
        var dateDifferenceInSeconds = 0;
        var userSession = ApplicationService.getUserSession();
        userSession.step11AccessEndDate = currentTime;
        dateDifferenceInSeconds = ApplicationService.getDateDifference(
          currentTime,
          userSession.step11AccessBeginDate
        );
        userSession.step11TimeSpentInSeconds = dateDifferenceInSeconds;
        if (exit) {
          userSession.lastAccessedEndDate = currentTime;
          userSession.totalTimeSpentInSeconds = ApplicationService.getDateDifference(
            currentTime,
            userSession.lastAccessedBeginDate
          );
        }
        ApplicationService.saveStep($scope.applicationStep11, function(
          response
        ) {
          ApplicationService.saveUserSession().then(function(response) {
            var overallStatus = 'Complete';
            ApplicationService.getApplicationStatus(
              ApplicationService.getApplicationId(),
              function(data) {
                for (var i = 0; i < data.length; i++) {
                  if (data[i].stepStatus === 'Incomplete') {
                    overallStatus = 'Incomplete';
                    break;
                  }
                }
                var app = ApplicationService.getApplication();
                app.overallApplicationStatus = overallStatus;
                ApplicationService.saveTable(app, function(response) {
                  if (exit) {
                    $scope.setAppStep(0);
                    $state.go('applicationDashboard', {
                      applicationId: ApplicationService.getApplicationId()
                    });
                  } else {
                    $('.modal-backdrop').remove();
                    $state.go('applicationDashboard', {
                      applicationId: ApplicationService.getApplicationId()
                    });
                  }
                });
              }
            );
          });
        });
      };
      function init() {
        $scope.appLabel = ApplicationService.getAppLabel();
      }
      init();
    }
  ])
  .controller('ApplicationAllStepsCtrl', [
    '$state',
    '$scope',
    '$location',
    '$log',
    'CakeSvc',
    'ApplicationService',
    'CharityService',
    'tokenData',
    '$q',
    'CharityDashboardService',
    'Step4Details',
    'PLEDGE_YEARS',
    '$filter',
    'CubeSvc',
    'DownloadService',
    function(
      $state,
      $scope,
      $location,
      $log,
      CakeSvc,
      ApplicationService,
      CharityService,
      tokenData,
      $q,
      CharityDashboardService,
      Step4Details,
      PLEDGE_YEARS,
      $filter,
      CubeSvc,
      DownloadService
    ) {
      $location.hash('top-container');
      $scope.mode = 'VIEW';
      CubeSvc.performManualCacheRelease();
      $scope.pledgeYear = tokenData.pledgeYear;
      $scope.cfcCode = tokenData.cfcCode;
      $scope.displayForPriorYears = false;
      $scope.isDOD = false;

      $scope.applicationStep1 = ApplicationService.getApplicationStep1();
      $scope.applicationStep2 = ApplicationService.getApplicationStep2();
      $scope.applicationStep2A = ApplicationService.getApplicationStep2A();
      $scope.applicationStep3 = ApplicationService.getApplicationStep3();
      $scope.applicationStep4 = ApplicationService.getApplicationStep4();
      $scope.applicationStep5 = ApplicationService.getApplicationStep5();
      $scope.applicationStep6 = ApplicationService.getApplicationStep6();
      $scope.applicationStep7 = ApplicationService.getApplicationStep7();
      $scope.applicationStep8 = ApplicationService.getApplicationStep8();
      $scope.applicationStep9 = ApplicationService.getApplicationStep9();
      $scope.applicationStep10 = ApplicationService.getApplicationStep10();
      $scope.applicationStep11 = ApplicationService.getApplicationStep11();

      if (
        $scope.pledgeYear &&
        $scope.pledgeYear < PLEDGE_YEARS.PLEDGE_YEAR_2022
      ) {
        $scope.displayForPriorYears = true;
      }

      var userOb = JSON.parse(sessionStorage.getItem('USER_OBJ'));
      $scope.isOpmAdmin =
        userOb.secondaryRoleList.indexOf('OPM_ADMIN') >= 0 || false;
      $scope.isZoneManager =
        userOb.secondaryRoleList.indexOf('ZONE_MANAGER') >= 0 || false;
      $scope.isOpmStaff =
        userOb.secondaryRoleList.indexOf('OPM_STAFF') >= 0 || false;
      $scope.isZoneReviewer =
        userOb.secondaryRoleList.indexOf('ZONE_REVIEWER') >= 0 || false;
      $scope.isOpmAuditor =
        userOb.secondaryRoleList.indexOf('OPM_AUDITOR') >= 0 || false;

      //step1 data
      $scope.applicationStep1.applicationId = ApplicationService.getApplicationId();
      $scope.applicationStep1.cfcCode = tokenData.cfcCode;
      $scope.applicationStep1.$get(
        function(res) {
          var tmp = CakeSvc.transformGenericResponseToFlatResourceResponse(res);
          if (!angular.equals(tmp, {})) {
            $scope.applicationStep1 = tmp;
          } else {
            $scope.applicationStep1.applicationId = ApplicationService.getApplicationId();
            $scope.applicationStep1.cfcCode = ApplicationService.getCfcCode();
          }
        },
        function(err) {
          $log.error('STEP1 failed to load');
        }
      );

      //step2 data
      $scope.applicationStep2.applicationId = ApplicationService.getApplicationId();
      $scope.applicationStep2.cfcCode = tokenData.cfcCode;
      $scope.applicationStep2.$get(
        function(res) {
          var tmp = CakeSvc.transformGenericResponseToFlatResourceResponse(res);
          if (!angular.equals(tmp, {})) {
            $scope.applicationStep2 = tmp;
            $scope.charity_type = $scope.applicationStep2.charityType;
          } else {
            $scope.applicationStep2.applicationId = ApplicationService.getApplicationId();
            $scope.applicationStep2.cfcCode = ApplicationService.getCfcCode();
          }
          var federationId =
            $scope.applicationStep2.federationId ||
            $scope.applicationStep2.federationNumber;
          if ($scope.applicationStep2.fysaFyspWfr) {
            if (!$scope.applicationStep2.militaryBaseLocation) {
              $scope.applicationStep2.militaryBaseLocation = ApplicationService.getPreApplicationDetails().militaryBaseLocation;
            }
          }
          $scope.previousFysaFyspWfr = $scope.applicationStep2.fysaFyspWfr;
          $scope.isDOD = $scope.applicationStep2.fysaFyspWfr
            ? $scope.applicationStep2.fysaFyspWfr
            : false;
        },
        function(err) {
          $log.error('STEP2 failed to load');
        }
      );

      //step 2a
      $scope.applicationStep2A.applicationId = tokenData.applicationId;
      $scope.applicationStep2A.cfcCode = tokenData.cfcCode;
      $scope.applicationStep2A.$get(
        function(res) {
          var tmp = CakeSvc.transformGenericResponseToFlatResourceResponse(res);
          if (!angular.equals(tmp, {})) {
            $scope.applicationStep2A = tmp;
          } else {
            $scope.applicationStep2A.applicationId = ApplicationService.getApplicationId();
            $scope.applicationStep2A.cfcCode = ApplicationService.getCfcCode();
          }
        },
        function(err) {
          $log.error('STEP2A failed to load');
        }
      );

      //step3 data
      $scope.applicationStep3.applicationId = ApplicationService.getApplicationId();
      $scope.applicationStep3.cfcCode = tokenData.cfcCode;
      $scope.applicationStep3.$get(
        function(res) {
          var tmp = CakeSvc.transformGenericResponseToFlatResourceResponse(res);
          // only if record exists, apply its existing values
          if (!angular.equals(tmp, {})) {
            $scope.applicationStep3 = tmp;
          } else {
            $scope.applicationStep3.applicationId = ApplicationService.getApplicationId();
            $scope.applicationStep3.cfcCode = ApplicationService.getCfcCode();
          }
        },
        function(err) {
          $log.error('STEP3 failed to load');
        }
      );

      //step4 data
      $scope.applicationStep4.applicationId = ApplicationService.getApplicationId();
      $scope.applicationStep4.cfcCode = tokenData.cfcCode;
      $scope.applicationStep4.$get(
        function(res) {
          var tmp = CakeSvc.transformGenericResponseToFlatResourceResponse(res);
          if (!angular.equals(tmp, {})) {
            $scope.applicationStep4 = tmp;
          } else {
            $scope.applicationStep4.applicationId = ApplicationService.getApplicationId();
            $scope.applicationStep4.cfcCode = ApplicationService.getCfcCode();
          }
          if (
            $scope.applicationStep4.webBasedService === null ||
            $scope.applicationStep4.webBasedService === undefined ||
            $scope.applicationStep4.webBasedService === false
          ) {
            $scope.applicationStep4.webBasedService = 'No';
          } else {
            $scope.applicationStep4.webBasedService = 'Yes';
          }
          if (
            $scope.applicationStep4.changedFileName != null ||
            $scope.applicationStep4.changedFileName != undefined
          ) {
            var us = $scope.applicationStep4.changedFileName.lastIndexOf('_');
            $scope.currentFileVersion = parseInt(
              $scope.applicationStep4.changedFileName
                .substring(us, $scope.applicationStep4.changedFileName.length)
                .replace('_ver', '')
                .replace(/\.[^\.]+$/, '')
            );
          }
          function formatServiceDescriptionDates(date) {
            return $filter('date')(new Date(date), 'MM/dd/yyyy');
          }
          Step4Details.queryStep4Details(
            ApplicationService.getApplicationId()
          ).then(function(res) {
            $scope.services = res.data.value;
            angular.forEach($scope.services, function(service) {
              if (service.serviceDescWhenStartDate) {
                service.serviceDescWhenStartDate = formatServiceDescriptionDates(
                  service.serviceDescWhenStartDate
                );
              }
              if (service.serviceDescWhenEndDate) {
                service.serviceDescWhenEndDate = formatServiceDescriptionDates(
                  service.serviceDescWhenEndDate
                );
              }
              if (service.serviceDescPrevYearWhenStartDate) {
                service.serviceDescPrevYearWhenStartDate = formatServiceDescriptionDates(
                  service.serviceDescPrevYearWhenStartDate
                );
              }
              if (service.serviceDescPrevYearWhenEndDate) {
                service.serviceDescPrevYearWhenEndDate = formatServiceDescriptionDates(
                  service.serviceDescPrevYearWhenEndDate
                );
              }
              if (service.serviceDescPrev2YearWhenStartDate) {
                service.serviceDescPrev2YearWhenStartDate = formatServiceDescriptionDates(
                  service.serviceDescPrev2YearWhenStartDate
                );
              }
              if (service.serviceDescPrev2YearWhenEndDate) {
                service.serviceDescPrev2YearWhenEndDate = formatServiceDescriptionDates(
                  service.serviceDescPrev2YearWhenEndDate
                );
              }
            });
          });
        },
        function(err) {
          $log.error('STEP4 failed to load');
        }
      );

      //step5 data
      $q(function(resolve, reject) {
        $scope.applicationStep5.applicationId = ApplicationService.getApplicationId();
        $scope.applicationStep5.cfcCode = tokenData.cfcCode;
        $scope.applicationStep5.$get(
          function(res) {
            var tmp = CakeSvc.transformGenericResponseToFlatResourceResponse(
              res
            );
            if (!angular.equals(tmp, {})) {
              $scope.applicationStep5 = tmp;
            } else {
              $scope.applicationStep5.applicationId = tokenData.applicationId;
              $scope.applicationStep5.cfcCode = tokenData.cfcCode;
              $scope.applicationStep5.isOffYear = $scope.isOffYear;
            }
            resolve($scope.applicationStep5);
          },
          function(err) {
            $log.error('STEP5 failed to load');
            reject(err);
          },
          function(err) {
            $log.error('STEP5 failed to load');
          }
        );
      });

      $scope.groupExemption = false;
      $scope.affliate = false;
      if (
        ApplicationService.getPreApplicationDetails() != null &&
        ApplicationService.getPreApplicationDetails().groupExemption &&
        ApplicationService.getPreApplicationDetails().foundation
      ) {
        if (
          ApplicationService.getPreApplicationDetails().groupExemption !==
            '0' &&
          ApplicationService.getPreApplicationDetails().foundation !== '10'
        ) {
          $scope.groupExemption = true;
        }
      }
      if (
        ApplicationService.getPreApplicationDetails() != null &&
        ApplicationService.getPreApplicationDetails().parentName
      ) {
        $scope.affliate = true;
        $scope.parentName = ApplicationService.getPreApplicationDetails().parentName;
        $scope.parentEin = ApplicationService.getPreApplicationDetails().parentEin;
        $scope.parentCfcCode = ApplicationService.getPreApplicationDetails().parentCfcCode;
      }
      $scope.isFileExist = function(optionValue) {
        if ($scope.applicationStep5.exemptionStatus === optionValue) {
          if (
            !$scope.applicationStep5.supportingFile &&
            $scope.applicationStep5.fileName
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      };

      //step6 data
      $scope.applicationStep6.applicationId = ApplicationService.getApplicationId();
      $scope.applicationStep6.cfcCode = tokenData.cfcCode;
      $scope.applicationStep6.$get(
        function(res) {
          var tmp = CakeSvc.transformGenericResponseToFlatResourceResponse(res);
          if (!angular.equals(tmp, {})) {
            $scope.applicationStep6 = tmp;
          } else {
            $scope.applicationStep6.applicationId = ApplicationService.getApplicationId();
            $scope.applicationStep6.cfcCode = ApplicationService.getCfcCode();
          }
        },
        function(err) {
          $log.error('STEP6 failed to load');
        }
      );

      var stepApplicable = true;
      if (
        ApplicationService.getPreApplicationDetails() != null &&
        ApplicationService.getPreApplicationDetails().charityEin &&
        ApplicationService.getPreApplicationDetails().charityEin !== '0'
      ) {
        stepApplicable = false;
      }
      $scope.stepApplicable = stepApplicable;

      if ($scope.pledgeYear && $scope.cfcCode) {
        CharityDashboardService.getCharityListingPageInfo(
          $scope.pledgeYear,
          $scope.cfcCode
        ).then(function(resp) {
          var result = resp.data.value;
          if (result !== null && result !== '') {
            $scope.charityListingPageInfo = result;

            $scope.charityListingViewPageInfo = angular.copy(
              $scope.charityListingPageInfo
            );
            if (
              $scope.charityListingPageInfo &&
              $scope.charityListingPageInfo.dashboardListingList
            ) {
              CharityDashboardService.getCharityDbaAuthLetters(
                $scope.pledgeYear,
                $scope.cfcCode,
                $scope.charityListingPageInfo.id
              ).then(function(resp) {
                if (resp.data.value != null) {
                  $scope.charityDbaAuthLetters = resp.data.value;
                  if (resp.data.value[0] !== undefined) {
                    $scope.dba_auth_s3_link = resp.data.value[0].s3Link;
                  }
                }
              });
              angular.forEach(
                $scope.charityListingPageInfo.dashboardListingList,
                function(obj) {
                  if ($scope.pledgeYear === obj.pledgeYear) {
                    $scope.selectedPledgeYearObject = obj;
                    $scope.pledgeYearId = obj.pledgeYearId;
                  }
                }
              );
            }
          }
        });
      }

      CharityDashboardService.getCharityListingObject(
        $scope.pledgeYear,
        $scope.cfcCode
      ).then(function(resp) {
        var result = resp.data.value;
        if (result !== null && result !== '') {
          $scope.charityListingObject = result;

          $scope.charityListingViewObject = angular.copy(
            $scope.charityListingObject
          );
          $scope.campaignId = result.campaignId;
        }
      });

      //step7 data
      $scope.applicationStep7.applicationId = ApplicationService.getApplicationId();
      $scope.applicationStep7.cfcCode = tokenData.cfcCode;
      $scope.applicationStep7.pledgeYear = tokenData.pledgeYear;
      $q(function(resolve, reject) {
        $scope.applicationStep7.$get(
          function(res) {
            var tmp = CakeSvc.transformGenericResponseToFlatResourceResponse(
              res
            );
            if (!angular.equals(tmp, {})) {
              $scope.applicationStep7 = tmp;
            } else {
              // need a minimum while $resource resolves... later
              $scope.applicationStep7.applicationId = ApplicationService.getApplicationId();
              $scope.applicationStep7.cfcCode = ApplicationService.getCfcCode();
              $scope.applicationStep7.isOffYear = $scope.isOffYear;
              $scope.applicationStep7.pledgeYear = ApplicationService.getPledgeYear();
            }
            resolve($scope.applicationStep7);
            if ($scope.applicationStep7.documentStartDate) {
              $scope.applicationStep7.documentStartDate = $filter('date')(
                new Date($scope.applicationStep7.documentStartDate),
                'MM/dd/yyyy'
              );
            }
            if ($scope.applicationStep7.documentEndDate) {
              $scope.applicationStep7.documentEndDate = $filter('date')(
                new Date($scope.applicationStep7.documentEndDate),
                'MM/dd/yyyy'
              );
            }
          },
          function(err) {
            $log.error('STEP7 failed to load');
            reject(err);
          }
        );
      }).then(function() {
        CharityDashboardService.getCharityDashboardData(tokenData.cfcCode).then(
          function(resp) {
            var result = resp.data.value;
            if (result !== null && result !== '') {
              $scope.applicationStep7.charityEin = result.einNumber;
            }
          }
        );

        if (tokenData.pledgeYear && tokenData.cfcCode) {
          if (!$scope.isZoneManager && !$scope.isZoneReviewer) {
            CharityDashboardService.getCharityListingObject(
              tokenData.pledgeYear,
              tokenData.cfcCode
            ).then(function(resp) {
              var result = resp.data.value;
              if (result !== null && result !== '') {
                $scope.charityListingObject = result;

                $scope.applicationStep7.charityListingViewObject = angular.copy(
                  $scope.charityListingObject
                );
              }
            });
          }
        }
      });

      //step8 data
      $scope.applicationStep8.applicationId = ApplicationService.getApplicationId();
      $scope.applicationStep8.cfcCode = tokenData.cfcCode;
      $scope.applicationStep8.pledgeYear = tokenData.pledgeYear;
      $q(function(resolve, reject) {
        $scope.applicationStep8.$get(
          function(res) {
            var tmp = CakeSvc.transformGenericResponseToFlatResourceResponse(
              res
            );
            if (!angular.equals(tmp, {})) {
              $scope.applicationStep8 = tmp;
            } else {
              $scope.applicationStep8.applicationId = ApplicationService.getApplicationId();
              $scope.applicationStep8.cfcCode = ApplicationService.getCfcCode();
              $scope.applicationStep8.pledgeYear = ApplicationService.getPledgeYear();
            }
            if (
              $scope.applicationStep8.changedFileName != null ||
              $scope.applicationStep8.changedFileName != undefined
            ) {
              var us = $scope.applicationStep8.changedFileName.lastIndexOf('_');
              $scope.currentFileVersion = parseInt(
                $scope.applicationStep8.changedFileName
                  .substring(us, $scope.applicationStep8.changedFileName.length)
                  .replace('_ver', '')
                  .replace(/\.[^\.]+$/, '')
              );
            }
            if ($scope.applicationStep8.signed === true) {
              $scope.applicationStep8.signed = 'Yes';
            } else if ($scope.applicationStep8.signed === false) {
              $scope.applicationStep8.signed = 'No';
            } else {
              $scope.applicationStep8.signed = null;
            }
            if ($scope.applicationStep8.startDate != null) {
              $scope.applicationStep8.startDate = $filter('date')(
                new Date($scope.applicationStep8.startDate),
                'MM/dd/yyyy'
              );
            }
            if ($scope.applicationStep8.endDate != null) {
              $scope.applicationStep8.endDate = $filter('date')(
                new Date($scope.applicationStep8.endDate),
                'MM/dd/yyyy'
              );
            }
            if ($scope.applicationStep8.accountingMethod === null) {
              $scope.applicationStep8.accountingMethod = '--';
            }
            resolve($scope.applicationStep8);
          },
          function(err) {
            $log.error('STEP8 failed to load');
            reject(err);
          }
        );
      });

      CharityDashboardService.getCharityDashboardData(tokenData.cfcCode).then(
        function(resp) {
          var result = resp.data.value;
          if (result !== null && result !== '') {
            $scope.applicationStep8.charityEin = result.einNumber;
          }
          if (
            $scope.applicationStep8.changedFileName != null ||
            $scope.applicationStep8.changedFileName != undefined
          ) {
            var us = $scope.applicationStep8.changedFileName.lastIndexOf('_');
            $scope.currentFileVersion = parseInt(
              $scope.applicationStep8.changedFileName
                .substring(us, $scope.applicationStep8.changedFileName.length)
                .replace('_ver', '')
                .replace(/\.[^\.]+$/, '')
            );
          }
        }
      );

      if (tokenData.pledgeYear && tokenData.cfcCode) {
        if (!$scope.isZoneManager && !$scope.isZoneReviewer) {
          CharityDashboardService.getCharityListingObject(
            tokenData.pledgeYear,
            tokenData.cfcCode
          ).then(function(resp) {
            var result = resp.data.value;
            if (result !== null && result !== '') {
              $scope.charityListingObject = result;

              $scope.applicationStep8.charityListingViewObject = angular.copy(
                $scope.charityListingObject
              );
              //$scope.campaignId = result.campaignId;
            }
          });
        }
      }

      //step9 data
      $scope.applicationStep9.applicationId = ApplicationService.getApplicationId();
      $scope.applicationStep9.cfcCode = tokenData.cfcCode;
      $scope.applicationStep9.$get(
        function(res) {
          var tmp = CakeSvc.transformGenericResponseToFlatResourceResponse(res);
          if (!angular.equals(tmp, {})) {
            $scope.applicationStep9 = tmp;
          } else {
            $scope.applicationStep9.applicationId = ApplicationService.getApplicationId();
            $scope.applicationStep9.cfcCode = ApplicationService.getCfcCode();
          }
        },
        function(err) {
          $log.error('STEP9 failed to load');
        }
      );

      //step10 data
      $scope.applicationStep10.applicationId = ApplicationService.getApplicationId();
      $scope.applicationStep10.cfcCode = tokenData.cfcCode;
      $scope.applicationStep10.pledgeYear = tokenData.pledgeYear;
      $q(function(resolve, reject) {
        $scope.applicationStep10.$get(
          function(res) {
            var tmp = CakeSvc.transformGenericResponseToFlatResourceResponse(
              res
            );
            if (!angular.equals(tmp, {})) {
              $scope.applicationStep10 = tmp;
            } else {
              $scope.applicationStep10.applicationId = ApplicationService.getApplicationId();
              $scope.applicationStep10.cfcCode = ApplicationService.getCfcCode();
              $scope.applicationStep10.pledgeYear = ApplicationService.getPledgeYear();
            }
            resolve($scope.applicationStep10);
          },
          function(err) {
            $log.error('STEP10 failed to load');
            reject(err);
          }
        );
      }).then(function() {
        CharityDashboardService.getCharityDashboardData(tokenData.cfcCode).then(
          function(resp) {
            var result = resp.data.value;
            if (result !== null && result !== '') {
              $scope.applicationStep10.charityEin = result.einNumber;
            }
          }
        );

        if (tokenData.pledgeYear && tokenData.cfcCode) {
          if (!$scope.isZoneManager && !$scope.isZoneReviewer) {
            CharityDashboardService.getCharityListingObject(
              tokenData.pledgeYear,
              tokenData.cfcCode
            ).then(function(resp) {
              var result = resp.data.value;
              if (result !== null && result !== '') {
                $scope.charityListingObject = result;

                $scope.applicationStep10.charityListingViewObject = angular.copy(
                  $scope.charityListingObject
                );
              }
            });
          }
        }
      });

      //step11 data
      $scope.applicationStep11.applicationId = ApplicationService.getApplicationId();
      $scope.applicationStep11.cfcCode = tokenData.cfcCode;
      $scope.applicationStep11.$get(
        function(res) {
          var tmp = CakeSvc.transformGenericResponseToFlatResourceResponse(res);
          if (!angular.equals(tmp, {})) {
            $scope.applicationStep11 = tmp;
          } else {
            $scope.applicationStep11.applicationId = ApplicationService.getApplicationId();
            $scope.applicationStep11.cfcCode = ApplicationService.getCfcCode();
          }
        },
        function(err) {
          $log.error('STEP11 failed to load');
        }
      );

      $scope.downloadPDFAndDocuments = function(isFed) {
        window.print();
        var downloadURL =
          '/CfcCharity/api/public/downloadappanddocuments/' +
          tokenData.applicationId +
          '/' +
          isFed +
          '/' +
          ApplicationService.getApplication().campaignId +
          '/' +
          tokenData.cfcCode;
        DownloadService.downloadFile(downloadURL);
      };

      function init() {
        $scope.appLabel = {};
        CharityService.findCharityWithCFC(tokenData.cfcCode).then(function(
          resp
        ) {
          var charity = resp.data;
          $scope.charity = charity; //Information
          $scope.appLabel.charityName = charity.charityName;
          $scope.appLabel.appYear = tokenData.pledgeYear;
          $scope.appLabel.appType = tokenData.appType;
          $scope.appLabel.cfcCode = tokenData.cfcCode;
        });

        $scope.canViewMsg =
          $scope.isOpmAdmin ||
          $scope.isOpmStaff ||
          $scope.isZoneManager ||
          $scope.isZoneReviewer ||
          $scope.isOpmAuditor;

        //revenue mismatch check
        CubeSvc.get(
          '/CfcCharity/api/private/application/revenuedetails/' +
            tokenData.applicationId,
          null,
          false
        ).then(function(res) {
          $scope.validRevenue = res.data.value;
        });

        CubeSvc.get(
          '/CfcCharity/api/private/application/details/' +
            tokenData.applicationId,
          null,
          false
        ).then(function(res) {
          if (res.data.value) {
            $scope.isOffYear = res.data.value.isOffYear;
          }
        });
      }
      init();
    }
  ])
  .controller('ApplicationReviewCtrl', [
    '$state',
    '$scope',
    'MODULE_NAME',
    'UserCharityRole',
    'ApplicationService',
    'CharityService',
    function(
      $state,
      $scope,
      MODULE_NAME,
      UserCharityRole,
      ApplicationService,
      CharityService
    ) {
      $scope.setAppStep(0);
      $scope.fromPage = $state.params.fromPage;
      var applicationId = $state.params.applicationId;
      var mode = $state.params.mode;
      $scope.mode = mode;
      applicationId =
        applicationId === ''
          ? ApplicationService.getApplicationId()
          : applicationId;

      CharityService.findCharityWithCFC(ApplicationService.getCfcCode()).then(
        function(resp) {
          var charity = resp.data;
          $scope.appNavbar.charityName = charity.charityName;
        }
      );
      ApplicationService.getApplicationStatus(applicationId, function(data) {
        $scope.applicationStatus = data;
      });
      $scope.showApplicationPage = function(stepNo, applicationId, mode) {
        $state.go('step' + stepNo, {
          mode: mode
        });
      };
      $scope.gotoSignature = function() {
        $state.go('signature');
      };
      $scope.gotoApplicationDashboard = function() {
        var applicationId = ApplicationService.getApplicationId();
        ApplicationService.reset();
        $state.go('applicationDashboard', {
          applicationId: applicationId
        });
      };
      $scope.gotoInviteUsers = function() {
        $state.go('inviteUserToCharity', {
          charityDashboardCfcCode: ApplicationService.getCfcCode()
        });
      };
    }
  ])
  .controller('ApplicationSignatureCtrl', [
    '$state',
    '$scope',
    '$q',
    'MODULE_NAME',
    'APPLICATION_STATUS',
    'APPLICATION_TYPE',
    'ApplicationService',
    function(
      $state,
      $scope,
      $q,
      MODULE_NAME,
      APPLICATION_STATUS,
      APPLICATION_TYPE,
      ApplicationService
    ) {
      $scope.setAppStep(-1);
      $scope.mode = $state.params.mode;
      $scope.applicationSignatureHistory = [];
      $scope.applicationRFIs = [];
      if (!$scope.application) {
        $scope.application = ApplicationService.getApplication();
        if (ApplicationService.getApplicationStep2()) {
          $scope.applicationType = ApplicationService.getApplicationStep2().applicationType;
        }
      }
      $scope.gotoApplicationDashboard = function() {
        var applicationId = ApplicationService.getApplicationId();
        ApplicationService.reset();
        $state.go('applicationDashboard', {
          applicationId: applicationId
        });
      };
      $scope.validateSignature = function() {
        $scope.fieldsMissing = [];
        if (!$scope.application.certifyApplication) {
          $scope.fieldsMissing.push('Certify Application');
        }
        if (!$scope.application.signatureFullName) {
          $scope.fieldsMissing.push('Full name');
        }
        if (!$scope.application.signatureInitials) {
          $scope.fieldsMissing.push('Initials');
        }
        if ($scope.fieldsMissing.length > 0) {
          $('#missingInfo').modal();
        } else {
          $scope.application.applicationStatus = APPLICATION_STATUS.signed;
          $scope.saveApplication();
        }
      };
      $scope.saveApplication = function() {
        var currentTime = ApplicationService.toUTCDate(new Date());
        $scope.application.signedDate = currentTime;
        ApplicationService.saveTable($scope.application, function(response) {
          var applicationId = ApplicationService.getApplicationId();
          if (
            ApplicationService.getApplicationStep2().applicationType ===
            APPLICATION_TYPE.independent
          ) {
            $state.go('applicationPayment', {
              charityApplicationId: applicationId
            });
          } else if (
            ApplicationService.getApplicationStep2().applicationType ===
            APPLICATION_TYPE.member_of_federation
          ) {
            ApplicationService.reset();
            $state.go('applicationDashboard', {
              applicationId: applicationId
            });
          }
        });
      };

      // check for sufficient rights to signature history and RFIs
      $scope.hasSufficientSignatureAndRFIviewingRights = false;
      var user = $scope.getCurrentUser();
      if (user.secondaryRoleList && Array.isArray(user.secondaryRoleList)) {
        $scope.hasSufficientSignatureAndRFIviewingRights =
          user.secondaryRoleList.indexOf('OPM_ADMIN') >= 0 ||
          user.secondaryRoleList.indexOf('OPM_STAFF') >= 0 ||
          user.secondaryRoleList.indexOf('CUSTOMER_SERVICE_MANAGER') >= 0 ||
          user.secondaryRoleList.indexOf('CUSTOMER_SERVICE_TIER_1') >= 0 ||
          user.secondaryRoleList.indexOf('CUSTOMER_SERVICE_TIER_2_FINANCE') >=
            0 ||
          user.secondaryRoleList.indexOf('ZONE_MANAGER') >= 0 ||
          user.secondaryRoleList.indexOf('REVIEWER') >= 0;
      }

      if ($scope.hasSufficientSignatureAndRFIviewingRights) {
        // load up signature history and RFIs if user has appropriate rights
        ApplicationService.getApplicationSignatureHistory(applicationId)
          .then(function(resp) {
            var data = resp.data.value;
            $log.debug(data);
            $scope.applicationSignatureHistory = data;
          })
          .catch(function(err) {
            $log.error(err);
          });
        ApplicationService.getAllApplicationRFIs(applicationId)
          .then(function(resp) {
            var data = resp.data.value;
            $log.debug(data);
            $scope.applicationRFIs = data;
          })
          .catch(function(err) {
            $log.error(err);
          });
      }
    }
  ])
  .controller('ApplicationPaymentCtrl', [
    '$state',
    '$scope',
    '$locale',
    'MODULE_NAME',
    'UtilityService',
    'ApplicationService',
    'charityApplicationData',
    'BrowserEventService',
    'CharityService',
    '$filter',
    function(
      $state,
      $scope,
      $locale,
      MODULE_NAME,
      UtilityService,
      ApplicationService,
      charityApplicationData,
      BrowserEventService,
      CharityService,
      $filter
    ) {
      $scope.setAppStep(-1);
      $scope.mode = $state.params.mode;
      ApplicationService.getStates(function(data) {
        $scope.states = data;
      });
      $scope.processingPayment = false;
      if (!$scope.applicationFee) {
        $scope.applicationFee = ApplicationService.getApplicationFee();
        //charityApplicationData.applicationId = ApplicationService.getApplicationId();
        if (charityApplicationData.cfcCode) {
          CharityService.findCharityWithCFC(
            charityApplicationData.cfcCode
          ).then(function(resp) {
            var charity = resp.data;
            charityApplicationData.charityName = charity.charityName;
          });
        }
        $scope.charityApplicationData = charityApplicationData;
        $scope.currentYear = new Date().getFullYear();
        $scope.currentMonth = new Date().getMonth() + 1;
        $scope.months = $locale.DATETIME_FORMATS.MONTH;
        $scope.applicationFee.nameOnCard = '';
        $scope.applicationFee.billingAddress1 = '';
        $scope.applicationFee.billingAddress2 = '';
        $scope.applicationFee.billingCity = '';
        $scope.applicationFee.billingState = '';
        $scope.applicationFee.billingzip = '';
        $scope.applicationFee.creditCardNumber = '';
        $scope.expirationDate = '';
        $scope.applicationFee.securityCode = '';
        $scope.expiryDateYears = [];
        for (i = 0; i <= 10; i++) {
          $scope.expiryDateYears.push($scope.currentYear + i);
        }
        var campaignId = ApplicationService.getApplication().campaignId;
        if (!campaignId) {
          campaignId = charityApplicationData.campaignId;
        }
        UtilityService.findIndependentAppFees(
          campaignId,
          charityApplicationData.applicationId
        ).then(function(res) {
          var response = res.data;
          $scope.appFee = response.APPLICATION_FEE;
          $scope.creditCardFeePercent = response.CREDITCARD_FEE;
          $scope.creditCardFee =
            (parseFloat($scope.appFee) *
              parseFloat($scope.creditCardFeePercent)) /
            100;
          $scope.applicationFee.applicationFee =
            parseFloat($scope.appFee) + parseFloat($scope.creditCardFee);
        });
      }
      $scope.gotoApplicationDashboard = function() {
        var applicationId = ApplicationService.getApplicationId();
        ApplicationService.reset();
        $state.go('applicationDashboard', {
          applicationId: applicationId
        });
      };
      $scope.validatePayment = function() {
        $scope.fieldsMissing = [];
        if (!$scope.applicationFee.nameOnCard) {
          $scope.fieldsMissing.push('Name on card is required.');
        }
        if (!$scope.applicationFee.billingAddress1) {
          $scope.fieldsMissing.push('Address 1 is required.');
        }
        if (!$scope.applicationFee.billingState) {
          $scope.fieldsMissing.push('State is required.');
        }
        if (!$scope.applicationFee.billingCity) {
          $scope.fieldsMissing.push('City is required.');
        }
        if (!$scope.applicationFee.billingZip) {
          $scope.fieldsMissing.push('Zip is required.');
        }
        if (!$scope.applicationFee.creditCardNumber) {
          $scope.fieldsMissing.push('Credit Card Number is required.');
        }
        if (!$scope.applicationFee.securityCode) {
          $scope.fieldsMissing.push('Security Code is required');
        }
        if (
          $scope.applicationFee.securityCode &&
          $scope.applicationFee.securityCode.length < 3
        ) {
          $scope.fieldsMissing.push('Security Code should be minimum 3 digits');
        }
        if (
          !$scope.applicationFee.selectedYear ||
          !$scope.applicationFee.selectedMonth
        ) {
          $scope.fieldsMissing.push('Expiration Date is required.');
        } else {
          if (
            Number($scope.applicationFee.selectedYear) < $scope.currentYear ||
            (Number($scope.applicationFee.selectedYear) ===
              $scope.currentYear &&
              Number($scope.applicationFee.selectedMonth) < $scope.currentMonth)
          ) {
            $scope.fieldsMissing.push('Provided expiration date is invalid');
          }
        }
        if ($scope.applicationFee.creditCardNumber) {
          var creditCardType = $filter('creditCardTypeFilter')(
            $scope.applicationFee.creditCardNumber
          );
          if (!creditCardType) {
            $scope.fieldsMissing.push(
              'Invalid Credit Card type. Please enter a valid Visa, MasterCard, Discover or American Express card number.'
            );
          } else {
            $scope.applicationFee.creditCardType = creditCardType;
          }
        }
        if ($scope.fieldsMissing.length > 0) {
          $('#missingInfo').modal();
        } else {
          var selectedMonth = $scope.applicationFee.selectedMonth;
          var selectedYear = $scope.applicationFee.selectedYear;
          $scope.applicationFee.applicationId = ApplicationService.getApplicationId();
          if ($scope.applicationFee.applicationId === undefined) {
            $scope.applicationFee.applicationId =
              $scope.charityApplicationData.applicationId;
          }
          $scope.applicationFee.expirationDate =
            selectedYear + '-' + selectedMonth;
          $scope.applicationFee.$resolved = false;
          $scope.processingPayment = true;
          ApplicationService.saveStep($scope.applicationFee, function(
            response
          ) {
            $scope.processingPayment = false;
            if (response.paymentSuccessful) {
              $scope.applicationFee.creditCardNumber = '';
              $scope.applicationFee.securityCode = '';
              $scope.applicationFee.id = '';
              $state.go('thankYou', {
                charityApplicationId: $scope.applicationFee.applicationId,
                paymentUserid: response.userId,
                paymentid: response.id,
                paymenttype: 'INDEPENDENT',
                paymentfeeType: 'Application',
                paymentMethod: 'Credit Card',
                paymentyear: selectedYear
              });
            } else {
              $scope.applicationFee.selectedMonth = selectedMonth;
              $scope.applicationFee.selectedYear = selectedYear;
              var error =
                'Credit Card Transaction Failed. Contact Customer Care if the problem persists';
              $scope.fieldsMissing.push(error);
              $scope.applicationFee.id = '';
              $('#missingInfo').modal();
            }
          });
        }
      };
      $scope.gotoAchPayment = function() {
        var applicationId = ApplicationService.getApplicationId();
        if (applicationId === undefined) {
          applicationId = $scope.charityApplicationData.applicationId;
        }
        BrowserEventService.setCampaignId(campaignId);
        BrowserEventService.setApplicationId(applicationId);
        BrowserEventService.setFedCfcCode(charityApplicationData.cfcCode);
        BrowserEventService.setCharityName(charityApplicationData.name);
        BrowserEventService.setApplicationFee(
          $scope.applicationFee.applicationFee
        );
        $state.go('charityapplicationachpayment', {
          campaignId: campaignId,
          applicationId: applicationId,
          cfcCode: charityApplicationData.cfcCode,
          charityName: charityApplicationData.name,
          applicationFee: $scope.applicationFee.applicationFee
        });
      };
    }
  ])
  .controller('ApplicationThankyouCtrl', [
    '$state',
    '$scope',
    'ApplicationService',
    'charityApplicationData',
    function($state, $scope, ApplicationService, charityApplicationData) {
      $scope.charityApplicationData = charityApplicationData;
      $scope.gotoApplicationDashboard = function() {
        var applicationId = ApplicationService.getApplicationId();
        ApplicationService.reset();
        if (applicationId === undefined) {
          applicationId = charityApplicationData.charityApplicationId;
        }
        $state.go('applicationDashboard', {
          applicationId: applicationId
        });
      };
    }
  ])
  .directive('fileModel', [
    '$parse',
    function($parse) {
      return {
        restrict: 'A',
        link: function(scope, element, attrs) {
          var model = $parse(attrs.fileModel);
          var modelSetter = model.assign;
          element.bind('change', function() {
            scope.$apply(function() {
              modelSetter(scope, element[0].files[0]);
            });
          });
        }
      };
    }
  ]);
