angular
  .module('CfcCharity.charity', [
    'ui.router',
    'CfcCharity.security',
    'hateoas',
    'CfcCharity.common'
  ])
  .config([
    '$stateProvider',
    '$urlRouterProvider',
    'HateoasInterceptorProvider',
    'USER_ROLES',
    function myAppConfig(
      $stateProvider,
      $urlRouterProvider,
      HateoasInterceptorProvider,
      USER_ROLES
    ) {
      $stateProvider
        .state('findCharityWithCFC', {
          url: '/findCharityWithCFC/:cfcCode',
          views: {
            main: {
              template: require('../views/private/charity/findCharityWithCFC.html'),
              controller: 'CharityCtrl'
            }
          },
          data: {
            pageTitle: 'Find Charity',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.charity_user]
          }
        })
        .state('findCharityWithEIN', {
          url: '/findCharityWithEIN',
          views: {
            main: {
              template: require('../views/private/charity/findCharityWithEIN.html'),
              controller: 'CharityCtrl'
            }
          },
          data: {
            pageTitle: 'Find Charity',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.charity_user]
          }
        })
        .state('findCharityWithCFCorEIN', {
          url: '/findCharityWithCFCorEIN',
          views: {
            main: {
              template: require('../views/private/charity/findCharityWithCFCorEIN.html'),
              controller: 'CharityCtrl'
            }
          },
          data: {
            pageTitle: 'Find Charity',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.charity_user]
          }
        })
        .state('newCharityEinAddress', {
          url: '/newCharityEinAddress/:charityEin/:charityName',
          views: {
            main: {
              template: require('../views/private/charity/newCharityEinAddress.html'),
              controller: 'CharityCtrl'
            }
          },
          data: {
            pageTitle: 'New Charity',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.charity_user]
          }
        })
        .state('editCharityAddress', {
          url: '/editCharityAddress/:cfcCode',
          views: {
            main: {
              template: require('../views/private/charity/editCharityAddress.html'),
              controller: 'EditCharityCtrl'
            }
          },
          data: {
            pageTitle: 'New Charity',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.charity_user]
          }
        })
        .state('newChapterToEin', {
          url: '/newChapterToEin/:charityEin/:charityName',
          views: {
            main: {
              template: require('../views/private/charity/newChapterToEin.html'),
              controller: 'CharityCtrl'
            }
          },
          data: {
            pageTitle: 'New Chapter',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.charity_user]
          }
        })
        .state('requestAccessToCharity', {
          url: '/requestAccessToCharity',
          views: {
            main: {
              template: require('../views/private/charity/requestAccessToCharity.html'),
              controller: 'RequestAccessToCharityCtrl'
            }
          },
          data: {
            pageTitle: 'Request Access To Charity',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.charity_user]
          },
          params: { cfcCode: null }
        })
        .state('applicationTypeHome', {
          url: '/applicationTypeHome',
          views: {
            main: {
              template: require('../views/private/application/applicationTypeHome.html'),
              controller: 'CharityCtrl'
            }
          },
          data: {
            pageTitle: 'Application Type',
            requireAuthentication: true,
            authorizedRoles: [USER_ROLES.charity_user]
          },
          params: { prevYearObj: null }
        });
    }
  ])
  .service('CharityService', [
    '$http',
    'CubeSvc',
    function($http, CubeSvc) {
      this.findCharityWithCFC = function(cfcCode) {
        var url = '/CfcCharity/api/private/charity/findCharityWithCFC';
        // CCA-3226: disables toaster notification now, to be re-implemented in future sprint
        return CubeSvc.get(url, { params: { cfcCode: cfcCode } }, false);
      };
      this.findCharityWithCFCLimited = function(cfcCode) {
        return $http.get(
          '/CfcCharity/api/private/charity/limited/findCharityWithCFC?cfcCode=' +
            cfcCode
        );
      };
      this.findCharityWithEIN = function(ein) {
        return $http.get(
          '/CfcCharity/api/private/charity/findCharityWithEIN?ein=' + ein
        );
      };
      this.findCharityEINWithIRS = function(ein) {
        return $http.get(
          '/CfcCharity/api/private/charity/findCharityEINWithIRS?ein=' + ein
        );
      };
      this.addNewCharity = function(charity) {
        return CubeSvc.post(
          '/CfcCharity/api/private/charity/createNewCharity',
          charity
        );
      };
      this.updateCharityAddress = function(charity) {
        return $http.post(
          '/CfcCharity/api/private/charity/updateCharityAddress',
          charity
        );
      };
      this.findEnrollmentStatus = function() {
        return $http.get('/CfcCharity/api/private/charity/getCampaignStatus');
      };
      this.findListingStatus = function() {
        return $http.get('/CfcCharity/api/private/charity/getListingStatus');
      };
      this.getCreateApplicationEligibility = function(cfcCode) {
        return $http.get(
          '/CfcCharity/api/private/charity/getCreateApplicationEligibility?cfcCode=' +
            cfcCode
        );
      };
      this.loadStates = function() {
        return $http.get('/CfcCharity/api/private/loadStates');
      };
      this.loadCountries = function() {
        return $http.get('/CfcCharity/api/private/lookup/COUNTRIES');
      };
      this.loadReviewGroups = function() {
        return $http.get('/CfcCharity/api/private/lookup/ADD_CAMPAIGN_GROUP');
      };
      this.getInvalidBankReasonCodes = function() {
        return $http.get(
          '/CfcCharity/api/private/lookup/INVALID_BANK_REASON_CODES'
        );
      };
      this.findCharities = function(charity) {
        return $http.get('/CfcCharity/api/charity/findCharities', charity);
      };
      this.getRequestAccessEligibility = function(cfcCode) {
        return $http.get(
          '/CfcCharity/api/private/charity/getRequestAccessEligibility?cfcCode=' +
            cfcCode
        );
      };
      this.createAccessRequest = function(cfcCode, notes, charityName) {
        return CubeSvc.post(
          '/CfcCharity/api/private/charity/createAccessRequest?cfcCode=' +
            cfcCode +
            '&notes=' +
            notes +
            '&charityName=' +
            charityName
        );
      };
      this.getPreApplicationDetails = function(cfcCode, pledgeYear) {
        var url = '/CfcCharity/api/private/pre/application/details';
        return CubeSvc.get(
          url,
          { params: { cfcCode: cfcCode, pledgeYear: pledgeYear } },
          false
        );
      };
      this.getCharityUserRole = function(cfcCode) {
        return $http.get(
          '/CfcCharity/api/private/charity/getCharityUserRole?cfcCode=' +
            cfcCode
        );
      };
      this.getCharitiesByUser = function(userId) {
        return $http.get(
          '/CfcCharity/api/private/users/usercharities/' + userId
        );
      };
      // check if charity has a previous year application
      this.getPrevYearApplicationStatus = function(cfcCode, currentYear) {
        return CubeSvc.get(
          '/CfcCharity/api/private/charity/previousApplication/' +
            cfcCode +
            '/' +
            currentYear
        );
      };
      this.createNewFederationApplication = function(
        cfcCode,
        pledgeYear,
        createMembers
      ) {
        var url =
          '/CfcCharity/api/private/application/federation?createMembers=' +
          createMembers;
        var data = {
          cfcCode: cfcCode,
          pledgeYear: pledgeYear
        };
        return $http.post(url, data, {
          headers: {
            'Content-Type': 'application/json; charset=utf-8'
          }
        });
      };
      this.getUsersWithPendingAccess = function(cfcCode) {
        return $http.get(
          '/CfcCharity/api/private/manageUsers/getUsersWithPendingAccess',
          { params: { cfcCode: cfcCode } }
        );
      };
    }
  ])
  .controller('CharityCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    '$stateParams',
    '$log',
    '$http',
    '$q',
    'CharityService',
    'UserCharityRole',
    'ApplicationService',
    'FederationApplicationService',
    'ApplicationDashboardService',
    'FederationDashboardService',
    'Step2CharitySearch',
    'ValidationService',
    'ZoneCenterService',
    'ValidationUtil',
    'CubeSvc',
    'toastrService',
    'APPLICATION_STATUS',
    'BrowserEventService',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      $stateParams,
      $log,
      $http,
      $q,
      CharityService,
      UserCharityRole,
      ApplicationService,
      FederationApplicationService,
      ApplicationDashboardService,
      FederationDashboardService,
      Step2CharitySearch,
      ValidationService,
      ZoneCenterService,
      ValidationUtil,
      CubeSvc,
      toastrService,
      APPLICATION_STATUS,
      BrowserEventService
    ) {
      $scope.setModuleName('CfcCharity.charity');
      CharityService.loadStates().then(function(res) {
        var states = res.data;
        $rootScope.states = states;
        CharityService.loadReviewGroups().then(function(resp) {
          var groups = resp.data;
          $scope.reviewGroups = groups;
        });
      });
      var charity = {};
      charity.officeState = '';
      charity.reviewGroup = '';
      $scope.charityFetched = false;
      $scope.charitiesFetched = false;
      charity.charityEin = $state.params.charityEin;
      charity.charityName = $state.params.charityName;
      charity.cfcOrEIN = '';
      $scope.charity = charity;
      $scope.searchResultsPerPage = 50;
      var activePageNumber = 1;

      var application = ApplicationService.getApplication();
      var campaignId = application.campaignId;
      if (undefined === campaignId) {
        campaignId = sessionStorage.getItem(
          'CREATE_APPLICATION_CURRENT_PLEDGE_YEAR'
        );
        application.campaignId = campaignId;
      }

      $scope.isPrevIndependent = false;
      $scope.isPrevMember = false;
      $scope.isPrevFederation = false;
      $scope.isIndependentNoPrev = false;
      $scope.isMemberNoPrev = false;
      $scope.isFederationNoPrev = false;
      $scope.isDisabled = false;

      $scope.isShowParentFederationFound = false;

      var prevYearObj;
      if ($state.params.prevYearObj) {
        prevYearObj = $state.params.prevYearObj;

        var appType = (prevYearObj.applicationType || '').toLowerCase();

        if (appType === 'independent') {
          if (true === prevYearObj.hasPrevYearApplication) {
            $scope.isPrevIndependent = true;
          } else {
            $scope.isIndependentNoPrev = true; // charity is an independent, knows that, different msg
          }
        } else if (appType === 'member') {
          if (true === prevYearObj.hasPrevYearApplication) {
            $scope.isPrevMember = true;
          } else {
            $scope.isMemberNoPrev = true; // charity is an independent, knows that, different msg
          }
        } else if (appType === 'federation') {
          if (true === prevYearObj.hasPrevYearApplication) {
            $scope.isPrevFederation = true;
          } else {
            $scope.isFederationNoPrev = true; // charity is an independent, knows that, different msg
          }
        }
      }

      if (
        !!prevYearObj &&
        true === prevYearObj.hasPrevYearApplication &&
        (null != prevYearObj.parentFederationCfcCode &&
          '' !== prevYearObj.parentFederationCfcCode.trim())
      ) {
        $scope.isShowParentFederationFound = true;
      } else {
        $scope.isShowParentFederationFound = false;
      }
      if (undefined === prevYearObj) {
        prevYearObj = {};
      }
      $scope.isMemberAllowedToContinueApp = function() {
        var cfcRe = /\d{5}/;
        if (
          null == $scope.memberParentErrorMessage &&
          null != prevYearObj.parentFederationCfcCode &&
          undefined !== prevYearObj.parentFederationCfcCode &&
          '' !== prevYearObj.parentFederationCfcCode.trim() &&
          cfcRe.test(prevYearObj.parentFederationCfcCode)
        ) {
          return true;
        } else {
          return false;
        }
      };
      $scope.prevYearObj = prevYearObj;

      if (
        $stateParams.cfcCode &&
        $stateParams.cfcCode != null &&
        $stateParams.cfcCode !== ''
      ) {
        $scope.noCharity = false;
        var isCFCStartingWithZero = ValidationService.validateStartingZeroFormat(
          'CFC Code',
          $stateParams.cfcCode
        );
        if (!isCFCStartingWithZero) {
          CharityService.findCharityWithCFC($stateParams.cfcCode).then(function(
            res
          ) {
            var data = res.data;
            $scope.charityFetched = true;
            if (data !== null && data !== '') {
              $scope.charity = data;
              CharityService.findCharityEINWithIRS(data.charityEin).then(
                function(resp) {
                  $scope.irsCharity = resp.data;
                }
              );
            } else {
              $scope.cfcno = $stateParams.cfcCode;
              $scope.noCharity = true;
            }
          });
        } else {
          /*Todo: Log error or whatever*/
        }
      }
      $scope.allowFederationAndMembers = function() {
        return !!(
          $scope.prevYearObj &&
          (!$scope.prevYearObj.hasPrevYearApplication ||
            ($scope.prevYearObj.hasPrevYearApplication &&
              $scope.prevYearObj.numberOfApprovedMembers === 0))
        );
      };
      $scope.findCharityWithCFC = function(charity) {
        $scope.noCharity = false;
        var isCFCStartingWithZero = ValidationService.validateStartingZeroFormat(
          'CFC Code',
          charity.cfccode
        );
        if (!isCFCStartingWithZero) {
          CharityService.findCharityWithCFC(charity.cfccode).then(function(
            res
          ) {
            var data = res.data;
            $scope.charityFetched = true;
            if (data) {
              $scope.charity = data;
              CharityService.findCharityEINWithIRS(data.charityEin).then(
                function(resp) {
                  $scope.irsCharity = resp.data;
                }
              );
            } else {
              $scope.cfcno = charity.cfccode;
              $scope.noCharity = true;
            }
          });
        } else {
          $scope.charitySearchErrorMessage = isCFCStartingWithZero;
        }
      };
      $scope.findCharityWithEIN = function(charity) {
        $scope.isFromIRS = false;
        $scope.charitiesSize = 0;
        $scope.isAddChapterEnabled = true;
        var isEINStartingWithZero = ValidationService.validateStartingZeroFormat(
          'EIN',
          charity.ein
        );
        if (!isEINStartingWithZero) {
          CharityService.findCharityWithEIN(charity.ein).then(function(res) {
            var data = res.data;
            $scope.charitiesFetched = true;
            if (data != null) {
              if (data.length === 0) {
                CharityService.findCharityEINWithIRS(charity.ein).then(function(
                  resp
                ) {
                  var myData = resp.data;
                  if (myData.length === 0) {
                    $scope.charity.charityEin = charity.ein;
                    $scope.charitiesSize = 0;
                  } else {
                    $scope.charity.charityEin = myData[0].charityEin;
                    $scope.charity.charityName = myData[0].charityName;
                    $scope.charity.officeAddress1 = myData[0].street;
                    $scope.charity.officeCity = myData[0].city;
                    $scope.charity.officeState = myData[0].state;
                    $scope.charity.officeZip = myData[0].zip;
                    $scope.charitiesSize = 1;
                    $scope.isFromIRS = true;
                    if (
                      parseInt(myData[0].group) === 0 ||
                      parseInt(myData[0].foundation) === 10
                    ) {
                      $scope.isAddChapterEnabled = true;
                    } else {
                      $scope.isAddChapterEnabled = false;
                    }
                  }
                });
              } else if (data.length === 1) {
                $scope.charity = data[0];
                $scope.charitiesSize = 1;
                CharityService.findCharityEINWithIRS(data[0].charityEin).then(
                  function(response) {
                    var irs = response.data;

                    if (
                      parseInt(irs.group) === 0 ||
                      parseInt(irs.foundation) === 10
                    ) {
                      $scope.isAddChapterEnabled = true;
                    } else {
                      $scope.isAddChapterEnabled = false;
                    }
                  }
                );
              } else {
                $scope.charitiesSize = 2;
                var parentCharity = {};
                var childCharities = [];
                CharityService.findCharityEINWithIRS(charity.ein).then(function(
                  response
                ) {
                  var results = response.data;
                  if (results.length !== 0) {
                    var chart = results[0];

                    if (
                      parseInt(results[0].group) === 0 ||
                      parseInt(results[0].foundation) === 10
                    ) {
                      $scope.isAddChapterEnabled = true;
                    } else {
                      $scope.isAddChapterEnabled = false;
                    }
                    for (var i = 0; i < result.length; i++) {
                      var temp = result[i];
                      if (
                        temp.officeAddress1 === chart.street &&
                        temp.officeZip === chart.zip
                      ) {
                        parentCharity = temp;
                      } else {
                        childCharities.push(temp);
                      }
                    }
                    $scope.charities = childCharities;
                    $scope.charity = parentCharity;
                  } else {
                    for (var j = 0; j < result.length; j++) {
                      if (j === 0) {
                        parentCharity = result[j];
                      } else {
                        childCharities.push(result[j]);
                      }
                    }
                    $scope.charities = childCharities;
                    $scope.charity = parentCharity;
                  }
                });
              }
            }
          });
        } else {
          $scope.charitySearchErrorMessage = isEINStartingWithZero;
        }
      };
      $scope.isEinValid = ValidationUtil.isEinValidLength;
      if (
        null != $stateParams.myMultiSearchTerm &&
        '' !== $stateParams.myMultiSearchTerm
      ) {
        $scope.myMultiSearchTerm = $stateParams.myMultiSearchTerm;
      }
      $scope.findCharityWithCFCorEIN = function(
        charity,
        pageNumber,
        searchField
      ) {
        // check both/either
        $scope.isCreateNewCharityEnabled = true;
        $scope.myMultiSearchTerm = charity.cfcOrEIN;
        var einRE = new RegExp('^\\d{9}$');
        if (einRE.test($scope.myMultiSearchTerm)) {
          $scope.isEIN = true;
          charity.charityEin = $scope.myMultiSearchTerm;
        } else {
          $scope.isEIN = false;
          charity.charityEin = null;
        }
        $scope.charity = charity;
        activePageNumber = pageNumber;
        if (pageNumber === undefined) {
          pageNumber = 1;
        }
        // search for either CFC or EIN; this follows the admin CFC,EIN,name search
        // /api/private/admin/getZoneApplicationUpdateResults/:zoneId/:pledgeYar/:status/:cfcOrEIN
        ZoneCenterService.getZoneAppdataUpdateResults(
          0,
          0,
          'Any',
          charity.cfcOrEIN,
          pageNumber - 1,
          searchField
        )
          .then(function(res) {
            var result = res.data;
            $scope.pledgeYear = result.pledgeYear;
            $scope.charityFetched = true;
            $scope.totalElements =
              result.limitedMasterCharityDataListTotalCount;
            if (null !== result && undefined !== result && '' !== result) {
              // result array
              $scope.charityMatchAr = result.limitedMasterCharityDataList;
              if ($scope.charityMatchAr.length > 0) {
                // matches found
                $scope.noCharity = false;
                $scope.foundCharityList = [];
                $scope.foundCharities = result.limitedMasterCharityDataList;
                //  remove all 6 digits + cfc's from the list
                angular.forEach($scope.foundCharities, function(ch) {
                  if (ch.cfcCode.length === 5) {
                    $scope.foundCharityList.push(ch);
                  }
                });
                if ($scope.foundCharityList.length === 0) {
                  $scope.noCharity = true;
                }
              } else {
                // no matches
                $scope.noCharity = true;
                $scope.foundCharityList = [];
              }
            } else {
              $log.error(
                'Searching for a charity by CFC code or EIN failed hard.'
              );
            }
          })
          .finally(function() {
            window.scroll(0, 0);
          })
          .catch(function(err) {
            $log.error(err);
          });
      };

      $scope.filterSearchResults = function(searchField) {
        $scope.findCharityWithCFCorEIN($scope.charity, 1, searchField);
      };

      $scope.pageChanged = function(newPage, searchField) {
        if (activePageNumber !== newPage) {
          $scope.findCharityWithCFCorEIN($scope.charity, newPage, searchField);
        }
      };

      $scope.chaptersListLimit = 100;
      $scope.loadMoreChapters = function() {
        if (
          $scope.charities &&
          $scope.chaptersListLimit < $scope.charities.length
        ) {
          $scope.chaptersListLimit += 100;
        }
      };
      $scope.addNewCharity = function(charity) {
        $scope.isContinueCFC = false;
        $scope.isNewCFCCreated = false;
        charity.createdDate = ApplicationService.toUTCDate(new Date());
        CharityService.addNewCharity(charity).then(function(res) {
          var result = res.data;
          if (result.message.type === CubeSvc.messageType.SUCCESS) {
            $scope.isNewCFCCreated = true;
            $scope.charity = result.charityData;
          }
        });
      };
      $scope.continueCharity = function(charity) {
        $scope.isContinueCFC = true;
        $scope.charity = charity;
      };
      $scope.editCharity = function(charity) {
        $scope.charityCreationErrorMessage =
          'If this is your organizations old address and it has changed, you will be allowed to Change your Address from within the system from the Charity page later';
      };
      $scope.continueForIRSCharity = function(charity) {
        $scope.isFromIRS = false;
        $scope.charity = charity;
      };
      $scope.applicationCreationConfirmation = function(cfcCode) {
        var charityCreationErrorMessage = '';
        var userObj = JSON.parse(sessionStorage.getItem('USER_OBJ'));
        $scope.isOpmAdmin =
          userObj.secondaryRoleList.indexOf('OPM_ADMIN') >= 0 || false;
        CharityService.getCreateApplicationEligibility(cfcCode)
          .then(function(res) {
            var data = res.data;
            if (data.statusCode !== undefined && data.statusCode === 'ERROR') {
              if (data.message === 'CAMPAIGN_PERIOD_NOT_OPEN') {
                charityCreationErrorMessage =
                  'Campaign period is not open for now.';
              } else if (
                data.message === 'ACCESS_NOT_AVAILABLE_FOR_THIS_USER'
              ) {
                $scope.cfcCode = cfcCode;
                charityCreationErrorMessage =
                  'ACCESS_NOT_AVAILABLE_FOR_THIS_USER';
                $scope.charityCreationErrorMessage =
                  'ACCESS_NOT_AVAILABLE_FOR_THIS_USER';
                $('#application-already-exists-modal').modal('show');
              } else if (
                data.message === 'CHARITY_APPLICATION_AVAILABLE_WITH_THIS_CFC'
              ) {
                var currentPledgeYear = sessionStorage.getItem(
                  'CREATE_APPLICATION_CURRENT_PLEDGE_YEAR'
                );
                if (
                  ApplicationService.getApplication().pledgeYear === undefined
                ) {
                  ApplicationService.getApplication().pledgeYear = currentPledgeYear;
                }
                Step2CharitySearch.findCurrentCharityType(cfcCode)
                  .then(function(resp) {
                    var result = resp.data.value;
                    if (result) {
                      $q(function(resolve, reject) {
                        if (null == result.applicationId) {
                          // pledgeYear, cfcCode
                          var currentPledgeYear = sessionStorage.getItem(
                            'CREATE_APPLICATION_CURRENT_PLEDGE_YEAR'
                          );
                          ApplicationDashboardService.getApplicationByPledgeYearAndCFC(
                            currentPledgeYear,
                            cfcCode
                          )
                            .then(function(res) {
                              resolve(res.data);
                            })
                            .catch(function(err) {
                              $log.error(err);
                              reject(err);
                            });
                        } else {
                          resolve({});
                        }
                      }).then(function(newRes) {
                        $scope.applicationType =
                          result.applicationType || newRes.applicationType;
                        $scope.applicationId =
                          result.applicationId || newRes.id;
                        $scope.hasAuthority = false;
                        CharityService.getCharityUserRole(cfcCode).then(
                          function(res) {
                            var userRole = res.data;
                            if (userRole || $scope.isOpmAdmin) {
                              $scope.hasAuthority = true;
                              $('#application-already-exists-modal').modal(
                                'show'
                              );
                            } else {
                              $scope.hasAuthority = false;
                              ApplicationDashboardService.applicationDetailsById(
                                result.applicationId
                              ).then(function(resp) {
                                var data = resp.data.value;
                                if (data) {
                                  if (data.federationNumber) {
                                    FederationDashboardService.getFederationApplication(
                                      data.federationNumber
                                    ).then(function(response) {
                                      var federation = response.data.value;
                                      if (federation) {
                                        CharityService.getCharityUserRole(
                                          federation.cfcCode
                                        ).then(function(roleResp) {
                                          var fRole = roleResp.data;
                                          if (fRole) {
                                            $scope.hasAuthority = true;
                                          } else {
                                            $scope.hasAuthority = false;
                                          }
                                        });
                                      }
                                    });
                                  }
                                  $scope.charityCreationErrorMessage =
                                    'CHARITY_APPLICATION_AVAILABLE_WITH_THIS_CFC';
                                  $('#application-already-exists-modal').modal(
                                    'show'
                                  );
                                }
                              });
                            }
                          }
                        );
                      });
                    }
                  })
                  .catch(function(err) {
                    $log.error(err);
                  });
                charityCreationErrorMessage =
                  'CHARITY_APPLICATION_AVAILABLE_WITH_THIS_CFC';
              }
            } else if (data.message === 'ALLOW_TO_CREATE_NEW_APPLICATION') {
              ApplicationService.reset();
              FederationApplicationService.reset();
              var currentPledgeYear = sessionStorage.getItem(
                'CREATE_APPLICATION_CURRENT_PLEDGE_YEAR'
              );
              if (
                ApplicationService.getApplication().pledgeYear === undefined
              ) {
                ApplicationService.getApplication().pledgeYear = currentPledgeYear;
              }
              CharityService.getPreApplicationDetails(
                cfcCode,
                currentPledgeYear
              ).then(function(res) {
                var data = res.data.value;
                ApplicationService.setPreApplicationDetails(data);
                FederationApplicationService.setPreApplicationDetails(data);
                $scope.applicationType = data.applicationType;
                if ($scope.memberApplicationParentCfcCode) {
                  Step2CharitySearch.findCurrentCharityType(
                    $scope.memberApplicationParentCfcCode
                  ).then(function(resp) {
                    var parentApplicationDetails = resp.data.value;
                    if (parentApplicationDetails) {
                      $scope.prevYearObj.parentFederationCfcCode =
                        $scope.memberApplicationParentCfcCode;
                      $scope.setMemberApplicationType(null);
                      $scope.applicationType = 'member';
                      $scope.setMemberApplicationParentCfcCode(null);
                      $scope.setMemberApplicationParentCharityType(null);
                      createApplication(parentApplicationDetails.applicationId);
                    }
                  });
                } else {
                  var currentPledgeYear = sessionStorage.getItem(
                    'CREATE_APPLICATION_CURRENT_PLEDGE_YEAR'
                  );
                  var prevYrOb = {};
                  CharityService.getPrevYearApplicationStatus(
                    cfcCode,
                    currentPledgeYear
                  )
                    .then(function(resp) {
                      prevYrOb = resp.data.value;
                    })
                    .catch(function(err) {
                      $log.error(err);
                      prevYrOb = {
                        hasPrevYearApplication: false,
                        applicationType: null,
                        parentFederationCfcCode: null
                      };
                    })
                    .finally(function() {
                      $state.go('applicationTypeHome', {
                        prevYearObj: prevYrOb
                      });
                    });
                }
              });
            }
            $scope.charityCreationErrorMessage = charityCreationErrorMessage;
            if ('' !== charityCreationErrorMessage) {
              $('#application-already-exists-modal').modal('show');
            }
          })
          .catch(function(err) {
            $log.error(err);
          });
      };

      // cfc-notification directive
      $scope.notification = {
        status: null,
        message: null,
        type: null
      };
      $scope.cancelApplication = function() {
        $scope.isDisabled = false;
      };

      // start new application process
      $scope.createNewAplication = function(applicationType) {
        $scope.isDisabled = true;
        // if independent, direct to create independent application
        if (applicationType === 'independent') {
          createApplication(null, applicationType);
        } else if (applicationType === 'member') {
          // show modal to approve parent federation or search for new
          $scope.applicationType = applicationType;
          $('#confirmFederation').modal();
        } else if (applicationType === 'federation') {
          // show modal to confirm if creating only federation or federation and all members
          $('#confirmSingleOrAll').modal();
        }
      };

      // called from the modal #confirmFederation
      $scope.createMemberApplication = function(parentFederationCode) {
        $scope.validationMessageList = [];
        var preAppDetails = ApplicationService.getPreApplicationDetails();
        var pledgeYear = preAppDetails.pledgeYear;
        ApplicationService.findCurrentCharityInfo(
          parentFederationCode,
          pledgeYear
        ).then(function(value) {
          if (!value) {
            $scope.validationMessageList.push(
              'You cannot create a member application for the selected parent charity because the parent charity is not accepting applications at this time due to its current application status.'
            );
          } else {
            createApplication(parentFederationCode, $scope.applicationType);
          }
        });
      };

      $scope.onParentFederationCfcCodeChange = function() {
        $scope.memberParentErrorMessage = null;
        $scope.validationMessageList = [];
      };

      $scope.createNewFederationApplication = function(createMembers) {
        $('#confirmSingleOrAll').modal('hide');
        $('.modal-backdrop').remove();

        var cfcCode = FederationApplicationService.getPreApplicationDetails()
          .cfcCode;
        var pledgeYear = FederationApplicationService.getPreApplicationDetails()
          .pledgeYear;
        FederationApplicationService.setCfcCode(cfcCode);
        FederationApplicationService.setPledgeYear(pledgeYear);
        var userSession = FederationApplicationService.getUserSession();
        var userObj = JSON.parse(sessionStorage.getItem('USER_OBJ'));
        var userId = userObj.id;
        userSession.cfcCode = cfcCode;
        userSession.userId = userId;
        CharityService.createNewFederationApplication(
          cfcCode,
          pledgeYear,
          createMembers
        )
          .then(function(response) {
            var data = response.data.value;
            BrowserEventService.setCampaignId(data.campaignId);
            userSession.applicationId = data.id;
            BrowserEventService.setCampaignId(data.campaignId);
            FederationApplicationService.setApplication(data);
            var role = UserCharityRole.get(
              {
                userId: userId,
                cfcCode: cfcCode
              },
              function() {
                if (role) {
                  userSession.userRole = role.userRole;
                }
                userSession.lastAccessedBeginDate = FederationApplicationService.toUTCDate(
                  new Date()
                );
                CharityService.getPreApplicationDetails(
                  cfcCode,
                  pledgeYear
                ).then(function(res) {
                  var data = res.data.value;
                  ApplicationService.setPreApplicationDetails(data);
                  FederationApplicationService.setPreApplicationDetails(data);
                });
                userSession
                  .$save(function(userSessResp) {
                    FederationApplicationService.setUserSession(userSessResp);
                    $state.go('federationStep1', {
                      applicationId: data.id,
                      mode: 'EDIT',
                      cfcCode: cfcCode
                    });
                  })
                  .catch(function(failedResponse) {
                    $log.error(failedResponse);
                  });
              }
            );
          })
          .catch(function(err) {
            $scope.notification.status = 'show';
            if (err.status === 504) {
              $scope.notification.type = 'warning';
              $scope.notification.message =
                'Please return to your user dashboard and access your federation application as your member package continues to load.';
            } else {
              $scope.notification.type = 'danger';
              $scope.notification.message =
                err.data.errorMessage ||
                'An unexpected error has occurred. Please contact your system administrator.';
            }
          });
      };

      var createApplication = function(federationId, applicationType) {
        if ($('.modal-backdrop')) {
          $('.modal-backdrop').remove();
        }
        var userObj = JSON.parse(sessionStorage.getItem('USER_OBJ'));
        var userId = userObj.id;
        $scope.userId = userId;

        var preAppDetails = ApplicationService.getPreApplicationDetails();
        var campaignId = preAppDetails.campaignId;
        var pledgeYear = preAppDetails.pledgeYear;
        var cfcCode = preAppDetails.cfcCode;
        var isFysa = preAppDetails.isFysa;
        $scope.setModuleName('CfcCharity.application');
        ApplicationService.setPledgeYear(pledgeYear);
        ApplicationService.setCfcCode(cfcCode);
        ApplicationService.setCampaignId(campaignId);
        $scope.setAppPledgeYear(pledgeYear);
        $scope.setAppCfcCode(cfcCode);
        $scope.setFysa(isFysa);

        applicationType = applicationType || $scope.applicationType;
        $scope.queryParams = {
          applicationType: applicationType
        };
        if ('member' === (applicationType || '').toLowerCase()) {
          var parCfc;
          if ($scope.prevYearObj.parentFederationCfcCode) {
            parCfc = $scope.prevYearObj.parentFederationCfcCode;
          } else {
            if (preAppDetails.parentFederationCfcCode) {
              parCfc = preAppDetails.parentFederationCfcCode;
            }
          }
          $q(function(resolve, reject) {
            if (undefined === parCfc || null == parCfc) {
              CubeSvc.get(
                '/CfcCharity/api/private/federation_application/' +
                  federationId,
                null,
                false
              ).then(
                function(raw) {
                  var data = raw.data.value;
                  $log.info(data);
                  parCfc = data.cfcCode;
                  resolve(parCfc);
                },
                function(err) {
                  $log.error(err);
                  reject(null);
                }
              );
            } else {
              resolve(parCfc);
            }
          })
            .then(function(parCfcCode) {
              if (null != parCfcCode) {
                $scope.queryParams.parentCFCCode =
                  $scope.prevYearObj.parentFederationCfcCode || parCfcCode;
              } else {
                $log.error('parCfc not resolved!!');
              }
              return;
            })
            .then(function() {
              $scope.createApplicationSecondHalf();
            });
        } else {
          $scope.createApplicationSecondHalf();
        }
      };

      $scope.createApplicationSecondHalf = function() {
        var userSession = ApplicationService.getUserSession();
        userSession.cfcCode = ApplicationService.getCfcCode();
        userSession.userId = $scope.userId;
        // url, post body,
        CubeSvc.post(
          '/CfcCharity/api/private/application/charity',
          {
            cfcCode: ApplicationService.getCfcCode(),
            pledgeYear: ApplicationService.getPledgeYear()
          },
          { params: $scope.queryParams }
        )
          .then(function(resp) {
            var data = resp.data.value;
            BrowserEventService.setCampaignId(data.campaignId);
            ApplicationService.setApplication(data);
            userSession.applicationId = data.id;
            ApplicationService.setParentFederationApplicationId(
              data.federationId || null
            );
            var role = UserCharityRole.get(
              {
                userId: $scope.userId,
                cfcCode: ApplicationService.getCfcCode()
              },
              function() {
                if (role) {
                  userSession.userRole = role.userRole;
                }
                userSession.lastAccessedBeginDate = ApplicationService.toUTCDate(
                  new Date()
                );
                CharityService.getPreApplicationDetails(
                  ApplicationService.getCfcCode(),
                  ApplicationService.getPledgeYear()
                ).then(function(response) {
                  ApplicationService.setPreApplicationDetails(
                    response.data.value
                  );
                });
                userSession
                  .$save(function(response) {
                    $state.go('step1', {
                      applicationId: data.id,
                      mode: 'EDIT',
                      parentFedAppId: data.federationId
                    });
                  })
                  .catch(function(failedResponse) {
                    $log.error(failedResponse);
                  });
              }
            );
          })
          .catch(function(error) {
            $log.error(error);
            var err = error.data;
            if (
              !!err.errorMessage &&
              err.errorMessage.toLowerCase().indexOf('member') > -1
            ) {
              $scope.memberParentErrorMessage = err.errorMessage;
              // set 'continue' disabled until changed
            }
          });
      };

      $scope.createFederationApplication = function() {
        $('.modal-backdrop').remove();
        var userObj = JSON.parse(sessionStorage.getItem('USER_OBJ'));
        var userId = userObj.id;
        var campaignId = FederationApplicationService.getPreApplicationDetails()
          .campaignId;
        var pledgeYear = FederationApplicationService.getPreApplicationDetails()
          .pledgeYear;
        var cfcCode = FederationApplicationService.getPreApplicationDetails()
          .cfcCode;
        $scope.setModuleName('CfcCharity.federation_application');
        FederationApplicationService.setPledgeYear(pledgeYear);
        FederationApplicationService.setCfcCode(cfcCode);
        FederationApplicationService.setCampaignId(campaignId);
        $scope.setFederationAppPledgeYear(pledgeYear);
        $scope.setFederationAppCfcCode(cfcCode);
        var application = FederationApplicationService.getApplication();
        application.cfcCode = cfcCode;
        application.campaignId = campaignId;
        application.pledgeYear = pledgeYear;
        application.applicationStatus = 'IN_PROGRESS';
        application.createdBy = userId;
        application.createdDate = FederationApplicationService.toUTCDate(
          new Date()
        );
        var userSession = FederationApplicationService.getUserSession();
        userSession.cfcCode = cfcCode;
        userSession.userId = userId;
        application.$save(
          function(response) {
            userSession.applicationId = response.id;
            FederationApplicationService.setApplicationId(response.id);
            var role = UserCharityRole.get(
              {
                userId: userId,
                cfcCode: cfcCode
              },
              function() {
                if (role) {
                  userSession.userRole = role.userRole;
                }
                userSession.lastAccessedBeginDate = FederationApplicationService.toUTCDate(
                  new Date()
                );
                userSession.$save(
                  function(response) {
                    FederationApplicationService.setUserSession(response);
                    $state.go('federationStep1', {
                      applicationId: resp.id,
                      mode: 'EDIT',
                      cfcCode: cfcCode
                    });
                  },
                  function(failedResponse) {
                    $log.error(failedResponse);
                  }
                );
              }
            );
          },
          function(failedResponse) {
            $log.error(failedResponse);
          }
        );
      };
      $scope.newChapterEinAddress = function(charity) {
        $state.go('newChapterToEin', {
          charityEin: charity.charityEin,
          charityName: charity.charityName
        });
      };
      $scope.newCharityEinAddress = function(charity) {
        $state.go('newCharityEinAddress', {
          charityEin: charity.charityEin,
          charityName: charity.charityName
        });
      };
      $scope.newChapterToEin = function(charity) {
        $scope.chapterCreated = false;
        $scope.isCharityConfirmed = false;
        $scope.charity = charity;
        CharityService.loadStates().then(function(res) {
          $rootScope.states = res.data;
        });
        $state.go('newChapterToEin', {
          charityEin: charity.charityEin,
          charityName: charity.charityName
        });
      };
      $scope.addNewChapter = function(charity) {
        $scope.zipCodeError = false;
        if (!ValidationService.validateUIZip(charity.officeZip)) {
          $scope.zipCodeError = true;
          return;
        }
        CharityService.addNewCharity(charity).then(function(res) {
          var result = res.data;
          if (result.message.type !== 'FAILURE') {
            $scope.charity = {};
            $scope.chapterCreated = true;
            $scope.charity = result.charityData;
          } else {
            //this will be changed to call the right service for messages.
            toastrService.error(
              result.validationMessageList[0].variableMessage
            );
          }
        });
      };
      $scope.charityConfirmed = function(charity) {
        $scope.chapterCreated = false;
        $scope.isCharityConfirmed = true;
      };
      $scope.requestCharityConfirmed = function(cfcCode) {
        if ($('.modal-backdrop')) {
          $('.modal-backdrop').remove();
        }
        $state.go('requestAccessToCharity', {
          cfcCode: cfcCode
        });
      };
      $scope.gotoPageSection = function(divId) {
        $location.hash(divId);
      };
    }
  ])
  .controller('EditCharityCtrl', [
    '$log',
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    'CharityService',
    'Step2CharitySearch',
    'ApplicationService',
    'FederationApplicationService',
    'ApplicationDashboardService',
    'FederationDashboardService',
    'UserCharityRole',
    function(
      $log,
      $state,
      $scope,
      $location,
      $rootScope,
      CharityService,
      Step2CharitySearch,
      ApplicationService,
      FederationApplicationService,
      ApplicationDashboardService,
      FederationDashboardService,
      UserCharityRole
    ) {
      $scope.setModuleName('CfcCharity.charity');
      var charity = {};
      var application = ApplicationService.getApplication();
      var campaignId = application.campaignId;
      $scope.isCharityUpdated = false;
      CharityService.findCharityWithCFC($state.params.cfcCode).then(function(
        result
      ) {
        var data = result.data;
        $scope.charityFetched = true;
        if (data) {
          $scope.charity = data;
          CharityService.loadStates().then(function(resp) {
            $scope.states = resp.data;
          });
        }
      });
      $scope.updateCharityAddress = function(charity) {
        CharityService.updateCharityAddress(charity).then(function(response) {
          $scope.isCharityUpdated = true;
          $scope.charity = response.data;
        });
      };
      $scope.editCharity = function(charity) {
        $scope.charityCreationErrorMessage =
          'If this is your organizations old address and it has change, you will be allowed to Change your Address from within the system from the Charity page later';
      };
      $scope.applicationCreateConfirmation = function(cfcCode) {
        var charityCreationErrorMessage = '';
        var userObj = JSON.parse(sessionStorage.getItem('USER_OBJ'));
        CharityService.getCreateApplicationEligibility(cfcCode).then(function(
          res
        ) {
          var data = res.data;
          if (data.statusCode !== undefined && data.statusCode === 'ERROR') {
            if (data.message === 'CAMPAIGN_PERIOD_NOT_OPEN') {
              charityCreationErrorMessage =
                'Campaign period is not open for now.';
            } else if (data.message === 'ACCESS_NOT_AVAILABLE_FOR_THIS_USER') {
              $scope.cfcCode = cfcCode;
              charityCreationErrorMessage =
                'ACCESS_NOT_AVAILABLE_FOR_THIS_USER';
            } else if (
              data.message === 'CHARITY_APPLICATION_AVAILABLE_WITH_THIS_CFC'
            ) {
              var currentPledgeYear = sessionStorage.getItem(
                'CREATE_APPLICATION_CURRENT_PLEDGE_YEAR'
              );
              if (
                ApplicationService.getApplication().pledgeYear === undefined
              ) {
                ApplicationService.getApplication().pledgeYear = currentPledgeYear;
              }
              Step2CharitySearch.findCurrentCharityType(cfcCode).then(function(
                resp
              ) {
                var result = resp.data.value;
                if (result) {
                  $scope.applicationType = result.applicationType;
                  $scope.applicationId = result.applicationId;
                  $scope.hasAuthority = false;
                  CharityService.getCharityUserRole(cfcCode).then(function(
                    response
                  ) {
                    var userRole = response.data;
                    if (userRole) {
                      $scope.hasAuthority = true;
                    } else {
                      $scope.hasAuthority = false;
                      ApplicationDashboardService.applicationDetailsById(
                        result.applicationId
                      ).then(function(respo) {
                        var data = respo.data.value;
                        if (data) {
                          if (data.federationNumber) {
                            FederationDashboardService.getFederationApplication(
                              data.federationNumber
                            ).then(function(respon) {
                              var federation = respon.data.value;
                              if (federation) {
                                CharityService.getCharityUserRole(
                                  federation.cfcCode
                                ).then(function(roleReponse) {
                                  var fRole = roleResponse.data;
                                  if (fRole) {
                                    $scope.hasAuthority = true;
                                  } else {
                                    $scope.hasAuthority = false;
                                  }
                                });
                              }
                            });
                          }
                        }
                      });
                    }
                  });
                }
              });
              charityCreationErrorMessage =
                'CHARITY_APPLICATION_AVAILABLE_WITH_THIS_CFC';
            }
          } else if (data.message === 'ALLOW_TO_CREATE_NEW_APPLICATION') {
            ApplicationService.reset();
            FederationApplicationService.reset();
            var currentPledgeYear = sessionStorage.getItem(
              'CREATE_APPLICATION_CURRENT_PLEDGE_YEAR'
            );
            if (ApplicationService.getApplication().pledgeYear === undefined) {
              ApplicationService.getApplication().pledgeYear = currentPledgeYear;
            }
            CharityService.getPreApplicationDetails(
              cfcCode,
              currentPledgeYear
            ).then(function(resp) {
              var data = resp.data.value;
              ApplicationService.setPreApplicationDetails(data);
              FederationApplicationService.setPreApplicationDetails(data);
              $scope.applicationType = data.applicationType;
              if ($scope.memberApplicationParentCfcCode) {
                Step2CharitySearch.findCurrentCharityType(
                  $scope.memberApplicationParentCfcCode
                ).then(function(respo) {
                  var parentApplicationDetails = respo.data.value;
                  if (parentApplicationDetails) {
                    $scope.setMemberApplicationType(null);
                    $scope.setMemberApplicationParentCfcCode(null);
                    $scope.setMemberApplicationParentCharityType(null);
                    createApplication(parentApplicationDetails.applicationId);
                  }
                });
              } else {
                var currentPledgeYear = sessionStorage.getItem(
                  'CREATE_APPLICATION_CURRENT_PLEDGE_YEAR'
                );
                var prevYrOb = {};
                CharityService.getPrevYearApplicationStatus(
                  cfcCode,
                  currentPledgeYear
                )
                  .then(function(res) {
                    prevYrOb = res.data.value;
                  })
                  .catch(function(err) {
                    $log.error(err);
                    prevYrOb = {
                      hasPrevYearApplication: false,
                      applicationType: null,
                      parentFederationCfcCode: null
                    };
                  })
                  .finally(function() {
                    $state.go('applicationTypeHome', { prevYearObj: prevYrOb });
                  });
              }
            });
          }
          $scope.charityCreationErrorMessage = charityCreationErrorMessage;
        });
      };
      $scope.createApplication = function() {
        createApplication(undefined);
      };
      var createApplication = function(federationId) {
        if ($('.modal-backdrop')) {
          $('.modal-backdrop').remove();
        }
        var userObj = JSON.parse(sessionStorage.getItem('USER_OBJ'));
        var userId = userObj.id;
        var campaignId = ApplicationService.getPreApplicationDetails()
          .campaignId;
        var pledgeYear = ApplicationService.getPreApplicationDetails()
          .pledgeYear;
        var cfcCode = ApplicationService.getPreApplicationDetails().cfcCode;
        $scope.setModuleName('CfcCharity.application');
        ApplicationService.setPledgeYear(pledgeYear);
        ApplicationService.setCfcCode(cfcCode);
        ApplicationService.setCampaignId(campaignId);
        $scope.setAppPledgeYear(pledgeYear);
        $scope.setAppCfcCode(cfcCode);
        var application = ApplicationService.getApplication();
        application.cfcCode = cfcCode;
        application.campaignId = campaignId;
        application.pledgeYear = pledgeYear;
        application.applicationStatus = 'IN_PROGRESS';
        application.createdBy = userId;
        application.createdDate = ApplicationService.toUTCDate(new Date());
        if (federationId) {
          application.federationId = federationId;
        }
        ApplicationService.setParentFederationApplicationId(
          application.federationId || null
        );
        var userSession = ApplicationService.getUserSession();
        userSession.cfcCode = cfcCode;
        userSession.userId = userId;
        application.$save(
          function(response) {
            userSession.applicationId = response.id;
            ApplicationService.setApplicationId(response.id);
            var role = UserCharityRole.get(
              {
                userId: userId,
                cfcCode: cfcCode
              },
              function() {
                if (role) {
                  userSession.userRole = role.userRole;
                }
                userSession.lastAccessedBeginDate = ApplicationService.toUTCDate(
                  new Date()
                );
                userSession.$save(
                  function(response) {
                    $state.go('step1', {
                      applicationId: ApplicationService.getApplicationId(),
                      mode: 'EDIT',
                      parentFedAppId: ApplicationService.getParentFederationApplicationId()
                    });
                  },
                  function(failedResponse) {
                    $log.error(failedResponse);
                  }
                );
              }
            );
          },
          function(failedResponse) {
            $log.error(failedResponse);
          }
        );
      };
      $scope.createFederationApplication = function() {
        $('.modal-backdrop').remove();
        var userObj = JSON.parse(sessionStorage.getItem('USER_OBJ'));
        var userId = userObj.id;
        var campaignId = FederationApplicationService.getPreApplicationDetails()
          .campaignId;
        var pledgeYear = FederationApplicationService.getPreApplicationDetails()
          .pledgeYear;
        var cfcCode = FederationApplicationService.getPreApplicationDetails()
          .cfcCode;
        $scope.setModuleName('CfcCharity.federation_application');
        FederationApplicationService.setPledgeYear(pledgeYear);
        FederationApplicationService.setCfcCode(cfcCode);
        FederationApplicationService.setCampaignId(campaignId);
        $scope.setFederationAppPledgeYear(pledgeYear);
        $scope.setFederationAppCfcCode(cfcCode);
        var application = FederationApplicationService.getApplication();
        application.cfcCode = cfcCode;
        application.campaignId = campaignId;
        application.pledgeYear = pledgeYear;
        application.applicationStatus = 'IN_PROGRESS';
        //application.createdBy = userId;
        application.createdDate = FederationApplicationService.toUTCDate(
          new Date()
        );
        var userSession = FederationApplicationService.getUserSession();
        userSession.cfcCode = cfcCode;
        //userSession.userId = userId;
        application.$save(
          function(response) {
            userSession.applicationId = response.id;
            FederationApplicationService.setApplicationId(response.id);
            var role = UserCharityRole.get(
              {
                cfcCode: cfcCode
              },
              function() {
                if (role) {
                  userSession.userRole = role.userRole;
                }
                userSession.lastAccessedBeginDate = FederationApplicationService.toUTCDate(
                  new Date()
                );
                userSession.$save(
                  function(response) {
                    FederationApplicationService.setUserSession(response);
                    $state.go('federationStep1');
                  },
                  function(failedResponse) {
                    $log.error(failedResponse);
                  }
                );
              }
            );
          },
          function(failedResponse) {
            $log.error(failedResponse);
          }
        );
      };
    }
  ])
  .controller('RequestAccessToCharityCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    'CharityService',
    'CubeSvc',
    function($state, $scope, $location, $rootScope, CharityService, CubeSvc) {
      $scope.setModuleName('CfcCharity.charity');
      $scope.charity = {};
      $scope.charities = [];
      $scope.noCharities = false;
      $scope.charitiesFetched = false;
      $scope.createRequest = false;
      $scope.notes = '';
      if ($state.params.cfcCode) {
        $scope.noCharities = false;
        $scope.charitiesFetched = false;
        $scope.noOwnerExistMsg = false;
        $scope.createRequest = true;
        CharityService.findCharityWithCFC($state.params.cfcCode).then(function(
          res
        ) {
          var result = res.data;
          $scope.charity = result;
          $scope.charities.push(result);
        });
      }
      $scope.findCharityForRequestAccess = function(charity) {
        $scope.isEnrollmentAvailable = false;
        $scope.noInputErrorMessage = '';
        if (charity.cfcCode !== undefined && charity.cfcCode !== '') {
          $scope.charities = [];
          CharityService.findCharityWithCFCLimited(charity.cfcCode).then(
            function(res) {
              var result = res.data;
              $scope.charitiesFetched = true;
              if (result) {
                $scope.charities.push(result);
              } else {
                $scope.noCharities = true;
                var charity = {};
                $scope.charity = charity;
                CharityService.findEnrollmentStatus().then(function(resp) {
                  $scope.isEnrollmentAvailable = resp.data;
                });
              }
            }
          );
        } else if (
          charity.charityEin !== undefined &&
          charity.charityEin !== ''
        ) {
          $scope.charities = [];
          CharityService.findCharityWithEIN(charity.charityEin).then(function(
            res
          ) {
            var result = res.data;
            $scope.charitiesFetched = true;
            if (result != null && result.length > 0) {
              $scope.charities = result;
            } else {
              $scope.noCharities = true;
              $scope.charity = charity;
              CharityService.findEnrollmentStatus().then(function(resp) {
                $scope.isEnrollmentAvailable = resp.data;
              });
            }
          });
        } else {
          $scope.noInputErrorMessage =
            'Enter 5 digit CFC code or 9 digit Ein Number';
        }
      };
      $scope.charityRequestAccessConfirmation = function(charity) {
        $scope.noInputErrorMessage = '';
        CharityService.findEnrollmentStatus().then(function(res) {
          $scope.isEnrollmentAvailable = res.data;
        });
        var userObj = JSON.parse(sessionStorage.getItem('USER_OBJ'));
        CharityService.getRequestAccessEligibility(charity.cfcCode).then(
          function(resp) {
            var data = resp.data;
            if (data.statusCode !== undefined) {
              if (data.message === 'ELIGIBLE_FOR_CREATE_REQUEST') {
                $scope.noCharities = false;
                $scope.charitiesFetched = false;
                $scope.noOwnerExistMsg = false;
                $scope.createRequest = true;
                $scope.charity = charity;
              } else if (data.message === 'NO_ACTIVE_OWNER_EXIST') {
                $scope.noCharities = false;
                $scope.charitiesFetched = false;
                $scope.noOwnerExistMsg = true;
                $scope.createRequest = false;
                $scope.charity = charity;
              } else {
                $scope.noInputErrorMessage = data.message;
              }
            }
          }
        );
      };
      $scope.goRequestListPage = function() {
        $scope.charitiesFetched = true;
        $scope.createRequest = false;
        $scope.noOwnerExistMsg = false;
      };
      $scope.goRequestSearchPage = function() {
        $scope.charitiesFetched = false;
        $scope.createRequest = false;
        $scope.charities = [];
        $scope.charity = {};
        $scope.noInputErrorMessage = undefined;
      };
      $scope.createApplicationWithCFC = function() {
        $state.go('findCharityWithCFC', { cfcCode: $scope.charity.cfcCode });
      };
      $scope.createAccessRequest = function(cfcCode, notes, charityName) {
        cfcCode = angular.isDefined(cfcCode) ? cfcCode : '';
        notes = angular.isDefined(notes) ? notes : '';
        charityName = angular.isDefined(charityName) ? charityName : '';
        CharityService.createAccessRequest(cfcCode, notes, charityName).then(
          function(res) {
            var data = res.data;
            if (data.message.type === CubeSvc.messageType.SUCCESS) {
              $scope.requestCreated = true;
            }
          }
        );
      };
      $scope.goToUserDashboard = function() {
        var user = $scope.getCurrentUser();
        $state.go('userDashboard', { userId: user.id });
      };
      $scope.addNewChapterFromRequest = function(charity) {
        CharityService.loadStates().then(function(resp) {
          $scope.states = resp.data;
          $state.go('newChapterToEin', {
            charityEin: charity.charityEin,
            charityName: charity.charityName
          });
        });
      };
    }
  ]);
