angular
  .module('CfcCharity.federationapplicationfee', [
    'ui.router',
    'CfcCharity.security',
    'hateoas'
  ])
  .config([
    '$stateProvider',
    '$urlRouterProvider',
    'HateoasInterceptorProvider',
    'USER_ROLES',
    function myAppConfig(
      $stateProvider,
      $urlRouterProvider,
      HateoasInterceptorProvider,
      USER_ROLES
    ) {
      $stateProvider.state('federationapplicationfee', {
        url: '/federationapplicationfee',
        views: {
          main: {
            template: require('../views/private/federation/federationapplicationfee.html'),
            controller: 'FederationApplicationFeeCtrl'
          }
        },
        data: {
          pageTitle: 'Federation Application Fee',
          requireAuthentication: true,
          authorizedRoles: [USER_ROLES.charity_user, USER_ROLES.system_user]
        },
        params: {
          federationId: null
        },
        resolve: {
          federationApplicationFeeResponse: function($http, $stateParams) {
            if ($stateParams.federationId != null) {
              var federationId = $stateParams.federationId;
              var url =
                '/CfcCharity/api/private/users/federation/feedetails/' +
                federationId;
              return $http.get(url).then(function(res) {
                return res.data;
              });
            }
          }
        }
      });
    }
  ])
  .controller('FederationApplicationFeeCtrl', [
    '$state',
    '$scope',
    '$location',
    '$rootScope',
    '$locale',
    '$http',
    'federationApplicationFeeResponse',
    'BrowserEventService',
    'ApplicationService',
    'CharityService',
    '$filter',
    function(
      $state,
      $scope,
      $location,
      $rootScope,
      $locale,
      $http,
      federationApplicationFeeResponse,
      BrowserEventService,
      ApplicationService,
      CharityService,
      $filter
    ) {
      function init() {
        $scope.processingPayment = false;
        $scope.isPayByAchEnabled = false;
        var secondaryRoleList = $scope.getCurrentUser().secondaryRoleList;
        if (
          secondaryRoleList.indexOf('GB_FINANCE_ADMIN') >= 0 ||
          secondaryRoleList.indexOf('CUSTOMER_SERVICE_MANAGER') >= 0 ||
          secondaryRoleList.indexOf('CUSTOMER_SERVICE_TIER_2_FINANCE') >= 0
        ) {
          $scope.isPayByAchEnabled = true;
        }
        opmAdminOverride();
      }

      function opmAdminOverride() {
        //console.log(federationApplicationFeeResponse);
        if ($scope.isPayByAchEnabled) {
          return;
        }

        $http
          .get('/CfcCharity/api/private/charity/getCampaignStatus')
          .then(function(resp) {
            if (resp.data) {
              // Application period is open
              return;
            }
          });

        var secondaryRoleList = $scope.getCurrentUser().secondaryRoleList;
        if (
          secondaryRoleList.indexOf('GB_FINANCE_ADMIN') >= 0 ||
          secondaryRoleList.indexOf('OPM_ADMIN') >= 0
        ) {
          $scope.isPayByAchEnabled = true;
        }
      }

      $scope.setModuleName('CfcCharity.federationapplicationfee');
      $scope.federationListingVisible = false;
      $scope.federationApplicationVisible = true;
      $scope.creditCardTypeSelected = false;
      $scope.isCharityUser = false;
      $scope.federationApplicationFee = {};
      $scope.creditCardTypeClicked = function() {
        $scope.creditCardTypeSelected = true;
        $scope.expiryDateYears = [];
        for (i = 0; i <= 10; i++) {
          $scope.expiryDateYears.push($scope.currentYear + i);
        }
        $scope.federationApplicationFeeWithOutPercent =
          $scope.federationApplicationFeeData.federationApplicationFee;
        $scope.totalMemberApplicationsFeeWithOutPercent =
          $scope.federationApplicationFeeData.totalMemberApplicationsFee;
        $scope.federationApplicationFeePercentAmt =
          ($scope.federationApplicationFeeData.federationApplicationFee *
            parseFloat(
              $scope.federationApplicationFeeData.creditCardPercentageFee
            )) /
          100;
        $scope.totalMemberApplicationsFeePercentAmt =
          ($scope.federationApplicationFeeData.totalMemberApplicationsFee *
            parseFloat(
              $scope.federationApplicationFeeData.memberProcessingPercentageFee
            )) /
          100;
        $scope.federationApplicationFeeData.totalMemberApplicationsFee =
          $scope.federationApplicationFeeData.totalMemberApplicationsFee +
          $scope.totalMemberApplicationsFeePercentAmt;
        $scope.federationApplicationFeeData.federationApplicationFee =
          $scope.federationApplicationFeeData.federationApplicationFee +
          $scope.federationApplicationFeePercentAmt;
        $scope.federationApplicationFeeData.totalAmountDue =
          $scope.federationApplicationFeeData.totalMemberApplicationsFee +
          $scope.federationApplicationFeeData.federationApplicationFee;
      };
      if (
        federationApplicationFeeResponse &&
        (federationApplicationFeeResponse.error ||
          federationApplicationFeeResponse.statusCode === 'ERROR')
      ) {
        $scope.showFederationApplicationFeeErrorMessage(
          federationApplicationFeeResponse.errorResponse.errorCode +
            ' : ' +
            federationApplicationFeeResponse.errorResponse.errorMessage +
            '. Please try again later!'
        );
      } else if (
        federationApplicationFeeResponse &&
        federationApplicationFeeResponse.statusCode === 'SUCCESS'
      ) {
        ApplicationService.getStates(function(data) {
          $scope.states = data;
        });
        $scope.federationApplicationFeeData =
          federationApplicationFeeResponse.federationApplicationFeeBean;
        CharityService.getCharityUserRole(
          $scope.federationApplicationFeeData.cfcCode
        ).then(function(res) {
          var result = res.data;
          if (result !== null && result !== '') {
            if (
              result.userRole === 'CHARITY_OWNER' ||
              result.userRole === 'CHARITY_EDITOR'
            ) {
              $scope.isCharityUser = true;
            }
          }
        });
      }
      $scope.currentYear = new Date().getFullYear();
      $scope.currentMonth = new Date().getMonth() + 1;
      $scope.months = $locale.DATETIME_FORMATS.MONTH;
      $scope.validatePayment = function() {
        $scope.fieldsMissing = [];
        if (!$scope.federationApplicationFee) {
          $scope.fieldsMissing.push('Name on card is required.');
          $scope.fieldsMissing.push('Address 1 is required.');
          $scope.fieldsMissing.push('State is required.');
          $scope.fieldsMissing.push('City is required.');
          $scope.fieldsMissing.push('Zip is required.');
          $scope.fieldsMissing.push('Credit Card Number is required.');
          $scope.fieldsMissing.push('Expiration Date is required.');
          $scope.fieldsMissing.push('Security Code is required');
        } else {
          if (!$scope.federationApplicationFee.nameOnCard) {
            $scope.fieldsMissing.push('Name on card is required.');
          }
          if (!$scope.federationApplicationFee.billingAddress1) {
            $scope.fieldsMissing.push('Address 1 is required.');
          }
          if (!$scope.federationApplicationFee.billingState) {
            $scope.fieldsMissing.push('State is required.');
          }
          if (!$scope.federationApplicationFee.billingCity) {
            $scope.fieldsMissing.push('City is required.');
          }
          if (!$scope.federationApplicationFee.billingZip) {
            $scope.fieldsMissing.push('Zip is required.');
          }
          if (!$scope.federationApplicationFee.creditCardNumber) {
            $scope.fieldsMissing.push('Credit Card Number is required.');
          }
          if (!$scope.federationApplicationFee.securityCode) {
            $scope.fieldsMissing.push('Security Code is required');
          }
          if (
            $scope.federationApplicationFee.securityCode &&
            $scope.federationApplicationFee.securityCode.length < 3
          ) {
            $scope.fieldsMissing.push(
              'Security Code should be minimum 3 digits'
            );
          }
          if (
            !$scope.federationApplicationFee.selectedYear ||
            !$scope.federationApplicationFee.selectedMonth
          ) {
            $scope.fieldsMissing.push('Expiration Date is required.');
          } else {
            if (
              Number($scope.federationApplicationFee.selectedYear) <
                $scope.currentYear ||
              (Number($scope.federationApplicationFee.selectedYear) ===
                $scope.currentYear &&
                Number($scope.federationApplicationFee.selectedMonth) <
                  $scope.currentMonth)
            ) {
              $scope.fieldsMissing.push('Provided expiration date is invalid');
            }
          }
          if ($scope.federationApplicationFee.creditCardNumber) {
            var creditCardType = $filter('creditCardTypeFilter')(
              $scope.federationApplicationFee.creditCardNumber
            );
            if (!creditCardType) {
              $scope.fieldsMissing.push(
                'Invalid Credit Card type. Please enter a valid Visa, MasterCard, Discover or American Express card number.'
              );
            } else {
              $scope.federationApplicationFee.creditCardType = creditCardType;
            }
          }
        }
        if ($scope.fieldsMissing.length > 0) {
          $('#missingInfo').modal();
        } else {
          $scope.federationApplicationFee.applicationId =
            $scope.federationApplicationFeeData.federationId;
          $scope.federationApplicationFee.applicationFee =
            $scope.federationApplicationFeeData.totalAmountDue;
          $scope.federationApplicationFee.federationApplicationFee =
            $scope.federationApplicationFeeData.federationApplicationFee;
          $scope.federationApplicationFee.noOfMembers =
            $scope.federationApplicationFeeData.totalMemberApplications;
          $scope.federationApplicationFee.totalMemberApplicationFee =
            $scope.federationApplicationFeeData.totalMemberApplicationsFee;
          $scope.federationApplicationFee.cfcFee =
            $scope.federationApplicationFeeWithOutPercent +
            $scope.totalMemberApplicationsFeeWithOutPercent;
          $scope.federationApplicationFee.processingFee =
            $scope.federationApplicationFeePercentAmt +
            $scope.totalMemberApplicationsFeePercentAmt;
          $scope.federationApplicationFee.expirationDate =
            $scope.federationApplicationFee.selectedYear +
            '-' +
            $scope.federationApplicationFee.selectedMonth;
          $scope.processingPayment = true;
          $http
            .post(
              '/CfcCharity/api/private/users/federation/application/payment',
              $scope.federationApplicationFee
            )
            .then(function(res) {
              var data = res.data;

              if (data.paymentSuccessful) {
                var cfcCode = data.cfcCode;
                $state.go('federationapplicationthankyou', {
                  cfcCode: cfcCode,
                  userId: data.userId,
                  applicationId: $scope.federationApplicationFee.applicationId,
                  paymentid: data.id,
                  paymenttype: 'FEDERATION',
                  paymentfeeType: 'Application',
                  paymentMethod: 'Credit Card',
                  paymentyear: $scope.currentYear
                });
              } else {
                var error = '';
                if (!data.error) {
                  error =
                    'Credit Card Transaction Failed. Contact Customer Care if the problem persists';
                } else if (data.error || data.statusCode === 'ERROR') {
                  error =
                    data.errorResponse.errorCode +
                    ' : ' +
                    data.errorResponse.errorMessage;
                }
                $scope.federationApplicationFee.expirationDate = '';
                $scope.fieldsMissing.push(error);
                $('#missingInfo').modal();
              }
            })
            .finally(function() {
              $scope.processingPayment = false;
            })
            .catch(function(error) {
              $scope.fieldsMissing.push(error.data);
              $('#missingInfo').modal();
            });
        }
      };
      $scope.showFederationApplicationFeeErrorMessage = function(error) {
        $scope.federationApplicationFeeError = error;
      };
      $scope.gotoAchPayment = function() {
        BrowserEventService.setCampaignId(
          $scope.federationApplicationFeeData.campaignId
        );
        BrowserEventService.setApplicationId(
          $scope.federationApplicationFeeData.federationId
        );
        BrowserEventService.setTotalMemberAppFee(
          $scope.federationApplicationFeeData.totalMemberApplicationsFee
        );
        BrowserEventService.setFedCfcCode(
          $scope.federationApplicationFeeData.cfcCode
        );
        BrowserEventService.setFederationName(
          $scope.federationApplicationFeeData.federationName
        );
        BrowserEventService.setTotalMemberApplications(
          $scope.federationApplicationFeeData.totalMemberApplications
        );
        BrowserEventService.setFederationApplicationFee(
          $scope.federationApplicationFeeData.federationApplicationFee
        );
        $state.go('federationapplicationachpayment', {
          cfcCode: $scope.federationApplicationFeeData.cfcCode,
          federationName: $scope.federationApplicationFeeData.federationName,
          applicationId: $scope.federationApplicationFeeData.federationId,
          totalMemberApplicationsFee:
            $scope.federationApplicationFeeData.totalMemberApplicationsFee,
          campaignId: $scope.federationApplicationFeeData.campaignId,
          federationApplicationFee:
            $scope.federationApplicationFeeData.federationApplicationFee
        });
      };
      $scope.gotoFedAppAchPaymentByCharityUser = function() {
        BrowserEventService.setCampaignId(
          $scope.federationApplicationFeeData.campaignId
        );
        BrowserEventService.setApplicationId(
          $scope.federationApplicationFeeData.federationId
        );
        BrowserEventService.setTotalMemberApplications(
          $scope.federationApplicationFeeData.totalMemberApplications
        );
        BrowserEventService.setFedCfcCode(
          $scope.federationApplicationFeeData.cfcCode
        );
        BrowserEventService.setFederationName(
          $scope.federationApplicationFeeData.federationName
        );
        BrowserEventService.setMemberApplicationFee(
          $scope.federationApplicationFeeData.memberApplicationFee
        );
        BrowserEventService.setFederationApplicationFee(
          $scope.federationApplicationFeeData.federationApplicationFee
        );
        $state.go('federationappachpaymentcharityuser', {
          cfcCode: $scope.federationApplicationFeeData.cfcCode,
          federationName: $scope.federationApplicationFeeData.federationName,
          applicationId: $scope.federationApplicationFeeData.federationId,
          totalMemberApplications:
            $scope.federationApplicationFeeData.totalMemberApplications,
          campaignId: $scope.federationApplicationFeeData.campaignId,
          memberApplicationFee:
            $scope.federationApplicationFeeData.memberApplicationFee,
          federationApplicationFee:
            $scope.federationApplicationFeeData.federationApplicationFee
        });
      };
      init();
    }
  ]);
